<template>
  <nav>
    <router-link to="/">Home</router-link>
    <router-link to="/research-team">Research & Team</router-link>
    <router-link to="/insurance">Insurance</router-link>
    <router-link to="/wealth-management">Wealth Management</router-link>
    <router-link to="/creative-community">Creative Community</router-link>
    <router-link to="/privacy-policy">Privacy Policy</router-link>
    <router-link to="/terms">Terms</router-link>
  </nav>
  <div class="address-copyright">
    <address>
      215 Spadina Ave, Floor 4<br />
      Toronto, ON M5T 2C7
    </address>
    <div class="copyright-notice">
      © Wondeur AI Inc. 2024 all rights reserved
    </div>
  </div>
</template>

<style scoped>
  nav {
    display: flex;
    flex-wrap: wrap;
    gap: calc(0.25vh + 0.25vw + 1rem);
    margin: calc(2.5vh + 2.5vw) 0;
  }
  
  a {
    color: var(--white-text-colour);
    text-decoration: none;
    &.router-link-active {
      text-decoration: underline;
    }
  }
  
  address {
    font-style: normal;
    font-size: calc(0.425vh + 0.425vw + 0.125rem);
  }
  
  .copyright-notice {
    font-size: calc(0.425vh + 0.425vw + 0.125rem);
  }
  
  .address-copyright {
    display: flex;
    flex-direction: column;
    gap: calc(2.5vh + 2.5vw);
    @media (min-width: 1000px) {
      flex-direction: row;
      align-content: flex-end;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
</style>