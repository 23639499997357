import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/HomeView.vue'),
    },
    {
      path: '/research-team',
      name: 'research-team',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('@/views/ResearchTeamView.vue'),
    },
    {
      path: '/request-access',
      redirect: '/insurance'
    },
    {
      path: '/insurance',
      name: 'insurance',
      component: () => import('@/views/InsuranceView.vue'),
    },
    {
      path: '/wealth-management',
      name: 'wealth-management',
      component: () => import('@/views/WealthManagementView.vue'),
    },
    {
      path: '/creative-community',
      name: 'creative-community',
      component: () => import('@/views/CreativeCommunityView.vue'),
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/views/PrivacyPolicyView.vue'),
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('@/views/TermsView.vue'),
    },
    {
      path: '/:catchAll(.*)*',
      component: () => import('@/views/NotFoundView.vue'),
    },
  ],
});

export default router;
