<script setup lang="ts">
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';
import type { Ref } from 'vue';

const state: Ref<'nav-opened' | 'nav-closed'> = ref('nav-closed');

const toggleNav = () => {
  state.value = state.value === 'nav-opened' ? 'nav-closed' : 'nav-opened';
};

const route = useRoute();

watch(
  () => route.path,
  () => {
    state.value = 'nav-closed';
  },
);
</script>

<template>
  <button :class="state" @click="toggleNav">
    <span class="sr-only">Toggle Main Navigation</span>
    <span class="line line-1"></span>
    <span class="line line-2"></span>
    <span class="line line-3"></span>
  </button>
  <nav :class="state">
    <router-link to="/research-team">Research & Team</router-link>
    <router-link to="/insurance">Insurance</router-link>
    <router-link to="/wealth-management">Wealth Management</router-link>
    <router-link to="/creative-community">Creative Community</router-link>
    <a href="https://app.wondeur.ai">Login</a>
  </nav>
</template>

<style scoped>
nav {
  display: flex;
  flex-direction: column;
  text-align: right;
  position: absolute;
  top: calc(3vh + 3vw + 1rem);
  left: 0;
  right: 0;
  font-size: calc(0.25vh + 0.25vw + 0.75rem);
  transform: scaleY(0);
  opacity: 0.125;
  transition:
    transform 350ms cubic-bezier(0.45, 0, 0.55, 1),
    opacity 350ms cubic-bezier(0.45, 0, 0.55, 1);
  transform-origin: top;
  padding: 0 env(safe-area-inset-left) calc(1.125vh + 1.125vw) env(safe-area-inset-right);
  background-color: rgba(var(--main-bg-colour), 0.95);
  &.nav-opened {
    transform: scaleY(1);
    opacity: 1;
  }
  @media (min-width: 1000px) {
    transform: scaleY(1);
    opacity: 1;
    flex-direction: row;
    gap: calc(0.25vh + 0.25vw + 1rem);
    width: auto;
    position: static;
    font-size: calc(0.25vh + 0.25vw + 0.675rem);
    background-color: transparent;
    padding: 0;
    white-space: nowrap;
  }
}
button {
  cursor: pointer;
  height: calc(0.625vh + 0.625vw + 0.625rem);
  aspect-ratio: 1.25;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 0;
  border: none;
  appearance: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 1000px) {
    display: none;
  }
}
.line {
  background-color: var(--main-text-colour);
  height: calc(0.025vh + 0.025vw + 0.125rem);
  width: 100%;
  display: inline-block;
  transition: transform 350ms cubic-bezier(0.45, 0, 0.55, 1);
}
.nav-opened .line-1 {
  transform: rotate(45deg) translateY(calc(-0.0125vh + -0.0125vw + -0.0125rem));
  transform-origin: top left;
}
.nav-opened .line-2 {
  transform: scale(0);
}
.nav-opened .line-3 {
  transform: rotate(-45deg) translateY(calc(-0.0125vh + -0.0125vw + -0.0125rem));
  transform-origin: top left;
}
a {
  text-decoration: none;
  padding: 0.5rem calc(2.25vh + 2.25vw);
  width: 100%;
  @media (min-width: 1000px) {
    padding: 0;
  }
}
a:hover,
a.router-link-active {
  text-decoration: underline;
}
</style>
