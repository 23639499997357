export const logoData = {
	v: '5.1.13',
	fr: 25,
	ip: 0,
	op: 159,
	w: 1920,
	h: 1080,
	nm: 'LOGO',
	ddd: 0,
	assets: [
		{
			id: 'comp_0',
			layers: [
				{
					ddd: 0,
					ind: 1,
					ty: 4,
					nm: 'w',
					sr: 1,
					ks: {
						o: {
							a: 1,
							k: [
								{ t: 0, s: [100], h: 1 },
								{ t: 2, s: [0], h: 1 },
								{ t: 4, s: [100], h: 1 },
								{ t: 6, s: [0], h: 1 },
								{ t: 8, s: [100], h: 1 },
							],
							ix: 11,
						},
						r: { a: 0, k: 0, ix: 10 },
						p: { a: 1, k: [{ i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 18, s: [1223.198, 561.904, 0], e: [726.198, 561.904, 0], to: [-82.8333358764648, 0, 0], ti: [82.8333358764648, 0, 0] }, { t: 38 }], ix: 2 },
						a: { a: 0, k: [46.288, -30.212, 0], ix: 1 },
						s: { a: 0, k: [100, 100, 100], ix: 6 },
					},
					ao: 0,
					shapes: [
						{
							ty: 'gr',
							it: [
								{
									ind: 0,
									ty: 'sh',
									ix: 1,
									ks: {
										a: 1,
										k: [
											{
												i: { x: 0.833, y: 1 },
												o: { x: 0.167, y: 0 },
												n: '0p833_1_0p167_0',
												t: 8,
												s: [
													{
														i: [
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[-0.077, 0.231],
															[0, 0],
															[0.54, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.347, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.27, 0],
															[0, 0],
															[-0.01, -0.515],
														],
														o: [
															[0.077, 0.231],
															[0, 0],
															[0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.347, 0],
															[0, 0],
															[-0.023, -0.553],
															[0, 0],
															[-0.424, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.54, 0],
															[0, 0],
														],
														v: [
															[40.325, 1.109],
															[40.98, 1.494],
															[41.173, 1.494],
															[41.789, 1.486],
															[46.5, 1.509],
															[46.539, 1.509],
															[51.27, 1.479],
															[51.925, 1.494],
															[52.079, 1.494],
															[52.773, 1.109],
															[52.778, -13.74],
															[52.2, -14.626],
															[50.737, -14.626],
															[49.55, -14.674],
															[46.999, -14.677],
															[46.961, -14.677],
															[47.818, -14.706],
															[46.684, -14.693],
															[46.453, -14.693],
															[45.479, -14.706],
															[45.94, -14.715],
															[45.902, -14.715],
															[43.314, -14.68],
															[42.625, -14.616],
															[40.883, -14.616],
															[40.343, -13.73],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[-0.077, 0.231],
															[0, 0],
															[0.54, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.347, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.27, 0],
															[0, 0],
															[-0.01, -0.515],
														],
														o: [
															[0.077, 0.231],
															[0, 0],
															[0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.347, 0],
															[0, 0],
															[-0.023, -0.553],
															[0, 0],
															[-0.424, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.54, 0],
															[0, 0],
														],
														v: [
															[40.235, -22.516],
															[40.89, -22.131],
															[41.083, -22.131],
															[41.699, -22.139],
															[46.41, -22.116],
															[46.449, -22.116],
															[51.18, -22.146],
															[51.835, -22.131],
															[51.99, -22.131],
															[52.683, -22.516],
															[52.688, -37.365],
															[52.11, -38.251],
															[50.647, -38.251],
															[49.46, -38.299],
															[46.91, -38.302],
															[46.871, -38.302],
															[47.728, -38.331],
															[46.594, -38.318],
															[46.363, -38.318],
															[45.389, -38.331],
															[45.85, -38.34],
															[45.812, -38.34],
															[43.224, -38.305],
															[42.535, -38.241],
															[40.793, -38.241],
															[40.253, -37.355],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.667, y: 1 },
												o: { x: 0.333, y: 0 },
												n: '0p667_1_0p333_0',
												t: 13,
												s: [
													{
														i: [
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[-0.077, 0.231],
															[0, 0],
															[0.54, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.347, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.27, 0],
															[0, 0],
															[-0.01, -0.515],
														],
														o: [
															[0.077, 0.231],
															[0, 0],
															[0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.347, 0],
															[0, 0],
															[-0.023, -0.553],
															[0, 0],
															[-0.424, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.54, 0],
															[0, 0],
														],
														v: [
															[40.235, -22.516],
															[40.89, -22.131],
															[41.083, -22.131],
															[41.699, -22.139],
															[46.41, -22.116],
															[46.449, -22.116],
															[51.18, -22.146],
															[51.835, -22.131],
															[51.99, -22.131],
															[52.683, -22.516],
															[52.688, -37.365],
															[52.11, -38.251],
															[50.647, -38.251],
															[49.46, -38.299],
															[46.91, -38.302],
															[46.871, -38.302],
															[47.728, -38.331],
															[46.594, -38.318],
															[46.363, -38.318],
															[45.389, -38.331],
															[45.85, -38.34],
															[45.812, -38.34],
															[43.224, -38.305],
															[42.535, -38.241],
															[40.793, -38.241],
															[40.253, -37.355],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.038, -2.018],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[-0.09, -2.166],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.796, 1.479],
															[46.261, 1.568],
															[46.412, 1.568],
															[64.957, 1.452],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[70.868, -58.2],
															[68.602, -61.675],
															[62.867, -61.675],
															[58.213, -61.862],
															[48.217, -61.873],
															[48.066, -61.873],
															[51.424, -61.987],
															[46.98, -61.935],
															[46.074, -61.935],
															[42.257, -61.987],
															[44.066, -62.024],
															[43.915, -62.024],
															[33.771, -61.885],
															[31.072, -61.636],
															[24.243, -61.636],
															[22.128, -58.161],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.667, y: 1 },
												o: { x: 0.333, y: 0 },
												n: '0p667_1_0p333_0',
												t: 18,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.038, -2.018],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[-0.09, -2.166],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.796, 1.479],
															[46.261, 1.568],
															[46.412, 1.568],
															[64.957, 1.452],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[70.868, -58.2],
															[68.602, -61.675],
															[62.867, -61.675],
															[58.213, -61.862],
															[48.217, -61.873],
															[48.066, -61.873],
															[51.424, -61.987],
															[46.98, -61.935],
															[46.074, -61.935],
															[42.257, -61.987],
															[44.066, -62.024],
															[43.915, -62.024],
															[33.771, -61.885],
															[31.072, -61.636],
															[24.243, -61.636],
															[22.128, -58.161],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.038, -2.018],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[-0.09, -2.166],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.796, 1.479],
															[46.261, 1.568],
															[46.412, 1.568],
															[64.957, 1.452],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[70.868, -58.2],
															[68.602, -61.675],
															[62.867, -61.675],
															[58.213, -61.862],
															[48.217, -61.873],
															[48.066, -61.873],
															[51.424, -61.987],
															[46.98, -61.935],
															[46.074, -61.935],
															[42.257, -61.987],
															[44.066, -62.024],
															[43.915, -62.024],
															[33.771, -61.885],
															[31.072, -61.636],
															[24.243, -61.636],
															[22.128, -58.161],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.999, y: 1 },
												o: { x: 0.333, y: 0 },
												n: '0p999_1_0p333_0',
												t: 36,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.038, -2.018],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[-0.09, -2.166],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.796, 1.479],
															[46.261, 1.568],
															[46.412, 1.568],
															[64.957, 1.452],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[70.868, -58.2],
															[68.602, -61.675],
															[62.867, -61.675],
															[58.213, -61.862],
															[48.217, -61.873],
															[48.066, -61.873],
															[51.424, -61.987],
															[46.98, -61.935],
															[46.074, -61.935],
															[42.257, -61.987],
															[44.066, -62.024],
															[43.915, -62.024],
															[33.771, -61.885],
															[31.072, -61.636],
															[24.243, -61.636],
															[22.128, -58.161],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.182],
															[46.376, -40.182],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.256, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.076],
															[65.561, -25.076],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.342, -25.227],
															[27.191, -25.227],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.194, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.001, y: 0 },
												n: '0p5_1_0p001_0',
												t: 40,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.182],
															[46.376, -40.182],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.256, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.076],
															[65.561, -25.076],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.342, -25.227],
															[27.191, -25.227],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.194, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.36, 0.901],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.901, -2.163],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.36, -0.901],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-1.074, -1.953],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.36, 0.901],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[1.099, -1.925],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.36, -0.901],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.36, -1.262],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, -0.105],
															[46.218, -48.243],
															[46.369, -48.243],
															[64.957, -0.1],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[91.931, -56.709],
															[89.665, -60.183],
															[83.743, -60.183],
															[79.055, -57.985],
															[69.09, -17.972],
															[68.938, -17.972],
															[49.186, -60.123],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.747, -60.123],
															[24.113, -18.123],
															[23.962, -18.123],
															[13.846, -57.98],
															[11.123, -60.191],
															[2.709, -60.191],
															[0.594, -56.717],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 42,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.36, 0.901],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.901, -2.163],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.36, -0.901],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-1.074, -1.953],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.36, 0.901],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[1.099, -1.925],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.36, -0.901],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.36, -1.262],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, -0.105],
															[46.218, -48.243],
															[46.369, -48.243],
															[64.957, -0.1],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[91.931, -56.709],
															[89.665, -60.183],
															[83.743, -60.183],
															[79.055, -57.985],
															[69.09, -17.972],
															[68.938, -17.972],
															[49.186, -60.123],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.747, -60.123],
															[24.113, -18.123],
															[23.962, -18.123],
															[13.846, -57.98],
															[11.123, -60.191],
															[2.709, -60.191],
															[0.594, -56.717],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.281, 0.702],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.702, -1.684],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.281, -0.702],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.845, -1.968],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.281, 0.702],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.836, -1.978],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.281, -0.702],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.281, -0.982],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.245],
															[46.228, -37.217],
															[46.379, -37.217],
															[64.957, 0.243],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[87.268, -57.039],
															[85.003, -60.514],
															[79.122, -60.514],
															[74.441, -58.843],
															[64.469, -27.69],
															[64.318, -27.69],
															[49.682, -60.536],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.417, -60.536],
															[28.53, -27.841],
															[28.379, -27.841],
															[18.256, -58.845],
															[15.538, -60.511],
															[7.476, -60.511],
															[5.361, -57.036],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 46,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.281, 0.702],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.702, -1.684],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.281, -0.702],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.845, -1.968],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.281, 0.702],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.836, -1.978],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.281, -0.702],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.281, -0.982],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.245],
															[46.228, -37.217],
															[46.379, -37.217],
															[64.957, 0.243],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[87.268, -57.039],
															[85.003, -60.514],
															[79.122, -60.514],
															[74.441, -58.843],
															[64.469, -27.69],
															[64.318, -27.69],
															[49.682, -60.536],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.417, -60.536],
															[28.53, -27.841],
															[28.379, -27.841],
															[18.256, -58.845],
															[15.538, -60.511],
															[7.476, -60.511],
															[5.361, -57.036],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.31, 0.775],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.775, -1.86],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.31, -0.775],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.929, -1.962],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.31, 0.775],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.932, -1.959],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.31, -0.775],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.31, -1.085],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.116],
															[46.224, -41.273],
															[46.375, -41.273],
															[64.957, 0.117],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.984, -56.918],
															[86.718, -60.392],
															[80.822, -60.392],
															[76.139, -58.527],
															[66.169, -24.115],
															[66.018, -24.115],
															[49.499, -60.384],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.538, -60.384],
															[26.905, -24.266],
															[26.754, -24.266],
															[16.634, -58.527],
															[13.914, -60.393],
															[5.722, -60.393],
															[3.607, -56.919],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 50,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.31, 0.775],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.775, -1.86],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.31, -0.775],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.929, -1.962],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.31, 0.775],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.932, -1.959],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.31, -0.775],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.31, -1.085],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.116],
															[46.224, -41.273],
															[46.375, -41.273],
															[64.957, 0.117],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.984, -56.918],
															[86.718, -60.392],
															[80.822, -60.392],
															[76.139, -58.527],
															[66.169, -24.115],
															[66.018, -24.115],
															[49.499, -60.384],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.538, -60.384],
															[26.905, -24.266],
															[26.754, -24.266],
															[16.634, -58.527],
															[13.914, -60.393],
															[5.722, -60.393],
															[3.607, -56.919],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.299, 0.748],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.748, -1.795],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.299, -0.748],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.898, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.299, 0.748],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.897, -1.966],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.299, -0.748],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.299, -1.047],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.164],
															[46.225, -39.781],
															[46.376, -39.781],
															[64.957, 0.164],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.353, -56.962],
															[86.087, -60.437],
															[80.197, -60.437],
															[75.514, -58.643],
															[65.544, -25.43],
															[65.393, -25.43],
															[49.566, -60.44],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.494, -60.44],
															[27.503, -25.581],
															[27.352, -25.581],
															[17.231, -58.644],
															[14.512, -60.436],
															[6.367, -60.436],
															[4.252, -56.962],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 54,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.299, 0.748],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.748, -1.795],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.299, -0.748],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.898, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.299, 0.748],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.897, -1.966],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.299, -0.748],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.299, -1.047],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.164],
															[46.225, -39.781],
															[46.376, -39.781],
															[64.957, 0.164],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.353, -56.962],
															[86.087, -60.437],
															[80.197, -60.437],
															[75.514, -58.643],
															[65.544, -25.43],
															[65.393, -25.43],
															[49.566, -60.44],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.494, -60.44],
															[27.503, -25.581],
															[27.352, -25.581],
															[17.231, -58.644],
															[14.512, -60.436],
															[6.367, -60.436],
															[4.252, -56.962],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.303, 0.758],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.758, -1.819],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.303, -0.758],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.909, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.303, 0.758],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.91, -1.963],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.303, -0.758],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.303, -1.061],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.146],
															[46.225, -40.33],
															[46.376, -40.33],
															[64.957, 0.146],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.585, -56.946],
															[86.319, -60.42],
															[80.427, -60.42],
															[75.744, -58.601],
															[65.774, -24.946],
															[65.623, -24.946],
															[49.542, -60.419],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.51, -60.419],
															[27.283, -25.097],
															[27.132, -25.097],
															[17.011, -58.6],
															[14.292, -60.421],
															[6.13, -60.421],
															[4.015, -56.946],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 58,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.303, 0.758],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.758, -1.819],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.303, -0.758],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.909, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.303, 0.758],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.91, -1.963],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.303, -0.758],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.303, -1.061],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.146],
															[46.225, -40.33],
															[46.376, -40.33],
															[64.957, 0.146],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.585, -56.946],
															[86.319, -60.42],
															[80.427, -60.42],
															[75.744, -58.601],
															[65.774, -24.946],
															[65.623, -24.946],
															[49.542, -60.419],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.51, -60.419],
															[27.283, -25.097],
															[27.132, -25.097],
															[17.011, -58.6],
															[14.292, -60.421],
															[6.13, -60.421],
															[4.015, -56.946],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.754],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.754, -1.81],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.754],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.905, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.754],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.905, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.754],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.056],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.153],
															[46.225, -40.128],
															[46.376, -40.128],
															[64.957, 0.153],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.499, -56.952],
															[86.233, -60.426],
															[80.342, -60.426],
															[75.659, -58.616],
															[65.689, -25.124],
															[65.538, -25.124],
															[49.551, -60.427],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.504, -60.427],
															[27.364, -25.275],
															[27.213, -25.275],
															[17.092, -58.616],
															[14.373, -60.426],
															[6.217, -60.426],
															[4.102, -56.952],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 62,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.754],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.754, -1.81],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.754],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.905, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.754],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.905, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.754],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.056],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.153],
															[46.225, -40.128],
															[46.376, -40.128],
															[64.957, 0.153],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.499, -56.952],
															[86.233, -60.426],
															[80.342, -60.426],
															[75.659, -58.616],
															[65.689, -25.124],
															[65.538, -25.124],
															[49.551, -60.427],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.504, -60.427],
															[27.364, -25.275],
															[27.213, -25.275],
															[17.092, -58.616],
															[14.373, -60.426],
															[6.217, -60.426],
															[4.102, -56.952],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.756],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.756, -1.814],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.756],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.907, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.756],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.907, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.756],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.058],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.15],
															[46.225, -40.202],
															[46.376, -40.202],
															[64.957, 0.15],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.531, -56.95],
															[86.265, -60.424],
															[80.373, -60.424],
															[75.69, -58.611],
															[65.72, -25.059],
															[65.569, -25.059],
															[49.547, -60.424],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.424],
															[27.334, -25.21],
															[27.183, -25.21],
															[17.062, -58.61],
															[14.343, -60.424],
															[6.185, -60.424],
															[4.07, -56.95],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 66,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.756],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.756, -1.814],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.756],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.907, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.756],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.907, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.756],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.058],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.15],
															[46.225, -40.202],
															[46.376, -40.202],
															[64.957, 0.15],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.531, -56.95],
															[86.265, -60.424],
															[80.373, -60.424],
															[75.69, -58.611],
															[65.72, -25.059],
															[65.569, -25.059],
															[49.547, -60.424],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.424],
															[27.334, -25.21],
															[27.183, -25.21],
															[17.062, -58.61],
															[14.343, -60.424],
															[6.185, -60.424],
															[4.07, -56.95],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.812],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.175],
															[46.376, -40.175],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.519, -56.951],
															[86.253, -60.425],
															[80.362, -60.425],
															[75.679, -58.613],
															[65.709, -25.083],
															[65.558, -25.083],
															[49.549, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.345, -25.234],
															[27.194, -25.234],
															[17.073, -58.613],
															[14.354, -60.425],
															[6.197, -60.425],
															[4.082, -56.951],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 70,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.812],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.175],
															[46.376, -40.175],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.519, -56.951],
															[86.253, -60.425],
															[80.362, -60.425],
															[75.679, -58.613],
															[65.709, -25.083],
															[65.558, -25.083],
															[49.549, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.345, -25.234],
															[27.194, -25.234],
															[17.073, -58.613],
															[14.354, -60.425],
															[6.197, -60.425],
															[4.082, -56.951],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.185],
															[46.376, -40.185],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.523, -56.95],
															[86.258, -60.425],
															[80.366, -60.425],
															[75.683, -58.612],
															[65.713, -25.074],
															[65.562, -25.074],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.341, -25.225],
															[27.19, -25.225],
															[17.069, -58.612],
															[14.35, -60.425],
															[6.192, -60.425],
															[4.077, -56.95],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 74,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.185],
															[46.376, -40.185],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.523, -56.95],
															[86.258, -60.425],
															[80.366, -60.425],
															[75.683, -58.612],
															[65.713, -25.074],
															[65.562, -25.074],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.341, -25.225],
															[27.19, -25.225],
															[17.069, -58.612],
															[14.35, -60.425],
															[6.192, -60.425],
															[4.077, -56.95],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.182],
															[46.376, -40.182],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.256, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.077],
															[65.561, -25.077],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.343, -25.228],
															[27.192, -25.228],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.194, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 78,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.182],
															[46.376, -40.182],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.256, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.077],
															[65.561, -25.077],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.343, -25.228],
															[27.192, -25.228],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.194, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.183],
															[46.376, -40.183],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.257, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.076],
															[65.561, -25.076],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.342, -25.227],
															[27.191, -25.227],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.193, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 82,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.183],
															[46.376, -40.183],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.257, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.076],
															[65.561, -25.076],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.342, -25.227],
															[27.191, -25.227],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.193, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.182],
															[46.376, -40.182],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.256, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.076],
															[65.561, -25.076],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.342, -25.227],
															[27.191, -25.227],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.194, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 86,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.182],
															[46.376, -40.182],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.256, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.076],
															[65.561, -25.076],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.342, -25.227],
															[27.191, -25.227],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.194, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.183],
															[46.376, -40.183],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.256, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.076],
															[65.561, -25.076],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.342, -25.227],
															[27.191, -25.227],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.194, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 90,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.183],
															[46.376, -40.183],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.256, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.076],
															[65.561, -25.076],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.342, -25.227],
															[27.191, -25.227],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.194, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.182],
															[46.376, -40.182],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.256, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.076],
															[65.561, -25.076],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.342, -25.227],
															[27.191, -25.227],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.194, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.833, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p833_1_0p5_0',
												t: 94,
												s: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.182],
															[46.376, -40.182],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.256, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.076],
															[65.561, -25.076],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.342, -25.227],
															[27.191, -25.227],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.194, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0.755, -1.813],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.302, -0.755],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.906, -1.964],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.302, 0.755],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0.906, -1.964],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -0.755],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.302, -1.057],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.795, 0.151],
															[46.225, -40.182],
															[46.376, -40.182],
															[64.957, 0.151],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[88.522, -56.95],
															[86.256, -60.425],
															[80.365, -60.425],
															[75.682, -58.612],
															[65.712, -25.076],
															[65.561, -25.076],
															[49.548, -60.425],
															[46.98, -61.935],
															[46.074, -61.935],
															[43.506, -60.425],
															[27.342, -25.227],
															[27.191, -25.227],
															[17.07, -58.612],
															[14.351, -60.425],
															[6.194, -60.425],
															[4.079, -56.95],
														],
														c: true,
													},
												],
											},
											{ t: 98 },
										],
										ix: 2,
									},
									nm: 'w',
									mn: 'ADBE Vector Shape - Group',
									hd: false,
								},
								{ ty: 'fl', c: { a: 0, k: [0.090196078431, 0.16862745098, 0.301960784314, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
								{ ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
							],
							nm: 'w',
							np: 3,
							cix: 2,
							ix: 1,
							mn: 'ADBE Vector Group',
							hd: false,
						},
					],
					ip: 0,
					op: 79,
					st: 0,
					bm: 0,
				},
				{
					ddd: 0,
					ind: 2,
					ty: 4,
					nm: 'MATTE',
					td: 1,
					sr: 1,
					ks: {
						o: {
							a: 1,
							k: [
								{ t: 0, s: [100], h: 1 },
								{ t: 2, s: [0], h: 1 },
								{ t: 4, s: [100], h: 1 },
								{ t: 6, s: [0], h: 1 },
								{ t: 8, s: [100], h: 1 },
							],
							ix: 11,
						},
						r: { a: 0, k: 0, ix: 10 },
						p: { a: 1, k: [{ i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 18, s: [1223.198, 561.904, 0], e: [726.198, 561.904, 0], to: [-82.8333358764648, 0, 0], ti: [82.8333358764648, 0, 0] }, { i: { x: 0.1, y: 0.1 }, o: { x: 0.9, y: 0.9 }, n: '0p1_0p1_0p9_0p9', t: 38, s: [726.198, 561.904, 0], e: [726.198, 561.904, 0], to: [0, 0, 0], ti: [0, 0, 0] }, { i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 78, s: [726.198, 561.904, 0], e: [1223.198, 561.904, 0], to: [82.8333358764648, 0, 0], ti: [-82.8333358764648, 0, 0] }, { t: 98 }], ix: 2 },
						a: { a: 0, k: [46.288, -30.212, 0], ix: 1 },
						s: { a: 0, k: [100, 103, 100], ix: 6 },
					},
					ao: 0,
					shapes: [
						{
							ty: 'gr',
							it: [
								{
									ind: 0,
									ty: 'sh',
									ix: 1,
									ks: {
										a: 0,
										k: {
											i: [
												[0, 0],
												[0, 0],
												[0, 0],
												[0, 0],
											],
											o: [
												[0, 0],
												[0, 0],
												[0, 0],
												[0, 0],
											],
											v: [
												[247, -31.688],
												[247, 31.688],
												[-651, 31.491],
												[-651, -31.884],
											],
											c: true,
										},
										ix: 2,
									},
									nm: 'Path 1',
									mn: 'ADBE Vector Shape - Group',
									hd: false,
								},
								{ ty: 'fl', c: { a: 0, k: [0.090196078431, 0.16862745098, 0.301960784314, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
								{ ty: 'tr', p: { a: 0, k: [-201.229, -30.179], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
							],
							nm: 'Rectangle 1',
							np: 3,
							cix: 2,
							ix: 1,
							mn: 'ADBE Vector Group',
							hd: false,
						},
						{
							ty: 'gr',
							it: [
								{
									ind: 0,
									ty: 'sh',
									ix: 1,
									ks: {
										a: 1,
										k: [
											{
												i: { x: 0.833, y: 1 },
												o: { x: 0.167, y: 0 },
												n: '0p833_1_0p167_0',
												t: 8,
												s: [
													{
														i: [
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[-0.077, 0.231],
															[0, 0],
															[0.54, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.347, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.27, 0],
															[0, 0],
															[-0.01, -0.515],
														],
														o: [
															[0.077, 0.231],
															[0, 0],
															[0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.347, 0],
															[0, 0],
															[-0.023, -0.553],
															[0, 0],
															[-0.424, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.54, 0],
															[0, 0],
														],
														v: [
															[40.325, 1.109],
															[40.98, 1.494],
															[41.173, 1.494],
															[41.789, 1.486],
															[46.5, 1.509],
															[46.539, 1.509],
															[51.27, 1.479],
															[51.925, 1.494],
															[52.079, 1.494],
															[52.773, 1.109],
															[52.778, -13.74],
															[52.2, -14.626],
															[50.737, -14.626],
															[49.55, -14.674],
															[46.999, -14.677],
															[46.961, -14.677],
															[47.818, -14.706],
															[46.684, -14.693],
															[46.453, -14.693],
															[45.479, -14.706],
															[45.94, -14.715],
															[45.902, -14.715],
															[43.314, -14.68],
															[42.625, -14.616],
															[40.883, -14.616],
															[40.343, -13.73],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[-0.077, 0.231],
															[0, 0],
															[0.54, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.347, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.27, 0],
															[0, 0],
															[-0.01, -0.515],
														],
														o: [
															[0.077, 0.231],
															[0, 0],
															[0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.347, 0],
															[0, 0],
															[-0.023, -0.553],
															[0, 0],
															[-0.424, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.54, 0],
															[0, 0],
														],
														v: [
															[40.235, -22.516],
															[40.89, -22.131],
															[41.083, -22.131],
															[41.699, -22.139],
															[46.41, -22.116],
															[46.449, -22.116],
															[51.18, -22.146],
															[51.835, -22.131],
															[51.99, -22.131],
															[52.683, -22.516],
															[52.688, -37.365],
															[52.11, -38.251],
															[50.647, -38.251],
															[49.46, -38.299],
															[46.91, -38.302],
															[46.871, -38.302],
															[47.728, -38.331],
															[46.594, -38.318],
															[46.363, -38.318],
															[45.389, -38.331],
															[45.85, -38.34],
															[45.812, -38.34],
															[43.224, -38.305],
															[42.535, -38.241],
															[40.793, -38.241],
															[40.253, -37.355],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.667, y: 1 },
												o: { x: 0.333, y: 0 },
												n: '0p667_1_0p333_0',
												t: 13,
												s: [
													{
														i: [
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[-0.077, 0.231],
															[0, 0],
															[0.54, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.347, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.27, 0],
															[0, 0],
															[-0.01, -0.515],
														],
														o: [
															[0.077, 0.231],
															[0, 0],
															[0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0.347, 0],
															[0, 0],
															[-0.023, -0.553],
															[0, 0],
															[-0.424, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.308, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-0.54, 0],
															[0, 0],
														],
														v: [
															[40.235, -22.516],
															[40.89, -22.131],
															[41.083, -22.131],
															[41.699, -22.139],
															[46.41, -22.116],
															[46.449, -22.116],
															[51.18, -22.146],
															[51.835, -22.131],
															[51.99, -22.131],
															[52.683, -22.516],
															[52.688, -37.365],
															[52.11, -38.251],
															[50.647, -38.251],
															[49.46, -38.299],
															[46.91, -38.302],
															[46.871, -38.302],
															[47.728, -38.331],
															[46.594, -38.318],
															[46.363, -38.318],
															[45.389, -38.331],
															[45.85, -38.34],
															[45.812, -38.34],
															[43.224, -38.305],
															[42.535, -38.241],
															[40.793, -38.241],
															[40.253, -37.355],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[-0.302, 0.906],
															[0, 0],
															[2.115, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.057, 0],
															[0, 0],
															[-0.038, -2.018],
														],
														o: [
															[0.302, 0.906],
															[0, 0],
															[1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[1.36, 0],
															[0, 0],
															[-0.09, -2.166],
															[0, 0],
															[-1.662, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-1.208, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[0, 0],
															[-2.115, 0],
															[0, 0],
														],
														v: [
															[22.055, 0],
															[24.623, 1.511],
															[25.378, 1.511],
															[27.796, 1.479],
															[46.261, 1.568],
															[46.412, 1.568],
															[64.957, 1.452],
															[67.525, 1.511],
															[68.129, 1.511],
															[70.848, 0],
															[70.868, -58.2],
															[68.602, -61.675],
															[62.867, -61.675],
															[58.213, -61.862],
															[48.217, -61.873],
															[48.066, -61.873],
															[51.424, -61.987],
															[46.98, -61.935],
															[46.074, -61.935],
															[42.257, -61.987],
															[44.066, -62.024],
															[43.915, -62.024],
															[33.771, -61.885],
															[31.072, -61.636],
															[24.243, -61.636],
															[22.128, -58.161],
														],
														c: true,
													},
												],
											},
											{ t: 18 },
										],
										ix: 2,
									},
									nm: 'w',
									mn: 'ADBE Vector Shape - Group',
									hd: false,
								},
								{ ty: 'fl', c: { a: 0, k: [0.090196078431, 0.16862745098, 0.301960784314, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
								{ ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
							],
							nm: 'w',
							np: 3,
							cix: 2,
							ix: 2,
							mn: 'ADBE Vector Group',
							hd: false,
						},
					],
					ip: 18,
					op: 79,
					st: 0,
					bm: 0,
				},
				{ ddd: 0, ind: 3, ty: 0, nm: 'logo_precomp_text', tt: 2, refId: 'comp_1', sr: 1, ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 540, 0], ix: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } }, ao: 0, w: 1920, h: 1080, ip: 18, op: 79, st: 0, bm: 0 },
			],
		},
		{
			id: 'comp_1',
			layers: [
				{
					ddd: 0,
					ind: 1,
					ty: 4,
					nm: 'o',
					sr: 1,
					ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 1, k: [{ i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 18, s: [447.66, 561.904, 0], e: [807.66, 561.904, 0], to: [60, 0, 0], ti: [-60, 0, 0] }, { i: { x: 0.1, y: 0.1 }, o: { x: 0.9, y: 0.9 }, n: '0p1_0p1_0p9_0p9', t: 38, s: [807.66, 561.904, 0], e: [807.66, 561.904, 0], to: [0, 0, 0], ti: [0, 0, 0] }, { i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 78, s: [807.66, 561.904, 0], e: [447.66, 561.904, 0], to: [-60, 0, 0], ti: [60, 0, 0] }, { t: 98 }], ix: 2 }, a: { a: 0, k: [127.75, -30.212, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
					ao: 0,
					shapes: [
						{
							ty: 'gr',
							it: [
								{
									ind: 0,
									ty: 'sh',
									ix: 1,
									ks: {
										a: 0,
										k: {
											i: [
												[0, -17.07],
												[-16.919, 0],
												[0, 17.372],
												[16.919, 0],
											],
											o: [
												[0, 17.372],
												[16.919, 0],
												[0, -17.07],
												[-16.919, 0],
											],
											v: [
												[97.236, -30.363],
												[127.75, 1.511],
												[158.265, -30.363],
												[127.75, -61.935],
											],
											c: true,
										},
										ix: 2,
									},
									nm: 'o',
									mn: 'ADBE Vector Shape - Group',
									hd: false,
								},
								{
									ind: 1,
									ty: 'sh',
									ix: 2,
									ks: {
										a: 0,
										k: {
											i: [
												[0, 10.423],
												[-9.517, 0],
												[0, -10.121],
												[9.517, 0],
											],
											o: [
												[0, -10.121],
												[9.517, 0],
												[0, 10.423],
												[-9.517, 0],
											],
											v: [
												[110.68, -30.363],
												[127.75, -48.793],
												[144.82, -30.363],
												[127.75, -11.481],
											],
											c: true,
										},
										ix: 2,
									},
									nm: 'o',
									mn: 'ADBE Vector Shape - Group',
									hd: false,
								},
								{ ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
								{ ty: 'fl', c: { a: 0, k: [0.090196078431, 0.16862745098, 0.301960784314, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
								{ ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
							],
							nm: 'o',
							np: 5,
							cix: 2,
							ix: 1,
							mn: 'ADBE Vector Group',
							hd: false,
						},
					],
					ip: 0,
					op: 165,
					st: 0,
					bm: 0,
				},
				{
					ddd: 0,
					ind: 2,
					ty: 4,
					nm: 'n',
					sr: 1,
					ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 1, k: [{ i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 18, s: [521.705, 561.149, 0], e: [881.705, 561.149, 0], to: [60, 0, 0], ti: [-60, 0, 0] }, { i: { x: 0.1, y: 0.1 }, o: { x: 0.9, y: 0.9 }, n: '0p1_0p1_0p9_0p9', t: 38, s: [881.705, 561.149, 0], e: [881.705, 561.149, 0], to: [0, 0, 0], ti: [0, 0, 0] }, { i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 78, s: [881.705, 561.149, 0], e: [521.705, 561.149, 0], to: [-60, 0, 0], ti: [60, 0, 0] }, { t: 98 }], ix: 2 }, a: { a: 0, k: [201.796, -30.968, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
					ao: 0,
					shapes: [
						{
							ty: 'gr',
							it: [
								{
									ind: 0,
									ty: 'sh',
									ix: 1,
									ks: {
										a: 0,
										k: {
											i: [
												[0, 0],
												[-1.511, 0],
												[0, 0],
												[0, 1.964],
												[0, 0],
												[-9.517, 0],
												[0, -9.668],
												[0, 0],
												[-1.662, 0],
												[0, 0],
												[0, 1.511],
												[0, 0],
												[18.43, 0],
												[1.057, -1.208],
												[0, 0],
												[1.208, 0],
												[0, 0],
												[0, -1.511],
											],
											o: [
												[0, 1.511],
												[0, 0],
												[2.568, 0],
												[0, 0],
												[0.755, -2.115],
												[7.855, 0],
												[0, 0],
												[0, 1.511],
												[0, 0],
												[1.511, 0],
												[0, 0],
												[0, -15.71],
												[-12.085, 0],
												[0, 0],
												[-0.302, -1.057],
												[0, 0],
												[-1.511, 0],
												[0, 0],
											],
											v: [
												[172.868, -2.87],
												[175.738, 0],
												[183.593, 0],
												[187.067, -2.87],
												[187.067, -39.729],
												[203.835, -48.793],
												[216.373, -33.838],
												[216.373, -2.87],
												[219.244, 0],
												[227.854, 0],
												[230.724, -2.87],
												[230.724, -33.234],
												[205.497, -61.935],
												[184.348, -53.476],
												[182.536, -58.612],
												[180.119, -60.425],
												[175.738, -60.425],
												[172.868, -57.555],
											],
											c: true,
										},
										ix: 2,
									},
									nm: 'n',
									mn: 'ADBE Vector Shape - Group',
									hd: false,
								},
								{ ty: 'fl', c: { a: 0, k: [0.090196078431, 0.16862745098, 0.301960784314, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
								{ ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
							],
							nm: 'n',
							np: 3,
							cix: 2,
							ix: 1,
							mn: 'ADBE Vector Group',
							hd: false,
						},
					],
					ip: 0,
					op: 165,
					st: 0,
					bm: 0,
				},
				{
					ddd: 0,
					ind: 3,
					ty: 4,
					nm: 'd',
					sr: 1,
					ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 1, k: [{ i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 18, s: [593.977, 540, 0], e: [953.977, 540, 0], to: [60, 0, 0], ti: [-60, 0, 0] }, { i: { x: 0.1, y: 0.1 }, o: { x: 0.9, y: 0.9 }, n: '0p1_0p1_0p9_0p9', t: 38, s: [953.977, 540, 0], e: [953.977, 540, 0], to: [0, 0, 0], ti: [0, 0, 0] }, { i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 78, s: [953.977, 540, 0], e: [593.977, 540, 0], to: [-60, 0, 0], ti: [60, 0, 0] }, { t: 98 }], ix: 2 }, a: { a: 0, k: [274.068, -52.116, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
					ao: 0,
					shapes: [
						{
							ty: 'gr',
							it: [
								{
									ind: 0,
									ty: 'sh',
									ix: 1,
									ks: {
										a: 1,
										k: [
											{
												i: { x: 0.999, y: 1 },
												o: { x: 0.9, y: 0 },
												n: '0p999_1_0p9_0',
												t: 27,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.558, -58.904],
															[300.688, -61.775],
															[291.926, -61.775],
															[289.056, -58.904],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.873],
															[300.579, -105.743],
															[291.817, -105.743],
															[288.947, -102.873],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.001, y: 0 },
												n: '0p5_1_0p001_0',
												t: 32,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.873],
															[300.579, -105.743],
															[291.817, -105.743],
															[288.947, -102.873],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.428, -111.362],
															[300.558, -114.232],
															[291.796, -114.232],
															[288.926, -111.362],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 34.5,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.428, -111.362],
															[300.558, -114.232],
															[291.796, -114.232],
															[288.926, -111.362],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.457, -99.75],
															[300.587, -102.621],
															[291.825, -102.621],
															[288.955, -99.75],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 39.5,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.457, -99.75],
															[300.587, -102.621],
															[291.825, -102.621],
															[288.955, -99.75],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.446, -104.022],
															[300.576, -106.892],
															[291.815, -106.892],
															[288.944, -104.022],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 44.5,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.446, -104.022],
															[300.576, -106.892],
															[291.815, -106.892],
															[288.944, -104.022],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.45, -102.451],
															[300.58, -105.321],
															[291.819, -105.321],
															[288.948, -102.451],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 49.5,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.45, -102.451],
															[300.58, -105.321],
															[291.819, -105.321],
															[288.948, -102.451],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -103.029],
															[300.579, -105.899],
															[291.817, -105.899],
															[288.947, -103.029],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 54.5,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -103.029],
															[300.579, -105.899],
															[291.817, -105.899],
															[288.947, -103.029],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.816],
															[300.579, -105.686],
															[291.818, -105.686],
															[288.947, -102.816],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 59.5,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.816],
															[300.579, -105.686],
															[291.818, -105.686],
															[288.947, -102.816],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.894],
															[300.579, -105.764],
															[291.817, -105.764],
															[288.947, -102.894],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 64.5,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.894],
															[300.579, -105.764],
															[291.817, -105.764],
															[288.947, -102.894],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.865],
															[300.579, -105.736],
															[291.818, -105.736],
															[288.947, -102.865],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.5, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p5_1_0p5_0',
												t: 69.5,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.865],
															[300.579, -105.736],
															[291.818, -105.736],
															[288.947, -102.865],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.876],
															[300.579, -105.746],
															[291.817, -105.746],
															[288.947, -102.876],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.833, y: 1 },
												o: { x: 0.5, y: 0 },
												n: '0p833_1_0p5_0',
												t: 74.5,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.876],
															[300.579, -105.746],
															[291.817, -105.746],
															[288.947, -102.876],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.873],
															[300.579, -105.743],
															[291.817, -105.743],
															[288.947, -102.873],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.833, y: 1 },
												o: { x: 0.167, y: 0 },
												n: '0p833_1_0p167_0',
												t: 78,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.873],
															[300.579, -105.743],
															[291.817, -105.743],
															[288.947, -102.873],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.873],
															[300.579, -105.743],
															[291.817, -105.743],
															[288.947, -102.873],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{
												i: { x: 0.833, y: 1 },
												o: { x: 0.167, y: 0 },
												n: '0p833_1_0p167_0',
												t: 82,
												s: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.449, -102.873],
															[300.579, -105.743],
															[291.817, -105.743],
															[288.947, -102.873],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
												e: [
													{
														i: [
															[0, -17.674],
															[-16.013, 0],
															[0, 0],
															[0, 0],
															[-1.36, 0],
															[0, 0],
															[0, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[7.704, 0],
														],
														o: [
															[0, 17.372],
															[11.934, 0],
															[0, 0],
															[0.453, 1.511],
															[0, 0],
															[1.511, 0],
															[0, 0],
															[0, -1.511],
															[0, 0],
															[-1.511, 0],
															[0, 0],
															[-1.36, -0.453],
															[-16.768, 0],
														],
														v: [
															[244.686, -30.212],
															[272.935, 1.511],
															[292.12, -6.949],
															[293.328, -2.568],
															[296.047, 0],
															[300.579, 0],
															[303.449, -2.87],
															[303.558, -58.904],
															[300.688, -61.775],
															[291.926, -61.775],
															[289.056, -58.904],
															[288.947, -58.612],
															[274.445, -61.935],
														],
														c: true,
													},
												],
											},
											{ t: 88 },
										],
										ix: 2,
									},
									nm: 'd',
									mn: 'ADBE Vector Shape - Group',
									hd: false,
								},
								{
									ind: 1,
									ty: 'sh',
									ix: 2,
									ks: {
										a: 0,
										k: {
											i: [
												[0, 10.121],
												[-10.121, 0],
												[0, 0],
												[0, 0],
												[9.215, 0],
											],
											o: [
												[0, -10.272],
												[7.855, 0],
												[0, 0],
												[-0.755, 1.662],
												[-9.668, 0],
											],
											v: [
												[258.735, -30.212],
												[275.956, -48.793],
												[289.098, -45.168],
												[289.098, -20.544],
												[274.747, -11.481],
											],
											c: true,
										},
										ix: 2,
									},
									nm: 'd',
									mn: 'ADBE Vector Shape - Group',
									hd: false,
								},
								{ ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
								{ ty: 'fl', c: { a: 0, k: [0.090196078431, 0.16862745098, 0.301960784314, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
								{ ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
							],
							nm: 'd',
							np: 5,
							cix: 2,
							ix: 1,
							mn: 'ADBE Vector Group',
							hd: false,
						},
					],
					ip: 0,
					op: 165,
					st: 0,
					bm: 0,
				},
				{
					ddd: 0,
					ind: 4,
					ty: 4,
					nm: 'e',
					sr: 1,
					ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 1, k: [{ i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 18, s: [667.098, 561.904, 0], e: [1027.098, 561.904, 0], to: [60, 0, 0], ti: [-60, 0, 0] }, { i: { x: 0.1, y: 0.1 }, o: { x: 0.9, y: 0.9 }, n: '0p1_0p1_0p9_0p9', t: 38, s: [1027.098, 561.904, 0], e: [1027.098, 561.904, 0], to: [0, 0, 0], ti: [0, 0, 0] }, { i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 78, s: [1027.098, 561.904, 0], e: [667.098, 561.904, 0], to: [-60, 0, 0], ti: [60, 0, 0] }, { t: 98 }], ix: 2 }, a: { a: 0, k: [347.189, -30.212, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
					ao: 0,
					shapes: [
						{
							ty: 'gr',
							it: [
								{
									ind: 0,
									ty: 'sh',
									ix: 1,
									ks: {
										a: 0,
										k: {
											i: [
												[0, -17.07],
												[-18.279, 0],
												[-4.381, 3.777],
												[1.057, 1.208],
												[0, 0],
												[1.813, -1.057],
												[5.438, 0],
												[0.453, 8.157],
												[0, 0],
												[-0.151, 1.511],
												[0, 1.057],
												[15.71, 0],
											],
											o: [
												[0, 17.372],
												[10.121, 0],
												[1.813, -1.511],
												[0, 0],
												[-1.208, -1.511],
												[-3.021, 1.964],
												[-9.819, 0],
												[0, 0],
												[1.36, 0],
												[0.151, -1.057],
												[0, -16.164],
												[-18.127, 0],
											],
											v: [
												[318.185, -30.212],
												[348.548, 1.511],
												[370.905, -6.042],
												[371.207, -9.97],
												[366.978, -15.71],
												[362.899, -15.559],
												[349.454, -11.179],
												[332.082, -27.342],
												[373.171, -27.342],
												[375.89, -29.91],
												[376.192, -33.989],
												[348.397, -61.935],
											],
											c: true,
										},
										ix: 2,
									},
									nm: 'e',
									mn: 'ADBE Vector Shape - Group',
									hd: false,
								},
								{
									ind: 1,
									ty: 'sh',
									ix: 2,
									ks: {
										a: 0,
										k: {
											i: [
												[0, 0],
												[-8.157, 0],
												[-0.453, -7.251],
											],
											o: [
												[0.906, -7.553],
												[7.402, 0],
												[0, 0],
											],
											v: [
												[332.838, -36.859],
												[348.246, -50.304],
												[362.144, -36.859],
											],
											c: true,
										},
										ix: 2,
									},
									nm: 'e',
									mn: 'ADBE Vector Shape - Group',
									hd: false,
								},
								{ ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
								{ ty: 'fl', c: { a: 0, k: [0.090196078431, 0.16862745098, 0.301960784314, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
								{ ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
							],
							nm: 'e',
							np: 5,
							cix: 2,
							ix: 1,
							mn: 'ADBE Vector Group',
							hd: false,
						},
					],
					ip: 0,
					op: 165,
					st: 0,
					bm: 0,
				},
				{
					ddd: 0,
					ind: 5,
					ty: 4,
					nm: 'u',
					sr: 1,
					ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 1, k: [{ i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 18, s: [737.088, 562.659, 0], e: [1097.088, 562.659, 0], to: [60, 0, 0], ti: [-60, 0, 0] }, { i: { x: 0.1, y: 0.1 }, o: { x: 0.9, y: 0.9 }, n: '0p1_0p1_0p9_0p9', t: 38, s: [1097.088, 562.659, 0], e: [1097.088, 562.659, 0], to: [0, 0, 0], ti: [0, 0, 0] }, { i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 78, s: [1097.088, 562.659, 0], e: [737.088, 562.659, 0], to: [-60, 0, 0], ti: [60, 0, 0] }, { t: 98 }], ix: 2 }, a: { a: 0, k: [417.178, -29.457, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
					ao: 0,
					shapes: [
						{
							ty: 'gr',
							it: [
								{
									ind: 0,
									ty: 'sh',
									ix: 1,
									ks: {
										a: 0,
										k: {
											i: [
												[0, 0],
												[-18.127, 0],
												[-1.36, 1.208],
												[0, 0],
												[-1.511, 0],
												[0, 0],
												[0, 1.511],
												[0, 0],
												[1.511, 0],
												[0, 0],
												[0, -2.417],
												[0, 0],
												[8.913, 0],
												[0, 9.819],
												[0, 0],
												[1.511, 0],
												[0, 0],
												[0, -1.511],
											],
											o: [
												[0, 15.71],
												[12.236, 0],
												[0, 0],
												[0.453, 1.36],
												[0, 0],
												[1.511, 0],
												[0, 0],
												[0, -1.511],
												[0, 0],
												[-2.115, 0],
												[0, 0],
												[-0.453, 2.719],
												[-7.704, 0],
												[0, 0],
												[0, -1.511],
												[0, 0],
												[-1.511, 0],
												[0, 0],
											],
											v: [
												[389.307, -27.04],
												[413.779, 1.511],
												[433.72, -6.798],
												[435.23, -2.417],
												[437.949, 0],
												[442.179, 0],
												[445.049, -2.87],
												[445.049, -57.555],
												[442.179, -60.425],
												[434.324, -60.425],
												[431.001, -57.555],
												[431.001, -21.451],
												[416.045, -11.481],
												[403.658, -26.587],
												[403.658, -57.555],
												[400.788, -60.425],
												[392.178, -60.425],
												[389.307, -57.555],
											],
											c: true,
										},
										ix: 2,
									},
									nm: 'u',
									mn: 'ADBE Vector Shape - Group',
									hd: false,
								},
								{ ty: 'fl', c: { a: 0, k: [0.090196078431, 0.16862745098, 0.301960784314, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
								{ ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
							],
							nm: 'u',
							np: 3,
							cix: 2,
							ix: 1,
							mn: 'ADBE Vector Group',
							hd: false,
						},
					],
					ip: 0,
					op: 165,
					st: 0,
					bm: 0,
				},
				{
					ddd: 0,
					ind: 6,
					ty: 4,
					nm: 'r',
					sr: 1,
					ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 1, k: [{ i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 18, s: [804.289, 561.149, 0], e: [1164.289, 561.149, 0], to: [60, 0, 0], ti: [-60, 0, 0] }, { i: { x: 0.1, y: 0.1 }, o: { x: 0.9, y: 0.9 }, n: '0p1_0p1_0p9_0p9', t: 38, s: [1164.289, 561.149, 0], e: [1164.289, 561.149, 0], to: [0, 0, 0], ti: [0, 0, 0] }, { i: { x: 0.1, y: 1 }, o: { x: 0.9, y: 0 }, n: '0p1_1_0p9_0', t: 78, s: [1164.289, 561.149, 0], e: [804.289, 561.149, 0], to: [-60, 0, 0], ti: [60, 0, 0] }, { t: 98 }], ix: 2 }, a: { a: 0, k: [484.38, -30.968, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
					ao: 0,
					shapes: [
						{
							ty: 'gr',
							it: [
								{
									ind: 0,
									ty: 'sh',
									ix: 1,
									ks: {
										a: 0,
										k: {
											i: [
												[0, 0],
												[-1.511, 0],
												[0, 0],
												[0, 2.568],
												[0, 0],
												[-8.762, 0],
												[-0.906, -0.453],
												[-0.755, 1.662],
												[0, 0],
												[5.74, 0],
												[1.057, -1.511],
												[0, 0],
												[1.057, 0],
												[0, 0],
												[0, -1.511],
											],
											o: [
												[0, 1.511],
												[0, 0],
												[2.417, 0],
												[0, 0],
												[0.604, -1.057],
												[1.964, 0],
												[1.36, 0.604],
												[0, 0],
												[1.662, -4.079],
												[-11.028, 0],
												[0, 0],
												[-0.302, -1.208],
												[0, 0],
												[-1.511, 0],
												[0, 0],
											],
											v: [
												[464.163, -2.87],
												[467.033, 0],
												[474.284, 0],
												[478.363, -2.87],
												[478.363, -41.24],
												[491.959, -48.642],
												[497.095, -47.585],
												[500.72, -48.944],
												[504.346, -56.346],
												[493.318, -61.935],
												[475.644, -53.174],
												[473.982, -58.31],
												[471.414, -60.425],
												[467.033, -60.425],
												[464.163, -57.555],
											],
											c: true,
										},
										ix: 2,
									},
									nm: 'r',
									mn: 'ADBE Vector Shape - Group',
									hd: false,
								},
								{ ty: 'fl', c: { a: 0, k: [0.090196078431, 0.16862745098, 0.301960784314, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
								{ ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
							],
							nm: 'r',
							np: 3,
							cix: 2,
							ix: 1,
							mn: 'ADBE Vector Group',
							hd: false,
						},
					],
					ip: 0,
					op: 165,
					st: 0,
					bm: 0,
				},
			],
		},
	],
	layers: [
		{
			ddd: 0,
			ind: 1,
			ty: 1,
			nm: 'Pale Orange Solid 6',
			sr: 1,
			ks: {
				o: { a: 0, k: 100, ix: 11 },
				r: { a: 0, k: 0, ix: 10 },
				p: {
					a: 1,
					k: [
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 122, s: [1026, 540, 0], e: [1031, 540, 0], to: [0.83333331346512, 0, 0], ti: [-2, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 123, s: [1031, 540, 0], e: [1038, 540, 0], to: [2, 0, 0], ti: [-2.58333325386047, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 124, s: [1038, 540, 0], e: [1046.5, 540, 0], to: [2.58333325386047, 0, 0], ti: [-4.25, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 125, s: [1046.5, 540, 0], e: [1063.5, 540, 0], to: [4.25, 0, 0], ti: [-4.75, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 126, s: [1063.5, 540, 0], e: [1075, 540, 0], to: [4.75, 0, 0], ti: [-7.91666650772095, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 127, s: [1075, 540, 0], e: [1111, 540, 0], to: [7.91666650772095, 0, 0], ti: [-27.3333339691162, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 128, s: [1111, 540, 0], e: [1239, 540, 0], to: [27.3333339691162, 0, 0], ti: [-64.9166641235352, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 129, s: [1239, 540, 0], e: [1500.5, 540, 0], to: [64.9166641235352, 0, 0], ti: [-17.75, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 130, s: [1500.5, 540, 0], e: [1345.5, 540, 0], to: [17.75, 0, 0], ti: [59.1666679382324, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 131, s: [1345.5, 540, 0], e: [1145.5, 540, 0], to: [-59.1666679382324, 0, 0], ti: [41.6666679382324, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 132, s: [1145.5, 540, 0], e: [1095.5, 540, 0], to: [-41.6666679382324, 0, 0], ti: [15.8333330154419, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 133, s: [1095.5, 540, 0], e: [1050.5, 540, 0], to: [-15.8333330154419, 0, 0], ti: [5.83333349227905, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 134, s: [1050.5, 540, 0], e: [1060.5, 540, 0], to: [-5.83333349227905, 0, 0], ti: [1.66666662693024, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 135, s: [1060.5, 540, 0], e: [1040.5, 540, 0], to: [-1.66666662693024, 0, 0], ti: [5, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 136, s: [1040.5, 540, 0], e: [1030.5, 540, 0], to: [-5, 0, 0], ti: [2.16666674613953, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 137, s: [1030.5, 540, 0], e: [1027.5, 540, 0], to: [-2.16666674613953, 0, 0], ti: [1, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 138, s: [1027.5, 540, 0], e: [1024.5, 540, 0], to: [-1, 0, 0], ti: [2, 0, 0] },
						{ i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, n: '0p833_0p833_0p167_0p167', t: 139, s: [1024.5, 540, 0], e: [1015.5, 540, 0], to: [-2, 0, 0], ti: [1.5, 0, 0] },
						{ t: 140 },
					],
					ix: 2,
				},
				a: { a: 0, k: [1920, 1080, 0], ix: 1 },
				s: { a: 0, k: [-100, 100, 100], ix: 6 },
			},
			ao: 0,
			hasMask: true,
			masksProperties: [
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 122,
								s: [
									{
										i: [
											[0.138, -0.101],
											[0.074, -0.059],
											[0.068, -0.059],
											[0.062, -0.06],
											[0.056, -0.06],
											[0.05, -0.06],
											[0.045, -0.061],
											[0.04, -0.062],
											[0.036, -0.063],
											[0.031, -0.064],
											[0.027, -0.066],
											[0.024, -0.068],
											[0.02, -0.07],
											[0.017, -0.072],
											[0.014, -0.074],
											[0.012, -0.076],
											[0.01, -0.079],
											[0.008, -0.082],
											[0.006, -0.085],
											[0.005, -0.088],
											[0.004, -0.091],
											[0.003, -0.095],
											[0.003, -0.099],
											[0.003, -0.103],
											[0.003, -0.107],
											[0.004, -0.111],
											[0.004, -0.116],
											[0.006, -0.157],
											[0.006, -0.169],
											[0.005, -0.177],
											[0.003, -0.183],
											[0, -0.186],
											[-0.005, -0.187],
											[-0.01, -0.184],
											[-0.016, -0.179],
											[-0.023, -0.171],
											[-0.031, -0.16],
											[-0.041, -0.146],
											[-0.051, -0.13],
											[-0.062, -0.11],
											[-0.074, -0.088],
											[-0.087, -0.063],
											[-0.185, -0.067],
											[-0.206, -0.02],
											[-0.222, 0.023],
											[-0.232, 0.064],
											[-0.237, 0.101],
											[-0.237, 0.136],
											[-0.231, 0.168],
											[-0.221, 0.197],
											[-0.205, 0.223],
											[-0.184, 0.246],
											[-0.158, 0.266],
											[-0.126, 0.284],
											[-0.089, 0.298],
											[-0.047, 0.31],
											[0, 0.319],
											[0.041, 0.306],
											[0.078, 0.284],
											[0.111, 0.261],
											[0.138, 0.237],
											[0.162, 0.212],
											[0.18, 0.185],
											[0.194, 0.158],
											[0.203, 0.129],
											[0.208, 0.1],
											[0.207, 0.069],
											[0.203, 0.037],
											[0.193, 0.004],
											[0.179, -0.03],
											[0.161, -0.065],
										],
										o: [
											[-0.081, 0.06],
											[-0.074, 0.059],
											[-0.068, 0.059],
											[-0.062, 0.06],
											[-0.056, 0.06],
											[-0.05, 0.06],
											[-0.045, 0.061],
											[-0.04, 0.062],
											[-0.036, 0.063],
											[-0.031, 0.064],
											[-0.027, 0.066],
											[-0.024, 0.068],
											[-0.02, 0.07],
											[-0.017, 0.072],
											[-0.014, 0.074],
											[-0.012, 0.076],
											[-0.01, 0.079],
											[-0.008, 0.082],
											[-0.006, 0.085],
											[-0.005, 0.088],
											[-0.004, 0.091],
											[-0.003, 0.095],
											[-0.003, 0.099],
											[-0.003, 0.103],
											[-0.003, 0.107],
											[-0.004, 0.111],
											[-0.006, 0.143],
											[-0.006, 0.157],
											[-0.006, 0.169],
											[-0.005, 0.177],
											[-0.003, 0.183],
											[0, 0.186],
											[0.005, 0.187],
											[0.01, 0.184],
											[0.016, 0.179],
											[0.023, 0.171],
											[0.031, 0.16],
											[0.041, 0.146],
											[0.051, 0.13],
											[0.062, 0.11],
											[0.074, 0.088],
											[0.159, 0.116],
											[0.185, 0.067],
											[0.206, 0.02],
											[0.222, -0.023],
											[0.232, -0.064],
											[0.237, -0.101],
											[0.237, -0.136],
											[0.231, -0.168],
											[0.221, -0.197],
											[0.205, -0.223],
											[0.184, -0.246],
											[0.158, -0.266],
											[0.126, -0.284],
											[0.089, -0.298],
											[0.047, -0.31],
											[0, -0.326],
											[-0.041, -0.306],
											[-0.078, -0.284],
											[-0.111, -0.261],
											[-0.138, -0.237],
											[-0.162, -0.212],
											[-0.18, -0.185],
											[-0.194, -0.158],
											[-0.203, -0.129],
											[-0.208, -0.1],
											[-0.207, -0.069],
											[-0.203, -0.037],
											[-0.193, -0.004],
											[-0.179, 0.03],
											[-0.161, 0.065],
										],
										v: [
											[2491, 1090],
											[2490.767, 1090.179],
											[2490.554, 1090.357],
											[2490.36, 1090.536],
											[2490.184, 1090.715],
											[2490.024, 1090.896],
											[2489.881, 1091.078],
											[2489.753, 1091.263],
											[2489.639, 1091.451],
											[2489.539, 1091.642],
											[2489.451, 1091.838],
											[2489.375, 1092.038],
											[2489.309, 1092.244],
											[2489.253, 1092.456],
											[2489.206, 1092.673],
											[2489.167, 1092.898],
											[2489.135, 1093.131],
											[2489.109, 1093.372],
											[2489.088, 1093.621],
											[2489.072, 1093.88],
											[2489.059, 1094.149],
											[2489.048, 1094.428],
											[2489.039, 1094.718],
											[2489.03, 1095.02],
											[2489.022, 1095.334],
											[2489.012, 1095.66],
											[2489, 1096],
											[2488.982, 1096.451],
											[2488.963, 1096.941],
											[2488.946, 1097.46],
											[2488.935, 1098.002],
											[2488.931, 1098.557],
											[2488.939, 1099.116],
											[2488.96, 1099.673],
											[2488.999, 1100.218],
											[2489.058, 1100.742],
											[2489.14, 1101.239],
											[2489.248, 1101.698],
											[2489.385, 1102.112],
											[2489.554, 1102.473],
											[2489.758, 1102.772],
											[2490, 1103],
											[2490.518, 1103.273],
											[2491.106, 1103.402],
											[2491.749, 1103.397],
											[2492.431, 1103.266],
											[2493.135, 1103.018],
											[2493.847, 1102.662],
											[2494.551, 1102.206],
											[2495.231, 1101.658],
											[2495.871, 1101.028],
											[2496.455, 1100.324],
											[2496.969, 1099.555],
											[2497.396, 1098.73],
											[2497.721, 1097.856],
											[2497.927, 1096.943],
											[2498, 1096],
											[2497.937, 1095.052],
											[2497.756, 1094.168],
											[2497.471, 1093.351],
											[2497.096, 1092.604],
											[2496.645, 1091.931],
											[2496.131, 1091.336],
											[2495.57, 1090.821],
											[2494.973, 1090.389],
											[2494.356, 1090.045],
											[2493.732, 1089.792],
											[2493.116, 1089.632],
											[2492.52, 1089.57],
											[2491.96, 1089.608],
											[2491.449, 1089.751],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 123,
								s: [
									{
										i: [
											[0.213, -0.046],
											[0.421, -0.587],
											[0.449, -0.56],
											[0.461, -0.555],
											[0.458, -0.57],
											[0.439, -0.606],
											[0.405, -0.662],
											[0.355, -0.74],
											[0.29, -0.838],
											[0.186, -1.01],
											[0.073, -1.056],
											[-0.003, -1.106],
											[-0.042, -1.161],
											[-0.045, -1.221],
											[-0.012, -1.286],
											[0.059, -1.356],
											[0.188, -1.14],
											[0.23, -1.148],
											[0.177, -1.184],
											[0.029, -1.248],
											[-0.157, -1.536],
											[-0.238, -1.602],
											[-0.212, -1.515],
											[-0.08, -1.275],
											[-0.056, -2.431],
											[-0.132, -2.14],
											[-0.385, -1.639],
											[-0.815, -0.927],
											[-0.17, -0.001],
											[-0.21, 0.029],
											[-0.185, 0.015],
											[-0.095, -0.043],
											[-0.073, -0.182],
											[-0.071, -0.229],
											[-0.087, -0.182],
											[-0.122, -0.042],
											[-1.42, 0.22],
											[-1.224, 0.621],
											[-0.817, 0.715],
											[-0.2, 0.504],
											[0.325, 1.708],
											[0.79, 1.534],
											[0.57, 1.559],
											[-0.335, 1.784],
											[-1.059, 1.008],
											[-1.314, 1.164],
											[-0.973, 1.574],
											[-0.035, 2.238],
											[0.153, 0.629],
											[0.223, 0.766],
											[0.209, 0.629],
											[0.11, 0.22],
											[0.468, 0.436],
											[0.559, 0.367],
											[0.562, 0.372],
											[0.476, 0.451],
											[0.53, 0.463],
											[0.446, 0.505],
											[0.318, 0.664],
											[0.146, 0.943],
											[-0.723, 1.51],
											[-1.182, 1.119],
											[-0.993, 1.299],
											[-0.157, 2.052],
											[0.738, 1.332],
											[1.171, 0.914],
											[1.098, 1.375],
											[0.518, 2.715],
											[0.03, 0.137],
											[0.068, 0.098],
											[0.129, 0.037],
										],
										o: [
											[-0.378, 0.634],
											[-0.421, 0.587],
											[-0.449, 0.56],
											[-0.461, 0.555],
											[-0.458, 0.57],
											[-0.439, 0.606],
											[-0.405, 0.662],
											[-0.355, 0.74],
											[-0.336, 0.97],
											[-0.186, 1.01],
											[-0.073, 1.056],
											[0.003, 1.106],
											[0.042, 1.161],
											[0.045, 1.221],
											[0.012, 1.286],
											[-0.05, 1.159],
											[-0.188, 1.14],
											[-0.23, 1.148],
											[-0.177, 1.184],
											[-0.031, 1.317],
											[0.157, 1.536],
											[0.238, 1.602],
											[0.212, 1.515],
											[0.158, 2.511],
											[0.056, 2.431],
											[0.132, 2.14],
											[0.385, 1.639],
											[0.065, 0.074],
											[0.17, 0.001],
											[0.21, -0.029],
											[0.185, -0.015],
											[0.094, 0.043],
											[0.073, 0.182],
											[0.071, 0.229],
											[0.087, 0.182],
											[1.404, 0.487],
											[1.42, -0.22],
											[1.224, -0.621],
											[0.817, -0.715],
											[0.826, -2.081],
											[-0.325, -1.708],
											[-0.79, -1.534],
											[-0.57, -1.559],
											[0.208, -1.107],
											[1.059, -1.008],
											[1.314, -1.164],
											[0.973, -1.574],
											[0.003, -0.218],
											[-0.153, -0.629],
											[-0.223, -0.766],
											[-0.209, -0.629],
											[-0.289, -0.579],
											[-0.468, -0.436],
											[-0.559, -0.367],
											[-0.562, -0.372],
											[-0.57, -0.539],
											[-0.53, -0.463],
											[-0.446, -0.505],
											[-0.318, -0.664],
											[-0.383, -2.474],
											[0.723, -1.51],
											[1.182, -1.119],
											[0.993, -1.299],
											[0.201, -2.628],
											[-0.738, -1.332],
											[-1.171, -0.914],
											[-1.098, -1.375],
											[-0.014, -0.152],
											[-0.03, -0.137],
											[-0.068, -0.098],
											[-0.129, -0.037],
										],
										v: [
											[2490, 1077],
											[2488.798, 1078.825],
											[2487.49, 1080.54],
											[2486.12, 1082.207],
											[2484.737, 1083.888],
											[2483.387, 1085.645],
											[2482.116, 1087.542],
											[2480.972, 1089.639],
											[2480, 1092],
											[2479.227, 1094.97],
											[2478.846, 1098.067],
											[2478.75, 1101.308],
											[2478.827, 1104.707],
											[2478.967, 1108.279],
											[2479.062, 1112.038],
											[2479, 1116],
											[2478.619, 1119.441],
											[2477.968, 1122.867],
											[2477.334, 1126.359],
											[2477, 1130],
											[2477.215, 1134.318],
											[2477.833, 1139.064],
											[2478.535, 1143.777],
											[2479, 1148],
											[2479.277, 1155.465],
											[2479.514, 1162.375],
											[2480.245, 1168.098],
											[2482, 1172],
											[2482.369, 1172.101],
											[2482.955, 1172.047],
											[2483.564, 1171.969],
											[2484, 1172],
											[2484.247, 1172.36],
											[2484.459, 1173],
											[2484.692, 1173.64],
											[2485, 1174],
											[2489.288, 1174.324],
											[2493.306, 1172.986],
											[2496.421, 1170.905],
											[2498, 1169],
											[2498.581, 1163.367],
											[2496.737, 1158.554],
											[2494.525, 1153.964],
											[2494, 1149],
											[2496.05, 1145.891],
											[2499.759, 1142.696],
											[2503.339, 1138.654],
											[2505, 1133],
											[2504.755, 1131.662],
											[2504.169, 1129.502],
											[2503.499, 1127.342],
											[2503, 1126],
											[2501.843, 1124.495],
											[2500.282, 1123.308],
											[2498.579, 1122.217],
											[2497, 1121],
											[2495.339, 1119.526],
											[2493.864, 1118.105],
											[2492.707, 1116.381],
											[2492, 1114],
											[2492.671, 1108.167],
											[2495.69, 1104.367],
											[2499.113, 1100.883],
											[2501, 1096],
											[2500.069, 1090.28],
											[2497.079, 1087.13],
											[2493.55, 1083.916],
											[2491, 1078],
											[2490.939, 1077.561],
											[2490.798, 1077.202],
											[2490.507, 1076.993],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 124,
								s: [
									{
										i: [
											[0.173, -0.012],
											[0.889, -0.736],
											[0.682, -1.16],
											[0.452, -1.344],
											[0.198, -1.288],
											[-0.025, -0.715],
											[-0.062, -0.748],
											[-0.006, -0.784],
											[0.142, -0.822],
											[0.193, -0.583],
											[0.204, -0.526],
											[0.159, -0.555],
											[0.058, -0.67],
											[-0.021, -1.416],
											[-0.066, -1.413],
											[-0.012, -1.415],
											[0.14, -1.423],
											[0.215, -0.901],
											[0.238, -0.93],
											[0.154, -0.939],
											[-0.037, -0.928],
											[-0.259, -0.995],
											[-0.349, -1.172],
											[-0.29, -1.09],
											[-0.083, -0.752],
											[0.076, -4.819],
											[-0.351, -3.703],
											[-1.877, -2.032],
											[-4.499, 0.193],
											[-1.851, 0.588],
											[-1.5, 1.037],
											[-0.899, 1.43],
											[-0.047, 1.767],
											[0.844, 1.407],
											[1.149, 1.04],
											[0.855, 1.113],
											[-0.037, 1.626],
											[-0.485, 0.899],
											[-0.778, 0.749],
											[-0.904, 0.601],
											[-0.863, 0.454],
											[-1.299, 0.343],
											[-1.227, 0.204],
											[-1.064, 0.26],
											[-0.81, 0.508],
											[-0.765, 0.767],
											[-0.681, 0.887],
											[-0.451, 0.802],
											[-0.073, 0.51],
											[0.038, 0.593],
											[0.098, 0.699],
											[0.136, 0.631],
											[0.153, 0.39],
											[0.736, 0.808],
											[0.818, 0.689],
											[0.548, 0.411],
											[-0.074, -0.025],
											[2.353, -0.24],
											[2.026, 0.078],
											[0.94, 1.609],
											[-2.065, 1.804],
											[-0.221, 0.251],
											[-1.373, 2.016],
											[0.487, 2.809],
											[2.892, 1.229],
											[2.862, 0.798],
											[1.111, 0.302],
											[0.632, 0.532],
											[0.286, 1.551],
											[0.705, 1.827],
											[0.631, -0.195],
										],
										o: [
											[-1.073, 0.073],
											[-0.889, 0.736],
											[-0.682, 1.16],
											[-0.452, 1.344],
											[-0.105, 0.685],
											[0.025, 0.715],
											[0.062, 0.748],
											[0.006, 0.784],
											[-0.126, 0.727],
											[-0.193, 0.583],
											[-0.204, 0.526],
											[-0.159, 0.555],
											[-0.123, 1.426],
											[0.021, 1.416],
											[0.066, 1.413],
											[0.012, 1.415],
											[-0.084, 0.852],
											[-0.215, 0.901],
											[-0.238, 0.93],
											[-0.154, 0.939],
											[0.023, 0.562],
											[0.259, 0.995],
											[0.349, 1.172],
											[0.29, 1.09],
											[0.595, 5.381],
											[-0.076, 4.819],
											[0.351, 3.703],
											[1.877, 2.032],
											[1.953, -0.084],
											[1.851, -0.588],
											[1.5, -1.037],
											[0.899, -1.43],
											[0.059, -2.213],
											[-0.844, -1.407],
											[-1.149, -1.04],
											[-0.855, -1.113],
											[0.024, -1.05],
											[0.485, -0.899],
											[0.778, -0.749],
											[0.904, -0.601],
											[1.28, -0.674],
											[1.299, -0.343],
											[1.227, -0.204],
											[1.064, -0.26],
											[0.701, -0.44],
											[0.765, -0.767],
											[0.681, -0.887],
											[0.451, -0.802],
											[0.045, -0.314],
											[-0.038, -0.593],
											[-0.098, -0.699],
											[-0.136, -0.631],
											[-0.302, -0.769],
											[-0.736, -0.808],
											[-0.818, -0.689],
											[-0.548, -0.411],
											[-1.923, -0.656],
											[-2.353, 0.24],
											[-2.026, -0.078],
											[-1.481, -2.535],
											[2.065, -1.804],
											[0.994, -1.128],
											[1.373, -2.016],
											[-0.569, -3.284],
											[-2.892, -1.23],
											[-0.925, -0.258],
											[-1.111, -0.303],
											[-1.152, -0.969],
											[-0.286, -1.551],
											[-0.305, -0.791],
											[-0.631, 0.195],
										],
										v: [
											[2482, 1071],
											[2479.051, 1072.274],
											[2476.687, 1075.178],
											[2474.98, 1078.993],
											[2474, 1083],
											[2473.903, 1085.1],
											[2474.056, 1087.294],
											[2474.181, 1089.592],
											[2474, 1092],
											[2473.507, 1093.944],
											[2472.898, 1095.586],
											[2472.339, 1097.184],
											[2472, 1099],
											[2471.871, 1103.261],
											[2472.026, 1107.503],
											[2472.168, 1111.743],
											[2472, 1116],
											[2471.525, 1118.635],
											[2470.819, 1121.386],
											[2470.203, 1124.194],
											[2470, 1127],
											[2470.46, 1129.4],
											[2471.409, 1132.715],
											[2472.403, 1136.172],
											[2473, 1139],
											[2473.504, 1154.44],
											[2473.643, 1167.362],
											[2476.71, 1176.103],
											[2486, 1179],
											[2491.769, 1177.978],
											[2496.858, 1175.525],
											[2500.519, 1171.81],
											[2502, 1167],
											[2500.673, 1161.68],
											[2497.534, 1158.12],
											[2494.378, 1154.999],
											[2493, 1151],
											[2493.805, 1148.077],
											[2495.742, 1145.606],
											[2498.308, 1143.582],
											[2501, 1142],
											[2504.893, 1140.523],
											[2508.706, 1139.751],
											[2512.166, 1139.104],
											[2515, 1138],
											[2517.236, 1136.138],
											[2519.442, 1133.605],
											[2521.178, 1131.02],
											[2522, 1129],
											[2522.005, 1127.596],
											[2521.797, 1125.614],
											[2521.44, 1123.575],
											[2521, 1122],
											[2519.356, 1119.595],
											[2516.937, 1117.309],
											[2514.8, 1115.619],
											[2514, 1115],
											[2507.396, 1114.679],
											[2500.638, 1115.227],
											[2496, 1113],
											[2498.224, 1106.287],
											[2503, 1103],
											[2507.111, 1098.261],
											[2509, 1091],
											[2503.22, 1084.636],
											[2494, 1082],
											[2490.781, 1081.206],
											[2488, 1080],
											[2486.165, 1076.144],
											[2485, 1071],
											[2483.401, 1070.398],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 125,
								s: [
									{
										i: [
											[0.635, 0.358],
											[0.237, -0.328],
											[0.183, -0.283],
											[0.104, -0.313],
											[0, -0.418],
											[0.026, -3.953],
											[0.071, -3.813],
											[0.134, -4.078],
											[0.216, -4.75],
											[0.177, -1.905],
											[0.208, -1.942],
											[0.177, -1.941],
											[0.083, -1.902],
											[-0.037, -2.569],
											[-0.127, -2.373],
											[-0.151, -2.117],
											[-0.109, -1.803],
											[-0.034, -1.839],
											[-0.121, -1.4],
											[-0.402, -0.986],
											[-0.875, -0.599],
											[-1.255, 0.029],
											[-1.512, 0.323],
											[-1.501, 0.382],
											[-1.221, 0.207],
											[-1.182, 0.11],
											[-1.121, 0.149],
											[-1.129, 0.34],
											[-1.205, 0.683],
											[-0.613, 0.278],
											[-0.54, 0.331],
											[-0.383, 0.499],
											[0.16, 1.505],
											[0.865, 1.016],
											[1.679, 0.324],
											[1.849, 1.019],
											[1.543, 1.541],
											[-0.336, 2.642],
											[-0.187, 0.317],
											[-0.198, 0.307],
											[-0.217, 0.46],
											[-0.099, 0.106],
											[-0.822, 0.189],
											[-0.994, 0.468],
											[-4.627, 0.107],
											[-5.347, 0.616],
											[-2.74, 1.293],
											[-2.065, 1.37],
											[0.148, 3.246],
											[1.48, 1.977],
											[1.714, 1.284],
											[2.082, 0.448],
											[6.818, -1.171],
											[1.489, 3.559],
											[-0.242, 1.271],
											[-0.245, 0.403],
											[-3.936, 0.111],
											[-2.592, 0.823],
											[-2.436, 2.837],
											[0.122, 2.899],
											[1.385, 1.571],
											[1.372, 0.619],
											[0.142, 0.46],
											[0.245, 0.077],
											[4.093, -1.243],
											[5.267, 0.596],
											[2.532, 1.233],
											[0.88, 0.843],
											[1.184, 1.318],
											[0.852, 1.376],
											[0.66, 0.369],
										],
										o: [
											[-0.266, 0.449],
											[-0.237, 0.328],
											[-0.183, 0.283],
											[-0.104, 0.313],
											[0, 4.5],
											[-0.026, 3.953],
											[-0.071, 3.813],
											[-0.134, 4.078],
											[-0.083, 1.831],
											[-0.177, 1.905],
											[-0.208, 1.942],
											[-0.177, 1.941],
											[-0.119, 2.706],
											[0.037, 2.569],
											[0.127, 2.373],
											[0.151, 2.117],
											[0.14, 2.303],
											[0.034, 1.839],
											[0.121, 1.4],
											[0.402, 0.986],
											[0.729, 0.498],
											[1.255, -0.029],
											[1.512, -0.323],
											[1.501, -0.382],
											[1.311, -0.222],
											[1.182, -0.11],
											[1.121, -0.149],
											[1.129, -0.34],
											[0.602, -0.341],
											[0.613, -0.278],
											[0.54, -0.331],
											[1.505, -1.962],
											[-0.16, -1.505],
											[-1.436, -1.687],
											[-1.679, -0.324],
											[-2.164, -1.193],
											[-1.543, -1.541],
											[0.054, -0.427],
											[0.187, -0.317],
											[0.034, -0.053],
											[0.217, -0.46],
											[0.716, -0.774],
											[0.822, -0.189],
											[4.144, -1.955],
											[4.627, -0.107],
											[3.976, -0.458],
											[2.74, -1.293],
											[3.073, -2.039],
											[-0.148, -3.246],
											[-1.061, -1.418],
											[-1.714, -1.284],
											[-5.239, -1.127],
											[-6.818, 1.171],
											[-0.214, -0.512],
											[0.242, -1.271],
											[1.664, -2.732],
											[3.936, -0.111],
											[2.377, -0.755],
											[2.436, -2.837],
											[-0.088, -2.099],
											[-1.385, -1.571],
											[-0.188, -0.084],
											[-0.142, -0.46],
											[-4.362, -1.374],
											[-4.093, 1.243],
											[-1.992, -0.226],
											[-2.532, -1.233],
											[-1.412, -1.352],
											[-1.184, -1.318],
											[-0.723, -1.167],
											[-0.66, -0.369],
										],
										v: [
											[2471, 1063],
											[2470.238, 1064.147],
											[2469.6, 1065.045],
											[2469.163, 1065.921],
											[2469, 1067],
											[2468.965, 1079.578],
											[2468.823, 1091.125],
											[2468.52, 1102.86],
											[2468, 1116],
											[2467.594, 1121.614],
											[2467, 1127.393],
											[2466.407, 1133.226],
											[2466, 1139],
											[2465.894, 1146.928],
											[2466.158, 1154.355],
											[2466.592, 1161.105],
											[2467, 1167],
											[2467.213, 1173.206],
											[2467.398, 1178.057],
											[2468.134, 1181.629],
											[2470, 1184],
											[2473.043, 1184.645],
											[2477.261, 1184.058],
											[2481.849, 1182.942],
											[2486, 1182],
											[2489.722, 1181.541],
											[2493.159, 1181.191],
											[2496.516, 1180.496],
											[2500, 1179],
											[2501.844, 1178.1],
											[2503.595, 1177.216],
											[2505, 1176],
											[2506.778, 1170.791],
											[2505, 1167],
											[2500.309, 1164.499],
											[2495, 1163],
											[2489.125, 1159.087],
											[2487, 1153],
											[2487.392, 1151.91],
											[2488, 1151],
											[2488.452, 1150.04],
											[2489, 1149],
											[2491.292, 1147.771],
											[2494, 1147],
											[2507.098, 1144.496],
											[2522, 1144],
											[2531.933, 1141.184],
											[2539, 1137],
											[2542.915, 1128.454],
											[2540, 1120],
											[2535.765, 1115.773],
											[2530, 1113],
											[2510.187, 1114.824],
											[2496, 1113],
											[2496.155, 1109.919],
											[2497, 1107],
											[2506.304, 1103.568],
											[2517, 1103],
											[2524.874, 1097.108],
											[2529, 1088],
											[2526.463, 1082.39],
											[2522, 1079],
											[2521.543, 1077.994],
											[2521, 1077],
											[2508.679, 1077.917],
											[2495, 1080],
											[2487.666, 1077.463],
											[2482, 1074],
											[2478.081, 1070.018],
											[2475, 1066],
											[2472.934, 1063.893],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 126,
								s: [
									{
										i: [
											[1.236, -1.421],
											[0.381, -0.334],
											[0, -1.115],
											[-0.243, -13.315],
											[-0.028, -10.033],
											[-0.04, -9.831],
											[-1.825, -1.825],
											[-1.056, -0.421],
											[-0.563, -0.102],
											[-1.148, 0.278],
											[-1.176, 0.112],
											[-3.449, 0.755],
											[-5.245, -0.49],
											[-1.662, -0.465],
											[-1.737, 0.012],
											[-1.679, 0.185],
											[-1.393, 0.255],
											[-0.65, 0.479],
											[-0.241, 0.053],
											[-0.915, -0.003],
											[-0.667, 0.639],
											[-1.757, 1.89],
											[0.479, 2.052],
											[2.134, 0.591],
											[2.86, 0.051],
											[1.047, -0.453],
											[1.319, -0.216],
											[2.842, 1.023],
											[4.211, 0.793],
											[2.056, -0.218],
											[1.467, 0.437],
											[1.101, 1.166],
											[0.216, 0.923],
											[-3.616, 1.825],
											[-5.663, -0.101],
											[-2.742, -0.545],
											[-2.237, 0.229],
											[-0.898, 0.357],
											[-1.044, 0.434],
											[-1.036, 0.347],
											[-1.546, 0.066],
											[-2.149, -0.787],
											[-3.216, 0.015],
											[-3.284, 1.511],
											[-1.167, 1.938],
											[-0.123, 3.512],
											[1.776, 2.818],
											[5.02, 1.563],
											[4.44, -0.599],
											[3.014, -1.724],
											[2.934, -0.564],
											[2.933, 0.899],
											[1.025, 1.025],
											[-2.016, 3.287],
											[-3.563, 0.224],
											[-1.11, -0.65],
											[-1.327, -0.476],
											[-2.907, -0.412],
											[-2.964, 0.512],
											[-2.058, 1.301],
											[-2.322, 0.546],
											[-1.579, -0.297],
											[-1.697, 1.484],
											[0.849, 4.19],
											[5.298, 0.194],
											[8.668, 3.146],
											[3.814, 1.204],
											[3.857, -0.186],
											[1.701, 0.359],
											[6.18, 0.975],
											[0.743, -0.259],
										],
										o: [
											[-0.476, 0.548],
											[-0.381, 0.334],
											[0.001, 8.707],
											[0.243, 13.315],
											[0.016, 5.851],
											[0.04, 9.831],
											[0.212, 0.212],
											[1.056, 0.421],
											[1.233, 0.225],
											[1.148, -0.278],
											[4.957, -0.472],
											[3.449, -0.755],
											[1.616, 0.151],
											[1.662, 0.465],
											[0.891, -0.006],
											[1.679, -0.185],
											[0.158, -0.029],
											[0.65, -0.479],
											[1.672, -0.371],
											[0.915, 0.003],
											[1.448, -1.389],
											[1.758, -1.89],
											[-0.603, -2.586],
											[-2.134, -0.591],
											[-1.493, -0.026],
											[-1.047, 0.453],
											[-5.421, 0.885],
											[-2.842, -1.023],
											[-2.309, -0.434],
											[-2.056, 0.218],
											[-1.381, -0.411],
											[-1.101, -1.166],
											[-1.127, -4.802],
											[3.616, -1.825],
											[2.796, 0.05],
											[2.742, 0.545],
											[1.363, -0.14],
											[0.898, -0.357],
											[1.311, -0.545],
											[1.036, -0.347],
											[3.188, -0.135],
											[2.149, 0.788],
											[3.697, -0.017],
											[3.284, -1.511],
											[1.284, -2.135],
											[0.123, -3.512],
											[-1.479, -2.347],
											[-5.02, -1.563],
											[-4.01, 0.541],
											[-3.014, 1.724],
											[-3.241, 0.624],
											[-2.933, -0.899],
											[-2.627, -2.627],
											[2.016, -3.287],
											[1.232, -0.077],
											[1.11, 0.65],
											[2.407, 0.865],
											[2.907, 0.412],
											[1.445, -0.249],
											[2.058, -1.301],
											[2.988, -0.703],
											[1.579, 0.297],
											[1.855, -1.622],
											[-1.356, -6.697],
											[-10.321, -0.378],
											[-4.409, -1.6],
											[-3.713, -1.172],
											[-2.256, 0.109],
											[-6.881, -1.452],
											[-1.667, -0.263],
											[-2.772, 0.967],
										],
										v: [
											[2455, 1063],
											[2453.643, 1064.075],
											[2453, 1066],
											[2453.479, 1101.005],
											[2454, 1138],
											[2453.644, 1164.519],
											[2456, 1185],
											[2458.237, 1186.082],
											[2461, 1187],
											[2464.543, 1186.752],
											[2468, 1186],
											[2479.784, 1183.778],
											[2492, 1183],
											[2496.909, 1184.122],
											[2502, 1185],
											[2506.123, 1184.686],
											[2511, 1184],
											[2512.437, 1183.018],
											[2514, 1182],
											[2517.754, 1181.701],
											[2520, 1181],
											[2525.445, 1175.997],
											[2528, 1170],
											[2523.693, 1165.598],
											[2516, 1165],
											[2512.37, 1165.818],
											[2509, 1167],
											[2497.593, 1166.258],
											[2488, 1163],
											[2481.369, 1163.002],
											[2476, 1163],
											[2472.126, 1160.384],
											[2470, 1157],
											[2474.408, 1147.323],
											[2489, 1145],
											[2497.419, 1146.21],
											[2505, 1147],
											[2508.24, 1146.22],
											[2511, 1145],
											[2514.324, 1143.64],
											[2518, 1143],
											[2525.479, 1144.41],
											[2533, 1146],
											[2543.898, 1143.441],
											[2551, 1138],
											[2553.296, 1129.012],
											[2551, 1119],
											[2540.22, 1112.791],
											[2525, 1111],
											[2514.693, 1114.982],
											[2506, 1119],
											[2496.338, 1118.237],
											[2490, 1115],
											[2489.858, 1105.197],
											[2499, 1099],
											[2502.429, 1100.084],
											[2506, 1102],
											[2514.082, 1104.032],
											[2523, 1104],
											[2528.342, 1101.222],
											[2535, 1098],
											[2541.469, 1098.085],
											[2546, 1097],
											[2549, 1085],
											[2535, 1076],
											[2504, 1078],
											[2493, 1071],
											[2482, 1070],
											[2475, 1071],
											[2462, 1062],
											[2459, 1063],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 127,
								s: [
									{
										i: [
											[7.959, -2.932],
											[0.6, -0.38],
											[0.199, -0.331],
											[1.314, -3.602],
											[0.604, -3.697],
											[-0.129, -2.952],
											[0.046, -2.316],
											[0.04, -6.108],
											[-0.271, -5.967],
											[-0.137, -3.38],
											[-0.287, -2.871],
											[-0.408, -1.169],
											[-0.261, -1.257],
											[-0.088, -1.449],
											[-0.354, -0.787],
											[-2.43, -2.581],
											[-0.699, -0.381],
											[-1.088, -0.098],
											[-1.078, -0.298],
											[-1.863, -0.157],
											[-2.814, 0.385],
											[-1.265, 0.212],
											[-1.519, -0.041],
											[-3.273, -0.743],
											[-3.145, 0.072],
											[-0.909, 0.467],
											[-0.828, 0.117],
											[-1.41, -0.105],
											[-0.899, 0.211],
											[-1.779, 0.832],
											[-1.914, 0.211],
											[-1.577, 0.01],
											[-1.449, -0.178],
											[-1.26, -0.462],
											[-1.593, 0.014],
											[-3.523, 1.6],
											[0.327, 3.569],
											[6.512, -0.277],
											[4.421, 0.312],
											[2.451, 0.507],
											[2.765, -0.055],
											[2.195, 6.576],
											[-1.479, 0.876],
											[-1.062, 0.544],
											[-2.244, 0.028],
											[-2.995, -0.352],
											[-3.619, -0.331],
											[-5.28, 0.008],
											[-2.93, 0.369],
											[-2.306, 0.494],
											[-4.386, 1.523],
											[-3.661, 0.985],
											[-2.327, 1.541],
											[0.162, 4.634],
											[1.967, 2.567],
											[1.035, 0.368],
											[5.208, 0.811],
											[2.926, 0.471],
											[4.44, 0.23],
											[6.156, -2.338],
											[4.795, -1.829],
											[1.168, -0.288],
											[7.885, 1.162],
											[1.573, 6.31],
											[-2.453, 2.077],
											[-5.494, 1.498],
											[0.295, 4.927],
											[5.565, 2.304],
											[4.148, 0.75],
											[2.805, 0],
											[2.735, 1.19],
										],
										o: [
											[-0.4, 0.148],
											[-0.6, 0.38],
											[-1.141, 1.894],
											[-1.314, 3.602],
											[-0.47, 2.876],
											[0.129, 2.952],
											[-0.113, 5.6],
											[-0.04, 6.108],
											[0.164, 3.61],
											[0.137, 3.38],
											[0.106, 1.066],
											[0.408, 1.169],
											[0.294, 1.416],
											[0.088, 1.449],
											[0.582, 1.296],
											[2.429, 2.581],
											[0.571, 0.312],
											[1.088, 0.098],
											[2.735, 0.756],
											[1.863, 0.157],
											[1.422, -0.195],
											[1.265, -0.212],
											[3.765, 0.101],
											[3.273, 0.743],
											[0.536, -0.013],
											[0.909, -0.467],
											[1.463, -0.208],
											[1.41, 0.105],
											[1.969, -0.461],
											[1.779, -0.832],
											[1.242, -0.138],
											[1.577, -0.01],
											[1.365, 0.168],
											[1.26, 0.462],
											[3.234, -0.03],
											[3.523, -1.6],
											[-0.53, -5.798],
											[-6.512, 0.277],
											[-2.846, -0.201],
											[-3.484, -0.721],
											[-6.2, 0.123],
											[-1.347, -4.035],
											[0.848, -0.502],
											[1.954, -1.002],
											[3.605, -0.045],
											[3.983, 0.469],
											[6.936, 0.634],
											[2.503, -0.004],
											[3.189, -0.401],
											[5.673, -1.215],
											[2.522, -0.875],
											[3.135, -0.844],
											[3.814, -2.526],
											[-0.081, -2.323],
											[-1.234, -1.611],
											[-5.185, -1.841],
											[-4.531, -0.705],
											[-3.783, -0.609],
											[-9.058, -0.469],
											[-5.704, 2.166],
											[-1.15, 0.439],
											[-8.428, 2.078],
											[-9.916, -1.461],
											[-1.63, -6.54],
											[5.215, -4.416],
											[4.285, -1.169],
											[-0.496, -8.297],
											[-3.628, -1.502],
											[-4.393, -0.794],
											[-3.487, 0],
											[-5.208, -2.266],
										],
										v: [
											[2430, 1063],
											[2428.349, 1063.863],
											[2427, 1065],
											[2423.097, 1073.648],
											[2420, 1085],
											[2419.683, 1093.92],
											[2420, 1102],
											[2419.712, 1119.725],
											[2420, 1138],
											[2420.408, 1148.554],
											[2421, 1158],
											[2421.884, 1161.356],
											[2423, 1165],
											[2423.455, 1169.471],
											[2424, 1173],
											[2429.412, 1179.686],
											[2435, 1185],
											[2437.62, 1185.51],
											[2441, 1186],
											[2447.44, 1187.356],
											[2454, 1187],
											[2457.927, 1186.323],
											[2462, 1186],
											[2472.465, 1187.631],
											[2482, 1189],
											[2484.281, 1188.079],
											[2487, 1187],
											[2491.423, 1187.002],
											[2495, 1187],
											[2500.542, 1184.813],
											[2506, 1183],
											[2510.344, 1182.763],
											[2515, 1183],
											[2518.829, 1184.137],
											[2523, 1185],
											[2534.17, 1182.654],
											[2540, 1175],
											[2527.418, 1168.385],
											[2509, 1170],
											[2501, 1168],
											[2492, 1167],
											[2472, 1165],
											[2477, 1157],
											[2480, 1155],
											[2495, 1151],
											[2505, 1153],
											[2516, 1153],
											[2534, 1156],
											[2543, 1155],
											[2552, 1154],
											[2565, 1147],
											[2575, 1145],
											[2585, 1142],
											[2593, 1128],
											[2590, 1121],
											[2585, 1116],
											[2568, 1116],
											[2557, 1112],
											[2544, 1112],
											[2522, 1113],
											[2508, 1122],
											[2503, 1123],
											[2485, 1121],
											[2467, 1116],
											[2473, 1107],
											[2487, 1100],
											[2497, 1091],
											[2480, 1083],
											[2468, 1075],
											[2457, 1076],
											[2446, 1075],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 128,
								s: [
									{
										i: [
											[0.061, -0.01],
											[1.611, -1.504],
											[0.568, -1.819],
											[-0.117, -1.56],
											[0.095, -1.566],
											[0.013, -7.232],
											[0.431, -9.489],
											[0.011, -3.756],
											[-0.199, -4.543],
											[0.149, -6.025],
											[-1.296, -2.07],
											[-7.671, -0.974],
											[-3.814, 0.296],
											[-1.907, 0.315],
											[-1.019, 0.635],
											[-0.748, 1.666],
											[0.582, 1.344],
											[1.954, 0.002],
											[2.3, 0.958],
											[0.219, 2.217],
											[-4.804, 0.495],
											[-1.473, -0.53],
											[-1.532, -0.043],
											[-3.029, 0.19],
											[-1.457, 0.28],
											[-2.889, 1.825],
											[-2.916, 1.021],
											[-1.516, 0.316],
											[-1.791, 0.336],
											[-1.79, 0.061],
											[-1.427, 0.492],
											[-1.043, 0.764],
											[-0.833, 0.601],
											[-0.8, 0.802],
											[-0.178, 1.623],
											[0.711, 1.141],
											[1.37, 1.481],
											[1.23, 0.937],
											[1.147, 0.245],
											[2.472, -0.457],
											[3.207, 0.419],
											[2.839, 1.561],
											[2.752, 0.378],
											[3.085, -0.732],
											[1.271, -0.537],
											[0.845, -0.085],
											[0.762, -0.296],
											[1.109, -0.6],
											[1.869, 0.057],
											[1.355, 0.82],
											[0.015, 1.61],
											[-3.277, 0.907],
											[-1.913, 1.928],
											[0.062, 0.409],
											[-0.094, 0.208],
											[-0.451, 0.152],
											[-0.117, 0.212],
											[0.037, 2.376],
											[-0.981, 2.374],
											[-1.601, 1.139],
											[-0.771, 1.448],
											[2.082, 2.508],
											[2.563, 0.414],
											[1.216, -0.17],
											[1.119, 0.516],
											[0.636, 1.099],
											[0.899, 0.885],
											[1.614, 0.332],
											[2.927, 0.96],
											[0.852, 1.104],
											[0.882, 0.882],
										],
										o: [
											[-0.988, 0.167],
											[-1.611, 1.504],
											[-0.372, 1.193],
											[0.117, 1.56],
											[-0.516, 8.582],
											[-0.013, 7.232],
											[-0.156, 3.434],
											[-0.011, 3.756],
											[0.3, 6.831],
											[-0.149, 6.025],
											[1.504, 2.402],
											[7.67, 0.974],
											[1.354, -0.105],
											[1.907, -0.315],
											[1.591, -0.99],
											[0.748, -1.666],
											[-0.882, -2.035],
											[-1.955, -0.002],
											[-3.926, -1.635],
											[-0.22, -2.217],
											[1.578, -0.162],
											[1.473, 0.53],
											[1.427, 0.041],
											[3.029, -0.19],
											[3.529, -0.678],
											[2.889, -1.825],
											[1.144, -0.4],
											[1.516, -0.316],
											[1.412, -0.265],
											[1.79, -0.061],
											[0.994, -0.342],
											[1.043, -0.764],
											[1.622, -1.17],
											[0.8, -0.802],
											[0.215, -1.956],
											[-0.711, -1.141],
											[-0.934, -1.009],
											[-1.23, -0.937],
											[-1.907, -0.407],
											[-2.472, 0.457],
											[-2.89, -0.378],
											[-2.839, -1.561],
											[-3.387, -0.465],
											[-3.085, 0.732],
											[-0.859, 0.362],
											[-0.845, 0.085],
											[-1.695, 0.658],
											[-1.109, 0.6],
											[-3.567, -0.11],
											[-1.355, -0.82],
											[-0.022, -2.443],
											[3.277, -0.907],
											[0.155, -0.155],
											[-0.062, -0.409],
											[0.08, -0.178],
											[0.451, -0.153],
											[1.167, -2.124],
											[-0.037, -2.376],
											[0.825, -1.995],
											[1.601, -1.139],
											[1.892, -3.553],
											[-2.082, -2.508],
											[-1.017, -0.164],
											[-1.216, 0.17],
											[-1.559, -0.72],
											[-0.636, -1.099],
											[-2.308, -2.274],
											[-3.183, -0.655],
											[-1.407, -0.461],
											[-0.783, -1.014],
											[-1.079, -1.079],
										],
										v: [
											[2399, 1063],
											[2394.685, 1065.761],
											[2391, 1071],
											[2390.792, 1075.22],
											[2391, 1080],
											[2390.436, 1102.82],
											[2390, 1127],
											[2389.733, 1137.668],
											[2390, 1150],
											[2389.753, 1170.071],
											[2391, 1183],
											[2407.267, 1188.024],
											[2427, 1189],
											[2432.251, 1188.397],
											[2437, 1187],
											[2440.63, 1182.765],
											[2441, 1178],
											[2436.564, 1175.693],
											[2430, 1175],
											[2423.453, 1168.645],
											[2430, 1164],
											[2434.534, 1164.845],
											[2439, 1166],
											[2446.478, 1165.74],
											[2454, 1165],
											[2463.46, 1160.758],
											[2472, 1156],
											[2476.014, 1154.952],
											[2481, 1154],
											[2485.989, 1153.67],
											[2491, 1153],
											[2494.12, 1151.194],
											[2497, 1149],
											[2500.583, 1146.339],
											[2502, 1143],
											[2501.189, 1138.644],
											[2498, 1135],
											[2494.66, 1131.927],
											[2491, 1130],
											[2484.475, 1130.509],
											[2476, 1131],
											[2467.396, 1127.5],
											[2459, 1124],
											[2448.913, 1124.749],
											[2442, 1127],
											[2439.428, 1127.55],
											[2437, 1128],
											[2433.131, 1130.037],
											[2429, 1131],
											[2421.836, 1129.625],
											[2420, 1126],
											[2426.049, 1121.613],
											[2435, 1118],
											[2435.045, 1117.039],
											[2435, 1116],
											[2435.973, 1115.526],
											[2437, 1115],
											[2438.139, 1108.187],
											[2439, 1101],
											[2443.041, 1096.589],
											[2447, 1093],
											[2445.841, 1083.646],
											[2438, 1079],
											[2434.577, 1079.264],
											[2431, 1079],
											[2428.005, 1076.124],
											[2426, 1073],
											[2419, 1068],
											[2408, 1069],
											[2404, 1066],
											[2403, 1062],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 129,
								s: [
									{
										i: [
											[1.127, -0.861],
											[0.246, -0.161],
											[0.188, -0.196],
											[0.098, -0.312],
											[-0.023, -0.508],
											[0.243, -3.508],
											[0.349, -3.909],
											[0.182, -4.197],
											[-0.259, -4.371],
											[-0.048, -2.332],
											[0.052, -2.238],
											[0.145, -2.336],
											[-0.54, -6.152],
											[-0.22, -5.232],
											[-0.006, -3.566],
											[-0.846, -0.698],
											[-3.817, 1.086],
											[-0.933, 0.142],
											[-1.326, -0.137],
											[-1.026, 0.291],
											[-1.446, 1.018],
											[-1.572, 0.461],
											[-1.085, -0.091],
											[-0.883, 0.134],
											[-1.391, 0.582],
											[-1.596, 0.394],
											[-0.87, 0.175],
											[-0.586, 0.071],
											[-5.921, -0.528],
											[-5.139, 0.49],
											[-3.177, 1.389],
											[-1.789, 1.112],
											[-0.518, 0.098],
											[-0.478, 0.326],
											[-1.106, 1.239],
											[-1.162, 1.078],
											[-1.267, 1.003],
											[-1.637, 0.789],
											[-5.787, 2.197],
											[0.599, 5.548],
											[4.368, 0.502],
											[5.037, 0.449],
											[2.825, 0.714],
											[2.729, -0.209],
											[2.906, -1.364],
											[3.767, -0.269],
											[3.704, 0.874],
											[1.688, 0.755],
											[1.763, 0.711],
											[1.908, 0.242],
											[4.946, -0.143],
											[0.334, 4.95],
											[-5.238, 0.956],
											[-3.377, 1.294],
											[-2.012, 1.83],
											[-2.616, 1.786],
											[-3.969, 2.256],
											[1.198, 4.024],
											[1.42, 0.44],
											[1.753, 0.34],
											[0.493, 0.453],
											[0.427, 0.073],
											[6.645, -0.974],
											[5.24, 0.84],
											[2.215, 1.855],
											[0.735, 1.194],
											[1.15, 1.152],
											[0.679, 0.225],
											[2.202, -0.437],
											[1.669, 0.243],
											[1.888, 1.381],
										],
										o: [
											[-0.271, 0.207],
											[-0.246, 0.161],
											[-0.188, 0.196],
											[-0.098, 0.312],
											[0.137, 2.992],
											[-0.243, 3.508],
											[-0.349, 3.909],
											[-0.182, 4.197],
											[0.155, 2.62],
											[0.048, 2.332],
											[-0.052, 2.238],
											[-0.381, 6.162],
											[0.54, 6.151],
											[0.128, 3.037],
											[0.006, 3.566],
											[1.799, 1.485],
											[3.817, -1.085],
											[1.671, -0.255],
											[1.325, 0.137],
											[1.642, -0.466],
											[1.446, -1.018],
											[0.778, -0.228],
											[1.084, 0.091],
											[1.84, -0.28],
											[1.391, -0.582],
											[0.936, -0.231],
											[0.87, -0.175],
											[5.175, -0.624],
											[5.921, 0.528],
											[3.503, -0.333],
											[3.177, -1.389],
											[0.452, -0.281],
											[0.518, -0.098],
											[1.416, -0.965],
											[1.106, -1.239],
											[1.293, -1.199],
											[1.267, -1.003],
											[3.45, -1.662],
											[5.787, -2.197],
											[-0.49, -4.542],
											[-4.368, -0.502],
											[-3.972, -0.354],
											[-2.825, -0.714],
											[-3.607, 0.276],
											[-2.906, 1.364],
											[-3.494, 0.249],
											[-3.704, -0.874],
											[-2.039, -0.912],
											[-1.763, -0.711],
											[-4.883, -0.62],
											[-4.946, 0.143],
											[-0.329, -4.881],
											[5.238, -0.956],
											[2.334, -0.894],
											[2.013, -1.83],
											[4.321, -2.95],
											[3.969, -2.256],
											[-0.565, -1.9],
											[-1.42, -0.44],
											[-0.603, -0.117],
											[-0.493, -0.453],
											[-6.179, -1.054],
											[-6.645, 0.974],
											[-2.407, -0.386],
											[-2.215, -1.855],
											[-0.72, -1.169],
											[-1.15, -1.151],
											[-1.523, -0.505],
											[-2.202, 0.437],
											[-2.321, -0.338],
											[-1.888, -1.381],
										],
										v: [
											[2351, 1061],
											[2350.217, 1061.533],
											[2349.558, 1062.049],
											[2349.121, 1062.791],
											[2349, 1064],
											[2348.773, 1073.778],
											[2347.817, 1084.931],
											[2346.952, 1097.119],
											[2347, 1110],
											[2347.304, 1117.38],
											[2347.296, 1124.187],
											[2347, 1131],
											[2347.549, 1149.698],
											[2349, 1167],
											[2348.961, 1177.754],
											[2350, 1185],
											[2359.65, 1184.72],
											[2368, 1182],
											[2372.484, 1182.027],
											[2376, 1182],
											[2380.553, 1179.496],
											[2385, 1177],
											[2387.921, 1176.93],
											[2391, 1177],
											[2395.683, 1175.586],
											[2400, 1174],
											[2402.762, 1173.38],
											[2405, 1173],
											[2422.027, 1173.399],
											[2439, 1174],
											[2449.286, 1171.084],
											[2457, 1167],
											[2458.48, 1166.534],
											[2460, 1166],
											[2463.69, 1162.585],
											[2467, 1159],
											[2470.741, 1155.692],
											[2475, 1153],
											[2491.037, 1147.914],
											[2501, 1137],
											[2492.91, 1130.431],
											[2478, 1130],
											[2468.067, 1128.078],
											[2460, 1127],
											[2450.62, 1130.006],
											[2441, 1133],
											[2429.645, 1131.753],
											[2421, 1129],
											[2415.402, 1126.498],
											[2410, 1125],
											[2394.088, 1125.747],
											[2385, 1120],
											[2394.22, 1112.309],
											[2409, 1110],
											[2415.288, 1105.669],
											[2422, 1100],
											[2435.639, 1092.805],
											[2441, 1084],
											[2437.891, 1080.829],
											[2433, 1080],
											[2431.368, 1078.967],
											[2430, 1078],
											[2410.296, 1078.84],
											[2392, 1080],
											[2384.746, 1076.106],
											[2380, 1071],
											[2376.97, 1067.292],
											[2374, 1065],
											[2368.11, 1065.304],
											[2362, 1066],
											[2355.605, 1062.601],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 130,
								s: [
									{
										i: [
											[1.237, -0.083],
											[0.286, -0.205],
											[0.377, -0.149],
											[1.904, -1.762],
											[0.948, -1.99],
											[-0.367, -6.767],
											[1.245, -5.267],
											[1.268, -2.104],
											[0.275, -1.825],
											[-0.294, -2.515],
											[-0.123, -2.949],
											[0.07, -2.568],
											[-0.176, -2.284],
											[-0.889, -3.943],
											[-1.193, -1.975],
											[-3.004, -2.543],
											[-1.15, -0.449],
											[-1.301, -0.136],
											[-1.061, -0.169],
											[-4.195, -0.205],
											[-3.671, 0.385],
											[-4.938, 1.272],
											[-7.142, -0.624],
											[-3.55, -1.306],
											[-3.672, -0.502],
											[-5.864, -0.038],
											[-4.974, 0.64],
											[-1.409, 0.728],
											[-1.377, 0.833],
											[-0.977, 0.496],
											[-1.055, 0.389],
											[-2.394, 0.357],
											[-2.178, 0.745],
											[-1.602, 0.963],
											[-1.744, 0.515],
											[-1.206, 0.114],
											[-1.262, 0.273],
											[-4.557, -0.293],
											[-3.607, 1.157],
											[-1.962, 0.517],
											[-5.694, 3.745],
											[-0.464, 3.634],
											[1.962, 1.74],
											[6.197, -0.897],
											[3.902, -0.314],
											[16.197, -1.577],
											[4.762, -0.124],
											[4.047, 0.051],
											[2.705, 0.371],
											[7.23, 0.946],
											[6.718, 0.011],
											[-0.506, 4.016],
											[-5.05, 0.526],
											[-2.869, 0.823],
											[-3.898, 1.139],
											[-2.687, 2.646],
											[-0.658, 0.797],
											[-0.964, 0.608],
											[0.445, 12.242],
											[1.168, 0.535],
											[3.691, 1.152],
											[0.273, 0.041],
											[3.608, 1.113],
											[5.705, -0.522],
											[5.633, -0.498],
											[4.023, 1.529],
											[3.845, 1.694],
											[2.694, 0.63],
											[2.19, 0.236],
											[4.607, 1.08],
											[2.535, 1.535],
										],
										o: [
											[-0.48, 0.033],
											[-0.286, 0.205],
											[-2.435, 0.961],
											[-1.904, 1.762],
											[-2.223, 4.665],
											[0.367, 6.767],
											[-0.653, 2.76],
											[-1.268, 2.103],
											[-0.3, 1.99],
											[0.294, 2.515],
											[0.102, 2.446],
											[-0.07, 2.568],
											[0.251, 3.253],
											[0.889, 3.943],
											[0.834, 1.38],
											[3.004, 2.543],
											[0.734, 0.287],
											[1.301, 0.136],
											[3.55, 0.564],
											[4.195, 0.205],
											[5.104, -0.535],
											[4.938, -1.272],
											[3.128, 0.273],
											[3.55, 1.306],
											[3.569, 0.488],
											[5.864, 0.038],
											[1.987, -0.256],
											[1.409, -0.728],
											[1.039, -0.628],
											[0.977, -0.496],
											[2.244, -0.829],
											[2.394, -0.357],
											[1.848, -0.632],
											[1.602, -0.963],
											[0.914, -0.27],
											[1.206, -0.114],
											[5.756, -1.243],
											[5.727, 0.368],
											[2.288, -0.734],
											[8.785, -2.314],
											[5.195, -3.416],
											[0.317, -2.487],
											[-3.943, -3.497],
											[-4.777, 0.692],
											[-9.881, 0.795],
											[-4.941, 0.481],
											[-3.664, 0.095],
											[-3.131, -0.039],
											[-6.128, -0.84],
											[-9.006, -1.178],
											[-4.73, -0.007],
											[0.753, -5.975],
											[1.364, -0.142],
											[5.214, -1.497],
											[5.765, -1.684],
											[0.693, -0.683],
											[0.678, -0.821],
											[9.503, -5.994],
											[-0.093, -2.564],
											[-3.167, -1.451],
											[-1.671, -0.521],
											[-5.99, -0.905],
											[-5.604, -1.728],
											[-5.129, 0.469],
											[-4.229, 0.374],
											[-4.817, -1.831],
											[-1.807, -0.796],
											[-2.734, -0.639],
											[-7.583, -0.817],
											[-2.844, -0.666],
											[-1.229, -0.744],
										],
										v: [
											[2260, 1061],
											[2258.923, 1061.413],
											[2258, 1062],
											[2251.385, 1066.229],
											[2247, 1072],
											[2245.267, 1090.048],
											[2245, 1109],
											[2241.717, 1116.201],
											[2239, 1122],
											[2239.182, 1128.78],
											[2240, 1137],
											[2239.945, 1144.622],
											[2240, 1152],
											[2241.793, 1163.459],
											[2245, 1173],
											[2251.763, 1179.698],
											[2259, 1185],
											[2262.255, 1185.588],
											[2266, 1186],
											[2277.909, 1187.212],
											[2290, 1187],
											[2304.471, 1183.631],
											[2322, 1182],
											[2332.092, 1184.828],
											[2343, 1188],
											[2357.947, 1188.846],
											[2375, 1188],
											[2379.958, 1186.433],
											[2384, 1184],
											[2386.988, 1182.321],
											[2390, 1181],
											[2397.05, 1179.437],
											[2404, 1178],
											[2409.078, 1175.412],
											[2414, 1173],
											[2417.239, 1172.502],
											[2421, 1172],
											[2433, 1171],
											[2444, 1169],
											[2450, 1168],
											[2472, 1159],
											[2480, 1151],
											[2476, 1141],
											[2458, 1138],
											[2446, 1142],
											[2407, 1137],
											[2393, 1140],
											[2381, 1138],
											[2373, 1139],
											[2355, 1133],
											[2331, 1135],
											[2319, 1131],
											[2344, 1129],
											[2350, 1127],
											[2363, 1124],
											[2373, 1119],
											[2374, 1117],
											[2377, 1116],
											[2411, 1094],
											[2404, 1084],
											[2383, 1082],
											[2380, 1082],
											[2367, 1079],
											[2348, 1076],
											[2333, 1081],
											[2318, 1079],
											[2306, 1070],
											[2298, 1069],
											[2291, 1067],
											[2271, 1067],
											[2261, 1061],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 131,
								s: [
									{
										i: [
											[5.344, -0.769],
											[9.804, -0.266],
											[11.815, 0.277],
											[4.905, -0.374],
											[3.376, -1.991],
											[2.424, -4.895],
											[0.883, -2.073],
											[2.491, -7.447],
											[0.557, -3.786],
											[-1.867, -8.04],
											[-1.19, -2.801],
											[-2.186, -2.348],
											[-0.647, -0.625],
											[-0.607, -0.464],
											[-0.908, -0.604],
											[-4.623, -0.672],
											[-5.603, 0.389],
											[-2.691, 0.33],
											[-2.715, 0.112],
											[-3.01, -0.139],
											[-2.881, -0.417],
											[-3.844, -1.197],
											[-3.805, -0.423],
											[-4.959, 0.252],
											[-4.242, 0.645],
											[-5.468, 0.827],
											[-5.291, -1.456],
											[-1.438, -0.433],
											[-5.426, -1.838],
											[-1.266, -1.213],
											[-1.537, -0.192],
											[-10.454, 1.648],
											[-2.146, 0.374],
											[-1.244, 0.23],
											[-6.202, 1.81],
											[-12.074, 1.301],
											[-7.957, 0.847],
											[-5.993, 2.399],
											[-0.932, 0.563],
											[3.89, 8.22],
											[8.252, 0.384],
											[3.823, 1.37],
											[4.418, 2.762],
											[3.465, 1.284],
											[1.642, 0.272],
											[9.151, -1.983],
											[3.993, -1.576],
											[2.906, -0.955],
											[6.261, 0.117],
											[4.473, 1.536],
											[2.504, 0.717],
											[3.289, -0.107],
											[6.925, -0.383],
											[2.447, 0.978],
											[0.535, 2.994],
											[-0.936, 1.312],
											[-3.269, 1.424],
											[-4.056, 0.513],
											[-3.198, 0.391],
											[-1.215, 8.711],
											[1.21, 1.304],
											[3.898, 1.649],
											[2.208, 0.39],
											[1.583, -0.054],
											[6.366, 0.611],
											[8.598, 1.473],
											[10.439, 0.174],
											[1.784, 0.459],
											[1.987, 1.138],
											[0.793, 0.375],
											[0.469, 0.221],
										],
										o: [
											[-6.199, 0.893],
											[-10.831, 0.294],
											[-3.922, -0.092],
											[-5.495, 0.419],
											[-3.658, 2.158],
											[-1.18, 2.383],
											[-3.335, 7.83],
											[-1.88, 5.619],
											[-1.413, 9.598],
											[0.835, 3.597],
											[1.675, 3.944],
											[0.615, 0.66],
											[0.588, 0.568],
											[0.743, 0.568],
											[4.722, 3.142],
											[5.369, 0.78],
											[2.884, -0.2],
											[2.861, -0.351],
											[2.809, -0.116],
											[3.481, 0.161],
											[3.604, 0.521],
											[3.942, 1.228],
											[4.608, 0.512],
											[5.184, -0.264],
											[6.571, -1],
											[9.746, -1.474],
											[2.058, 0.566],
											[5.286, 1.59],
											[3.342, 1.132],
											[1.343, 1.287],
											[15.979, 1.995],
											[2.241, -0.353],
											[1.122, -0.195],
											[6.913, -1.279],
											[13.415, -3.916],
											[7.289, -0.785],
											[10.008, -1.066],
											[1.533, -0.614],
											[6.859, -4.145],
											[-3.262, -6.892],
											[-7.037, -0.328],
											[-6.249, -2.239],
											[-4.252, -2.658],
											[-1.1, -0.407],
											[-11.675, -1.937],
											[-5.507, 1.193],
											[-2.865, 1.131],
											[-5.141, 1.689],
											[-5.337, -0.1],
											[-2.208, -0.759],
											[-2.985, -0.855],
											[-5.503, 0.179],
											[-2.999, 0.166],
											[-3.353, -1.34],
											[-0.609, -3.405],
											[2.083, -2.92],
											[4.955, -2.159],
											[3.967, -0.502],
											[9.11, -1.113],
											[0.619, -4.438],
											[-1.583, -1.706],
											[-3.164, -1.339],
											[-0.207, -0.037],
											[-7.443, 0.254],
											[-10.257, -0.985],
											[-11.932, -2.045],
											[-2.319, -0.039],
											[-3.891, -1],
											[-0.758, -0.434],
											[-0.385, -0.182],
											[-5.737, -2.71],
										],
										v: [
											[2072, 1061],
											[2047, 1067],
											[2014, 1061],
											[1999, 1062],
											[1985, 1064],
											[1978, 1076],
											[1974, 1082],
											[1967, 1104],
											[1962, 1119],
											[1966, 1151],
											[1968, 1161],
											[1977, 1173],
											[1978, 1176],
											[1981, 1177],
											[1983, 1179],
											[1999, 1187],
											[2016, 1186],
											[2025, 1186],
											[2033, 1184],
											[2042, 1185],
											[2052, 1185],
											[2063, 1189],
											[2075, 1192],
											[2089, 1191],
											[2104, 1191],
											[2121, 1185],
											[2152, 1186],
											[2157, 1187],
											[2172, 1194],
											[2182, 1196],
											[2188, 1198],
											[2233, 1196],
											[2239, 1196],
											[2243, 1194],
											[2263, 1190],
											[2300, 1178],
											[2323, 1177],
											[2349, 1172],
											[2354, 1170],
											[2358, 1151],
											[2341, 1142],
											[2326, 1140],
											[2311, 1131],
											[2300, 1123],
											[2294, 1122],
											[2256, 1123],
											[2244, 1127],
											[2236, 1130],
											[2219, 1134],
											[2205, 1130],
											[2198, 1128],
											[2190, 1126],
											[2174, 1131],
											[2164, 1130],
											[2159, 1124],
											[2160, 1117],
											[2167, 1115],
											[2181, 1111],
											[2192, 1111],
											[2212, 1096],
											[2209, 1082],
											[2200, 1079],
											[2192, 1074],
											[2187, 1075],
											[2169, 1076],
											[2145, 1067],
											[2112, 1071],
											[2104, 1070],
											[2097, 1065],
											[2094, 1065],
											[2093, 1063],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 132,
								s: [
									{
										i: [
											[1.437, -0.538],
											[1.549, -1.152],
											[0.58, -0.933],
											[0.267, -1.689],
											[0.428, -1.428],
											[0.576, -1.47],
											[0.185, -1.421],
											[2.228, -6.648],
											[2.009, -2.733],
											[2.325, -7.819],
											[-3.598, -9.327],
											[-2.699, -3.103],
											[-1.917, -1.425],
											[-1.405, -0.773],
											[-1.991, -0.753],
											[-7.432, 1.239],
											[-1.634, 0.236],
											[-3.936, 0.498],
											[-1.15, 0.192],
											[-1.854, 0.289],
											[-13.73, -2.386],
											[-3.814, -1.891],
											[-1.599, -0.65],
											[-1.375, -0.471],
											[-3.011, -0.728],
											[-8.18, 0.79],
											[-1.951, 0.387],
											[-4.514, 1.2],
											[-2.813, 0.323],
											[-13.249, 0.034],
											[-3.876, 0.211],
											[-5.629, 0.96],
											[-1.82, 0.58],
											[-1.027, 0.507],
											[-1.194, 1.023],
											[-0.862, 0.962],
											[-0.318, 0.537],
											[0.556, 3.434],
											[6.127, 0.439],
											[4.484, -1.019],
											[10.062, 1.252],
											[8.119, -1.188],
											[7.078, -1.083],
											[4.54, 0.563],
											[1.173, 3.725],
											[-5.668, 1.83],
											[-1.627, 0.99],
											[-1.445, 1.958],
											[-1.582, 1.38],
											[-1.898, 1.307],
											[-0.958, 0.858],
											[-0.971, 0.843],
											[-0.891, 0.641],
											[-1.184, 0.987],
											[-0.741, 5.042],
											[3.212, 3.393],
											[3.258, 0.745],
											[7.549, -2.699],
											[5.555, -0.562],
											[4.086, 1.659],
											[6.787, 1.539],
											[5.127, -0.216],
											[6.39, 3.065],
											[2.177, 1.533],
											[0.629, 0.387],
											[0.736, 0.468],
											[2.946, 1.68],
											[0.522, 0.247],
											[0.732, 0.446],
											[2.578, 0.504],
											[1.946, -0.112],
										],
										o: [
											[-1.226, 0.458],
											[-1.549, 1.152],
											[-0.506, 0.815],
											[-0.266, 1.689],
											[-0.509, 1.699],
											[-0.577, 1.47],
											[-1.093, 8.388],
											[-1.016, 3.033],
											[-4.695, 6.387],
											[-2.997, 10.078],
											[1.765, 4.575],
											[0.921, 1.059],
											[1.585, 1.179],
											[1.525, 0.839],
											[6.677, 2.526],
											[1.692, -0.282],
											[3.925, -0.568],
											[1.317, -0.167],
											[2.1, -0.35],
											[11.405, -1.78],
											[5.476, 0.952],
											[1.374, 0.681],
											[1.627, 0.662],
											[2.506, 0.858],
											[6.941, 1.678],
											[1.926, -0.186],
											[5.656, -1.12],
											[2.441, -0.649],
											[12.322, -1.414],
											[3.582, -0.009],
											[6.348, -0.345],
											[0.703, -0.12],
											[1.244, -0.397],
											[1.911, -0.943],
											[1.151, -0.987],
											[0.491, -0.548],
											[2.277, -3.844],
											[-0.907, -5.608],
											[-5.691, -0.408],
											[-9.155, 2.08],
											[-7.368, -0.917],
											[-4.737, 0.693],
											[-5.06, 0.774],
											[-6.542, -0.811],
											[-3.229, -10.254],
											[3.412, -1.102],
											[2.762, -1.681],
											[1.565, -2.12],
											[2.242, -1.956],
											[0.951, -0.655],
											[0.809, -0.725],
											[0.823, -0.714],
											[1.426, -1.027],
											[3.683, -3.072],
											[1, -6.811],
											[-2.13, -2.25],
											[-12.824, -2.934],
											[-5.052, 1.806],
											[-7.374, 0.745],
											[-4.616, -1.874],
											[-4.393, -0.996],
											[-12.686, 0.535],
											[-3.231, -1.55],
											[-0.647, -0.455],
											[-0.926, -0.57],
											[-2.534, -1.609],
											[-0.436, -0.249],
											[-0.78, -0.369],
											[-2.402, -1.465],
											[-2.474, -0.484],
											[-4.236, 0.243],
										],
										v: [
											[1728, 1062],
											[1723.515, 1064.644],
											[1720, 1068],
											[1718.941, 1072.04],
											[1718, 1077],
											[1716.257, 1081.708],
											[1715, 1086],
											[1714, 1111],
											[1708, 1119],
											[1698, 1138],
											[1700, 1163],
											[1706, 1176],
											[1711, 1179],
											[1715, 1183],
											[1721, 1185],
											[1743, 1186],
											[1747, 1184],
											[1759, 1184],
											[1763, 1182],
											[1769, 1182],
											[1811, 1177],
											[1823, 1183],
											[1828, 1184],
											[1832, 1187],
											[1841, 1189],
											[1865, 1191],
											[1871, 1189],
											[1886, 1187],
											[1893, 1185],
											[1931, 1187],
											[1942, 1186],
											[1961, 1186],
											[1965, 1184],
											[1970, 1183],
											[1974, 1179],
											[1978, 1177],
											[1979, 1174],
											[1984, 1164],
											[1967, 1154],
											[1951, 1157],
											[1931, 1154],
											[1904, 1154],
											[1887, 1160],
											[1870, 1160],
											[1858, 1154],
											[1874, 1138],
											[1883, 1136],
											[1887, 1130],
											[1892, 1125],
											[1899, 1121],
											[1903, 1119],
											[1905, 1115],
											[1907, 1112],
											[1911, 1110],
											[1916, 1103],
											[1911, 1091],
											[1901, 1084],
											[1871, 1089],
											[1855, 1094],
											[1832, 1092],
											[1817, 1080],
											[1802, 1081],
											[1770, 1078],
											[1763, 1073],
											[1761, 1072],
											[1759, 1070],
											[1750, 1066],
											[1749, 1064],
											[1746, 1064],
											[1742, 1061],
											[1736, 1062],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 133,
								s: [
									{
										i: [
											[1.841, -0.506],
											[1.59, -1.023],
											[1.161, -1.501],
											[1.132, -2.841],
											[1.318, -3.143],
											[0.434, -0.587],
											[0.137, -0.354],
											[1.043, -3.297],
											[1.621, -1.693],
											[0.298, -0.539],
											[0.408, -0.425],
											[0.397, -0.923],
											[0.396, -1.246],
											[0.199, -2.131],
											[0.376, -1.869],
											[0.646, -2.059],
											[-0.068, -2.357],
											[-0.606, -1.39],
											[-0.53, -1.809],
											[-0.211, -1.913],
											[-0.613, -1.494],
											[-0.94, -1.159],
											[-0.805, -1.309],
											[-1.563, -2.237],
											[-1.948, -2.125],
											[-0.597, -0.836],
											[-0.639, -0.595],
											[-0.516, -0.073],
											[-0.52, -0.321],
											[-6.363, -0.741],
											[-4.185, 1.655],
											[-3.941, 1.209],
											[-3.898, -0.155],
											[-4.145, -1.587],
											[-4.62, -0.501],
											[-6.761, 0.472],
											[-3.63, 0.583],
											[-3.482, 0.47],
											[-3.146, 0.49],
											[-1.635, 0.378],
											[-1.739, 0.222],
											[-7.919, 0.095],
											[-7.514, 0.626],
											[-1.535, 0.408],
											[-1.705, 0.271],
											[-3.047, 0.529],
											[-2.19, 1.309],
											[-0.059, 0.183],
											[4.545, 1.716],
											[8.454, 1.009],
											[7.482, 1.534],
											[7.653, -0.24],
											[7.503, 6.02],
											[-0.163, 3.601],
											[-5.515, 3.507],
											[-2.064, 2.631],
											[0.894, 5.382],
											[5.841, 1.511],
											[5.785, 1.945],
											[0.494, 0.253],
											[1.252, 0.799],
											[1.619, 1.142],
											[0.5, 0.225],
											[0.295, 0.246],
											[3.561, 0.602],
											[6.657, -0.024],
											[3.519, 0.854],
											[3.02, 1.189],
											[2.803, 1.32],
											[0.457, 0.229],
											[1.118, 0.255],
										],
										o: [
											[-1.48, 0.407],
											[-1.59, 1.023],
											[-1.155, 1.493],
											[-1.132, 2.841],
											[-0.125, 0.298],
											[-0.435, 0.587],
											[-1.208, 3.12],
											[-1.043, 3.297],
											[-0.401, 0.419],
											[-0.298, 0.539],
											[-1.017, 1.062],
											[-0.397, 0.923],
											[-0.829, 2.605],
											[-0.199, 2.131],
											[-0.484, 2.406],
											[-0.646, 2.059],
											[0.047, 1.631],
											[0.606, 1.39],
											[0.543, 1.856],
											[0.211, 1.913],
											[0.434, 1.055],
											[0.94, 1.159],
											[1.8, 2.928],
											[1.563, 2.237],
											[0.975, 1.063],
											[0.597, 0.836],
											[0.414, 0.386],
											[0.516, 0.073],
											[4.364, 2.69],
											[6.363, 0.741],
											[3.34, -1.321],
											[3.941, -1.209],
											[3.802, 0.151],
											[4.145, 1.587],
											[4.324, 0.47],
											[6.762, -0.472],
											[3.924, -0.63],
											[3.482, -0.47],
											[1.72, -0.267],
											[1.635, -0.378],
											[7.809, -0.995],
											[7.919, -0.095],
											[1.155, -0.096],
											[1.535, -0.408],
											[3.622, -0.577],
											[3.047, -0.529],
											[1.422, -0.85],
											[3.304, -10.22],
											[-10.097, -3.812],
											[-8.686, -1.037],
											[-4.803, -0.985],
											[-14.056, 0.441],
											[-3.044, -2.443],
											[0.298, -6.575],
											[2.772, -1.763],
											[2.147, -2.737],
											[-0.64, -3.858],
											[-6.199, -1.604],
											[-0.387, -0.13],
											[-1.367, -0.7],
											[-1.999, -1.276],
											[-0.441, -0.311],
											[-0.274, -0.124],
											[-2.75, -2.292],
											[-7.85, -1.326],
											[-3.594, 0.013],
											[-3.991, -0.968],
											[-3.689, -1.452],
											[-0.38, -0.179],
											[-1.013, -0.506],
											[-3.291, -0.75],
										],
										v: [
											[1636, 1056],
											[1631.261, 1058.18],
											[1627, 1062],
											[1623.623, 1068.762],
											[1620, 1078],
											[1619.009, 1079.458],
											[1618, 1081],
											[1614.81, 1091.071],
											[1611, 1099],
											[1610.005, 1100.496],
											[1609, 1102],
											[1607.034, 1104.862],
											[1606, 1108],
											[1604.661, 1115.052],
											[1604, 1121],
											[1602.086, 1127.537],
											[1601, 1134],
											[1602.138, 1138.367],
											[1604, 1143],
											[1604.948, 1148.771],
											[1606, 1154],
											[1608.221, 1157.31],
											[1611, 1161],
											[1615.889, 1168.602],
											[1621, 1175],
											[1623.252, 1177.851],
											[1625, 1180],
											[1626.421, 1180.549],
											[1628, 1181],
											[1645.134, 1186.258],
											[1662, 1185],
											[1673.082, 1180.893],
											[1685, 1179],
											[1696.886, 1182.238],
											[1710, 1186],
											[1728.021, 1185.79],
											[1745, 1184],
											[1756.083, 1182.394],
											[1766, 1181],
											[1770.986, 1179.966],
											[1776, 1179],
											[1799.722, 1177.723],
											[1823, 1177],
											[1827.088, 1176.132],
											[1832, 1175],
											[1842.074, 1173.55],
											[1850, 1171],
											[1856, 1165],
											[1847, 1144],
											[1817, 1145],
											[1795, 1134],
											[1774, 1134],
											[1742, 1130],
											[1736, 1120],
											[1749, 1107],
											[1757, 1102],
											[1760, 1092],
											[1748, 1082],
											[1727, 1080],
											[1725, 1078],
											[1721, 1077],
											[1716, 1072],
											[1714, 1072],
											[1713, 1070],
											[1703, 1065],
											[1680, 1068],
											[1669, 1066],
											[1658, 1063],
											[1649, 1058],
											[1648, 1056],
											[1644, 1056],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[0.99, -0.033],
											[3.078, -1.165],
											[1.825, -2.277],
											[0.465, -1.216],
											[0.486, -1.321],
											[0.283, -1.433],
											[0.396, -0.986],
											[1.12, -1.844],
											[0.81, -1.856],
											[0.971, -2.224],
											[0.16, -1.916],
											[-0.24, -1.137],
											[-0.088, -0.894],
											[-0.549, -3.233],
											[0.525, -3.705],
											[2.197, -4.756],
											[0.677, -5.19],
											[0.092, -3.059],
											[-0.637, -1.715],
											[-1.516, -1.119],
											[-1.715, -0.922],
											[-3.606, -1.067],
											[-3.479, -1.066],
											[-4.802, -0.217],
											[-4.226, 1.267],
											[-7.642, -0.839],
											[-8.653, 1.323],
											[-1.392, 0.618],
											[-1.324, 0.36],
											[-1.716, 0.253],
											[-1.351, 0.504],
											[-0.376, 0.449],
											[-0.278, 0.121],
											[-0.65, 0.068],
											[-0.592, 0.374],
											[-0.934, 0.928],
											[-0.204, 0.62],
											[0.309, 3.131],
											[0.171, 0.234],
											[1.441, 0.046],
											[2.548, 0.876],
											[1.1, 0.804],
											[1.13, 0.28],
											[1.698, -0.016],
											[1.376, 0.61],
											[1.871, 1.716],
											[0.437, 0.659],
											[0.349, 0.284],
											[0.292, 0.389],
											[-0.355, 1.331],
											[-1.269, 1.423],
											[-1.29, 0.886],
											[-1.509, 0.961],
											[-0.74, 0.716],
											[-0.53, 1.176],
											[-0.451, 0.155],
											[-0.119, 0.207],
											[-0.327, 2.245],
											[0.809, 2.393],
											[2.969, 0.916],
											[4.589, 1.803],
											[1.629, 0.917],
											[1.53, 0.959],
											[1.61, 1.59],
											[0.673, 1.457],
											[0.383, 0.924],
											[1.59, 2.064],
											[0.343, 0.211],
											[1.957, 1.077],
											[3.864, 2.282],
											[2.217, 1.594],
										],
										o: [
											[-1.862, 0.063],
											[-3.078, 1.165],
											[-0.653, 0.814],
											[-0.465, 1.216],
											[-0.472, 1.283],
											[-0.283, 1.433],
											[-0.71, 1.766],
											[-1.12, 1.844],
											[-0.955, 2.187],
											[-0.971, 2.224],
											[-0.046, 0.557],
											[0.24, 1.138],
											[0.33, 3.365],
											[0.549, 3.233],
											[-0.537, 3.787],
											[-2.197, 4.756],
											[-0.267, 2.049],
											[-0.092, 3.059],
											[0.223, 0.601],
											[1.516, 1.119],
											[3.099, 1.667],
											[3.606, 1.067],
											[4.564, 1.398],
											[4.802, 0.217],
											[6.778, -2.033],
											[7.642, 0.839],
											[1.107, -0.169],
											[1.392, -0.618],
											[1.784, -0.485],
											[1.716, -0.253],
											[0.22, -0.082],
											[0.376, -0.449],
											[0.806, -0.353],
											[0.65, -0.068],
											[1.061, -0.669],
											[0.934, -0.928],
											[0.408, -1.243],
											[-0.309, -3.131],
											[-0.688, -0.941],
											[-1.441, -0.046],
											[-1.469, -0.505],
											[-1.1, -0.804],
											[-1.833, -0.455],
											[-1.698, 0.016],
											[-1.08, -0.478],
											[-1.871, -1.716],
											[-0.314, -0.473],
											[-0.349, -0.284],
											[-1.689, -2.252],
											[0.355, -1.331],
											[1.332, -1.494],
											[1.29, -0.886],
											[1.508, -0.96],
											[0.74, -0.716],
											[0.079, -0.175],
											[0.451, -0.155],
											[1.503, -2.626],
											[0.327, -2.245],
											[-1.534, -4.534],
											[-2.969, -0.916],
											[-0.953, -0.374],
											[-1.629, -0.917],
											[-1.887, -1.182],
											[-1.61, -1.59],
											[-0.517, -1.119],
											[-1.056, -2.548],
											[-0.241, -0.313],
											[-2.458, -1.513],
											[-3.621, -1.993],
											[-3.124, -1.845],
											[-2.489, -1.789],
										],
										v: [
											[1591, 1057],
											[1582.972, 1058.839],
											[1575, 1064],
											[1573.374, 1067.121],
											[1572, 1071],
											[1570.944, 1075.222],
											[1570, 1079],
											[1567.075, 1084.432],
											[1564, 1090],
											[1560.904, 1096.704],
											[1559, 1103],
											[1559.4, 1105.747],
											[1560, 1109],
											[1561.641, 1118.746],
											[1562, 1129],
											[1557.105, 1141.948],
											[1552, 1157],
											[1551.322, 1165.251],
											[1552, 1173],
											[1554.881, 1175.76],
											[1560, 1179],
											[1570.214, 1182.95],
											[1581, 1186],
											[1595.254, 1188.499],
											[1609, 1187],
											[1630.594, 1186.468],
											[1655, 1187],
											[1658.837, 1185.643],
											[1663, 1184],
											[1668.325, 1183.015],
											[1673, 1182],
											[1673.957, 1181.03],
											[1675, 1180],
											[1677.161, 1179.515],
											[1679, 1179],
											[1682.143, 1176.463],
											[1684, 1174],
											[1683.934, 1166.243],
											[1683, 1160],
											[1679.895, 1158.952],
											[1674, 1158],
											[1670.246, 1155.832],
											[1667, 1154],
											[1661.658, 1153.616],
											[1657, 1153],
											[1652.018, 1149.135],
											[1648, 1145],
											[1646.983, 1143.937],
											[1646, 1143],
											[1644.282, 1137.878],
											[1647, 1134],
											[1650.868, 1130.6],
											[1655, 1128],
											[1658.234, 1125.662],
											[1660, 1123],
											[1660.97, 1122.524],
											[1662, 1122],
											[1664.734, 1114.825],
											[1664, 1108],
											[1657.292, 1100.952],
											[1646, 1098],
											[1641.933, 1095.938],
											[1637, 1093],
											[1631.589, 1088.706],
											[1628, 1084],
											[1626, 1081],
											[1624, 1074],
											[1622, 1073],
											[1616, 1068],
											[1604, 1064],
											[1597, 1057],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[0.377, -0.209],
											[0.288, -0.042],
											[0.277, -0.027],
											[0.226, -0.096],
											[0.134, -0.251],
											[0.204, -0.961],
											[0.11, -1.082],
											[0.101, -1.08],
											[0.176, -0.955],
											[0.266, -1.056],
											[0.284, -1.018],
											[0.251, -0.952],
											[0.166, -0.86],
											[0.072, -1.027],
											[0.025, -0.996],
											[0.066, -0.971],
											[0.193, -0.952],
											[0.366, -0.973],
											[0.414, -0.992],
											[0.341, -1.023],
											[0.146, -1.066],
											[-0.045, -1.393],
											[-0.054, -1.535],
											[0.135, -1.599],
											[0.643, -2.518],
											[0.228, -2.559],
											[-0.099, -1.878],
											[0.246, -1.794],
											[0.429, -1.36],
											[0.115, -1.333],
											[0.021, -2.244],
											[-0.268, -1.887],
											[-0.636, -0.996],
											[-0.311, -0.979],
											[-0.077, -1.203],
											[-0.983, -0.955],
											[-6.481, 0.409],
											[-3.88, 0.468],
											[-2.61, 0.216],
											[-1.936, 0.699],
											[-2.469, 2.23],
											[-2.045, 0.967],
											[-1.58, 0.213],
											[-1.486, 0.583],
											[-1.275, 1.884],
											[0.798, 3.404],
											[3.806, 2.001],
											[0.917, 4.339],
											[-0.336, 2.24],
											[0.086, -0.169],
											[-2.231, 0.866],
											[-1.064, 1.553],
											[2.309, 2.669],
											[1.456, 1.394],
											[0.479, 2.534],
											[1.072, 2.935],
											[0.448, 0.383],
											[0.133, 0.26],
											[0.95, 1.338],
											[2.073, 1.447],
											[1.2, 0.582],
											[0.914, 0.774],
											[1.321, 2.051],
											[1.077, 1.409],
											[0.38, 0.358],
											[0.267, 0.289],
											[1.444, 0.156],
											[4.816, 0.774],
											[0.584, 0.468],
											[0.439, 0.091],
											[1.247, -0.283],
										],
										o: [
											[-0.258, 0.143],
											[-0.288, 0.042],
											[-0.277, 0.027],
											[-0.226, 0.096],
											[-0.383, 0.718],
											[-0.204, 0.961],
											[-0.11, 1.082],
											[-0.101, 1.08],
											[-0.197, 1.068],
											[-0.266, 1.056],
											[-0.284, 1.018],
											[-0.251, 0.952],
											[-0.206, 1.063],
											[-0.072, 1.027],
											[-0.025, 0.996],
											[-0.066, 0.971],
											[-0.196, 0.965],
											[-0.366, 0.973],
											[-0.414, 0.992],
											[-0.341, 1.023],
											[-0.161, 1.172],
											[0.045, 1.393],
											[0.054, 1.535],
											[-0.2, 2.368],
											[-0.643, 2.518],
											[-0.151, 1.695],
											[0.099, 1.878],
											[-0.168, 1.226],
											[-0.429, 1.36],
											[-0.184, 2.139],
											[-0.021, 2.244],
											[0.147, 1.037],
											[0.636, 0.996],
											[0.708, 2.232],
											[0.077, 1.203],
											[3.195, 3.102],
											[6.481, -0.409],
											[3.626, -0.437],
											[2.61, -0.216],
											[3.081, -1.113],
											[2.469, -2.23],
											[1.194, -0.565],
											[1.58, -0.213],
											[2.699, -1.058],
											[1.275, -1.885],
											[-0.858, -3.656],
											[-3.806, -2.001],
											[-0.255, -1.208],
											[0.335, -2.24],
											[1.014, -1.984],
											[2.231, -0.866],
											[2.691, -3.928],
											[-2.309, -2.669],
											[-2.013, -1.928],
											[-0.479, -2.534],
											[-0.077, -0.209],
											[-0.448, -0.383],
											[-1.126, -2.199],
											[-0.95, -1.339],
											[-1.285, -0.898],
											[-1.2, -0.582],
											[-1.638, -1.387],
											[-1.321, -2.051],
											[-0.213, -0.279],
											[-0.38, -0.358],
											[-2.407, -2.604],
											[-1.444, -0.156],
											[-0.224, -0.036],
											[-0.584, -0.468],
											[-1.635, -0.341],
											[-1.247, 0.283],
										],
										v: [
											[1564, 1063],
											[1563.172, 1063.257],
											[1562.314, 1063.339],
											[1561.549, 1063.501],
											[1561, 1064],
											[1560.139, 1066.55],
											[1559.688, 1069.645],
											[1559.393, 1072.918],
											[1559, 1076],
											[1558.293, 1079.194],
											[1557.455, 1082.312],
											[1556.639, 1085.274],
											[1556, 1088],
											[1555.605, 1091.133],
											[1555.482, 1094.166],
											[1555.367, 1097.116],
											[1555, 1100],
											[1554.127, 1102.903],
											[1552.925, 1105.847],
											[1551.762, 1108.868],
											[1551, 1112],
											[1550.875, 1115.868],
											[1551.073, 1120.28],
											[1551, 1125],
											[1549.521, 1132.357],
											[1548, 1140],
											[1548.071, 1145.425],
											[1548, 1151],
											[1546.96, 1154.92],
											[1546, 1159],
											[1545.661, 1165.689],
											[1546, 1172],
											[1547.377, 1175.044],
											[1549, 1178],
											[1549.794, 1182.958],
											[1551, 1186],
											[1566.986, 1189.177],
											[1584, 1187],
											[1593.267, 1186.197],
											[1600, 1185],
											[1608.277, 1179.391],
											[1615, 1174],
											[1619.281, 1173.014],
											[1624, 1172],
											[1630.123, 1167.76],
											[1631, 1160],
											[1622.545, 1152.512],
											[1614, 1144],
											[1614.373, 1137.967],
											[1615, 1134],
											[1620.463, 1130.176],
											[1626, 1127],
											[1625.11, 1117.1],
											[1618, 1111],
											[1614.794, 1104.255],
											[1613, 1096],
											[1612.042, 1095.038],
											[1611, 1094],
											[1608.211, 1088.936],
											[1604, 1085],
											[1600.222, 1082.908],
											[1597, 1081],
											[1592.58, 1075.517],
											[1589, 1070],
											[1588.041, 1069.007],
											[1587, 1068],
											[1582.306, 1064.628],
											[1574, 1064],
											[1572.662, 1063.042],
											[1571, 1062],
											[1566.557, 1062.088],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[-0.731, -1.929],
											[-1.579, 0.174],
											[-1.568, 0.133],
											[-1.455, 0.268],
											[-1.24, 0.578],
											[-0.247, 0.242],
											[-0.287, 0.315],
											[-0.271, 0.291],
											[-0.2, 0.168],
											[-0.566, 0.483],
											[-0.493, 0.469],
											[-0.431, 0.501],
											[-0.379, 0.579],
											[-0.481, 0.593],
											[-0.474, 0.376],
											[-0.631, 0.345],
											[-0.951, 0.501],
											[-0.865, 0.766],
											[-0.534, 0.919],
											[-0.402, 1.194],
											[-0.468, 1.588],
											[-0.084, 0.391],
											[-0.069, 0.4],
											[-0.076, 0.434],
											[-0.104, 0.492],
											[-0.076, 0.567],
											[-0.075, 0.587],
											[-0.087, 0.477],
											[-0.111, 0.236],
											[-0.468, 0.525],
											[-0.543, 0.44],
											[-0.554, 0.445],
											[-0.501, 0.539],
											[-0.536, 0.607],
											[-0.452, 0.642],
											[-0.315, 0.714],
											[-0.124, 0.823],
											[0.081, 1.011],
											[0.193, 1.225],
											[0.265, 1.102],
											[0.299, 0.642],
											[0.498, 0.587],
											[0.574, 0.504],
											[0.54, 0.415],
											[0.393, 0.321],
											[0.37, 0.445],
											[0.271, 0.422],
											[0.266, 0.392],
											[0.352, 0.352],
											[0.362, 0.072],
											[0.366, 0.07],
											[0.249, 0.228],
											[0.679, 0.902],
											[0.409, 0.591],
											[0.891, 2.796],
											[1.092, 1.876],
											[2.175, 2.028],
											[1.403, 2.347],
											[0.022, 1.071],
											[0.378, 0.823],
											[0.451, 0.17],
											[0.126, 0.168],
											[0.087, 0.54],
											[0.319, 0.394],
											[2.46, 0.691],
											[0.277, -3.088],
											[-0.356, -12.016],
											[0.555, -12.233],
											[-0.005, -7.115],
											[0.673, -5.921],
											[0.623, -1.744],
										],
										o: [
											[1.488, -0.389],
											[1.579, -0.174],
											[1.568, -0.133],
											[1.455, -0.268],
											[0.153, -0.071],
											[0.247, -0.242],
											[0.287, -0.315],
											[0.271, -0.291],
											[0.649, -0.544],
											[0.566, -0.483],
											[0.493, -0.469],
											[0.431, -0.501],
											[0.652, -0.996],
											[0.481, -0.593],
											[0.474, -0.376],
											[0.631, -0.345],
											[1.394, -0.734],
											[0.865, -0.766],
											[0.534, -0.92],
											[0.402, -1.194],
											[0.12, -0.408],
											[0.084, -0.391],
											[0.069, -0.4],
											[0.076, -0.433],
											[0.088, -0.417],
											[0.076, -0.567],
											[0.075, -0.587],
											[0.087, -0.477],
											[0.329, -0.7],
											[0.468, -0.525],
											[0.543, -0.44],
											[0.554, -0.445],
											[0.567, -0.611],
											[0.536, -0.607],
											[0.452, -0.642],
											[0.315, -0.714],
											[0.069, -0.46],
											[-0.081, -1.011],
											[-0.193, -1.225],
											[-0.265, -1.102],
											[-0.309, -0.664],
											[-0.498, -0.587],
											[-0.574, -0.504],
											[-0.54, -0.415],
											[-0.562, -0.458],
											[-0.37, -0.445],
											[-0.271, -0.422],
											[-0.266, -0.392],
											[-0.234, -0.234],
											[-0.362, -0.072],
											[-0.366, -0.07],
											[-0.874, -0.8],
											[-0.679, -0.902],
											[-1.345, -1.939],
											[-0.891, -2.796],
											[-0.898, -1.542],
											[-2.175, -2.028],
											[-0.534, -0.894],
											[-0.022, -1.071],
											[-0.07, -0.153],
											[-0.451, -0.17],
											[-0.334, -0.446],
											[-0.087, -0.54],
											[-1.885, -2.324],
											[-2.459, -0.691],
											[-0.87, 9.702],
											[0.356, 12.016],
											[-0.346, 7.619],
											[0.005, 7.115],
											[-0.238, 2.097],
											[-0.623, 1.744],
										],
										v: [
											[1533, 1188],
											[1537.626, 1187.2],
											[1542.372, 1186.783],
											[1546.932, 1186.225],
											[1551, 1185],
											[1551.614, 1184.506],
											[1552.429, 1183.645],
											[1553.279, 1182.713],
											[1554, 1182],
											[1555.819, 1180.471],
											[1557.404, 1179.053],
											[1558.787, 1177.609],
											[1560, 1176],
											[1561.659, 1173.663],
											[1563.051, 1172.257],
											[1564.668, 1171.222],
											[1567, 1170],
											[1570.339, 1167.781],
											[1572.389, 1165.283],
											[1573.744, 1162.143],
											[1575, 1158],
											[1575.301, 1156.807],
											[1575.525, 1155.626],
											[1575.736, 1154.382],
											[1576, 1153],
											[1576.242, 1151.493],
											[1576.466, 1149.73],
											[1576.706, 1148.102],
											[1577, 1147],
											[1578.211, 1145.184],
											[1579.742, 1143.758],
											[1581.403, 1142.453],
											[1583, 1141],
											[1584.668, 1139.183],
											[1586.165, 1137.319],
											[1587.328, 1135.296],
											[1588, 1133],
											[1587.972, 1130.71],
											[1587.552, 1127.273],
											[1586.856, 1123.699],
											[1586, 1121],
											[1584.762, 1119.122],
											[1583.126, 1117.485],
											[1581.427, 1116.105],
											[1580, 1115],
											[1578.625, 1113.644],
											[1577.686, 1112.341],
											[1576.904, 1111.118],
											[1576, 1110],
											[1575.076, 1109.58],
											[1573.953, 1109.407],
											[1573, 1109],
											[1570.652, 1106.343],
											[1569, 1104],
											[1565.811, 1096.452],
											[1563, 1089],
											[1557.879, 1083.604],
											[1552, 1077],
											[1551.383, 1073.947],
											[1551, 1071],
											[1550.042, 1070.512],
											[1549, 1070],
											[1548.488, 1068.461],
											[1548, 1067],
											[1540.794, 1061.941],
											[1536, 1065],
											[1535.764, 1098.102],
											[1536, 1135],
											[1535.745, 1157.273],
											[1535, 1177],
											[1533.274, 1182.625],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[0.639, -0.164],
											[0.989, -1.903],
											[0.27, -2.762],
											[-0.104, -3.103],
											[-0.133, -2.927],
											[-0.167, -1.726],
											[-0.152, -1.692],
											[-0.034, -1.62],
											[0.188, -1.509],
											[0.237, -0.927],
											[0.297, -0.952],
											[0.287, -0.924],
											[0.206, -0.843],
											[0.24, -1.034],
											[0.236, -0.993],
											[0.253, -0.963],
											[0.291, -0.944],
											[0.713, -1.962],
											[0.672, -1.864],
											[0.499, -1.72],
											[0.191, -1.53],
											[-0.265, -3.103],
											[-0.827, -2.561],
											[-1.273, -1.678],
											[-1.604, -0.453],
											[-0.908, 0.023],
											[-1.188, 0.093],
											[-1.061, 0.149],
											[-0.529, 0.189],
											[-0.313, 0.284],
											[-0.291, 0.37],
											[-0.332, 0.401],
											[-0.437, 0.377],
											[-0.245, 0.075],
											[-0.262, 0.038],
											[-0.261, 0.069],
											[-0.242, 0.169],
											[-0.159, 0.262],
											[-0.145, 0.302],
											[-0.163, 0.264],
											[-0.214, 0.148],
											[-0.742, 0.21],
											[-0.663, 0.14],
											[-0.441, 0.258],
											[-0.36, 0.406],
											[-0.309, 0.231],
											[-0.639, 1.007],
											[-0.913, 1.825],
											[-0.563, 0.836],
											[-0.32, 0.803],
											[-0.208, 1.435],
											[-0.421, 1.385],
											[-0.2, 0.638],
											[-0.18, 0.389],
											[-1.098, 1.016],
											[-0.889, 1.384],
											[-0.302, 0.89],
											[-0.351, 0.754],
											[1.978, 4.3],
											[2.09, 4.356],
											[0.81, 2.591],
											[0.622, 2.265],
											[0.346, 1.415],
											[0.342, 1.347],
											[0.762, 1.625],
											[2.88, 1.289],
											[1.225, 0.194],
											[1.175, 0.809],
											[0.833, 1.331],
											[0.547, 0.903],
											[0.867, -0.078],
										],
										o: [
											[-2.054, 0.525],
											[-0.989, 1.903],
											[-0.27, 2.762],
											[0.104, 3.103],
											[0.078, 1.722],
											[0.167, 1.726],
											[0.152, 1.692],
											[0.034, 1.62],
											[-0.106, 0.85],
											[-0.237, 0.927],
											[-0.297, 0.952],
											[-0.287, 0.924],
											[-0.265, 1.085],
											[-0.24, 1.034],
											[-0.236, 0.993],
											[-0.253, 0.963],
											[-0.619, 2.013],
											[-0.713, 1.962],
											[-0.672, 1.864],
											[-0.499, 1.72],
											[-0.413, 3.303],
											[0.265, 3.103],
											[0.827, 2.561],
											[1.273, 1.678],
											[0.221, 0.063],
											[0.908, -0.023],
											[1.188, -0.093],
											[1.061, -0.149],
											[0.4, -0.143],
											[0.313, -0.284],
											[0.291, -0.37],
											[0.332, -0.401],
											[0.209, -0.181],
											[0.245, -0.075],
											[0.262, -0.038],
											[0.261, -0.069],
											[0.207, -0.145],
											[0.159, -0.262],
											[0.145, -0.302],
											[0.163, -0.264],
											[0.678, -0.468],
											[0.742, -0.21],
											[0.663, -0.14],
											[0.249, -0.146],
											[0.36, -0.406],
											[1.531, -1.146],
											[0.639, -1.007],
											[0.428, -0.854],
											[0.563, -0.836],
											[0.746, -1.876],
											[0.208, -1.435],
											[0.019, -0.062],
											[0.2, -0.637],
											[0.72, -1.552],
											[1.098, -1.016],
											[0.441, -0.687],
											[0.302, -0.89],
											[3.003, -6.445],
											[-1.978, -4.3],
											[-1.137, -2.37],
											[-0.81, -2.591],
											[-0.273, -0.993],
											[-0.346, -1.415],
											[-1.072, -4.212],
											[-0.762, -1.625],
											[-0.925, -0.414],
											[-1.225, -0.194],
											[-1.121, -0.772],
											[-0.833, -1.331],
											[-0.894, -1.475],
											[-0.867, 0.078],
										],
										v: [
											[1518, 1064],
											[1513.521, 1067.771],
											[1511.719, 1074.897],
											[1511.558, 1083.825],
											[1512, 1093],
											[1512.394, 1098.182],
											[1512.899, 1103.319],
											[1513.205, 1108.297],
											[1513, 1113],
											[1512.469, 1115.678],
											[1511.651, 1118.51],
											[1510.757, 1121.336],
											[1510, 1124],
											[1509.247, 1127.176],
											[1508.537, 1130.212],
											[1507.81, 1133.143],
											[1507, 1136],
											[1504.969, 1141.973],
											[1502.858, 1147.723],
											[1501.069, 1153.112],
											[1500, 1158],
											[1499.807, 1167.695],
											[1501.475, 1176.275],
											[1504.656, 1182.718],
											[1509, 1186],
											[1510.795, 1186.056],
											[1514.039, 1185.878],
											[1517.513, 1185.511],
											[1520, 1185],
											[1521.054, 1184.346],
											[1521.944, 1183.351],
											[1522.862, 1182.181],
											[1524, 1181],
											[1524.686, 1180.634],
											[1525.452, 1180.482],
											[1526.242, 1180.339],
											[1527, 1180],
											[1527.542, 1179.37],
											[1527.99, 1178.504],
											[1528.443, 1177.637],
											[1529, 1177],
											[1531.166, 1176.03],
											[1533.308, 1175.551],
											[1535, 1175],
											[1535.955, 1174.065],
											[1537, 1173],
											[1539.963, 1170.01],
											[1542, 1166],
											[1543.581, 1163.461],
											[1545, 1161],
											[1546.244, 1156.132],
											[1547, 1152],
											[1547.379, 1150.745],
											[1548, 1149],
											[1550.872, 1145.374],
											[1554, 1142],
											[1555.068, 1139.551],
											[1556, 1137],
											[1556.319, 1121.433],
											[1549, 1109],
											[1546.114, 1101.421],
											[1544, 1094],
											[1543.052, 1090.265],
											[1542, 1086],
											[1539.856, 1077.808],
											[1535, 1074],
											[1531.688, 1073.296],
											[1528, 1072],
											[1525.069, 1068.598],
											[1523, 1065],
											[1520.31, 1063.271],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[0.404, -0.07],
											[0.19, -0.671],
											[0.306, -0.717],
											[0.373, -0.757],
											[0.39, -0.79],
											[0.357, -0.818],
											[0.274, -0.839],
											[0.141, -0.855],
											[0.068, -2.692],
											[-0.035, -2.858],
											[0.039, -2.612],
											[0.292, -1.955],
											[0.242, -0.83],
											[0.292, -0.818],
											[0.279, -0.829],
											[0.204, -0.864],
											[0.16, -0.923],
											[0.142, -0.925],
											[0.161, -0.915],
											[0.217, -0.893],
											[0.852, -2.537],
											[0.697, -2.36],
											[0.246, -2.393],
											[-0.5, -2.635],
											[-0.729, -1.428],
											[-0.955, -1.427],
											[-0.873, -1.035],
											[-0.485, -0.252],
											[-0.641, -0.164],
											[-0.696, -0.068],
											[-0.707, 0.005],
											[-0.675, 0.054],
											[0.138, -1.15],
											[-0.161, -0.914],
											[-0.594, -0.557],
											[-1.16, -0.08],
											[-0.241, 0.147],
											[-0.009, 0.287],
											[-0.013, 0.371],
											[-0.254, 0.399],
											[-0.245, 0.209],
											[-0.288, 0.226],
											[-0.274, 0.279],
											[-0.203, 0.367],
											[-0.68, 1.706],
											[-0.559, 1.758],
											[-0.474, 1.673],
											[-0.427, 1.452],
											[-0.022, 2.083],
											[0.207, 2.103],
											[0.125, 1.968],
											[-0.267, 1.68],
											[-0.455, 0.966],
											[-0.536, 0.834],
											[-0.438, 0.826],
											[-0.16, 0.941],
											[0.044, 1.608],
											[0.257, 1.369],
											[0.335, 1.077],
											[0.277, 0.731],
											[0.081, 0.43],
											[0.051, 0.427],
											[0.07, 0.409],
											[0.136, 0.374],
											[1.621, 2.936],
											[1.463, 3.021],
											[0.736, 3.574],
											[-0.56, 4.597],
											[0.215, 0.118],
											[0.194, 0.139],
											[0.257, 0.076],
										],
										o: [
											[-0.024, 0.619],
											[-0.19, 0.671],
											[-0.306, 0.717],
											[-0.373, 0.757],
											[-0.39, 0.79],
											[-0.357, 0.818],
											[-0.274, 0.839],
											[-0.349, 2.113],
											[-0.068, 2.692],
											[0.035, 2.858],
											[-0.039, 2.612],
											[-0.129, 0.864],
											[-0.242, 0.83],
											[-0.292, 0.818],
											[-0.279, 0.829],
											[-0.215, 0.909],
											[-0.16, 0.923],
											[-0.142, 0.925],
											[-0.161, 0.915],
											[-0.712, 2.923],
											[-0.852, 2.537],
											[-0.697, 2.36],
											[-0.246, 2.393],
											[0.197, 1.038],
											[0.729, 1.428],
											[0.955, 1.427],
											[0.873, 1.035],
											[0.542, 0.282],
											[0.641, 0.164],
											[0.696, 0.068],
											[0.707, -0.005],
											[-0.304, 1.265],
											[-0.138, 1.15],
											[0.161, 0.914],
											[0.594, 0.557],
											[0.709, 0.049],
											[0.241, -0.147],
											[0.009, -0.287],
											[0.013, -0.371],
											[0.145, -0.228],
											[0.245, -0.209],
											[0.288, -0.226],
											[0.274, -0.279],
											[0.838, -1.518],
											[0.68, -1.706],
											[0.559, -1.758],
											[0.474, -1.673],
											[0.561, -1.909],
											[0.022, -2.083],
											[-0.207, -2.103],
											[-0.125, -1.968],
											[0.194, -1.223],
											[0.455, -0.966],
											[0.536, -0.834],
											[0.438, -0.826],
											[0.306, -1.793],
											[-0.044, -1.608],
											[-0.257, -1.369],
											[-0.335, -1.077],
											[-0.158, -0.417],
											[-0.081, -0.43],
											[-0.051, -0.427],
											[-0.07, -0.409],
											[-1.209, -3.32],
											[-1.621, -2.936],
											[-1.463, -3.021],
											[-0.736, -3.574],
											[-0.32, -0.014],
											[-0.215, -0.118],
											[-0.194, -0.139],
											[-0.257, -0.076],
										],
										v: [
											[1509, 1063],
											[1508.667, 1064.938],
											[1507.911, 1067.022],
											[1506.88, 1069.234],
											[1505.724, 1071.556],
											[1504.593, 1073.97],
											[1503.635, 1076.457],
											[1503, 1079],
											[1502.419, 1086.31],
											[1502.414, 1094.737],
											[1502.452, 1103.046],
											[1502, 1110],
											[1501.428, 1112.535],
											[1500.613, 1115],
											[1499.74, 1117.466],
											[1499, 1120],
											[1498.447, 1122.75],
											[1498.004, 1125.524],
											[1497.559, 1128.286],
											[1497, 1131],
											[1494.58, 1139.138],
											[1492.182, 1146.432],
											[1490.693, 1153.51],
											[1491, 1161],
											[1492.466, 1164.797],
											[1495.068, 1169.179],
											[1497.887, 1172.971],
											[1500, 1175],
											[1501.786, 1175.663],
											[1503.801, 1176.005],
											[1505.916, 1176.094],
											[1508, 1176],
											[1507.302, 1179.653],
											[1507.303, 1182.778],
											[1508.402, 1185.015],
											[1511, 1186],
											[1512.367, 1185.838],
											[1512.684, 1185.172],
											[1512.658, 1184.17],
											[1513, 1183],
											[1513.599, 1182.353],
											[1514.413, 1181.709],
											[1515.271, 1180.96],
											[1516, 1180],
											[1518.268, 1175.131],
											[1520.117, 1169.901],
											[1521.658, 1164.722],
											[1523, 1160],
											[1523.796, 1153.973],
											[1523.441, 1147.657],
											[1522.865, 1141.511],
											[1523, 1136],
											[1524.018, 1132.748],
											[1525.55, 1130.079],
											[1527.057, 1127.62],
											[1528, 1125],
											[1528.36, 1119.885],
											[1527.874, 1115.406],
											[1526.952, 1111.724],
											[1526, 1109],
											[1525.654, 1107.726],
											[1525.467, 1106.436],
											[1525.297, 1105.178],
											[1525, 1104],
											[1520.613, 1094.733],
											[1515.846, 1085.915],
											[1512.406, 1076.14],
											[1512, 1064],
											[1511.219, 1063.781],
											[1510.626, 1063.374],
											[1509.97, 1063.03],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 139,
								s: [
									{
										i: [
											[1.148, -0.442],
											[0.119, -0.059],
											[0.1, -0.064],
											[0.084, -0.071],
											[0.073, -0.079],
											[0.065, -0.089],
											[0.062, -0.101],
											[0.062, -0.114],
											[0.066, -0.128],
											[0.34, -0.901],
											[0.236, -0.902],
											[0.157, -0.913],
											[0.102, -0.933],
											[0.071, -0.963],
											[0.065, -1.002],
											[0.082, -1.05],
											[0.124, -1.108],
											[0.153, -1.14],
											[0.168, -1.155],
											[0.171, -1.161],
											[0.162, -1.158],
											[0.14, -1.147],
											[0.107, -1.126],
											[0.061, -1.097],
											[0.075, -1.81],
											[0.065, -1.69],
											[0.083, -1.66],
											[0.126, -1.72],
											[0.333, -6.206],
											[-0.346, -6.237],
											[-1.756, -3.912],
											[-3.898, 0.767],
											[-0.329, 0.157],
											[-0.086, 0.211],
											[-0.089, 0.324],
											[-0.339, 0.495],
											[-0.182, 0.072],
											[-0.213, 0.061],
											[-0.175, 0.084],
											[-0.067, 0.141],
											[0.012, 0.256],
											[0.049, 0.273],
											[0.012, 0.256],
											[-0.098, 0.204],
											[-0.619, 1.572],
											[-0.496, 1.635],
											[-0.379, 1.746],
											[-0.269, 1.903],
											[-0.08, 0.989],
											[-0.059, 1.006],
											[-0.075, 1.014],
											[-0.126, 1.014],
											[-0.287, 1.317],
											[-0.311, 1.249],
											[-0.243, 1.183],
											[-0.085, 1.117],
											[-0.076, 2.271],
											[-0.009, 2.43],
											[0.072, 2.194],
											[0.165, 1.563],
											[0.226, 0.857],
											[0.297, 0.918],
											[0.298, 0.977],
											[0.227, 1.035],
											[0.378, 2.142],
											[0.356, 2.068],
											[0.425, 2.017],
											[0.584, 1.99],
											[0.384, 1.37],
											[0.473, 0.968],
											[0.727, 0.364],
										],
										o: [
											[-0.142, 0.055],
											[-0.119, 0.059],
											[-0.1, 0.064],
											[-0.084, 0.071],
											[-0.073, 0.079],
											[-0.065, 0.089],
											[-0.062, 0.101],
											[-0.062, 0.114],
											[-0.468, 0.91],
											[-0.34, 0.901],
											[-0.236, 0.902],
											[-0.157, 0.913],
											[-0.102, 0.933],
											[-0.071, 0.963],
											[-0.065, 1.002],
											[-0.082, 1.05],
											[-0.125, 1.117],
											[-0.153, 1.14],
											[-0.168, 1.155],
											[-0.171, 1.161],
											[-0.162, 1.158],
											[-0.14, 1.147],
											[-0.107, 1.126],
											[-0.112, 2.019],
											[-0.075, 1.81],
											[-0.065, 1.69],
											[-0.083, 1.66],
											[-0.281, 3.821],
											[-0.333, 6.206],
											[0.346, 6.237],
											[1.756, 3.912],
											[0.819, -0.161],
											[0.329, -0.157],
											[0.086, -0.211],
											[0.089, -0.324],
											[0.08, -0.117],
											[0.182, -0.072],
											[0.213, -0.061],
											[0.175, -0.084],
											[0.097, -0.204],
											[-0.012, -0.256],
											[-0.049, -0.273],
											[-0.012, -0.256],
											[0.747, -1.556],
											[0.619, -1.572],
											[0.496, -1.635],
											[0.379, -1.746],
											[0.136, -0.963],
											[0.08, -0.989],
											[0.059, -1.006],
											[0.075, -1.014],
											[0.173, -1.385],
											[0.287, -1.317],
											[0.311, -1.249],
											[0.243, -1.183],
											[0.13, -1.718],
											[0.076, -2.271],
											[0.009, -2.43],
											[-0.072, -2.194],
											[-0.084, -0.794],
											[-0.226, -0.857],
											[-0.297, -0.918],
											[-0.298, -0.977],
											[-0.491, -2.24],
											[-0.378, -2.142],
											[-0.356, -2.068],
											[-0.425, -2.017],
											[-0.461, -1.571],
											[-0.384, -1.37],
											[-0.473, -0.968],
											[-0.727, -0.364],
										],
										v: [
											[1504, 1062],
											[1503.608, 1062.17],
											[1503.281, 1062.353],
											[1503.005, 1062.555],
											[1502.77, 1062.779],
											[1502.564, 1063.031],
											[1502.375, 1063.316],
											[1502.191, 1063.637],
											[1502, 1064],
											[1500.795, 1066.714],
											[1499.936, 1069.417],
											[1499.352, 1072.138],
											[1498.969, 1074.904],
											[1498.715, 1077.745],
											[1498.517, 1080.689],
											[1498.303, 1083.764],
											[1498, 1087],
											[1497.581, 1090.388],
											[1497.097, 1093.834],
											[1496.586, 1097.31],
											[1496.084, 1100.791],
											[1495.628, 1104.251],
											[1495.254, 1107.662],
											[1495, 1111],
											[1494.726, 1116.721],
											[1494.522, 1121.949],
											[1494.307, 1126.953],
											[1494, 1132],
											[1492.896, 1147.63],
											[1492.732, 1166.882],
											[1495.702, 1182.694],
											[1504, 1188],
											[1505.66, 1187.537],
											[1506.22, 1187],
											[1506.42, 1186.213],
											[1507, 1185],
											[1507.41, 1184.726],
											[1508.02, 1184.536],
											[1508.62, 1184.329],
											[1509, 1184],
											[1509.108, 1183.301],
											[1508.998, 1182.499],
											[1508.889, 1181.697],
											[1509, 1181],
											[1511.047, 1176.32],
											[1512.717, 1171.521],
											[1514.029, 1166.462],
											[1515, 1161],
											[1515.315, 1158.07],
											[1515.515, 1155.076],
											[1515.707, 1152.044],
											[1516, 1149],
											[1516.713, 1144.948],
											[1517.632, 1141.098],
											[1518.486, 1137.45],
											[1519, 1134],
											[1519.313, 1127.917],
											[1519.443, 1120.767],
											[1519.352, 1113.733],
											[1519, 1108],
											[1518.517, 1105.523],
											[1517.714, 1102.862],
											[1516.805, 1100.019],
											[1516, 1097],
											[1514.719, 1090.434],
											[1513.64, 1084.125],
											[1512.491, 1078.004],
											[1511, 1072],
											[1509.773, 1067.537],
											[1508.53, 1063.98],
											[1506.771, 1061.933],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 140,
								s: [
									{
										i: [
											[0.141, -0.065],
											[0.162, -0.087],
											[0.142, -0.09],
											[0.122, -0.092],
											[0.103, -0.094],
											[0.085, -0.096],
											[0.067, -0.097],
											[0.051, -0.098],
											[0.035, -0.098],
											[0.02, -0.098],
											[0.005, -0.098],
											[-0.009, -0.097],
											[-0.022, -0.096],
											[-0.034, -0.094],
											[-0.045, -0.092],
											[-0.056, -0.09],
											[-0.066, -0.087],
											[-0.075, -0.084],
											[-0.083, -0.081],
											[-0.091, -0.077],
											[-0.097, -0.073],
											[-0.103, -0.068],
											[-0.109, -0.063],
											[-0.113, -0.058],
											[-0.117, -0.052],
											[-0.12, -0.046],
											[-0.122, -0.039],
											[-0.124, -0.032],
											[-0.125, -0.025],
											[-0.125, -0.017],
											[-0.124, -0.009],
											[-0.122, 0],
											[-0.12, 0.008],
											[-0.117, 0.018],
											[-0.113, 0.027],
											[-0.109, 0.037],
											[-0.103, 0.048],
											[-0.097, 0.059],
											[-0.09, 0.07],
											[-0.083, 0.082],
											[-0.074, 0.094],
											[-0.065, 0.106],
											[-0.055, 0.119],
											[-0.06, 0.153],
											[-0.046, 0.148],
											[-0.032, 0.142],
											[-0.019, 0.136],
											[-0.007, 0.129],
											[0.005, 0.123],
											[0.017, 0.116],
											[0.028, 0.11],
											[0.038, 0.103],
											[0.048, 0.096],
											[0.058, 0.089],
											[0.067, 0.081],
											[0.075, 0.074],
											[0.083, 0.066],
											[0.09, 0.059],
											[0.097, 0.051],
											[0.104, 0.043],
											[0.11, 0.035],
											[0.115, 0.026],
											[0.12, 0.018],
											[0.124, 0.009],
											[0.128, 0.001],
											[0.131, -0.008],
											[0.134, -0.017],
											[0.137, -0.026],
											[0.138, -0.036],
											[0.14, -0.045],
											[0.141, -0.055],
										],
										o: [
											[-0.184, 0.084],
											[-0.162, 0.087],
											[-0.142, 0.09],
											[-0.122, 0.092],
											[-0.103, 0.094],
											[-0.085, 0.096],
											[-0.067, 0.097],
											[-0.051, 0.098],
											[-0.035, 0.098],
											[-0.02, 0.098],
											[-0.005, 0.098],
											[0.009, 0.097],
											[0.022, 0.096],
											[0.034, 0.094],
											[0.045, 0.092],
											[0.056, 0.09],
											[0.066, 0.087],
											[0.075, 0.084],
											[0.083, 0.081],
											[0.091, 0.077],
											[0.097, 0.073],
											[0.103, 0.068],
											[0.109, 0.063],
											[0.113, 0.058],
											[0.117, 0.052],
											[0.12, 0.046],
											[0.122, 0.039],
											[0.124, 0.032],
											[0.125, 0.025],
											[0.125, 0.017],
											[0.124, 0.009],
											[0.122, 0],
											[0.12, -0.008],
											[0.117, -0.018],
											[0.113, -0.027],
											[0.109, -0.037],
											[0.103, -0.048],
											[0.097, -0.059],
											[0.09, -0.07],
											[0.083, -0.082],
											[0.074, -0.094],
											[0.065, -0.106],
											[0.074, -0.159],
											[0.06, -0.153],
											[0.046, -0.148],
											[0.032, -0.142],
											[0.019, -0.136],
											[0.007, -0.129],
											[-0.005, -0.123],
											[-0.017, -0.116],
											[-0.028, -0.11],
											[-0.038, -0.103],
											[-0.048, -0.096],
											[-0.058, -0.089],
											[-0.067, -0.081],
											[-0.075, -0.074],
											[-0.083, -0.066],
											[-0.09, -0.059],
											[-0.097, -0.051],
											[-0.104, -0.043],
											[-0.11, -0.035],
											[-0.115, -0.026],
											[-0.12, -0.018],
											[-0.124, -0.009],
											[-0.128, -0.001],
											[-0.131, 0.008],
											[-0.134, 0.017],
											[-0.137, 0.026],
											[-0.138, 0.036],
											[-0.14, 0.045],
											[-0.141, 0.055],
										],
										v: [
											[2211, 1120],
											[2210.481, 1120.257],
											[2210.025, 1120.523],
											[2209.63, 1120.797],
											[2209.292, 1121.077],
											[2209.011, 1121.363],
											[2208.782, 1121.652],
											[2208.606, 1121.944],
											[2208.478, 1122.238],
											[2208.397, 1122.533],
											[2208.36, 1122.827],
											[2208.365, 1123.12],
											[2208.411, 1123.41],
											[2208.494, 1123.695],
											[2208.612, 1123.976],
											[2208.763, 1124.25],
											[2208.945, 1124.516],
											[2209.156, 1124.774],
											[2209.392, 1125.022],
											[2209.653, 1125.259],
											[2209.935, 1125.484],
											[2210.237, 1125.695],
											[2210.556, 1125.892],
											[2210.889, 1126.074],
											[2211.235, 1126.238],
											[2211.591, 1126.385],
											[2211.955, 1126.512],
											[2212.325, 1126.619],
											[2212.698, 1126.705],
											[2213.072, 1126.768],
											[2213.445, 1126.807],
											[2213.815, 1126.822],
											[2214.179, 1126.81],
											[2214.535, 1126.771],
											[2214.88, 1126.703],
											[2215.213, 1126.606],
											[2215.531, 1126.478],
											[2215.832, 1126.318],
											[2216.114, 1126.126],
											[2216.374, 1125.898],
											[2216.609, 1125.636],
											[2216.819, 1125.337],
											[2217, 1125],
											[2217.201, 1124.531],
											[2217.359, 1124.08],
											[2217.475, 1123.646],
											[2217.552, 1123.23],
											[2217.59, 1122.833],
											[2217.592, 1122.454],
											[2217.558, 1122.095],
											[2217.491, 1121.756],
											[2217.392, 1121.438],
											[2217.262, 1121.14],
											[2217.103, 1120.863],
											[2216.916, 1120.608],
											[2216.703, 1120.375],
											[2216.466, 1120.164],
											[2216.206, 1119.976],
											[2215.924, 1119.812],
											[2215.622, 1119.671],
											[2215.302, 1119.555],
											[2214.965, 1119.464],
											[2214.612, 1119.397],
											[2214.246, 1119.356],
											[2213.867, 1119.341],
											[2213.478, 1119.352],
											[2213.079, 1119.391],
											[2212.673, 1119.456],
											[2212.26, 1119.549],
											[2211.843, 1119.671],
											[2211.422, 1119.821],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 141,
								s: [
									{
										i: [
											[0.058, -0.038],
											[0.188, -0.138],
											[0.158, -0.133],
											[0.129, -0.128],
											[0.101, -0.123],
											[0.075, -0.118],
											[0.05, -0.112],
											[0.027, -0.106],
											[0.005, -0.1],
											[-0.016, -0.094],
											[-0.035, -0.088],
											[-0.052, -0.082],
											[-0.068, -0.076],
											[-0.083, -0.069],
											[-0.096, -0.062],
											[-0.107, -0.055],
											[-0.118, -0.048],
											[-0.126, -0.041],
											[-0.134, -0.034],
											[-0.14, -0.026],
											[-0.144, -0.019],
											[-0.147, -0.011],
											[-0.148, -0.003],
											[-0.148, 0.005],
											[-0.147, 0.013],
											[-0.144, 0.022],
											[-0.14, 0.03],
											[-0.134, 0.039],
											[-0.126, 0.048],
											[-0.118, 0.057],
											[-0.107, 0.066],
											[-0.096, 0.075],
											[-0.083, 0.084],
											[-0.068, 0.094],
											[-0.052, 0.104],
											[-0.035, 0.114],
											[-0.016, 0.124],
											[0.005, 0.134],
											[0.027, 0.144],
											[0.05, 0.154],
											[0.075, 0.165],
											[0.101, 0.176],
											[0.129, 0.187],
											[0.052, 0.066],
											[0.053, 0.059],
											[0.053, 0.052],
											[0.054, 0.046],
											[0.055, 0.039],
											[0.055, 0.033],
											[0.056, 0.027],
											[0.057, 0.021],
											[0.057, 0.016],
											[0.058, 0.011],
											[0.058, 0.006],
											[0.058, 0.001],
											[0.059, -0.003],
											[0.059, -0.007],
											[0.059, -0.011],
											[0.059, -0.015],
											[0.059, -0.018],
											[0.06, -0.021],
											[0.06, -0.024],
											[0.06, -0.026],
											[0.06, -0.029],
											[0.06, -0.031],
											[0.059, -0.032],
											[0.059, -0.034],
											[0.059, -0.035],
											[0.059, -0.036],
											[0.059, -0.037],
											[0.058, -0.037],
										],
										o: [
											[-0.22, 0.143],
											[-0.188, 0.138],
											[-0.158, 0.133],
											[-0.129, 0.128],
											[-0.101, 0.123],
											[-0.075, 0.118],
											[-0.05, 0.112],
											[-0.027, 0.106],
											[-0.005, 0.1],
											[0.016, 0.094],
											[0.035, 0.088],
											[0.052, 0.082],
											[0.068, 0.076],
											[0.083, 0.069],
											[0.096, 0.062],
											[0.107, 0.055],
											[0.118, 0.048],
											[0.126, 0.041],
											[0.134, 0.034],
											[0.14, 0.026],
											[0.144, 0.019],
											[0.147, 0.011],
											[0.148, 0.003],
											[0.148, -0.005],
											[0.147, -0.013],
											[0.144, -0.022],
											[0.14, -0.03],
											[0.134, -0.039],
											[0.126, -0.048],
											[0.118, -0.057],
											[0.107, -0.066],
											[0.096, -0.075],
											[0.083, -0.084],
											[0.068, -0.094],
											[0.052, -0.104],
											[0.035, -0.114],
											[0.016, -0.124],
											[-0.005, -0.134],
											[-0.027, -0.144],
											[-0.05, -0.154],
											[-0.075, -0.165],
											[-0.101, -0.176],
											[-0.051, -0.074],
											[-0.052, -0.066],
											[-0.053, -0.059],
											[-0.053, -0.052],
											[-0.054, -0.046],
											[-0.055, -0.039],
											[-0.055, -0.033],
											[-0.056, -0.027],
											[-0.057, -0.021],
											[-0.057, -0.016],
											[-0.058, -0.011],
											[-0.058, -0.006],
											[-0.058, -0.001],
											[-0.059, 0.003],
											[-0.059, 0.007],
											[-0.059, 0.011],
											[-0.059, 0.015],
											[-0.059, 0.018],
											[-0.06, 0.021],
											[-0.06, 0.024],
											[-0.06, 0.026],
											[-0.06, 0.029],
											[-0.06, 0.031],
											[-0.059, 0.032],
											[-0.059, 0.034],
											[-0.059, 0.035],
											[-0.059, 0.036],
											[-0.059, 0.037],
											[-0.058, 0.037],
										],
										v: [
											[2216, 1120],
											[2215.387, 1120.422],
											[2214.868, 1120.829],
											[2214.439, 1121.221],
											[2214.095, 1121.598],
											[2213.832, 1121.959],
											[2213.646, 1122.303],
											[2213.531, 1122.63],
											[2213.485, 1122.94],
											[2213.501, 1123.233],
											[2213.577, 1123.507],
											[2213.708, 1123.762],
											[2213.888, 1123.998],
											[2214.115, 1124.215],
											[2214.383, 1124.412],
											[2214.688, 1124.588],
											[2215.026, 1124.744],
											[2215.393, 1124.878],
											[2215.783, 1124.99],
											[2216.194, 1125.08],
											[2216.619, 1125.148],
											[2217.056, 1125.192],
											[2217.498, 1125.213],
											[2217.944, 1125.21],
											[2218.387, 1125.182],
											[2218.823, 1125.129],
											[2219.248, 1125.052],
											[2219.659, 1124.948],
											[2220.049, 1124.818],
											[2220.415, 1124.662],
											[2220.753, 1124.478],
											[2221.059, 1124.267],
											[2221.327, 1124.028],
											[2221.553, 1123.761],
											[2221.734, 1123.464],
											[2221.864, 1123.138],
											[2221.939, 1122.783],
											[2221.956, 1122.397],
											[2221.909, 1121.981],
											[2221.794, 1121.533],
											[2221.608, 1121.054],
											[2221.344, 1120.543],
											[2221, 1120],
											[2220.846, 1119.79],
											[2220.689, 1119.601],
											[2220.53, 1119.434],
											[2220.369, 1119.288],
											[2220.206, 1119.161],
											[2220.041, 1119.053],
											[2219.873, 1118.963],
											[2219.705, 1118.891],
											[2219.534, 1118.835],
											[2219.362, 1118.795],
											[2219.189, 1118.769],
											[2219.015, 1118.759],
											[2218.839, 1118.761],
											[2218.663, 1118.777],
											[2218.486, 1118.804],
											[2218.308, 1118.842],
											[2218.13, 1118.891],
											[2217.951, 1118.949],
											[2217.773, 1119.016],
											[2217.594, 1119.091],
											[2217.415, 1119.174],
											[2217.236, 1119.263],
											[2217.057, 1119.357],
											[2216.879, 1119.456],
											[2216.702, 1119.56],
											[2216.525, 1119.667],
											[2216.349, 1119.776],
											[2216.174, 1119.888],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 142,
								s: [
									{
										i: [
											[0.155, -0.054],
											[0.014, -0.005],
											[0.015, -0.006],
											[0.016, -0.007],
											[0.017, -0.008],
											[0.018, -0.009],
											[0.019, -0.01],
											[0.02, -0.011],
											[0.021, -0.011],
											[0.022, -0.012],
											[0.022, -0.013],
											[0.023, -0.014],
											[0.023, -0.014],
											[0.023, -0.015],
											[0.024, -0.015],
											[0.024, -0.016],
											[0.024, -0.016],
											[0.024, -0.017],
											[0.024, -0.017],
											[0.023, -0.018],
											[0.023, -0.018],
											[0.023, -0.018],
											[0.022, -0.019],
											[0.022, -0.019],
											[0.021, -0.019],
											[0.02, -0.019],
											[0.019, -0.02],
											[0.018, -0.02],
											[0.017, -0.02],
											[0.016, -0.02],
											[0.015, -0.02],
											[0.013, -0.02],
											[0.012, -0.02],
											[0.01, -0.02],
											[0.009, -0.02],
											[0.007, -0.019],
											[0.005, -0.019],
											[0.003, -0.019],
											[0.001, -0.019],
											[-0.001, -0.019],
											[-0.003, -0.018],
											[-0.005, -0.018],
											[-0.008, -0.018],
											[-0.074, -0.128],
											[-0.079, -0.101],
											[-0.083, -0.075],
											[-0.085, -0.051],
											[-0.087, -0.029],
											[-0.088, -0.009],
											[-0.088, 0.009],
											[-0.086, 0.025],
											[-0.084, 0.04],
											[-0.081, 0.053],
											[-0.077, 0.064],
											[-0.072, 0.073],
											[-0.067, 0.08],
											[-0.06, 0.086],
											[-0.052, 0.089],
											[-0.043, 0.091],
											[-0.034, 0.091],
											[-0.023, 0.089],
											[-0.012, 0.085],
											[0.001, 0.079],
											[0.014, 0.072],
											[0.028, 0.062],
											[0.044, 0.051],
											[0.06, 0.038],
											[0.077, 0.023],
											[0.095, 0.007],
											[0.114, -0.012],
											[0.134, -0.032],
										],
										o: [
											[-0.012, 0.004],
											[-0.014, 0.005],
											[-0.015, 0.006],
											[-0.016, 0.007],
											[-0.017, 0.008],
											[-0.018, 0.009],
											[-0.019, 0.01],
											[-0.02, 0.011],
											[-0.021, 0.011],
											[-0.022, 0.012],
											[-0.022, 0.013],
											[-0.023, 0.014],
											[-0.023, 0.014],
											[-0.023, 0.015],
											[-0.024, 0.015],
											[-0.024, 0.016],
											[-0.024, 0.016],
											[-0.024, 0.017],
											[-0.024, 0.017],
											[-0.023, 0.018],
											[-0.023, 0.018],
											[-0.023, 0.018],
											[-0.022, 0.019],
											[-0.022, 0.019],
											[-0.021, 0.019],
											[-0.02, 0.019],
											[-0.019, 0.02],
											[-0.018, 0.02],
											[-0.017, 0.02],
											[-0.016, 0.02],
											[-0.015, 0.02],
											[-0.013, 0.02],
											[-0.012, 0.02],
											[-0.01, 0.02],
											[-0.009, 0.02],
											[-0.007, 0.019],
											[-0.005, 0.019],
											[-0.003, 0.019],
											[-0.001, 0.019],
											[0.001, 0.019],
											[0.003, 0.018],
											[0.005, 0.018],
											[0.069, 0.158],
											[0.074, 0.128],
											[0.079, 0.101],
											[0.083, 0.075],
											[0.085, 0.051],
											[0.087, 0.029],
											[0.088, 0.009],
											[0.088, -0.009],
											[0.086, -0.025],
											[0.084, -0.04],
											[0.081, -0.053],
											[0.077, -0.064],
											[0.072, -0.073],
											[0.067, -0.08],
											[0.06, -0.086],
											[0.052, -0.089],
											[0.043, -0.091],
											[0.034, -0.091],
											[0.023, -0.089],
											[0.012, -0.085],
											[-0.001, -0.079],
											[-0.014, -0.072],
											[-0.028, -0.062],
											[-0.044, -0.051],
											[-0.06, -0.038],
											[-0.077, -0.023],
											[-0.095, -0.007],
											[-0.114, 0.012],
											[-0.134, 0.032],
										],
										v: [
											[2220, 1121],
											[2219.962, 1121.014],
											[2219.919, 1121.031],
											[2219.873, 1121.052],
											[2219.823, 1121.074],
											[2219.769, 1121.1],
											[2219.713, 1121.128],
											[2219.654, 1121.159],
											[2219.592, 1121.192],
											[2219.528, 1121.227],
											[2219.462, 1121.265],
											[2219.395, 1121.304],
											[2219.326, 1121.346],
											[2219.256, 1121.389],
											[2219.185, 1121.434],
											[2219.113, 1121.481],
											[2219.042, 1121.529],
											[2218.97, 1121.579],
											[2218.899, 1121.63],
											[2218.828, 1121.683],
											[2218.758, 1121.736],
											[2218.69, 1121.791],
											[2218.622, 1121.846],
											[2218.556, 1121.903],
											[2218.493, 1121.96],
											[2218.431, 1122.018],
											[2218.372, 1122.076],
											[2218.316, 1122.135],
											[2218.263, 1122.194],
											[2218.213, 1122.253],
											[2218.167, 1122.313],
											[2218.125, 1122.372],
											[2218.087, 1122.432],
											[2218.054, 1122.491],
											[2218.025, 1122.55],
											[2218.001, 1122.609],
											[2217.983, 1122.667],
											[2217.97, 1122.724],
											[2217.963, 1122.781],
											[2217.963, 1122.837],
											[2217.968, 1122.893],
											[2217.981, 1122.947],
											[2218, 1123],
											[2218.215, 1123.429],
											[2218.446, 1123.773],
											[2218.688, 1124.037],
											[2218.94, 1124.226],
											[2219.198, 1124.347],
											[2219.46, 1124.405],
											[2219.724, 1124.405],
											[2219.985, 1124.353],
											[2220.241, 1124.254],
											[2220.489, 1124.114],
											[2220.728, 1123.939],
											[2220.953, 1123.734],
											[2221.161, 1123.504],
											[2221.351, 1123.255],
											[2221.519, 1122.992],
											[2221.663, 1122.722],
											[2221.779, 1122.449],
											[2221.865, 1122.179],
											[2221.917, 1121.918],
											[2221.934, 1121.671],
											[2221.912, 1121.444],
											[2221.848, 1121.242],
											[2221.74, 1121.071],
											[2221.585, 1120.936],
											[2221.379, 1120.843],
											[2221.121, 1120.798],
											[2220.807, 1120.805],
											[2220.434, 1120.87],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 121, s: [0], h: 1 },
							{ t: 122, s: [100], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 1',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 122,
								s: [
									{
										i: [
											[0.503, -0.362],
											[0.308, -1.385],
											[-0.012, -1.672],
											[-0.314, -1.326],
											[-0.597, -0.347],
											[-1.438, 1.382],
											[-0.151, 0.693],
											[1.047, 1.996],
											[1.047, 0.945],
											[0.47, -0.049],
										],
										o: [
											[-0.646, 0.466],
											[-0.308, 1.385],
											[0.012, 1.672],
											[0.314, 1.326],
											[2.097, 1.22],
											[1.438, -1.382],
											[0.234, -1.072],
											[-1.047, -1.996],
											[-0.618, -0.557],
											[-0.47, 0.048],
										],
										v: [
											[2492, 1125],
											[2490.573, 1127.935],
											[2490.135, 1132.678],
											[2490.629, 1137.332],
											[2492, 1140],
											[2497.459, 1138.935],
											[2500, 1135],
											[2498.461, 1129.904],
											[2495, 1125],
											[2493.414, 1124.311],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 127,
								s: [
									{
										i: [
											[5.461, -1.543],
											[1.733, -1.59],
											[0.468, -1.964],
											[-0.624, -2.017],
											[-1.542, -1.75],
											[-1.468, -1.14],
											[-1.093, -0.265],
											[-3.941, 4.216],
											[3.636, 6.046],
											[3.367, 1.025],
										],
										o: [
											[-3.172, 0.896],
											[-1.733, 1.59],
											[-0.468, 1.964],
											[0.624, 2.017],
											[1.036, 1.176],
											[1.468, 1.14],
											[7.87, 1.911],
											[3.941, -4.216],
											[-2.071, -3.445],
											[-3.367, -1.025],
										],
										v: [
											[2537, 1073],
											[2529.687, 1076.809],
											[2526.429, 1082.219],
											[2526.708, 1088.27],
											[2530, 1094],
											[2533.957, 1097.683],
											[2538, 1100],
											[2556.629, 1095.468],
											[2558, 1079],
											[2550.043, 1072.259],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 128,
								s: [
									{
										i: [
											[11.311, -3.405],
											[0.655, -0.479],
											[1.258, -1.446],
											[0.719, -1.256],
											[0.309, -2.889],
											[-1.114, -1.792],
											[-4.471, -0.679],
											[-1.55, 0.279],
											[-0.717, 6.344],
											[1.41, 3.79],
										],
										o: [
											[-2.123, 0.639],
											[-0.655, 0.479],
											[-1.815, 2.086],
											[-0.719, 1.256],
											[-0.575, 5.389],
											[2.395, 3.852],
											[1.805, 0.274],
											[4.679, -0.843],
											[0.375, -3.321],
											[-3.11, -8.357],
										],
										v: [
											[2533, 1070],
											[2529.351, 1071.395],
											[2527, 1074],
											[2523.37, 1078.398],
											[2522, 1084],
											[2524, 1093],
											[2536, 1102],
											[2542, 1102],
											[2554, 1088],
											[2552, 1079],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 129,
								s: [
									{
										i: [
											[0.323, -0.097],
											[1.254, -2.781],
											[0.233, -0.424],
											[-1.908, -3.991],
											[-3.779, -1.735],
											[-2.665, -0.585],
											[1.549, 9.253],
											[2.292, 2.462],
											[2.117, 0.544],
											[2.905, 0.621],
										],
										o: [
											[-4.108, 1.234],
											[-0.16, 0.356],
											[-3.624, 6.597],
											[2.261, 4.728],
											[3.211, 1.474],
											[11.026, 2.422],
											[-0.448, -2.677],
											[-2.159, -2.319],
											[-0.663, -0.171],
											[-1.061, -0.227],
										],
										v: [
											[2523, 1070],
											[2517, 1074],
											[2515, 1075],
											[2514, 1092],
											[2520, 1098],
											[2527, 1102],
											[2544, 1082],
											[2539, 1075],
											[2532, 1069],
											[2524, 1069],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 130,
								s: [
									{
										i: [
											[6.073, -1.094],
											[0.87, 0.095],
											[0.745, -0.24],
											[1.376, -1.313],
											[0.123, -0.309],
											[-1.201, -3.4],
											[-1.376, -0.614],
											[-2.881, 4.517],
											[2.708, 2.825],
											[3.178, 1.563],
										],
										o: [
											[-0.774, 0.139],
											[-0.87, -0.095],
											[-1.372, 0.441],
											[-1.376, 1.313],
											[-1.371, 3.439],
											[0.703, 1.989],
											[7.002, 3.124],
											[2.563, -4.019],
											[-1.679, -1.752],
											[-4.944, -2.431],
										],
										v: [
											[2539, 1072],
											[2536.478, 1071.925],
											[2534, 1072],
											[2529.564, 1075.099],
											[2527, 1078],
											[2527, 1095],
											[2536, 1103],
											[2558, 1098],
											[2558, 1077],
											[2553, 1073],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 131,
								s: [
									{
										i: [
											[0.456, -0.199],
											[1.256, -3.465],
											[-2.064, -3.249],
											[-3.211, -0.485],
											[-1.896, 0.925],
											[-1.008, 1.286],
											[-0.131, 0.448],
											[0.927, 2.911],
											[1.965, 1.052],
											[2.232, -0.167],
										],
										o: [
											[-2.042, 0.892],
											[-1.256, 3.465],
											[1.261, 1.985],
											[3.211, 0.485],
											[0.836, -0.408],
											[1.008, -1.286],
											[0.672, -2.305],
											[-0.927, -2.911],
											[-1.616, -0.865],
											[-2.232, 0.167],
										],
										v: [
											[2556, 1078],
											[2550.421, 1085.233],
											[2551, 1096],
											[2558.524, 1099.682],
											[2567, 1099],
											[2570.029, 1096.03],
											[2572, 1093],
											[2571.477, 1084.56],
											[2567, 1078],
											[2560.63, 1077.202],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 132,
								s: [
									{
										i: [
											[3.392, -1.198],
											[1.091, -1.258],
											[0.229, -1.117],
											[-4.102, -2.783],
											[-4.7, 1.539],
											[-0.715, 0.758],
											[-0.678, 0.818],
											[-0.561, 0.975],
											[-0.01, 1.202],
											[2.478, 1.174],
										],
										o: [
											[-2.409, 0.851],
											[-1.091, 1.258],
											[-1.111, 5.408],
											[4.103, 2.783],
											[0.461, -0.151],
											[0.715, -0.758],
											[0.745, -0.898],
											[0.561, -0.975],
											[0.025, -2.829],
											[-3.445, -1.632],
										],
										v: [
											[1997, 1076],
											[1991.866, 1079.3],
											[1990, 1083],
											[1995.642, 1095.71],
											[2010, 1098],
											[2011.838, 1096.5],
											[2014, 1094],
											[2016.051, 1091.228],
											[2017, 1088],
											[2008, 1076],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 133,
								s: [
									{
										i: [
											[6.733, -1.475],
											[1.654, -1.751],
											[0.26, -1.31],
											[-3.384, -2.757],
											[-5.578, 1.633],
											[-0.418, 0.458],
											[-0.242, 0.14],
											[-0.705, 1.565],
											[-0.178, 0.483],
											[0.217, 0.226],
										],
										o: [
											[-3.124, 0.684],
											[-1.654, 1.751],
											[-1.112, 5.604],
											[3.384, 2.757],
											[0.088, -0.026],
											[0.418, -0.459],
											[2.51, -1.453],
											[0.17, -0.378],
											[1.27, -3.45],
											[-3.816, -3.983],
										],
										v: [
											[2014, 1077],
											[2006.852, 1081.031],
											[2004, 1086],
											[2007.982, 1098.928],
											[2022, 1101],
											[2022.885, 1100.086],
											[2024, 1099],
											[2027, 1096],
											[2029, 1095],
											[2028, 1083],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[4.361, -1.42],
											[0.711, -0.267],
											[0.645, -0.374],
											[0.486, -0.545],
											[0.235, -0.781],
											[-3.377, -2.308],
											[-4.083, 0.515],
											[-1.143, 2.271],
											[0.105, 1.722],
											[2.602, 1.415],
										],
										o: [
											[-0.686, 0.224],
											[-0.711, 0.267],
											[-0.645, 0.374],
											[-0.486, 0.545],
											[-1.592, 5.283],
											[3.377, 2.308],
											[1.534, -0.193],
											[1.143, -2.271],
											[-0.229, -3.76],
											[-2.602, -1.415],
										],
										v: [
											[2030, 1084],
											[2027.881, 1084.719],
											[2025.824, 1085.664],
											[2024.105, 1087.027],
											[2023, 1089],
											[2026.743, 1100.348],
											[2039, 1103],
											[2043.229, 1098.646],
											[2045, 1092],
											[2040.599, 1084.115],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[1.27, -0.677],
											[0.859, -0.597],
											[0.152, -0.394],
											[-3.049, -1.756],
											[-2.467, 1.325],
											[-0.653, 1.431],
											[0.04, 1.046],
											[0.965, 1.45],
											[0.095, 0.039],
											[1.45, -0.177],
										],
										o: [
											[-0.412, 0.22],
											[-0.859, 0.597],
											[-1.662, 4.303],
											[3.049, 1.756],
											[0.426, -0.229],
											[0.653, -1.431],
											[-0.044, -1.159],
											[-0.965, -1.451],
											[-0.931, -0.383],
											[-1.45, 0.177],
										],
										v: [
											[2044, 1089],
											[2041.805, 1090.37],
											[2040, 1092],
											[2043.404, 1101.221],
											[2053, 1102],
											[2054.849, 1099.113],
											[2056, 1095],
											[2054.039, 1090.661],
											[2052, 1088],
											[2048.254, 1087.705],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[0.03, -0.006],
											[0.252, -1.018],
											[-1.046, -0.914],
											[-1.4, -0.189],
											[-0.81, 1.157],
											[0.064, 0.836],
											[0.492, 0.745],
											[0.807, 0.412],
											[1.008, -0.165],
											[-0.015, -0.247],
										],
										o: [
											[-2.494, 0.5],
											[-0.252, 1.018],
											[1.046, 0.914],
											[1.4, 0.189],
											[0.478, -0.683],
											[-0.064, -0.836],
											[-0.492, -0.745],
											[-0.807, -0.412],
											[-0.029, 0.005],
											[0.015, 0.247],
										],
										v: [
											[2057, 1093],
											[2053.118, 1095.432],
											[2054.545, 1098.485],
											[2058.45, 1100.295],
											[2062, 1099],
											[2062.593, 1096.661],
											[2061.73, 1094.228],
											[2059.752, 1092.431],
											[2057, 1092],
											[2057, 1092.499],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[0.039, -0.023],
											[-0.152, -0.944],
											[-1.097, -0.692],
											[-1.272, -0.152],
											[-0.676, 0.676],
											[0.293, 0.838],
											[0.903, 0.702],
											[1.034, 0.388],
											[0.686, -0.103],
											[-0.02, -0.243],
										],
										o: [
											[-1.564, 0.907],
											[0.152, 0.944],
											[1.097, 0.692],
											[1.272, 0.152],
											[0.796, -0.796],
											[-0.293, -0.838],
											[-0.902, -0.702],
											[-1.034, -0.388],
											[-0.039, 0.006],
											[0.019, 0.243],
										],
										v: [
											[2064, 1093],
											[2062.074, 1095.848],
											[2064.14, 1098.374],
											[2067.886, 1099.713],
											[2071, 1099],
											[2071.636, 1096.505],
											[2069.724, 1094.151],
											[2066.7, 1092.472],
											[2064, 1092],
											[2064.001, 1092.487],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[0.471, -0.256],
											[0.197, -0.415],
											[-0.22, -0.355],
											[-0.472, -0.243],
											[-0.557, -0.081],
											[-0.475, 0.133],
											[-0.228, 0.397],
											[0.139, 0.516],
											[0.501, 0.233],
											[0.611, -0.025],
										],
										o: [
											[-0.781, 0.425],
											[-0.197, 0.415],
											[0.22, 0.355],
											[0.472, 0.243],
											[0.557, 0.081],
											[0.475, -0.133],
											[0.473, -0.825],
											[-0.139, -0.516],
											[-0.5, -0.233],
											[-0.611, 0.025],
										],
										v: [
											[2072, 1095],
											[2070.575, 1096.272],
											[2070.651, 1097.439],
											[2071.731, 1098.348],
											[2073.315, 1098.847],
											[2074.904, 1098.782],
											[2076, 1098],
											[2076.439, 1095.994],
											[2075.417, 1094.877],
											[2073.686, 1094.572],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 139,
								s: [
									{
										i: [
											[0.946, -0.399],
											[0.684, -0.706],
											[0.44, -0.956],
											[-0.09, -1.022],
											[-0.903, -0.903],
											[-1.556, 0.439],
											[-0.959, 1.587],
											[0.077, 1.729],
											[1.551, 0.866],
											[0.847, -0.243],
										],
										o: [
											[-0.644, 0.272],
											[-0.684, 0.706],
											[-0.44, 0.956],
											[0.09, 1.022],
											[1.715, 1.715],
											[1.556, -0.439],
											[0.959, -1.587],
											[-0.077, -1.729],
											[-0.665, -0.371],
											[-0.847, 0.243],
										],
										v: [
											[2204, 1117],
											[2201.936, 1118.513],
											[2200.178, 1121.053],
											[2199.582, 1124.066],
											[2201, 1127],
											[2206.016, 1128.662],
											[2209.898, 1125.371],
											[2211.331, 1120.144],
											[2209, 1116],
											[2206.711, 1115.923],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 140,
								s: [
									{
										i: [
											[0.01, -0.001],
											[0.509, -0.123],
											[0.369, -0.274],
											[0.05, -0.514],
											[-0.447, -0.844],
											[-0.852, 0.186],
											[-0.523, 0.982],
											[0.216, 0.93],
											[1.365, 0.029],
											[-0.004, -0.25],
										],
										o: [
											[-0.471, 0.062],
											[-0.509, 0.123],
											[-0.369, 0.274],
											[-0.05, 0.514],
											[0.771, 1.457],
											[0.852, -0.186],
											[0.523, -0.982],
											[-0.216, -0.93],
											[-0.007, 0],
											[0.004, 0.25],
										],
										v: [
											[2395, 1168],
											[2393.485, 1168.254],
											[2392.123, 1168.826],
											[2391.45, 1169.985],
											[2392, 1172],
											[2394.538, 1173.694],
											[2396.705, 1171.73],
											[2397.269, 1168.65],
											[2395, 1167],
											[2395.002, 1167.499],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 141,
								s: [
									{
										i: [
											[0.832, -0.413],
											[0.225, -0.349],
											[-0.053, -0.352],
											[-0.243, -0.302],
											[-0.345, -0.199],
											[-0.359, -0.043],
											[-0.286, 0.166],
											[-0.16, 0.555],
											[0.159, 0.535],
											[0.49, 0.212],
										],
										o: [
											[-0.59, 0.293],
											[-0.225, 0.349],
											[0.053, 0.352],
											[0.243, 0.302],
											[0.345, 0.199],
											[0.359, 0.043],
											[0.468, -0.272],
											[0.16, -0.555],
											[-0.159, -0.535],
											[-0.49, -0.212],
										],
										v: [
											[2399, 1170],
											[2397.8, 1170.975],
											[2397.564, 1172.039],
											[2398.029, 1173.033],
											[2398.932, 1173.797],
											[2400.01, 1174.172],
											[2401, 1174],
											[2401.946, 1172.683],
											[2401.951, 1170.972],
											[2400.981, 1169.775],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 142,
								s: [
									{
										i: [
											[0.055, -0.023],
											[0.031, -0.448],
											[-0.66, -0.284],
											[-0.736, -0.068],
											[-0.197, 0.197],
											[0.124, 0.502],
											[0.485, 0.366],
											[0.493, 0.181],
											[0.149, -0.052],
											[-0.028, -0.239],
										],
										o: [
											[-1.336, 0.563],
											[-0.031, 0.448],
											[0.66, 0.284],
											[0.736, 0.068],
											[0.589, -0.589],
											[-0.124, -0.502],
											[-0.485, -0.366],
											[-0.493, -0.181],
											[-0.055, 0.019],
											[0.028, 0.239],
										],
										v: [
											[1770, 1174],
											[1768.104, 1175.529],
											[1769.2, 1176.64],
											[1771.447, 1177.181],
											[1773, 1177],
											[1773.61, 1175.352],
											[1772.608, 1174.038],
											[1771.052, 1173.206],
											[1770, 1173],
											[1770, 1173.497],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 121, s: [0], h: 1 },
							{ t: 122, s: [100], h: 1 },
							{ t: 123, s: [0], h: 1 },
							{ t: 127, s: [100], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 2',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 122,
								s: [
									{
										i: [
											[1.251, -0.836],
											[0.18, -1.04],
											[-0.61, -0.862],
											[-0.985, -0.391],
											[-0.945, 0.372],
											[-0.184, 1.077],
											[0.411, 1.121],
											[0.889, 0.483],
										],
										o: [
											[-1.385, 0.925],
											[-0.18, 1.04],
											[0.61, 0.862],
											[0.985, 0.391],
											[0.894, -0.352],
											[0.184, -1.077],
											[-0.411, -1.121],
											[-0.889, -0.483],
										],
										v: [
											[2491, 1161],
											[2488.757, 1164.02],
											[2489.505, 1166.946],
											[2492.001, 1168.898],
											[2495, 1169],
											[2496.588, 1166.685],
											[2496.218, 1163.218],
											[2494.239, 1160.642],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 127,
								s: [
									{
										i: [
											[1.282, -0.697],
											[-0.015, -1.408],
											[-1.405, -0.937],
											[-1.687, 0.065],
											[-0.863, 1.596],
											[0.197, 1.156],
											[0.883, 0.674],
											[1.244, 0.056],
										],
										o: [
											[-2.481, 1.35],
											[0.015, 1.408],
											[1.405, 0.937],
											[1.687, -0.065],
											[0.813, -1.503],
											[-0.197, -1.156],
											[-0.883, -0.674],
											[-1.244, -0.056],
										],
										v: [
											[2512, 1082],
											[2508.579, 1086.269],
											[2510.985, 1089.918],
											[2515.899, 1091.359],
											[2520, 1089],
											[2520.842, 1084.977],
											[2519.141, 1082.199],
											[2515.87, 1081.072],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 128,
								s: [
									{
										i: [
											[0.222, -0.09],
											[0.273, -0.99],
											[-0.642, -0.874],
											[-1.094, -0.322],
											[-1.083, 0.666],
											[-0.053, 1.788],
											[0.797, 0.489],
											[0.759, -0.341],
										],
										o: [
											[-1.651, 0.669],
											[-0.273, 0.99],
											[0.642, 0.874],
											[1.094, 0.322],
											[0.583, -0.359],
											[0.053, -1.788],
											[-0.741, -0.455],
											[-0.759, 0.341],
										],
										v: [
											[2508, 1083],
											[2505.23, 1085.597],
											[2505.9, 1088.501],
											[2508.619, 1090.405],
											[2512, 1090],
											[2513.035, 1086.098],
											[2512, 1082],
											[2509.611, 1082.091],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 129,
								s: [
									{
										i: [
											[0.05, -0.017],
											[0.432, -0.799],
											[-0.27, -0.806],
											[-0.817, -0.479],
											[-1.208, 0.182],
											[-0.196, 1.715],
											[1.001, 0.958],
											[0.619, -0.359],
										],
										o: [
											[-1.288, 0.458],
											[-0.432, 0.799],
											[0.27, 0.806],
											[0.817, 0.479],
											[1.216, -0.183],
											[0.196, -1.715],
											[-0.473, -0.453],
											[-0.619, 0.359],
										],
										v: [
											[2499, 1084],
											[2496.459, 1085.968],
											[2496.256, 1088.459],
											[2497.924, 1090.47],
											[2501, 1091],
											[2503.163, 1087.582],
											[2502, 1083],
											[2500.182, 1083.147],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 130,
								s: [
									{
										i: [
											[1.639, 0.671],
											[0.33, -0.334],
											[0.382, -0.23],
											[0.236, -0.281],
											[-0.107, -0.486],
											[-0.922, -0.333],
											[-0.145, 0.097],
											[0.024, 1.21],
										],
										o: [
											[-0.08, 0.592],
											[-0.33, 0.334],
											[-0.382, 0.23],
											[-0.236, 0.281],
											[0.168, 0.764],
											[0.922, 0.333],
											[0.735, -0.49],
											[-0.024, -1.21],
										],
										v: [
											[2491, 1085],
											[2490.336, 1086.35],
											[2489.22, 1087.158],
											[2488.243, 1087.887],
											[2488, 1089],
											[2490.017, 1090.645],
											[2492, 1091],
											[2493.28, 1088.136],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 131,
								s: [
									{
										i: [
											[3.551, -1.485],
											[1.207, -1.844],
											[-1.298, -3.008],
											[-1.121, -0.404],
											[-1.042, -0.285],
											[-1.317, 0.634],
											[-0.722, 2.289],
											[1.909, 1.932],
										],
										o: [
											[-1.472, 0.615],
											[-1.207, 1.844],
											[0.474, 1.098],
											[1.121, 0.404],
											[3.012, 0.823],
											[1.317, -0.634],
											[1.185, -3.756],
											[-1.909, -1.932],
										],
										v: [
											[2266, 1084],
											[2261.422, 1087.706],
											[2261, 1095],
											[2263.574, 1097.11],
											[2267, 1098],
											[2273.218, 1098.334],
											[2276, 1094],
											[2274.551, 1085.069],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 132,
								s: [
									{
										i: [
											[2.938, -1.912],
											[0.347, -1.688],
											[-0.803, -1.543],
											[-1.498, -0.833],
											[-1.739, 0.441],
											[-0.742, 2.008],
											[0.53, 2.098],
											[1.757, 0.791],
										],
										o: [
											[-1.95, 1.269],
											[-0.347, 1.688],
											[0.803, 1.543],
											[1.498, 0.833],
											[2.059, -0.522],
											[0.742, -2.008],
											[-0.53, -2.098],
											[-1.757, -0.791],
										],
										v: [
											[2570, 1083],
											[2566.668, 1087.578],
											[2567.465, 1092.565],
											[2571.03, 1096.27],
											[2576, 1097],
											[2580.189, 1092.856],
											[2580.495, 1086.349],
											[2577.053, 1081.667],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 133,
								s: [
									{
										i: [
											[1.07, -0.611],
											[-0.085, -1.423],
											[-1.594, -0.873],
											[-1.838, 0.111],
											[-0.814, 1.531],
											[0.358, 1.131],
											[1.059, 0.65],
											[1.296, 0.069],
										],
										o: [
											[-2.692, 1.537],
											[0.085, 1.423],
											[1.594, 0.873],
											[1.838, -0.111],
											[0.805, -1.513],
											[-0.358, -1.131],
											[-1.059, -0.65],
											[-1.296, -0.069],
										],
										v: [
											[2580, 1087],
											[2576.405, 1091.549],
											[2579.241, 1095.103],
											[2584.705, 1096.355],
											[2589, 1094],
											[2589.553, 1090.01],
											[2587.312, 1087.314],
											[2583.665, 1086.211],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[5.841, -1.846],
											[1.153, -3.117],
											[-1.659, -1.732],
											[-0.617, -0.746],
											[-7.547, 1.168],
											[-0.495, 0.574],
											[0.992, 3.138],
											[2.393, 1.258],
										],
										o: [
											[-5.073, 1.604],
											[-1.228, 3.319],
											[0.615, 0.642],
											[3.705, 4.479],
											[1.879, -0.291],
											[1.468, -1.703],
											[-1.044, -3.302],
											[-5.149, -2.708],
										],
										v: [
											[1710, 1088],
											[1701, 1096],
											[1701, 1109],
											[1702, 1111],
											[1725, 1118],
											[1734, 1114],
											[1737, 1097],
											[1727, 1089],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[8.406, -3.559],
											[0.623, -4.593],
											[-1.799, -2.803],
											[-3.526, -0.974],
											[-3.638, 0.856],
											[-1.664, 1.006],
											[0.128, -0.134],
											[1.198, 4.095],
										],
										o: [
											[-4.309, 1.824],
											[-0.622, 4.593],
											[1.258, 1.961],
											[3.526, 0.974],
											[0.471, -0.111],
											[1.664, -1.006],
											[2.129, -2.234],
											[-1.952, -6.671],
										],
										v: [
											[1726, 1091],
											[1718.919, 1101.766],
											[1721, 1114],
											[1728.715, 1118.613],
											[1740, 1119],
											[1743.949, 1116.816],
											[1747, 1115],
											[1751, 1098],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[9.266, -3.714],
											[1.938, -3.674],
											[-1.773, -4.493],
											[-2.336, -1.517],
											[-0.331, -0.109],
											[-0.872, 0.835],
											[-0.47, 2.183],
											[1.395, 1.395],
										],
										o: [
											[-2.023, 0.811],
											[-1.938, 3.674],
											[0.325, 0.825],
											[2.336, 1.517],
											[5.18, 1.694],
											[2.102, -2.014],
											[1.197, -5.556],
											[-1.41, -1.41],
										],
										v: [
											[1744, 1095],
											[1737.153, 1102.238],
											[1736, 1115],
											[1740.996, 1119.037],
											[1746, 1122],
											[1761, 1122],
											[1768, 1113],
											[1765, 1097],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[5.516, -1.833],
											[1.296, -1.627],
											[0.287, -1.65],
											[-0.852, -1.821],
											[-2.134, -1.583],
											[-6.653, -0.739],
											[-2.685, 2.848],
											[0.887, 2.797],
										],
										o: [
											[-2.531, 0.841],
											[-1.296, 1.627],
											[-0.544, 3.134],
											[0.852, 1.821],
											[4.323, 3.208],
											[5.382, 0.598],
											[1.518, -1.61],
											[-2.057, -6.487],
										],
										v: [
											[1766, 1098],
											[1760.317, 1101.893],
											[1758, 1107],
											[1758.492, 1114.163],
											[1763, 1119],
											[1776, 1123],
											[1787, 1121],
											[1790, 1106],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[2.174, -0.909],
											[1.476, -2.702],
											[-1.566, -3.496],
											[-2.608, -0.422],
											[-2.145, 1.192],
											[-0.362, 0.435],
											[0.735, 2.764],
											[1.25, 0.249],
										],
										o: [
											[-1.664, 0.696],
											[-1.476, 2.702],
											[1.245, 2.78],
											[3.701, 0.599],
											[0.5, -0.278],
											[1.72, -2.067],
											[-1.061, -3.989],
											[-2.174, -0.433],
										],
										v: [
											[1788, 1104],
											[1782.578, 1109.399],
											[1782, 1119],
											[1792, 1123],
											[1800, 1123],
											[1803, 1122],
											[1804, 1111],
											[1795, 1103],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 139,
								s: [
									{
										i: [
											[1.778, -0.329],
											[0.678, -0.079],
											[0.439, -0.528],
											[0.216, -1.056],
											[0.089, 0.284],
											[-2.859, 1.009],
											[2.213, 3.448],
											[0.628, -0.195],
										],
										o: [
											[-0.85, 0.158],
											[-0.678, 0.079],
											[-0.048, 0.059],
											[-0.216, 1.056],
											[0.776, 2.484],
											[2.859, -1.009],
											[-0.712, -1.11],
											[-0.628, 0.195],
										],
										v: [
											[2386, 1168],
											[2383.692, 1168.223],
											[2382, 1169],
											[2381.53, 1171.257],
											[2381, 1173],
											[2388.242, 1174.949],
											[2391, 1168],
											[2389.299, 1166.921],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 140,
								s: [
									{
										i: [
											[0.766, -0.263],
											[0.113, -0.908],
											[-0.962, -0.701],
											[-1.219, -0.067],
											[-0.658, 0.994],
											[0.116, 0.722],
											[0.585, 0.462],
											[0.802, 0.134],
										],
										o: [
											[-2.007, 0.689],
											[-0.113, 0.908],
											[0.962, 0.701],
											[1.219, 0.067],
											[0.606, -0.915],
											[-0.116, -0.722],
											[-0.585, -0.462],
											[-0.802, -0.134],
										],
										v: [
											[1761, 1172],
											[1758.024, 1174.503],
											[1759.502, 1177.024],
											[1762.979, 1178.283],
											[1766, 1177],
											[1766.672, 1174.527],
											[1765.558, 1172.733],
											[1763.415, 1171.823],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 141,
								s: [
									{
										i: [
											[1.964, -1.123],
											[0.279, -0.178],
											[0.258, -0.256],
											[0.1, -0.324],
											[-0.195, -0.385],
											[-0.963, 0.315],
											[-0.526, 1.079],
											[0.45, 0.6],
										],
										o: [
											[-0.162, 0.092],
											[-0.279, 0.178],
											[-0.258, 0.256],
											[-0.1, 0.324],
											[0.861, 1.693],
											[0.963, -0.315],
											[0.526, -1.079],
											[-0.45, -0.6],
										],
										v: [
											[1766, 1173],
											[1765.305, 1173.408],
											[1764.464, 1174.062],
											[1763.892, 1174.934],
											[1764, 1176],
											[1766.87, 1177.757],
											[1769.237, 1175.356],
											[1769.486, 1172.527],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 121, s: [0], h: 1 },
							{ t: 122, s: [100], h: 1 },
							{ t: 123, s: [0], h: 1 },
							{ t: 127, s: [100], h: 1 },
							{ t: 142, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 3',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 127,
								s: [
									{
										i: [
											[0.58, -0.28],
											[-0.703, -2.373],
											[-2.908, 0.807],
											[3.555, 1.273],
										],
										o: [
											[-2.72, 1.315],
											[0.703, 2.373],
											[3.966, -1.101],
											[-1.494, -0.535],
										],
										v: [
											[2482, 1106],
											[2479.279, 1112.592],
											[2485, 1116],
											[2485, 1105],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 128,
								s: [
									{
										i: [
											[0.547, -0.243],
											[-2.076, -1.916],
											[-1.685, 3.438],
											[1.657, 0.647],
										],
										o: [
											[-3.988, 1.774],
											[2.076, 1.916],
											[0.574, -1.171],
											[-1.466, -0.573],
										],
										v: [
											[2478, 1107],
											[2476.745, 1113.909],
											[2484, 1113],
											[2481, 1106],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 129,
								s: [
									{
										i: [
											[-0.306, 0.029],
											[-0.974, -1.979],
											[-1.971, 1.282],
											[1.558, 2.395],
										],
										o: [
											[-3.867, 0.365],
											[0.974, 1.979],
											[0.967, -0.629],
											[-0.688, -1.057],
										],
										v: [
											[2475, 1108],
											[2471.622, 1112.735],
											[2477, 1115],
											[2478, 1108],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 130,
								s: [
									{
										i: [
											[2.185, -1.471],
											[-1.456, -1.34],
											[-1.432, 0.553],
											[1.708, 1.078],
										],
										o: [
											[-3.256, 2.193],
											[1.456, 1.34],
											[1.974, -0.762],
											[-0.813, -0.513],
										],
										v: [
											[2467, 1109],
											[2465.484, 1114.559],
											[2471, 1116],
											[2472, 1108],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 131,
								s: [
									{
										i: [
											[1.993, -0.807],
											[0.582, -0.688],
											[-0.983, -0.983],
											[-0.569, 1.631],
										],
										o: [
											[-0.656, 0.266],
											[-0.582, 0.688],
											[1.717, 1.717],
											[0.569, -1.631],
										],
										v: [
											[2486, 1087],
											[2483.771, 1088.462],
											[2484, 1091],
											[2487.782, 1089.682],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 132,
								s: [
									{
										i: [
											[5.715, -3.651],
											[-1.865, -2.805],
											[-3.503, 1.751],
											[-0.255, 0.857],
										],
										o: [
											[-3.961, 2.532],
											[1.865, 2.805],
											[0.147, -0.074],
											[2.545, -8.564],
										],
										v: [
											[2277, 1087],
											[2274.902, 1096.212],
											[2284, 1099],
											[2287, 1095],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 133,
								s: [
									{
										i: [
											[2.073, -0.73],
											[1.072, -1.283],
											[-1.046, -1.601],
											[1.633, 3.707],
										],
										o: [
											[-0.76, 0.267],
											[-1.072, 1.283],
											[3.506, 5.365],
											[-0.972, -2.206],
										],
										v: [
											[2289, 1089],
											[2285.645, 1091.5],
											[2285, 1096],
											[2294, 1090],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[0.453, -0.231],
											[-1.546, -1.329],
											[-0.873, 0.874],
											[2.319, 1.142],
										],
										o: [
											[-3.056, 1.557],
											[1.546, 1.329],
											[0.269, -0.269],
											[-1.351, -0.665],
										],
										v: [
											[2590, 1089],
											[2589.053, 1093.823],
											[2594, 1095],
											[2593, 1088],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[1.167, -1.167],
											[-1.239, -0.399],
											[-0.459, 1.011],
											[0.808, 0.573],
										],
										o: [
											[-1.415, 1.415],
											[1.239, 0.399],
											[0.397, -0.874],
											[-0.808, -0.573],
										],
										v: [
											[2596, 1092],
											[2596.594, 1094.82],
											[2600, 1094],
											[2599.173, 1091.47],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[5.936, -2.783],
											[-0.223, -1.879],
											[-2.234, 2.234],
											[-0.038, 1.493],
										],
										o: [
											[-3.664, 1.718],
											[0.559, 4.703],
											[0.468, -0.468],
											[0.144, -5.616],
										],
										v: [
											[1592, 1100],
											[1588, 1106],
											[1600, 1110],
											[1601, 1105],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[0.065, -0.026],
											[-2.093, -1.693],
											[-1.665, 2.358],
											[1.926, -0.174],
										],
										o: [
											[-4.035, 1.585],
											[2.093, 1.693],
											[2.607, -3.69],
											[-0.06, 0.005],
										],
										v: [
											[1602, 1103],
											[1600.725, 1108.957],
											[1608, 1109],
											[1602, 1102],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[0.025, -0.004],
											[0.663, -0.781],
											[-0.385, -0.757],
											[5.55, 0.12],
										],
										o: [
											[-0.731, 0.12],
											[-0.663, 0.781],
											[2.991, 5.884],
											[-0.018, 0],
										],
										v: [
											[1613, 1106],
											[1610.663, 1107.522],
											[1610, 1110],
											[1613, 1105],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 139,
								s: [
									{
										i: [
											[2.637, -1.537],
											[-2.922, -4.172],
											[-1.173, -0.059],
											[1.645, 1.205],
										],
										o: [
											[-1.392, 0.811],
											[0.373, 0.533],
											[5.426, 0.272],
											[-0.804, -0.589],
										],
										v: [
											[1752, 1171],
											[1749, 1179],
											[1754, 1180],
											[1758, 1170],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 126, s: [0], h: 1 },
							{ t: 127, s: [100], h: 1 },
							{ t: 140, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 4',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 128,
								s: [
									{
										i: [
											[10.026, -1.02],
											[2.709, -1.284],
											[2.421, -2.38],
											[1.174, -2.692],
											[-0.335, -4.637],
											[-1.16, -1.775],
											[-3.02, -2.197],
											[-4.46, -1.179],
											[-5.929, 1.066],
											[-2.471, 2.392],
											[-1.493, 3.6],
											[0.541, 3.85],
											[3.016, 3.347],
										],
										o: [
											[-4.742, 0.482],
											[-2.709, 1.284],
											[-2.64, 2.596],
											[-1.174, 2.692],
											[0.34, 4.702],
											[1.16, 1.775],
											[3.229, 2.349],
											[4.46, 1.179],
											[4.625, -0.832],
											[2.47, -2.392],
											[2.178, -5.254],
											[-0.541, -3.85],
											[-6.199, -6.88],
										],
										v: [
											[2583, 1109],
											[2572.259, 1111.576],
											[2565, 1117],
											[2559.268, 1124.469],
											[2558, 1135],
											[2559.99, 1143.879],
											[2566, 1149],
											[2577.475, 1154.562],
											[2593, 1155],
											[2603.349, 1150.076],
											[2609, 1141],
											[2611.396, 1127.57],
											[2606, 1117],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 129,
								s: [
									{
										i: [
											[6.175, -1.391],
											[3.458, -3.152],
											[0.072, -0.187],
											[0.222, -2.299],
											[-4.399, -4.794],
											[-2.245, -0.829],
											[-4.998, -0.7],
											[-3.275, 0.472],
											[-1.845, 4.954],
											[0.721, 3.693],
											[3.525, 2.392],
											[1.937, 0.788],
											[0.955, 0.202],
										],
										o: [
											[-4.958, 1.117],
											[-1.196, 1.09],
											[-0.272, 0.711],
											[-1.127, 11.65],
											[2.147, 2.34],
											[3.405, 1.258],
											[4.132, 0.579],
											[6.007, -0.866],
											[1.728, -4.638],
											[-0.688, -3.525],
											[-1.081, -0.733],
											[-1.849, -0.752],
											[-4.91, -1.04],
										],
										v: [
											[2585, 1108],
											[2574, 1113],
											[2568, 1120],
											[2567, 1127],
											[2576, 1150],
											[2581, 1154],
											[2594, 1155],
											[2604, 1157],
											[2619, 1143],
											[2621, 1124],
											[2612, 1111],
											[2607, 1110],
											[2603, 1107],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 130,
								s: [
									{
										i: [
											[0.043, -0.024],
											[0.176, -0.097],
											[0.19, -0.124],
											[0.158, -0.148],
											[0.083, -0.169],
											[-0.037, -0.187],
											[-0.856, -0.161],
											[-0.907, 0.416],
											[-0.347, 0.744],
											[0.822, 0.822],
											[0.413, -0.035],
											[0.421, -0.187],
											[0.298, -0.183],
										],
										o: [
											[-0.119, 0.066],
											[-0.176, 0.097],
											[-0.19, 0.124],
											[-0.158, 0.148],
											[-0.083, 0.169],
											[0.195, 0.986],
											[0.856, 0.161],
											[0.907, -0.416],
											[0.347, -0.744],
											[-0.272, -0.272],
											[-0.413, 0.035],
											[-0.421, 0.187],
											[-0.298, 0.183],
										],
										v: [
											[2480, 1110],
											[2479.546, 1110.245],
											[2478.986, 1110.577],
											[2478.453, 1110.986],
											[2478.08, 1111.464],
											[2478, 1112],
											[2479.729, 1113.658],
											[2482.525, 1113.212],
											[2484.559, 1111.41],
											[2484, 1109],
											[2482.94, 1108.683],
											[2481.657, 1109.056],
											[2480.545, 1109.65],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 131,
								s: [
									{
										i: [
											[0, 0],
											[0.313, -0.175],
											[0.27, -0.229],
											[0.192, -0.243],
											[0.081, -0.218],
											[-0.064, -0.154],
											[-0.895, -0.081],
											[-0.864, 0.503],
											[-0.287, 0.832],
											[0.836, 0.909],
											[0.321, -0.05],
											[0.322, -0.183],
											[0.215, -0.166],
										],
										o: [
											[-0.322, 0.083],
											[-0.313, 0.175],
											[-0.27, 0.229],
											[-0.192, 0.243],
											[-0.081, 0.218],
											[0.38, 0.919],
											[0.895, 0.081],
											[0.864, -0.502],
											[0.287, -0.832],
											[-0.213, -0.232],
											[-0.321, 0.05],
											[-0.322, 0.183],
											[-0.215, 0.166],
										],
										v: [
											[2463, 1110],
											[2462.038, 1110.397],
											[2461.156, 1111.013],
											[2460.454, 1111.731],
											[2460.035, 1112.433],
											[2460, 1113],
											[2462.048, 1114.437],
											[2464.822, 1113.741],
											[2466.686, 1111.675],
											[2466, 1109],
											[2465.172, 1108.765],
											[2464.18, 1109.152],
											[2463.349, 1109.713],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 132,
								s: [
									{
										i: [
											[3.33, -1.042],
											[0.26, -0.145],
											[0.37, -0.237],
											[0.61, -0.587],
											[0.424, -0.554],
											[0.599, -0.978],
											[0.462, -6.728],
											[-4.722, -4.575],
											[-6.963, 1.073],
											[-2.853, 2.378],
											[-1.15, 7.305],
											[7.431, 4.228],
											[2.853, 0.763],
										],
										o: [
											[-0.588, 0.184],
											[-0.26, 0.145],
											[-0.688, 0.44],
											[-0.528, 0.508],
											[-0.593, 0.775],
											[-2.817, 4.599],
											[-0.687, 10.009],
											[3.754, 3.637],
											[1.023, -0.158],
											[5.355, -4.465],
											[1.604, -10.183],
											[-2.092, -1.19],
											[-5.389, -1.44],
										],
										v: [
											[2077, 1097],
											[2075.837, 1097.46],
											[2075, 1098],
											[2072, 1099],
											[2071, 1102],
											[2069, 1104],
											[2065, 1118],
											[2070, 1135],
											[2087, 1141],
											[2096, 1136],
											[2104, 1120],
											[2096, 1100],
											[2088, 1098],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 133,
								s: [
									{
										i: [
											[6.012, -1.408],
											[2.026, -1.581],
											[0.912, -1.976],
											[0.427, -0.549],
											[0.08, -0.349],
											[-1.325, -3.643],
											[-1.623, -1.41],
											[-1.625, -0.808],
											[-2.906, 0.633],
											[-0.917, 0.688],
											[-0.788, 0.83],
											[0.054, -0.235],
											[3.412, 2.077],
										],
										o: [
											[-2.984, 0.698],
											[-2.026, 1.581],
											[-0.211, 0.457],
											[-0.427, 0.549],
											[-0.925, 4.019],
											[1.325, 3.643],
											[0.535, 0.465],
											[1.888, 0.939],
											[1.926, -0.419],
											[0.963, -0.722],
											[0.963, -1.013],
											[2.222, -9.693],
											[-3.788, -2.306],
										],
										v: [
											[2094, 1096],
											[2086.446, 1099.542],
											[2082, 1105],
											[2080.902, 1106.581],
											[2080, 1108],
											[2081.089, 1119.956],
											[2086, 1128],
											[2092, 1131],
											[2103, 1132],
											[2108, 1128],
											[2111, 1127],
											[2116, 1119],
											[2109, 1097],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[0.131, -0.072],
											[0.186, -0.715],
											[-0.496, -0.625],
											[-0.823, -0.408],
											[-0.794, -0.064],
											[-0.408, 0.408],
											[-0.11, 0.57],
											[-0.006, 0.774],
											[0.232, 0.692],
											[0.604, 0.325],
											[0.407, -0.032],
											[0.37, -0.159],
											[0.278, -0.172],
										],
										o: [
											[-1.225, 0.678],
											[-0.186, 0.715],
											[0.496, 0.625],
											[0.823, 0.408],
											[0.794, 0.064],
											[0.082, -0.082],
											[0.11, -0.57],
											[0.006, -0.774],
											[-0.232, -0.692],
											[-0.388, -0.208],
											[-0.407, 0.032],
											[-0.37, 0.159],
											[-0.278, 0.172],
										],
										v: [
											[2294, 1091],
											[2291.972, 1093.121],
											[2292.526, 1095.164],
											[2294.594, 1096.746],
											[2297.108, 1097.485],
											[2299, 1097],
											[2299.321, 1095.951],
											[2299.528, 1093.865],
											[2299.221, 1091.596],
											[2298, 1090],
											[2296.794, 1089.764],
											[2295.614, 1090.079],
											[2294.627, 1090.605],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[0.024, -0.011],
											[0.195, -0.389],
											[-0.361, -0.287],
											[-0.607, -0.159],
											[-0.544, -0.006],
											[-0.171, 0.171],
											[-0.019, 0.37],
											[0, 0.48],
											[0.02, 0.37],
											[0.039, 0.039],
											[0.317, -0.047],
											[0.311, -0.18],
											[0.214, -0.167],
										],
										o: [
											[-1.06, 0.467],
											[-0.195, 0.389],
											[0.361, 0.287],
											[0.607, 0.159],
											[0.544, 0.006],
											[0.039, -0.039],
											[0.019, -0.37],
											[0, -0.481],
											[-0.019, -0.37],
											[-0.229, -0.229],
											[-0.317, 0.047],
											[-0.311, 0.18],
											[-0.214, 0.167],
										],
										v: [
											[2300, 1093],
											[2298.194, 1094.291],
											[2298.52, 1095.311],
											[2300.048, 1095.986],
											[2301.851, 1096.241],
											[2303, 1096],
											[2303.088, 1095.331],
											[2303.117, 1094],
											[2303.088, 1092.669],
											[2303, 1092],
											[2302.158, 1091.763],
											[2301.193, 1092.14],
											[2300.381, 1092.697],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[4.412, -1.061],
											[0.649, -0.208],
											[0.454, -0.227],
											[0.276, -0.265],
											[1.412, -2.505],
											[-1.146, -3.547],
											[-2.084, -1.366],
											[-2.283, -0.362],
											[-2.45, 1.538],
											[-0.427, 1.447],
											[0.293, 1.952],
											[0.223, 0.847],
											[1.905, 1.096],
										],
										o: [
											[-0.86, 0.207],
											[-0.649, 0.208],
											[-0.454, 0.227],
											[-1.497, 1.434],
											[-1.412, 2.505],
											[0.38, 1.175],
											[2.084, 1.366],
											[3.774, 0.599],
											[2.45, -1.538],
											[0.397, -1.345],
											[-0.293, -1.952],
											[-0.966, -3.675],
											[-1.906, -1.096],
										],
										v: [
											[1692, 1102],
											[1689.741, 1102.618],
											[1688.091, 1103.266],
											[1687, 1104],
											[1682.017, 1109.916],
											[1681, 1119],
											[1685.072, 1123.109],
											[1692, 1126],
											[1701.51, 1124.035],
											[1706, 1119],
											[1705.965, 1113.627],
											[1705, 1109],
											[1701.084, 1101.948],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[2.401, -1.229],
											[0.762, -0.956],
											[0.183, -1.073],
											[-0.306, -1.085],
											[-0.702, -0.99],
											[-1.007, -0.79],
											[-1.221, -0.484],
											[-1.343, -0.073],
											[-1.374, 0.444],
											[-0.728, 1.678],
											[0.572, 1.933],
											[1.528, 1.534],
											[2.137, 0.48],
										],
										o: [
											[-1.433, 0.734],
											[-0.762, 0.956],
											[-0.183, 1.073],
											[0.306, 1.085],
											[0.702, 0.99],
											[1.007, 0.79],
											[1.221, 0.484],
											[1.343, 0.073],
											[2.374, -0.768],
											[0.728, -1.678],
											[-0.572, -1.933],
											[-1.528, -1.534],
											[-2.137, -0.48],
										],
										v: [
											[1700, 1107],
											[1696.73, 1109.561],
											[1695.336, 1112.632],
											[1695.543, 1115.895],
											[1697.078, 1119.034],
											[1699.665, 1121.731],
											[1703.03, 1123.669],
											[1706.9, 1124.531],
											[1711, 1124],
											[1715.567, 1120.168],
											[1715.714, 1114.588],
											[1712.478, 1109.224],
											[1706.894, 1106.039],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[1.713, -0.707],
											[0.679, -0.519],
											[0.353, -0.533],
											[0.122, -0.498],
											[-0.012, -0.412],
											[-1.076, -1.077],
											[-1.543, -0.585],
											[-1.446, 0.034],
											[-0.784, 0.782],
											[-0.172, 1.616],
											[0.259, 1.52],
											[0.517, 0.485],
											[1.64, 0.006],
										],
										o: [
											[-1.101, 0.454],
											[-0.679, 0.519],
											[-0.353, 0.533],
											[-0.122, 0.498],
											[0.044, 1.44],
											[1.076, 1.077],
											[1.543, 0.585],
											[1.446, -0.034],
											[0.776, -0.773],
											[0.172, -1.616],
											[-0.259, -1.52],
											[-0.726, -0.681],
											[-1.64, -0.006],
										],
										v: [
											[1715, 1111],
											[1712.354, 1112.472],
											[1710.83, 1114.063],
											[1710.141, 1115.622],
											[1710, 1117],
											[1711.821, 1120.808],
											[1715.89, 1123.333],
											[1720.514, 1124.192],
											[1724, 1123],
											[1725.379, 1119.181],
											[1725.207, 1114.243],
											[1724, 1111],
											[1720.241, 1109.959],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 139,
								s: [
									{
										i: [
											[0.09, -0.026],
											[0.351, -0.672],
											[-0.399, -0.697],
											[-0.79, -0.503],
											[-0.824, -0.092],
											[-0.501, 0.538],
											[-0.037, 0.597],
											[0.04, 0.806],
											[0.182, 0.681],
											[0.389, 0.221],
											[0.314, -0.051],
											[0.272, -0.164],
											[0.198, -0.156],
										],
										o: [
											[-1.458, 0.429],
											[-0.351, 0.672],
											[0.399, 0.697],
											[0.79, 0.503],
											[0.824, 0.092],
											[0.05, -0.054],
											[0.037, -0.597],
											[-0.04, -0.806],
											[-0.182, -0.681],
											[-0.322, -0.183],
											[-0.314, 0.051],
											[-0.272, 0.164],
											[-0.198, 0.156],
										],
										v: [
											[1624, 1173],
											[1621.377, 1174.706],
											[1621.538, 1176.814],
											[1623.411, 1178.668],
											[1625.922, 1179.615],
											[1628, 1179],
											[1628.147, 1177.94],
											[1628.158, 1175.751],
											[1627.84, 1173.437],
											[1627, 1172],
											[1626.039, 1171.832],
											[1625.152, 1172.185],
											[1624.439, 1172.696],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 127, s: [0], h: 1 },
							{ t: 128, s: [100], h: 1 },
							{ t: 140, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 5',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 128,
								s: [
									{
										i: [
											[4.668, -0.831],
											[1.772, -0.928],
											[0.39, -1.019],
											[-1.567, -2.841],
											[-2.305, -0.534],
											[-2.595, 3.047],
											[1.635, 5.028],
											[0.77, 0.706],
											[-0.212, -0.231],
										],
										o: [
											[-1.523, 0.271],
											[-1.772, 0.928],
											[-1.573, 4.101],
											[1.567, 2.841],
											[5.254, 1.217],
											[2.595, -3.047],
											[-0.132, -0.406],
											[-0.77, -0.706],
											[-3.233, -3.531],
										],
										v: [
											[2527, 1125],
											[2521.651, 1126.939],
											[2518, 1130],
											[2518.592, 1140.675],
											[2525, 1146],
											[2537.167, 1142.684],
											[2539, 1130],
											[2537.242, 1128.022],
											[2536, 1127],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 129,
								s: [
									{
										i: [
											[3.938, -1.485],
											[1.353, -2.073],
											[-0.157, -1.673],
											[-2.783, -2.046],
											[-1.624, -0.2],
											[-2.06, 1.778],
											[-0.493, 2.415],
											[1.442, 2.602],
											[1.851, 0.566],
										],
										o: [
											[-2.747, 1.036],
											[-1.353, 2.073],
											[0.245, 2.616],
											[2.783, 2.046],
											[4.266, 0.526],
											[2.06, -1.778],
											[0.617, -3.024],
											[-1.442, -2.602],
											[-2.236, -0.684],
										],
										v: [
											[2535, 1126],
											[2528.822, 1131.023],
											[2527, 1137],
											[2532.465, 1144.312],
											[2540, 1148],
											[2549.33, 1145.706],
											[2553, 1139],
											[2551.351, 1130.157],
											[2546, 1125],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 130,
								s: [
									{
										i: [
											[13.845, -3.767],
											[-4.222, -17.297],
											[-1.383, -1.953],
											[-1.686, -0.826],
											[-4.235, 0.874],
											[-3.307, 6.417],
											[-0.251, 0.832],
											[-0.113, 0.531],
											[4.943, 5.256],
										],
										o: [
											[-12.139, 3.303],
											[0.215, 0.881],
											[1.597, 2.255],
											[3.65, 1.789],
											[3.739, -0.772],
											[0.395, -0.766],
											[0.112, -0.37],
											[2.584, -12.179],
											[-6.73, -7.157],
										],
										v: [
											[2605, 1111],
											[2587, 1146],
											[2591, 1153],
											[2597, 1159],
											[2619, 1161],
											[2637, 1146],
											[2637, 1143],
											[2639, 1141],
											[2632, 1118],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 131,
								s: [
									{
										i: [
											[9.428, -2.645],
											[0.283, -0.172],
											[0.321, -0.159],
											[0.993, -0.334],
											[0.349, -0.326],
											[0.766, -1.247],
											[0.426, -2.857],
											[-1.547, 18.304],
											[2.192, 3.343],
										],
										o: [
											[-0.548, 0.154],
											[-0.283, 0.172],
											[-0.68, 0.337],
											[-0.993, 0.334],
											[-0.903, 0.845],
											[-1.345, 2.19],
											[-4.008, 26.878],
											[0.277, -3.276],
											[-4.447, -6.78],
										],
										v: [
											[2633, 1121],
											[2631.829, 1121.496],
											[2631, 1122],
											[2628.251, 1123.008],
											[2626, 1124],
											[2624, 1129],
											[2621, 1134],
											[2658, 1140],
											[2654, 1129],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 132,
								s: [
									{
										i: [
											[3.516, -1.268],
											[1.365, -2.043],
											[0.098, -2.53],
											[-1.427, -2.316],
											[-3.511, -1.019],
											[-1.162, 0.362],
											[-0.646, 4.428],
											[2.06, 2.202],
											[2.223, 0.642],
										],
										o: [
											[-2.371, 0.855],
											[-1.365, 2.043],
											[-0.098, 2.53],
											[0.793, 1.288],
											[3.511, 1.019],
											[3.476, -1.085],
											[0.646, -4.428],
											[-1.592, -1.702],
											[-2.223, -0.642],
										],
										v: [
											[2657, 1129],
											[2651.331, 1133.522],
											[2649.072, 1140.556],
											[2651, 1148],
											[2658.724, 1151.738],
											[2667, 1153],
											[2673.152, 1143.337],
											[2671, 1132],
											[2665.443, 1128.273],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 133,
								s: [
									{
										i: [
											[4.113, -2.242],
											[0.518, -0.053],
											[0.361, -0.477],
											[0.227, -2.022],
											[-0.781, -1.758],
											[-2.515, -0.682],
											[-2.182, 1.436],
											[-0.391, 2.827],
											[1.293, 1.555],
										],
										o: [
											[-0.566, 0.309],
											[-0.518, 0.053],
											[-0.873, 1.153],
											[-0.227, 2.022],
											[0.758, 1.707],
											[2.515, 0.682],
											[1.729, -1.137],
											[0.391, -2.827],
											[-1.407, -1.692],
										],
										v: [
											[2673, 1135],
											[2671.347, 1135.373],
											[2670, 1136],
											[2668.26, 1141.047],
											[2669, 1147],
											[2674.432, 1150.857],
											[2682, 1150],
											[2685.267, 1143.314],
											[2684, 1136],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[1.449, -0.633],
											[0.674, -0.379],
											[0.473, -0.476],
											[0.201, -0.642],
											[-2.818, -2.192],
											[-3.083, 2.096],
											[-0.757, 2.498],
											[1.254, 2.177],
											[2.18, 0.298],
										],
										o: [
											[-0.802, 0.35],
											[-0.674, 0.379],
											[-0.473, 0.476],
											[-1.357, 4.329],
											[2.818, 2.192],
											[1.224, -0.832],
											[0.757, -2.498],
											[-0.829, -1.439],
											[-2.181, -0.298],
										],
										v: [
											[1770, 1099],
											[1767.769, 1100.076],
											[1766.03, 1101.34],
											[1765, 1103],
											[1768.17, 1113.319],
											[1778, 1114],
											[1781.358, 1108.509],
											[1781, 1101],
											[1775.965, 1098.446],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[6.099, -2.432],
											[1.267, -1.002],
											[0.554, -1.445],
											[0.15, -2.801],
											[0.042, 0.142],
											[-4.642, -2.23],
											[-3.772, 1.163],
											[-0.695, 4.314],
											[2.584, 1.88],
										],
										o: [
											[-1.376, 0.549],
											[-1.267, 1.002],
											[-0.36, 0.937],
											[-0.15, 2.801],
											[0.658, 2.243],
											[4.643, 2.23],
											[2.671, -0.824],
											[0.991, -6.154],
											[-1.756, -1.278],
										],
										v: [
											[1664, 1099],
											[1659.883, 1101.328],
											[1657, 1105],
											[1656.262, 1111.81],
											[1656, 1117],
											[1665.164, 1124.555],
											[1679, 1127],
											[1687, 1115],
											[1684, 1099],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[5.314, -1.716],
											[1.016, -0.404],
											[0.579, -0.945],
											[0.144, -1.847],
											[-0.301, -1.43],
											[-3.621, -1.52],
											[-2.703, 1.948],
											[-0.57, 3.994],
											[1.553, 2.106],
										],
										o: [
											[-1.359, 0.439],
											[-1.016, 0.404],
											[-0.725, 1.184],
											[-0.144, 1.847],
											[0.813, 3.869],
											[3.621, 1.52],
											[1.271, -0.917],
											[0.571, -3.994],
											[-1.319, -1.788],
										],
										v: [
											[2162, 1110],
											[2158.415, 1111.121],
											[2156, 1113],
											[2154.731, 1117.815],
											[2155, 1123],
											[2162.583, 1131.363],
											[2173, 1131],
											[2176.119, 1122.391],
											[2175, 1112],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[3.476, -1.376],
											[0.902, -1.624],
											[-0.394, -1.697],
											[-1.349, -1.225],
											[-1.962, -0.207],
											[-1.249, 0.46],
											[-0.672, 0.959],
											[-0.252, 1.286],
											[2.114, 1.658],
										],
										o: [
											[-2.539, 1.005],
											[-0.902, 1.624],
											[0.394, 1.697],
											[1.349, 1.225],
											[1.985, 0.209],
											[1.249, -0.46],
											[0.672, -0.959],
											[0.931, -4.745],
											[-2.114, -1.658],
										],
										v: [
											[2179, 1114],
											[2173.924, 1118.08],
											[2173.248, 1123.197],
											[2175.948, 1127.716],
											[2181, 1130],
											[2185.812, 1129.581],
											[2188.654, 1127.409],
											[2190, 1124],
											[2187.805, 1114.409],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[2.872, -1.932],
											[0.481, -0.808],
											[0.191, -0.865],
											[-0.01, -0.75],
											[-0.124, -0.461],
											[-2.567, -0.577],
											[-2.112, 1.554],
											[0.589, 3.509],
											[2.423, 1.085],
										],
										o: [
											[-0.859, 0.578],
											[-0.481, 0.808],
											[-0.191, 0.865],
											[0.01, 0.75],
											[0.775, 2.884],
											[2.567, 0.577],
											[2.112, -1.554],
											[-0.435, -2.59],
											[-2.423, -1.086],
										],
										v: [
											[2188, 1117],
											[2186.012, 1119.122],
											[2185.026, 1121.674],
											[2184.776, 1124.14],
											[2185, 1126],
											[2190.574, 1131.147],
											[2198.154, 1129.638],
											[2201, 1122],
											[2196.328, 1116.109],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 127, s: [0], h: 1 },
							{ t: 128, s: [100], h: 1 },
							{ t: 139, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 6',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 128,
								s: [
									{
										i: [
											[4.172, -0.734],
											[1.274, -0.579],
											[0.894, -1.329],
											[-2.985, -2.568],
											[-4.871, 1.45],
											[-0.631, 3.327],
											[2.032, 1.943],
										],
										o: [
											[-2.011, 0.354],
											[-1.274, 0.579],
											[-2.811, 4.178],
											[2.985, 2.568],
											[2.508, -0.746],
											[0.631, -3.328],
											[-1.859, -1.778],
										],
										v: [
											[2551, 1167],
											[2546.162, 1168.269],
											[2543, 1171],
											[2544.239, 1181.721],
											[2557, 1184],
											[2561.905, 1176.898],
											[2560, 1168],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 129,
								s: [
									{
										i: [
											[3.776, -0.991],
											[1.55, -1.195],
											[-0.111, -2.458],
											[-4.038, -1.908],
											[-3.733, 1.22],
											[0.59, 3.24],
											[2.197, 0.546],
										],
										o: [
											[-2.909, 0.764],
											[-1.55, 1.194],
											[0.117, 2.587],
											[4.038, 1.908],
											[3.469, -1.134],
											[-0.46, -2.53],
											[-2.544, -0.632],
										],
										v: [
											[2499, 1169],
											[2492.235, 1171.729],
											[2490, 1177],
											[2497.288, 1184.355],
											[2510, 1186],
											[2517, 1175],
											[2509, 1168],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 130,
								s: [
									{
										i: [
											[5.058, -1.947],
											[1.159, -0.8],
											[0.454, -1.447],
											[0.181, -2.352],
											[-0.586, -1.031],
											[-3.638, 1.826],
											[1.169, 3.502],
										],
										o: [
											[-0.912, 0.351],
											[-1.159, 0.8],
											[0.137, -0.438],
											[-0.181, 2.352],
											[1.397, 2.46],
											[3.386, -1.699],
											[-1.445, -4.331],
										],
										v: [
											[2553, 1129],
											[2549.656, 1130.678],
											[2547, 1134],
											[2546.664, 1137.899],
											[2547, 1144],
											[2564, 1147],
											[2568, 1134],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 131,
								s: [
									{
										i: [
											[2.038, -0.757],
											[0.42, -0.487],
											[0.545, -0.765],
											[-3.22, -1.565],
											[-1.894, 3.15],
											[0.816, 2.05],
											[0.59, 0.379],
										],
										o: [
											[-0.774, 0.287],
											[-0.42, 0.487],
											[-2.773, 3.889],
											[3.22, 1.565],
											[0.855, -1.422],
											[-0.816, -2.05],
											[-1.747, -1.121],
										],
										v: [
											[2566, 1133],
											[2564.328, 1134.142],
											[2563, 1136],
											[2565.5, 1144.78],
											[2575, 1143],
											[2574.583, 1137.217],
											[2572, 1133],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 132,
								s: [
									{
										i: [
											[1.269, -0.943],
											[0.166, -1.049],
											[-0.588, -0.968],
											[-1.388, -0.408],
											[-1.56, 0.385],
											[-0.498, 0.83],
											[2.743, 0.803],
										],
										o: [
											[-0.875, 0.65],
											[-0.166, 1.049],
											[0.588, 0.968],
											[1.261, 0.37],
											[1.56, -0.385],
											[2.242, -3.733],
											[-2.743, -0.803],
										],
										v: [
											[2574, 1138],
											[2572.427, 1140.669],
											[2573.047, 1143.816],
											[2576, 1146],
											[2580.572, 1145.9],
											[2584, 1144],
											[2581.633, 1137.493],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 133,
								s: [
									{
										i: [
											[0.827, -0.551],
											[-0.372, -0.995],
											[-1.213, -0.486],
											[-0.984, 0.502],
											[0.313, 1.968],
											[0.613, 0.48],
											[0.82, -0.14],
										],
										o: [
											[-1.538, 1.024],
											[0.372, 0.995],
											[1.213, 0.486],
											[0.984, -0.502],
											[-0.208, -1.308],
											[-0.613, -0.48],
											[-0.82, 0.14],
										],
										v: [
											[2584, 1139],
											[2582.518, 1142.148],
											[2585.162, 1144.488],
											[2588.725, 1144.585],
											[2590, 1141],
											[2588.718, 1138.37],
											[2586.519, 1137.912],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[0.269, -0.016],
											[0.712, -4.806],
											[-4.909, -1.683],
											[-3.035, 0.294],
											[-1.748, 6.196],
											[1.997, 3.015],
											[2.834, 0.493],
										],
										o: [
											[-8.613, 0.499],
											[-1.163, 7.852],
											[1.331, 0.456],
											[7.577, -0.734],
											[1.797, -6.37],
											[-2.123, -3.203],
											[-1.032, -0.179],
										],
										v: [
											[2125, 1102],
											[2109, 1114],
											[2117, 1133],
											[2126, 1133],
											[2138, 1123],
											[2137, 1108],
											[2127, 1101],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[2.296, -0.851],
											[0.586, -1.15],
											[-0.231, -1.197],
											[-0.952, -0.699],
											[-1.575, 0.346],
											[-0.469, 1.765],
											[0.941, 1.34],
										],
										o: [
											[-1.5, 0.556],
											[-0.586, 1.15],
											[0.231, 1.197],
											[0.952, 0.699],
											[1.933, -0.425],
											[0.469, -1.765],
											[-0.941, -1.34],
										],
										v: [
											[1786, 1102],
											[1782.895, 1104.696],
											[1782.388, 1108.354],
											[1784.187, 1111.335],
											[1788, 1112],
											[1791.589, 1108.273],
											[1790.869, 1103.174],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[0.039, -0.021],
											[-0.515, -1.115],
											[-1.423, -0.524],
											[-0.678, 0.678],
											[1.352, 1.419],
											[1.06, -0.146],
											[-0.019, -0.244],
										],
										o: [
											[-2.047, 1.094],
											[0.515, 1.115],
											[1.423, 0.524],
											[1.468, -1.468],
											[-1.352, -1.419],
											[-0.037, 0.005],
											[0.019, 0.244],
										],
										v: [
											[2709, 1142],
											[2707.115, 1145.466],
											[2710.435, 1148.077],
											[2714, 1148],
											[2713.396, 1143.289],
											[2709, 1141],
											[2709.001, 1141.488],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[1.438, -0.805],
											[0.095, -0.69],
											[-0.517, -0.554],
											[-0.729, -0.212],
											[-0.539, 0.334],
											[-0.035, 1.009],
											[0.71, 0.576],
										],
										o: [
											[-1.108, 0.62],
											[-0.095, 0.69],
											[0.517, 0.554],
											[0.729, 0.212],
											[0.796, -0.494],
											[0.035, -1.009],
											[-0.71, -0.576],
										],
										v: [
											[2719, 1143],
											[2717.295, 1145.016],
											[2718.029, 1146.932],
											[2719.998, 1148.132],
											[2722, 1148],
											[2723.242, 1145.509],
											[2722.226, 1142.894],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[2.073, -1.116],
											[0.278, -0.178],
											[0.255, -0.256],
											[0.099, -0.325],
											[-0.189, -0.386],
											[-0.987, 0.816],
											[0.017, 1.085],
										],
										o: [
											[-0.169, 0.091],
											[-0.278, 0.178],
											[-0.255, 0.256],
											[-0.099, 0.325],
											[0.94, 1.922],
											[0.987, -0.816],
											[-0.017, -1.085],
										],
										v: [
											[2725, 1143],
											[2724.296, 1143.405],
											[2723.463, 1144.058],
											[2722.899, 1144.931],
											[2723, 1146],
											[2726.154, 1147.041],
											[2727.872, 1143.571],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 127, s: [0], h: 1 },
							{ t: 128, s: [100], h: 1 },
							{ t: 139, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 7',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 128,
								s: [
									{
										i: [
											[3.5, -0.867],
											[1.469, -1.091],
											[0.728, -2.043],
											[0.262, -0.914],
											[-0.284, -0.68],
											[-1.55, -0.979],
											[-0.847, -0.198],
											[-3.762, 2.302],
											[1.293, 4.019],
											[1.511, 1.023],
											[0.716, 0.164],
										],
										o: [
											[-2.395, 0.593],
											[-1.469, 1.091],
											[-0.236, 0.664],
											[-0.262, 0.914],
											[-0.048, -0.115],
											[1.55, 0.979],
											[5.246, 1.229],
											[3.762, -2.302],
											[-0.239, -0.745],
											[-1.511, -1.023],
											[-2.784, -0.638],
										],
										v: [
											[2483, 1170],
											[2477.25, 1172.413],
											[2474, 1177],
											[2473.11, 1179.488],
											[2473, 1182],
											[2475.829, 1183.765],
											[2480, 1186],
											[2494.404, 1183.936],
											[2499, 1174],
											[2495.857, 1171.064],
											[2492, 1169],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 129,
								s: [
									{
										i: [
											[2.159, -1.045],
											[0.717, -1.456],
											[-0.342, -1.471],
											[-0.887, -1.155],
											[-0.921, -0.507],
											[-1.324, -0.354],
											[-1.234, -0.16],
											[-0.473, 0.173],
											[0.062, 3.701],
											[1.751, 1.097],
											[1.961, 0.105],
										],
										o: [
											[-2.287, 1.108],
											[-0.717, 1.456],
											[0.342, 1.471],
											[0.887, 1.155],
											[0.743, 0.409],
											[1.324, 0.354],
											[1.234, 0.16],
											[2.997, -1.099],
											[-0.062, -3.701],
											[-0.997, -0.624],
											[-1.961, -0.105],
										],
										v: [
											[2561, 1169],
											[2556.622, 1172.928],
											[2556.188, 1177.401],
											[2558.16, 1181.424],
											[2561, 1184],
											[2564.268, 1185.179],
											[2568.273, 1185.985],
											[2571, 1186],
											[2575.061, 1177.499],
											[2572, 1169],
											[2567.372, 1167.747],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 130,
								s: [
									{
										i: [
											[0.168, -0.044],
											[1.241, -1.468],
											[0.326, -1.887],
											[-0.611, -1.817],
											[-2.686, -1.066],
											[-1.528, 0.423],
											[-1.265, 2.865],
											[1.13, 2.966],
											[2.131, 1.213],
											[1.23, 0.51],
											[0.328, -0.293],
										],
										o: [
											[-2.133, 0.561],
											[-1.241, 1.468],
											[-0.326, 1.887],
											[0.726, 2.16],
											[2.686, 1.066],
											[2.068, -0.573],
											[1.265, -2.865],
											[-0.244, -0.64],
											[-2.131, -1.213],
											[-0.522, -0.216],
											[-0.327, 0.293],
										],
										v: [
											[2515, 1167],
											[2509.934, 1170.166],
											[2507.578, 1175.321],
											[2508, 1181],
											[2513.899, 1185.937],
											[2521, 1187],
											[2526.399, 1181.295],
											[2527, 1172],
											[2522.74, 1168.902],
											[2517, 1166],
											[2515.734, 1166.305],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 131,
								s: [
									{
										i: [
											[5.182, -0.747],
											[0.828, -0.029],
											[0.77, -0.11],
											[0.627, -0.358],
											[1.475, -3.643],
											[-1.374, -3.584],
											[-1.267, -1.049],
											[-0.739, -0.305],
											[-3.901, 2.039],
											[-0.564, 2.842],
											[2.53, 2.544],
										],
										o: [
											[-0.801, 0.116],
											[-0.828, 0.029],
											[-0.77, 0.11],
											[-1.475, 0.842],
											[-1.475, 3.643],
											[0.191, 0.499],
											[1.267, 1.049],
											[4.833, 1.998],
											[3.901, -2.039],
											[1.303, -6.569],
											[-2.53, -2.544],
										],
										v: [
											[2399, 1147],
											[2396.535, 1147.175],
											[2394.116, 1147.341],
											[2392, 1148],
											[2386.863, 1155.443],
											[2386, 1167],
											[2388.589, 1169.646],
											[2392, 1172],
											[2405.702, 1171.13],
											[2413, 1163],
											[2410.864, 1149.513],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 132,
								s: [
									{
										i: [
											[1.716, -0.65],
											[1.216, -6.871],
											[-4.216, -4.864],
											[-1.154, -0.421],
											[-1.1, 0.181],
											[-2.518, 3.855],
											[2.677, 3.656],
											[1.844, 1.179],
											[0.961, 0.497],
											[1.665, 0.702],
											[1.774, 0.296],
										],
										o: [
											[-6.49, 2.458],
											[-1.667, 9.418],
											[1.185, 1.367],
											[2.519, 0.919],
											[3.795, -0.626],
											[3.76, -5.757],
											[-0.545, -0.745],
											[-1.277, -0.817],
											[-1.206, -0.623],
											[-2.172, -0.916],
											[-4.304, -0.718],
										],
										v: [
											[2250, 1145],
											[2241, 1159],
											[2246, 1175],
											[2251, 1179],
											[2266, 1180],
											[2280, 1171],
											[2281, 1152],
											[2277, 1150],
											[2274, 1147],
											[2269, 1146],
											[2265, 1144],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 133,
								s: [
									{
										i: [
											[5.64, -0.704],
											[2.145, -1.097],
											[1.148, -1.94],
											[0.233, -2.93],
											[-0.562, -2.442],
											[-1.003, -1.047],
											[-0.614, -0.676],
											[-0.784, -0.503],
											[-5.582, 1.653],
											[-1.181, 5.914],
											[4.289, 3.678],
										],
										o: [
											[-3.222, 0.402],
											[-2.145, 1.097],
											[-1.148, 1.94],
											[-0.253, 3.184],
											[0.562, 2.442],
											[0.762, 0.795],
											[0.614, 0.675],
											[5.492, 3.525],
											[5.582, -1.653],
											[1.796, -8.99],
											[-4.289, -3.678],
										],
										v: [
											[2277, 1142],
											[2268.97, 1144.212],
											[2264.051, 1148.732],
											[2262, 1156],
											[2262.558, 1164.602],
											[2265, 1170],
											[2266.983, 1172.219],
											[2269, 1174],
											[2286.733, 1176.579],
											[2298, 1165],
											[2293.078, 1146.229],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[1.774, -0.471],
											[0.686, -0.321],
											[0.457, -0.466],
											[0.168, -0.662],
											[-0.182, -0.91],
											[-1.143, -0.717],
											[-1.396, 0.065],
											[-1.055, 0.874],
											[-0.12, 1.711],
											[0.446, 0.898],
											[1.067, 0.318],
										],
										o: [
											[-0.854, 0.227],
											[-0.686, 0.321],
											[-0.457, 0.466],
											[-0.168, 0.662],
											[0.294, 1.472],
											[1.143, 0.717],
											[1.396, -0.065],
											[1.056, -0.874],
											[0.089, -1.27],
											[-0.446, -0.898],
											[-1.067, -0.318],
										],
										v: [
											[2689, 1138],
											[2686.675, 1138.808],
											[2684.946, 1139.975],
											[2683.994, 1141.654],
											[2684, 1144],
											[2686.304, 1147.289],
											[2690.262, 1148.273],
											[2694.088, 1146.87],
											[2696, 1143],
											[2695.487, 1139.697],
											[2693.24, 1137.822],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[4.635, -1.705],
											[1.436, -2.039],
											[-0.613, -3.787],
											[-3.956, -1.718],
											[-3.548, 0.737],
											[-1.197, 4.452],
											[1.836, 2.559],
											[0.435, -0.052],
											[0.164, 0.074],
											[0.21, 0.466],
											[0.077, 0.1],
										],
										o: [
											[-2.869, 1.055],
											[-1.436, 2.039],
											[0.626, 3.867],
											[3.956, 1.718],
											[3.048, -0.633],
											[1.197, -4.452],
											[-0.097, -0.134],
											[-0.435, 0.052],
											[-0.082, -0.037],
											[-0.21, -0.466],
											[-1.319, -1.717],
										],
										v: [
											[2139, 1108],
											[2132.388, 1112.451],
											[2131, 1121],
											[2138.808, 1129.453],
											[2151, 1131],
											[2157.663, 1121.944],
											[2157, 1110],
											[2156.051, 1109.955],
											[2155, 1110],
											[2154.496, 1109.047],
											[2154, 1108],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[0.032, -0.022],
											[-0.018, -1.13],
											[-0.905, -0.901],
											[-1.185, -0.321],
											[-0.857, 0.61],
											[-0.177, 0.638],
											[-0.066, 0.856],
											[0.144, 0.79],
											[0.453, 0.439],
											[0.878, 0.007],
											[0.677, -0.206],
										],
										o: [
											[-1.477, 1.007],
											[0.018, 1.13],
											[0.905, 0.901],
											[1.185, 0.321],
											[0.19, -0.135],
											[0.177, -0.638],
											[0.066, -0.856],
											[-0.144, -0.79],
											[-0.637, -0.617],
											[-0.878, -0.007],
											[-0.677, 0.206],
										],
										v: [
											[2067, 1148],
											[2064.962, 1151.293],
											[2066.498, 1154.426],
											[2069.785, 1156.346],
											[2073, 1156],
											[2073.576, 1154.769],
											[2073.965, 1152.456],
											[2073.872, 1149.915],
											[2073, 1148],
											[2070.616, 1147.163],
											[2068.173, 1147.56],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[0.026, -0.016],
											[-0.327, -1.001],
											[-1.236, -0.562],
											[-1.167, 0.346],
											[-0.117, 1.72],
											[0.637, 0.551],
											[0.885, 0.268],
											[0.68, 0.083],
											[0.023, -0.003],
											[-0.009, -0.143],
											[-0.009, -0.147],
										],
										o: [
											[-1.563, 0.974],
											[0.327, 1.001],
											[1.236, 0.562],
											[1.167, -0.345],
											[0.064, -0.933],
											[-0.637, -0.551],
											[-0.885, -0.268],
											[-0.68, -0.083],
											[-0.026, 0.004],
											[0.009, 0.143],
											[0.009, 0.147],
										],
										v: [
											[2077, 1146],
											[2075.391, 1149.079],
											[2077.98, 1151.54],
											[2081.829, 1151.981],
											[2084, 1149],
											[2083.027, 1146.799],
											[2080.63, 1145.595],
											[2078.168, 1145.094],
											[2077, 1145],
											[2076.983, 1145.254],
											[2077.018, 1145.722],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[0.033, -0.015],
											[0.085, -0.601],
											[-0.624, -0.438],
											[-0.772, -0.118],
											[-0.36, 0.36],
											[0.006, 0.578],
											[0.265, 0.602],
											[0.501, 0.348],
											[0.714, -0.186],
											[-0.011, -0.143],
											[-0.011, -0.145],
										],
										o: [
											[-1.356, 0.606],
											[-0.085, 0.601],
											[0.624, 0.438],
											[0.772, 0.118],
											[0.276, -0.276],
											[-0.006, -0.578],
											[-0.265, -0.602],
											[-0.501, -0.348],
											[-0.033, 0.009],
											[0.011, 0.143],
											[0.011, 0.145],
										],
										v: [
											[2087, 1146],
											[2084.978, 1147.851],
											[2085.926, 1149.45],
											[2088.161, 1150.324],
											[2090, 1150],
											[2090.399, 1148.649],
											[2089.985, 1146.808],
											[2088.829, 1145.313],
											[2087, 1145],
											[2086.978, 1145.261],
											[2087.022, 1145.727],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 127, s: [0], h: 1 },
							{ t: 128, s: [100], h: 1 },
							{ t: 139, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 8',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 128,
								s: [
									{
										i: [
											[-0.186, 0.078],
											[0.555, -0.626],
											[-0.132, -0.564],
											[-0.59, -0.432],
											[-0.819, -0.229],
											[-0.819, 0.044],
											[-0.59, 0.387],
											[-0.133, 0.801],
											[0.554, 1.286],
											[0.373, 0.247],
											[0.432, 0.048],
											[0.406, -0.068],
											[0.295, -0.102],
											[0.097, -0.053],
										],
										o: [
											[-1.471, 0.618],
											[-0.555, 0.626],
											[0.132, 0.564],
											[0.59, 0.432],
											[0.819, 0.23],
											[0.819, -0.044],
											[0.59, -0.387],
											[0.133, -0.801],
											[-0.227, -0.528],
											[-0.373, -0.247],
											[-0.432, -0.048],
											[-0.406, 0.068],
											[-0.295, 0.102],
											[-0.097, 0.053],
										],
										v: [
											[2458, 1174],
											[2455.017, 1175.883],
											[2454.439, 1177.687],
											[2455.577, 1179.2],
											[2457.747, 1180.21],
											[2460.261, 1180.506],
											[2462.432, 1179.878],
											[2463.574, 1178.113],
											[2463, 1175],
											[2462.078, 1173.858],
											[2460.849, 1173.436],
											[2459.569, 1173.487],
											[2458.497, 1173.763],
											[2457.888, 1174.017],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 129,
								s: [
									{
										i: [
											[0.674, -0.271],
											[0.394, -0.35],
											[0.147, -0.389],
											[-0.062, -0.389],
											[-0.23, -0.352],
											[-0.359, -0.276],
											[-0.449, -0.162],
											[-0.5, -0.01],
											[-0.51, 0.18],
											[-0.308, 0.443],
											[-0.002, 0.535],
											[0.248, 0.509],
											[0.445, 0.366],
											[0.587, 0.106],
										],
										o: [
											[-0.681, 0.273],
											[-0.394, 0.35],
											[-0.147, 0.389],
											[0.062, 0.389],
											[0.23, 0.352],
											[0.359, 0.276],
											[0.449, 0.162],
											[0.5, 0.01],
											[0.668, -0.235],
											[0.308, -0.443],
											[0.002, -0.535],
											[-0.248, -0.509],
											[-0.445, -0.366],
											[-0.587, -0.106],
										],
										v: [
											[2463, 1175],
											[2461.397, 1175.945],
											[2460.595, 1177.062],
											[2460.478, 1178.239],
											[2460.925, 1179.359],
											[2461.819, 1180.31],
											[2463.042, 1180.977],
											[2464.475, 1181.245],
											[2466, 1181],
											[2467.449, 1179.953],
											[2467.901, 1178.457],
											[2467.518, 1176.863],
											[2466.465, 1175.521],
											[2464.905, 1174.783],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 130,
								s: [
									{
										i: [
											[1.651, -0.725],
											[1.004, -1.665],
											[0.105, -1.925],
											[-0.952, -1.548],
											[-2.166, -0.533],
											[-1.949, 0.348],
											[-1.278, 1.082],
											[-0.565, 1.568],
											[0.189, 1.807],
											[0.438, 0.869],
											[0.57, 0.804],
											[0.472, 0.532],
											[0.143, 0.056],
											[1.816, -0.175],
										],
										o: [
											[-1.747, 0.767],
											[-1.004, 1.665],
											[-0.105, 1.925],
											[0.952, 1.548],
											[2.578, 0.634],
											[1.949, -0.348],
											[1.278, -1.082],
											[0.565, -1.568],
											[-0.076, -0.729],
											[-0.438, -0.869],
											[-0.57, -0.804],
											[-0.472, -0.532],
											[-1.085, -0.424],
											[-1.816, 0.175],
										],
										v: [
											[2574, 1170],
											[2569.834, 1173.807],
											[2568.131, 1179.352],
											[2569.363, 1184.72],
											[2574, 1188],
											[2580.8, 1188.368],
											[2585.65, 1186.162],
											[2588.424, 1182.126],
											[2589, 1177],
											[2588.171, 1174.55],
											[2586.6, 1171.989],
											[2584.979, 1169.934],
											[2584, 1169],
											[2579.425, 1168.638],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 131,
								s: [
									{
										i: [
											[1.584, -0.781],
											[0.696, -0.754],
											[0.259, -0.838],
											[-0.106, -0.848],
											[-0.4, -0.783],
											[-0.622, -0.643],
											[-0.772, -0.428],
											[-0.85, -0.138],
											[-0.856, 0.226],
											[-0.715, 0.989],
											[-0.158, 1.297],
											[0.351, 1.275],
											[0.811, 0.921],
											[1.222, 0.235],
										],
										o: [
											[-1.206, 0.595],
											[-0.696, 0.754],
											[-0.259, 0.838],
											[0.106, 0.848],
											[0.4, 0.783],
											[0.622, 0.643],
											[0.772, 0.428],
											[0.85, 0.138],
											[1.321, -0.349],
											[0.715, -0.989],
											[0.158, -1.297],
											[-0.351, -1.275],
											[-0.811, -0.921],
											[-1.222, -0.235],
										],
										v: [
											[2527, 1172],
											[2524.165, 1174.042],
											[2522.75, 1176.449],
											[2522.538, 1178.998],
											[2523.315, 1181.463],
											[2524.865, 1183.62],
											[2526.973, 1185.245],
											[2529.423, 1186.113],
											[2532, 1186],
											[2535.042, 1183.91],
											[2536.338, 1180.397],
											[2536.036, 1176.456],
											[2534.281, 1173.081],
											[2531.22, 1171.264],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 132,
								s: [
									{
										i: [
											[0.537, -0.251],
											[0.761, -1.34],
											[-0.28, -1.438],
											[-1.015, -1.277],
											[-1.443, -0.857],
											[-1.566, -0.179],
											[-1.381, 0.758],
											[-0.528, 0.95],
											[-0.368, 1.298],
											[-0.032, 1.324],
											[0.483, 1.027],
											[1.136, 0.54],
											[1.378, 0.197],
											[1.178, -0.066],
										],
										o: [
											[-2.108, 0.984],
											[-0.761, 1.34],
											[0.28, 1.438],
											[1.015, 1.277],
											[1.443, 0.857],
											[1.566, 0.179],
											[0.509, -0.279],
											[0.528, -0.95],
											[0.368, -1.298],
											[0.032, -1.324],
											[-0.452, -0.962],
											[-1.136, -0.54],
											[-1.378, -0.197],
											[-1.178, 0.066],
										],
										v: [
											[2008, 1150],
											[2003.774, 1153.551],
											[2003.13, 1157.782],
											[2005.149, 1161.918],
											[2008.913, 1165.184],
											[2013.503, 1166.803],
											[2018, 1166],
											[2019.6, 1164.075],
											[2020.988, 1160.622],
											[2021.633, 1156.608],
											[2021, 1153],
											[2018.508, 1150.767],
											[2014.627, 1149.682],
											[2010.683, 1149.505],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 133,
								s: [
									{
										i: [
											[4.193, -1.677],
											[0.878, -0.535],
											[0.523, -0.736],
											[0.133, -1.083],
											[-0.291, -1.575],
											[-0.942, -0.997],
											[-1.302, -0.596],
											[-1.334, -0.175],
											[-1.039, 0.263],
											[-0.751, 0.746],
											[-0.381, 1.078],
											[-0.065, 1.293],
											[0.198, 1.39],
											[1.837, 0.863],
										],
										o: [
											[-1.198, 0.479],
											[-0.878, 0.535],
											[-0.523, 0.736],
											[-0.133, 1.083],
											[0.255, 1.38],
											[0.942, 0.997],
											[1.301, 0.596],
											[1.334, 0.175],
											[1.174, -0.297],
											[0.751, -0.746],
											[0.381, -1.078],
											[0.065, -1.293],
											[-0.461, -3.224],
											[-1.837, -0.863],
										],
										v: [
											[2018, 1149],
											[2014.876, 1150.486],
											[2012.766, 1152.356],
											[2011.773, 1155.049],
											[2012, 1159],
											[2013.877, 1162.571],
											[2017.323, 1164.966],
											[2021.358, 1166.127],
											[2025, 1166],
											[2027.874, 1164.405],
											[2029.559, 1161.639],
											[2030.214, 1158.053],
											[2030, 1154],
											[2026.799, 1147.824],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[0.946, -0.265],
											[0.264, -0.157],
											[0.357, -0.206],
											[2.794, -2.331],
											[0.495, -3.755],
											[-6.142, -3.309],
											[-6.461, 1.034],
											[-1.586, 0.609],
											[-0.479, 0.376],
											[-1.118, 2.591],
											[-0.233, 1.622],
											[4.053, 3.546],
											[4.324, 0.845],
											[2.313, 0.238],
										],
										o: [
											[-0.585, 0.164],
											[-0.264, 0.158],
											[-3.328, 1.919],
											[-2.794, 2.331],
											[-1.028, 7.797],
											[6.142, 3.309],
											[1.177, -0.188],
											[1.586, -0.609],
											[1.295, -1.015],
											[1.118, -2.591],
											[0.622, -4.33],
											[-0.936, -0.819],
											[-2.473, -0.483],
											[-4.805, -0.494],
										],
										v: [
											[1764, 1145],
											[1762.829, 1145.468],
											[1762, 1146],
											[1752.376, 1152.123],
											[1747, 1161],
											[1756.383, 1177.623],
											[1777, 1181],
											[1781.523, 1179.641],
											[1785, 1178],
											[1788.796, 1171.955],
											[1791, 1165],
											[1788, 1147],
											[1781, 1146],
											[1774, 1144],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[0.401, -0.259],
											[0.331, -0.792],
											[-0.32, -0.698],
											[-0.716, -0.518],
											[-0.857, -0.252],
											[-0.743, 0.1],
											[-0.374, 0.537],
											[-0.087, 0.715],
											[0.055, 0.865],
											[0.239, 0.718],
											[0.466, 0.273],
											[0.394, 0.017],
											[0.457, -0.1],
											[0.459, -0.192],
										],
										o: [
											[-1.237, 0.801],
											[-0.331, 0.792],
											[0.32, 0.698],
											[0.716, 0.518],
											[0.857, 0.252],
											[0.743, -0.1],
											[0.186, -0.267],
											[0.087, -0.715],
											[-0.055, -0.865],
											[-0.239, -0.718],
											[-0.271, -0.159],
											[-0.394, -0.017],
											[-0.457, 0.1],
											[-0.459, 0.192],
										],
										v: [
											[2700, 1140],
											[2697.711, 1142.41],
											[2697.757, 1144.666],
											[2699.374, 1146.51],
											[2701.797, 1147.686],
											[2704.26, 1147.935],
											[2706, 1147],
											[2706.42, 1145.453],
											[2706.478, 1143.009],
											[2706.047, 1140.561],
											[2705, 1139],
											[2703.987, 1138.743],
											[2702.695, 1138.873],
											[2701.305, 1139.317],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[5.349, -1.592],
											[0.753, -0.495],
											[1.683, -4.839],
											[-5.352, -2.412],
											[-0.445, -0.221],
											[-0.14, -0.042],
											[-3.993, 1.281],
											[-0.24, 0.18],
											[-0.796, 0.69],
											[1.008, 5.518],
											[2.715, 3.121],
											[0.709, 0.508],
											[0.706, 0.214],
											[0.491, 0.216],
										],
										o: [
											[-1.203, 0.358],
											[-4.523, 2.971],
											[-2.885, 8.293],
											[0.366, 0.165],
											[0.65, 0.322],
											[3.399, 1.035],
											[0.179, -0.057],
											[0.915, -0.687],
											[3.848, -3.335],
											[-0.405, -2.217],
											[-1.705, -1.96],
											[-0.586, -0.42],
											[-0.363, -0.11],
											[-5.496, -2.416],
										],
										v: [
											[1817, 1149],
											[1815, 1150],
											[1805, 1161],
											[1812, 1177],
											[1813, 1179],
											[1816, 1180],
											[1834, 1181],
											[1835, 1179],
											[1838, 1178],
											[1844, 1163],
											[1840, 1158],
											[1836, 1152],
											[1833, 1152],
											[1831, 1150],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[2.759, -0.673],
											[1.986, -1.317],
											[1.443, -1.998],
											[0.486, -2.557],
											[-0.886, -2.994],
											[-0.624, -0.842],
											[-0.812, -0.778],
											[-0.782, -0.547],
											[-0.535, -0.148],
											[-2.595, 0.365],
											[-1.74, 1.061],
											[-1.293, 5.808],
											[3.736, 3.206],
											[1.913, 0.078],
										],
										o: [
											[-2.113, 0.515],
											[-1.986, 1.317],
											[-1.443, 1.998],
											[-0.486, 2.557],
											[0.219, 0.74],
											[0.624, 0.842],
											[0.812, 0.778],
											[0.782, 0.547],
											[1.878, 0.519],
											[2.595, -0.364],
											[2.563, -1.562],
											[1.293, -5.808],
											[-1.588, -1.362],
											[-1.913, -0.078],
										],
										v: [
											[1844, 1150],
											[1837.748, 1152.78],
											[1832.501, 1157.782],
											[1829.504, 1164.644],
											[1830, 1173],
											[1831.318, 1175.415],
											[1833.526, 1177.888],
											[1835.971, 1179.917],
											[1838, 1181],
											[1845.103, 1181.185],
											[1852, 1179],
											[1858.725, 1166.233],
											[1856, 1151],
											[1850.878, 1148.973],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[1.604, -0.434],
											[1.098, -0.664],
											[0.702, -0.812],
											[0.378, -0.867],
											[0.126, -0.83],
											[-0.864, -2.052],
											[-1.702, -1.286],
											[-2.112, -0.341],
											[-2.094, 0.784],
											[-1.345, 3.3],
											[0.777, 3.278],
											[1.029, 1.456],
											[0.666, 0.253],
											[1.549, -0.255],
										],
										o: [
											[-1.566, 0.424],
											[-1.098, 0.664],
											[-0.702, 0.812],
											[-0.378, 0.867],
											[-0.401, 2.639],
											[0.864, 2.052],
											[1.702, 1.286],
											[2.112, 0.341],
											[1.396, -0.523],
											[1.345, -3.3],
											[-0.219, -0.923],
											[-1.029, -1.456],
											[-1.201, -0.456],
											[-1.549, 0.256],
										],
										v: [
											[1858, 1158],
											[1854.022, 1159.655],
											[1851.34, 1161.891],
											[1849.738, 1164.432],
											[1849, 1167],
											[1849.802, 1174.083],
											[1853.758, 1179.135],
											[1859.585, 1181.62],
											[1866, 1181],
											[1870.629, 1174.566],
											[1872, 1164],
											[1869.835, 1159.997],
											[1867, 1157],
											[1862.802, 1156.833],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 127, s: [0], h: 1 },
							{ t: 128, s: [100], h: 1 },
							{ t: 139, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 9',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 129,
								s: [
									{
										i: [
											[2.3, -0.581],
											[0.528, -0.057],
											[0.387, -0.023],
											[0.29, -0.08],
											[0.238, -0.227],
											[-1.11, -1.734],
											[-3.035, -0.399],
											[-2.658, 1.034],
											[0.021, 2.565],
											[2.668, 0.553],
										],
										o: [
											[-0.713, 0.18],
											[-0.528, 0.057],
											[-0.387, 0.023],
											[-0.29, 0.08],
											[-3.118, 2.97],
											[1.11, 1.734],
											[3.035, 0.399],
											[2.658, -1.034],
											[-0.028, -3.37],
											[-2.668, -0.553],
										],
										v: [
											[2413, 1177],
											[2411.149, 1177.332],
											[2409.787, 1177.43],
											[2408.782, 1177.562],
											[2408, 1178],
											[2405.563, 1185.08],
											[2412.355, 1188.303],
											[2421.47, 1187.375],
											[2426, 1182],
											[2421.203, 1176.537],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 130,
								s: [
									{
										i: [
											[0.547, 0.037],
											[0.242, -0.356],
											[0.072, -0.351],
											[-0.104, -0.318],
											[-0.284, -0.259],
											[-0.469, -0.172],
											[-0.659, -0.059],
											[0.01, 0.574],
											[0.122, 0.461],
											[0.302, 0.282],
										],
										o: [
											[-0.408, 0.334],
											[-0.242, 0.356],
											[-0.072, 0.351],
											[0.104, 0.318],
											[0.284, 0.259],
											[0.469, 0.172],
											[0.037, -0.62],
											[-0.01, -0.574],
											[-0.123, -0.461],
											[-0.301, -0.282],
										],
										v: [
											[2469, 1175],
											[2468.024, 1176.042],
											[2467.552, 1177.109],
											[2467.599, 1178.12],
											[2468.18, 1178.993],
											[2469.309, 1179.647],
											[2471, 1180],
											[2471.057, 1178.193],
											[2470.875, 1176.625],
											[2470.256, 1175.494],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 131,
								s: [
									{
										i: [
											[1.359, -0.69],
											[0.259, -1.554],
											[-1.22, -1.272],
											[-1.869, -0.457],
											[-1.688, 0.89],
											[-0.437, 0.922],
											[-0.097, 1.052],
											[0.138, 0.854],
											[0.269, 0.328],
											[1.991, 0.216],
										],
										o: [
											[-2.568, 1.303],
											[-0.259, 1.554],
											[1.22, 1.272],
											[1.869, 0.457],
											[0.881, -0.465],
											[0.437, -0.922],
											[0.097, -1.052],
											[-0.138, -0.854],
											[-0.676, -0.824],
											[-1.991, -0.216],
										],
										v: [
											[2588, 1175],
											[2583.967, 1179.419],
											[2585.616, 1183.79],
											[2590.457, 1186.516],
											[2596, 1186],
											[2597.95, 1183.838],
											[2598.724, 1180.795],
											[2598.636, 1177.854],
											[2598, 1176],
											[2593.512, 1174.364],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 132,
								s: [
									{
										i: [
											[3.251, -0.85],
											[1.045, -0.447],
											[0.738, -0.678],
											[0.324, -1.017],
											[-0.198, -1.464],
											[-2.667, -1.308],
											[-2.014, 0.586],
											[-0.86, 3.146],
											[1.329, 1.575],
											[1.556, 0.306],
										],
										o: [
											[-1.246, 0.325],
											[-1.045, 0.447],
											[-0.738, 0.678],
											[-0.324, 1.017],
											[0.318, 2.355],
											[2.667, 1.308],
											[2.89, -0.84],
											[0.86, -3.146],
											[-0.527, -0.624],
											[-1.556, -0.306],
										],
										v: [
											[2422, 1152],
											[2418.537, 1153.132],
											[2415.835, 1154.792],
											[2414.216, 1157.306],
											[2414, 1161],
											[2419.228, 1166.705],
											[2427, 1168],
											[2432.664, 1161.051],
											[2432, 1153],
											[2429.043, 1151.394],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 133,
								s: [
									{
										i: [
											[0.302, -0.146],
											[-0.089, -1.276],
											[-1.378, -0.88],
											[-1.6, 0.085],
											[-0.756, 1.621],
											[0.307, 0.764],
											[0.673, 0.688],
											[0.779, 0.456],
											[0.626, 0.066],
											[0.267, -0.23],
										],
										o: [
											[-2.267, 1.101],
											[0.089, 1.276],
											[1.378, 0.88],
											[1.6, -0.085],
											[0.318, -0.681],
											[-0.307, -0.764],
											[-0.673, -0.688],
											[-0.779, -0.456],
											[-0.628, -0.066],
											[-0.268, 0.23],
										],
										v: [
											[2435, 1155],
											[2431.999, 1158.707],
											[2434.466, 1162.083],
											[2439.199, 1163.418],
											[2443, 1161],
											[2442.95, 1158.793],
											[2441.415, 1156.576],
											[2439.172, 1154.821],
											[2437, 1154],
											[2435.756, 1154.341],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[3.099, -0.685],
											[1.918, -1.375],
											[0.938, -1.974],
											[0.175, -2.824],
											[-0.556, -0.904],
											[-0.648, -1.203],
											[-5.939, 1.229],
											[-1.867, 1.335],
											[2.468, 8.578],
											[0.798, 0.335],
										],
										o: [
											[-2.39, 0.528],
											[-1.918, 1.375],
											[-0.856, 1.801],
											[-0.175, 2.824],
											[0.475, 0.772],
											[2.846, 5.284],
											[0.32, -0.066],
											[4.887, -3.495],
											[-0.462, -1.606],
											[-2.541, -1.066],
										],
										v: [
											[2299, 1149],
											[2292.411, 1151.916],
											[2288, 1157],
											[2286.44, 1164.673],
											[2287, 1171],
											[2288, 1174],
											[2306, 1181],
											[2311, 1178],
											[2318, 1158],
											[2310, 1149],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[3.283, -0.79],
											[1.223, -0.37],
											[0.917, -1.697],
											[0.43, -1.183],
											[-0.614, -3.418],
											[-10.57, 2.584],
											[-0.702, 0.526],
											[-1.106, 4.059],
											[5.141, 2.318],
											[0.474, 0.194],
										],
										o: [
											[-1.245, 0.299],
											[-2.82, 0.853],
											[-0.623, 1.153],
											[-0.874, 2.402],
											[1.554, 8.652],
											[1.044, -0.255],
											[3.236, -2.422],
											[2.317, -8.5],
											[-0.377, -0.17],
											[-4.503, -1.843],
										],
										v: [
											[1790, 1148],
											[1786, 1148],
											[1780, 1154],
											[1778, 1157],
											[1777, 1165],
											[1807, 1178],
											[1814, 1173],
											[1819, 1165],
											[1813, 1150],
											[1812, 1148],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[10.48, -2.686],
											[0.566, 0.074],
											[0.345, -0.198],
											[1.283, -1.35],
											[0.065, -0.14],
											[0.262, -2.442],
											[-0.444, -1.381],
											[-4.074, -1.284],
											[-2.849, 1.791],
											[-0.439, 2.042],
										],
										o: [
											[-0.391, 0.1],
											[-0.566, -0.074],
											[-0.802, 0.461],
											[-1.283, 1.35],
											[-0.395, 0.85],
											[-0.262, 2.442],
											[0.853, 2.656],
											[4.075, 1.284],
											[0.635, -0.399],
											[2.741, -12.758],
										],
										v: [
											[2342, 1155],
											[2340.466, 1154.927],
											[2339, 1155],
											[2335.448, 1158.24],
											[2333, 1161],
											[2331.871, 1166.601],
											[2332, 1173],
											[2340.503, 1179.335],
											[2352, 1179],
											[2356, 1172],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[2.892, -1.157],
											[0.473, -0.087],
											[0.454, -0.39],
											[0.565, -2.871],
											[-1.654, -2.319],
											[-3.573, -0.453],
											[-1.698, 1.226],
											[-0.151, 0.242],
											[1.089, 3.34],
											[2.08, 0.516],
										],
										o: [
											[-0.653, 0.261],
											[-0.473, 0.087],
											[-1.394, 1.198],
											[-0.565, 2.871],
											[1.008, 1.414],
											[3.573, 0.453],
											[0.23, -0.166],
											[1.238, -1.991],
											[-0.45, -1.381],
											[-2.069, -0.513],
										],
										v: [
											[2356, 1160],
											[2354.35, 1160.403],
											[2353, 1161],
											[2349.714, 1167.659],
											[2351, 1176],
											[2358.982, 1178.98],
											[2368, 1178],
											[2369, 1175],
											[2371, 1165],
											[2364, 1159],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[0.864, -0.466],
											[0.661, -0.673],
											[0.308, -0.761],
											[-0.007, -0.833],
											[-0.284, -0.889],
											[-3.257, -0.186],
											[-1.053, 1.612],
											[0.621, 2.493],
											[1.437, 0.615],
											[1.48, -0.263],
										],
										o: [
											[-1.053, 0.569],
											[-0.661, 0.672],
											[-0.308, 0.761],
											[0.007, 0.833],
											[0.918, 2.87],
											[3.257, 0.186],
											[0.922, -1.412],
											[-0.621, -2.493],
											[-1.214, -0.519],
											[-1.481, 0.263],
										],
										v: [
											[2372, 1165],
											[2369.438, 1166.866],
											[2367.994, 1169.019],
											[2367.553, 1171.413],
											[2368, 1174],
											[2375.398, 1178.362],
											[2383, 1176],
											[2383.27, 1169.402],
											[2380, 1164],
											[2375.738, 1163.761],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 128, s: [0], h: 1 },
							{ t: 129, s: [100], h: 1 },
							{ t: 139, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 10',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 130,
								s: [
									{
										i: [
											[4.102, -1.448],
											[1.139, -1.184],
											[-0.925, -1.781],
											[-3.238, 0.441],
											[-0.039, 3.18],
										],
										o: [
											[-1.369, 0.483],
											[-1.139, 1.184],
											[1.01, 1.944],
											[3.238, -0.441],
											[0.06, -4.995],
										],
										v: [
											[2425, 1180],
											[2420.78, 1182.526],
											[2420, 1187],
											[2427.729, 1189.343],
											[2434, 1184],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 131,
								s: [
									{
										i: [
											[1.948, -1.16],
											[0.23, -0.636],
											[-0.27, -0.651],
											[-2.062, 0.72],
											[1.948, 2.806],
										],
										o: [
											[-1.35, 0.804],
											[-0.23, 0.636],
											[0.699, 1.688],
											[2.062, -0.72],
											[-1.395, -2.009],
										],
										v: [
											[2434, 1181],
											[2431.785, 1183.115],
											[2432, 1185],
											[2437.485, 1186.37],
											[2439, 1181],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 132,
								s: [
									{
										i: [
											[2.559, -1.01],
											[0.672, -0.891],
											[0.116, -0.965],
											[-2.867, 1.964],
											[0.62, 1.503],
										],
										o: [
											[-1.197, 0.472],
											[-0.672, 0.891],
											[-0.574, 4.783],
											[1.235, -0.846],
											[-0.834, -2.022],
										],
										v: [
											[2537, 1176],
											[2534.189, 1178.131],
											[2533, 1181],
											[2543, 1186],
											[2544, 1178],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 133,
								s: [
									{
										i: [
											[1.947, -1.16],
											[0.23, -0.636],
											[-0.27, -0.651],
											[-2.062, 0.72],
											[1.948, 2.807],
										],
										o: [
											[-1.35, 0.804],
											[-0.23, 0.636],
											[0.699, 1.688],
											[2.062, -0.72],
											[-1.395, -2.01],
										],
										v: [
											[2544, 1179],
											[2541.785, 1181.114],
											[2542, 1183],
											[2547.485, 1184.371],
											[2549, 1179],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[4.472, -1.827],
											[0.695, -1.443],
											[-1.064, -2.768],
											[-2.523, 0.969],
											[4.32, 4.32],
										],
										o: [
											[-2.418, 0.988],
											[-0.531, 1.103],
											[0.662, 1.722],
											[3.191, -1.225],
											[-1.066, -1.066],
										],
										v: [
											[2034, 1150],
											[2029, 1152],
											[2029, 1159],
											[2040, 1163],
											[2043, 1150],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[4.249, -2.279],
											[0.543, -0.054],
											[0.293, -0.495],
											[-2.923, -1.26],
											[-1.418, 2.615],
										],
										o: [
											[-0.534, 0.287],
											[-0.543, 0.054],
											[-2.11, 3.577],
											[2.923, 1.26],
											[3.158, -5.827],
										],
										v: [
											[2045, 1152],
											[2043.319, 1152.343],
											[2042, 1153],
											[2044.854, 1160.644],
											[2053, 1159],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[1.244, -0.665],
											[-0.203, -1.031],
											[-0.986, -0.599],
											[-0.723, 0.425],
											[0.824, 1.44],
										],
										o: [
											[-1.625, 0.87],
											[0.203, 1.031],
											[0.986, 0.599],
											[1.539, -0.905],
											[-0.824, -1.44],
										],
										v: [
											[2459, 1160],
											[2457.129, 1162.998],
											[2459.175, 1165.591],
											[2462, 1166],
											[2462.586, 1161.823],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[2.252, -0.789],
											[0.332, -0.215],
											[0.24, -0.277],
											[-0.107, -0.245],
											[-0.813, 1.27],
										],
										o: [
											[-0.169, 0.059],
											[-0.332, 0.215],
											[-0.24, 0.277],
											[0.999, 2.289],
											[0.813, -1.27],
										],
										v: [
											[2468, 1162],
											[2467.185, 1162.434],
											[2466.263, 1163.194],
											[2466, 1164],
											[2469.438, 1164.125],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[3.441, -1.014],
											[0.539, -0.412],
											[0.287, -0.546],
											[-0.284, -0.542],
											[-1.215, 2.007],
										],
										o: [
											[-0.471, 0.139],
											[-0.539, 0.412],
											[-0.287, 0.546],
											[1.58, 3.013],
											[1.215, -2.007],
										],
										v: [
											[1676, 1169],
											[1674.405, 1169.861],
											[1673.085, 1171.333],
											[1673, 1173],
											[1678.265, 1172.499],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 129, s: [0], h: 1 },
							{ t: 130, s: [100], h: 1 },
							{ t: 139, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 11',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 132,
								s: [
									{
										i: [
											[0.111, -0.068],
											[-1.879, -1.305],
											[-1.238, 1.537],
											[0.628, 1.429],
											[1.177, 0.428],
										],
										o: [
											[-3.755, 2.317],
											[1.879, 1.305],
											[0.69, -0.856],
											[-0.628, -1.429],
											[0.189, 0.069],
										],
										v: [
											[2598, 1179],
											[2596.755, 1184.891],
											[2603, 1185],
											[2602.9, 1181.178],
											[2600, 1178],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 133,
								s: [
									{
										i: [
											[2.252, -0.789],
											[0.332, -0.215],
											[0.24, -0.277],
											[-0.107, -0.245],
											[-0.813, 1.27],
										],
										o: [
											[-0.169, 0.059],
											[-0.332, 0.215],
											[-0.24, 0.277],
											[0.999, 2.289],
											[0.813, -1.27],
										],
										v: [
											[2605, 1181],
											[2604.185, 1181.434],
											[2603.263, 1182.194],
											[2603, 1183],
											[2606.438, 1183.125],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[0.573, -0.33],
											[-1.732, -1.617],
											[-1.358, 1.358],
											[0.396, 1.785],
											[0.94, 0.338],
										],
										o: [
											[-3.287, 1.889],
											[1.732, 1.617],
											[0.524, -0.524],
											[-0.396, -1.785],
											[-1.477, -0.531],
										],
										v: [
											[2444, 1157],
											[2443.017, 1162.935],
											[2449, 1164],
											[2449.098, 1159.861],
											[2447, 1156],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[10.007, -2.332],
											[1.329, -3.394],
											[0.022, 0.067],
											[-4.635, 4.442],
											[-0.578, 3.658],
										],
										o: [
											[-4.442, 1.035],
											[-0.927, 2.367],
											[2.135, 6.547],
											[1.771, -1.697],
											[2.123, -13.436],
										],
										v: [
											[2319, 1154],
											[2308, 1162],
											[2307, 1172],
											[2332, 1176],
											[2336, 1169],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[3.125, -2.043],
											[-3.458, -2.81],
											[-2.744, 1.885],
											[-0.42, 1.197],
											[-0.12, 1.073],
										],
										o: [
											[-5.575, 3.646],
											[3.458, 2.81],
											[0.2, -0.138],
											[0.42, -1.197],
											[0.952, -8.521],
										],
										v: [
											[1718, 1163],
											[1717.26, 1173.648],
											[1729, 1176],
											[1730.06, 1173.702],
											[1731, 1170],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[5.434, -4.352],
											[0.315, -1.946],
											[-0.563, -1.171],
											[-3.038, 0.548],
											[-0.531, 1.541],
										],
										o: [
											[-1.303, 1.043],
											[-0.315, 1.946],
											[1.316, 2.734],
											[3.038, -0.548],
											[2.424, -7.033],
										],
										v: [
											[1731, 1167],
											[1728.6, 1171.904],
											[1729, 1177],
											[1736.589, 1179.706],
											[1743, 1176],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[0.708, -0.401],
											[-2.196, -2.614],
											[-2.522, 1.899],
											[1.328, 2.574],
											[2.146, -0.148],
										],
										o: [
											[-4.307, 2.442],
											[2.196, 2.614],
											[2.457, -1.85],
											[-1.328, -2.575],
											[-1.298, 0.089],
										],
										v: [
											[1742, 1169],
											[1740.378, 1177.756],
											[1749, 1180],
											[1749.952, 1172.502],
											[1744, 1168],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 131, s: [0], h: 1 },
							{ t: 132, s: [100], h: 1 },
							{ t: 139, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 12',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 132,
								s: [
									{
										i: [
											[1.896, -1.896],
											[0.17, -0.534],
											[-0.151, -0.441],
											[-0.511, -0.177],
											[0.071, 1.662],
										],
										o: [
											[-0.453, 0.453],
											[-0.17, 0.534],
											[0.151, 0.441],
											[2.18, 0.754],
											[-0.071, -1.662],
										],
										v: [
											[2442, 1182],
											[2441.055, 1183.524],
											[2441.016, 1185.03],
											[2442, 1186],
											[2445.057, 1183.144],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 134,
								s: [
									{
										i: [
											[1.765, -0.928],
											[-0.091, -0.864],
											[-0.811, -0.47],
											[-0.616, 0.37],
											[0.314, 1.329],
										],
										o: [
											[-1.543, 0.811],
											[0.091, 0.864],
											[0.811, 0.47],
											[1.024, -0.614],
											[-0.314, -1.328],
										],
										v: [
											[2551, 1180],
											[2549.051, 1182.625],
											[2550.631, 1184.737],
											[2553, 1185],
											[2554.092, 1181.343],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 135,
								s: [
									{
										i: [
											[0.505, 0.028],
											[-0.516, -1.877],
											[-2.673, 0.304],
											[-0.444, 1.479],
											[0.595, 0.992],
										],
										o: [
											[-3.738, -0.205],
											[0.516, 1.877],
											[0.818, -0.093],
											[0.444, -1.479],
											[-0.934, -1.558],
										],
										v: [
											[2453, 1158],
											[2448.691, 1161.575],
											[2454, 1165],
											[2456.059, 1162.174],
											[2456, 1158],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[7.043, -3.4],
											[0.24, -0.15],
											[-2.239, -4.585],
											[-1.313, -0.325],
											[5.172, 6.34],
										],
										o: [
											[-0.257, 0.124],
											[-2.399, 1.498],
											[0.316, 0.646],
											[14.449, 3.573],
											[-2.121, -2.6],
										],
										v: [
											[1589, 1165],
											[1588, 1165],
											[1584, 1180],
											[1590, 1184],
											[1601, 1167],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[5.791, -2.419],
											[1.21, -2.011],
											[-0.561, -2.238],
											[-5.157, 2.245],
											[-0.247, 2.255],
										],
										o: [
											[-1.722, 0.719],
											[-1.21, 2.011],
											[1.168, 4.661],
											[2.032, -0.885],
											[0.918, -8.397],
										],
										v: [
											[1602, 1168],
											[1597.287, 1172.361],
											[1596, 1179],
											[1613, 1185],
											[1617, 1178],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 138,
								s: [
									{
										i: [
											[3.988, -1.63],
											[1.044, -1.347],
											[-1.148, -2.216],
											[-2.783, 1.761],
											[1.758, 3.101],
										],
										o: [
											[-0.973, 0.398],
											[-1.044, 1.347],
											[1.628, 3.144],
											[2.783, -1.761],
											[-1.22, -2.152],
										],
										v: [
											[1615, 1173],
											[1611.41, 1175.636],
											[1611, 1181],
											[1619.039, 1182.183],
											[1622, 1174],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 131, s: [0], h: 1 },
							{ t: 132, s: [100], h: 1 },
							{ t: 133, s: [0], h: 1 },
							{ t: 134, s: [100], h: 1 },
							{ t: 139, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 13',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 135,
								s: [
									{
										i: [
											[9.26, -5.277],
											[0.442, -1.094],
											[-6.62, 2.497],
											[-0.945, 1.319],
										],
										o: [
											[-0.64, 0.365],
											[-3.796, 9.4],
											[1.052, -0.396],
											[5.983, -8.349],
										],
										v: [
											[1700, 1161],
											[1696, 1164],
											[1710, 1177],
											[1713, 1174],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 136,
								s: [
									{
										i: [
											[6.118, -3.674],
											[-0.413, -1.358],
											[-0.789, -0.25],
											[-0.337, 2.547],
										],
										o: [
											[-2.759, 1.657],
											[0.747, 2.458],
											[3.848, 1.22],
											[0.724, -5.47],
										],
										v: [
											[1659, 1166],
											[1656, 1173],
											[1661, 1176],
											[1669, 1174],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 137,
								s: [
									{
										i: [
											[0.692, -0.297],
											[-0.881, -2.078],
											[-0.652, 0.899],
											[1.784, 0.689],
										],
										o: [
											[-3.256, 1.399],
											[1.278, 3.013],
											[1.252, -1.726],
											[-1.766, -0.682],
										],
										v: [
											[1668, 1168],
											[1665, 1174],
											[1674, 1175],
											[1672, 1167],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 134, s: [0], h: 1 },
							{ t: 135, s: [100], h: 1 },
							{ t: 138, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 14',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 0,
						k: {
							i: [
								[4.484, -1.138],
								[-0.615, -4.994],
								[-1.379, 5.418],
								[1.015, 0.854],
							],
							o: [
								[-3.853, 0.978],
								[1.146, 9.302],
								[0.653, -2.564],
								[-2.191, -1.845],
							],
							v: [
								[1644, 1164],
								[1636, 1172],
								[1657, 1173],
								[1654, 1165],
							],
							c: true,
						},
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 134, s: [0], h: 1 },
							{ t: 135, s: [100], h: 1 },
							{ t: 136, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 15',
				},
			],
			ef: [
				{
					ty: 21,
					nm: 'Fill',
					np: 9,
					mn: 'ADBE Fill',
					ix: 1,
					en: 1,
					ef: [
						{ ty: 10, nm: 'Fill Mask', mn: 'ADBE Fill-0001', ix: 1, v: { a: 0, k: 0, ix: 1 } },
						{ ty: 7, nm: 'All Masks', mn: 'ADBE Fill-0007', ix: 2, v: { a: 0, k: 0, ix: 2 } },
						{ ty: 2, nm: 'Color', mn: 'ADBE Fill-0002', ix: 3, v: { a: 0, k: [0.090196080506, 0.168627455831, 0.301960796118, 1], ix: 3 } },
						{ ty: 7, nm: 'Invert', mn: 'ADBE Fill-0006', ix: 4, v: { a: 0, k: 0, ix: 4 } },
						{ ty: 0, nm: 'Horizontal Feather', mn: 'ADBE Fill-0003', ix: 5, v: { a: 0, k: 0, ix: 5 } },
						{ ty: 0, nm: 'Vertical Feather', mn: 'ADBE Fill-0004', ix: 6, v: { a: 0, k: 0, ix: 6 } },
						{ ty: 0, nm: 'Opacity', mn: 'ADBE Fill-0005', ix: 7, v: { a: 0, k: 1, ix: 7 } },
					],
				},
			],
			sw: 3840,
			sh: 2160,
			sc: '#ece8e5',
			ip: 122,
			op: 143,
			st: 103,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 2,
			ty: 1,
			nm: 'Pale Orange Solid 6',
			sr: 1,
			ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 540, 0], ix: 2 }, a: { a: 0, k: [1920, 1080, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
			ao: 0,
			hasMask: true,
			masksProperties: [
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 19,
								s: [
									{
										i: [
											[0.138, -0.101],
											[0.074, -0.059],
											[0.068, -0.059],
											[0.062, -0.06],
											[0.056, -0.06],
											[0.05, -0.06],
											[0.045, -0.061],
											[0.04, -0.062],
											[0.036, -0.063],
											[0.031, -0.064],
											[0.027, -0.066],
											[0.024, -0.068],
											[0.02, -0.07],
											[0.017, -0.072],
											[0.014, -0.074],
											[0.012, -0.076],
											[0.01, -0.079],
											[0.008, -0.082],
											[0.006, -0.085],
											[0.005, -0.088],
											[0.004, -0.091],
											[0.003, -0.095],
											[0.003, -0.099],
											[0.003, -0.103],
											[0.003, -0.107],
											[0.004, -0.111],
											[0.004, -0.116],
											[0.006, -0.157],
											[0.006, -0.169],
											[0.005, -0.177],
											[0.003, -0.183],
											[0, -0.186],
											[-0.005, -0.187],
											[-0.01, -0.184],
											[-0.016, -0.179],
											[-0.023, -0.171],
											[-0.031, -0.16],
											[-0.041, -0.146],
											[-0.051, -0.13],
											[-0.062, -0.11],
											[-0.074, -0.088],
											[-0.087, -0.063],
											[-0.185, -0.067],
											[-0.206, -0.02],
											[-0.222, 0.023],
											[-0.232, 0.064],
											[-0.237, 0.101],
											[-0.237, 0.136],
											[-0.231, 0.168],
											[-0.221, 0.197],
											[-0.205, 0.223],
											[-0.184, 0.246],
											[-0.158, 0.266],
											[-0.126, 0.284],
											[-0.089, 0.298],
											[-0.047, 0.31],
											[0, 0.319],
											[0.041, 0.306],
											[0.078, 0.284],
											[0.111, 0.261],
											[0.138, 0.237],
											[0.162, 0.212],
											[0.18, 0.185],
											[0.194, 0.158],
											[0.203, 0.129],
											[0.208, 0.1],
											[0.207, 0.069],
											[0.203, 0.037],
											[0.193, 0.004],
											[0.179, -0.03],
											[0.161, -0.065],
										],
										o: [
											[-0.081, 0.06],
											[-0.074, 0.059],
											[-0.068, 0.059],
											[-0.062, 0.06],
											[-0.056, 0.06],
											[-0.05, 0.06],
											[-0.045, 0.061],
											[-0.04, 0.062],
											[-0.036, 0.063],
											[-0.031, 0.064],
											[-0.027, 0.066],
											[-0.024, 0.068],
											[-0.02, 0.07],
											[-0.017, 0.072],
											[-0.014, 0.074],
											[-0.012, 0.076],
											[-0.01, 0.079],
											[-0.008, 0.082],
											[-0.006, 0.085],
											[-0.005, 0.088],
											[-0.004, 0.091],
											[-0.003, 0.095],
											[-0.003, 0.099],
											[-0.003, 0.103],
											[-0.003, 0.107],
											[-0.004, 0.111],
											[-0.006, 0.143],
											[-0.006, 0.157],
											[-0.006, 0.169],
											[-0.005, 0.177],
											[-0.003, 0.183],
											[0, 0.186],
											[0.005, 0.187],
											[0.01, 0.184],
											[0.016, 0.179],
											[0.023, 0.171],
											[0.031, 0.16],
											[0.041, 0.146],
											[0.051, 0.13],
											[0.062, 0.11],
											[0.074, 0.088],
											[0.159, 0.116],
											[0.185, 0.067],
											[0.206, 0.02],
											[0.222, -0.023],
											[0.232, -0.064],
											[0.237, -0.101],
											[0.237, -0.136],
											[0.231, -0.168],
											[0.221, -0.197],
											[0.205, -0.223],
											[0.184, -0.246],
											[0.158, -0.266],
											[0.126, -0.284],
											[0.089, -0.298],
											[0.047, -0.31],
											[0, -0.326],
											[-0.041, -0.306],
											[-0.078, -0.284],
											[-0.111, -0.261],
											[-0.138, -0.237],
											[-0.162, -0.212],
											[-0.18, -0.185],
											[-0.194, -0.158],
											[-0.203, -0.129],
											[-0.208, -0.1],
											[-0.207, -0.069],
											[-0.203, -0.037],
											[-0.193, -0.004],
											[-0.179, 0.03],
											[-0.161, 0.065],
										],
										v: [
											[2491, 1090],
											[2490.767, 1090.179],
											[2490.554, 1090.357],
											[2490.36, 1090.536],
											[2490.184, 1090.715],
											[2490.024, 1090.896],
											[2489.881, 1091.078],
											[2489.753, 1091.263],
											[2489.639, 1091.451],
											[2489.539, 1091.642],
											[2489.451, 1091.838],
											[2489.375, 1092.038],
											[2489.309, 1092.244],
											[2489.253, 1092.456],
											[2489.206, 1092.673],
											[2489.167, 1092.898],
											[2489.135, 1093.131],
											[2489.109, 1093.372],
											[2489.088, 1093.621],
											[2489.072, 1093.88],
											[2489.059, 1094.149],
											[2489.048, 1094.428],
											[2489.039, 1094.718],
											[2489.03, 1095.02],
											[2489.022, 1095.334],
											[2489.012, 1095.66],
											[2489, 1096],
											[2488.982, 1096.451],
											[2488.963, 1096.941],
											[2488.946, 1097.46],
											[2488.935, 1098.002],
											[2488.931, 1098.557],
											[2488.939, 1099.116],
											[2488.96, 1099.673],
											[2488.999, 1100.218],
											[2489.058, 1100.742],
											[2489.14, 1101.239],
											[2489.248, 1101.698],
											[2489.385, 1102.112],
											[2489.554, 1102.473],
											[2489.758, 1102.772],
											[2490, 1103],
											[2490.518, 1103.273],
											[2491.106, 1103.402],
											[2491.749, 1103.397],
											[2492.431, 1103.266],
											[2493.135, 1103.018],
											[2493.847, 1102.662],
											[2494.551, 1102.206],
											[2495.231, 1101.658],
											[2495.871, 1101.028],
											[2496.455, 1100.324],
											[2496.969, 1099.555],
											[2497.396, 1098.73],
											[2497.721, 1097.856],
											[2497.927, 1096.943],
											[2498, 1096],
											[2497.937, 1095.052],
											[2497.756, 1094.168],
											[2497.471, 1093.351],
											[2497.096, 1092.604],
											[2496.645, 1091.931],
											[2496.131, 1091.336],
											[2495.57, 1090.821],
											[2494.973, 1090.389],
											[2494.356, 1090.045],
											[2493.732, 1089.792],
											[2493.116, 1089.632],
											[2492.52, 1089.57],
											[2491.96, 1089.608],
											[2491.449, 1089.751],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 20,
								s: [
									{
										i: [
											[0.213, -0.046],
											[0.421, -0.587],
											[0.449, -0.56],
											[0.461, -0.555],
											[0.458, -0.57],
											[0.439, -0.606],
											[0.405, -0.662],
											[0.355, -0.74],
											[0.29, -0.838],
											[0.186, -1.01],
											[0.073, -1.056],
											[-0.003, -1.106],
											[-0.042, -1.161],
											[-0.045, -1.221],
											[-0.012, -1.286],
											[0.059, -1.356],
											[0.188, -1.14],
											[0.23, -1.148],
											[0.177, -1.184],
											[0.029, -1.248],
											[-0.157, -1.536],
											[-0.238, -1.602],
											[-0.212, -1.515],
											[-0.08, -1.275],
											[-0.056, -2.431],
											[-0.132, -2.14],
											[-0.385, -1.639],
											[-0.815, -0.927],
											[-0.17, -0.001],
											[-0.21, 0.029],
											[-0.185, 0.015],
											[-0.095, -0.043],
											[-0.073, -0.182],
											[-0.071, -0.229],
											[-0.087, -0.182],
											[-0.122, -0.042],
											[-1.42, 0.22],
											[-1.224, 0.621],
											[-0.817, 0.715],
											[-0.2, 0.504],
											[0.325, 1.708],
											[0.79, 1.534],
											[0.57, 1.559],
											[-0.335, 1.784],
											[-1.059, 1.008],
											[-1.314, 1.164],
											[-0.973, 1.574],
											[-0.035, 2.238],
											[0.153, 0.629],
											[0.223, 0.766],
											[0.209, 0.629],
											[0.11, 0.22],
											[0.468, 0.436],
											[0.559, 0.367],
											[0.562, 0.372],
											[0.476, 0.451],
											[0.53, 0.463],
											[0.446, 0.505],
											[0.318, 0.664],
											[0.146, 0.943],
											[-0.723, 1.51],
											[-1.182, 1.119],
											[-0.993, 1.299],
											[-0.157, 2.052],
											[0.738, 1.332],
											[1.171, 0.914],
											[1.098, 1.375],
											[0.518, 2.715],
											[0.03, 0.137],
											[0.068, 0.098],
											[0.129, 0.037],
										],
										o: [
											[-0.378, 0.634],
											[-0.421, 0.587],
											[-0.449, 0.56],
											[-0.461, 0.555],
											[-0.458, 0.57],
											[-0.439, 0.606],
											[-0.405, 0.662],
											[-0.355, 0.74],
											[-0.336, 0.97],
											[-0.186, 1.01],
											[-0.073, 1.056],
											[0.003, 1.106],
											[0.042, 1.161],
											[0.045, 1.221],
											[0.012, 1.286],
											[-0.05, 1.159],
											[-0.188, 1.14],
											[-0.23, 1.148],
											[-0.177, 1.184],
											[-0.031, 1.317],
											[0.157, 1.536],
											[0.238, 1.602],
											[0.212, 1.515],
											[0.158, 2.511],
											[0.056, 2.431],
											[0.132, 2.14],
											[0.385, 1.639],
											[0.065, 0.074],
											[0.17, 0.001],
											[0.21, -0.029],
											[0.185, -0.015],
											[0.094, 0.043],
											[0.073, 0.182],
											[0.071, 0.229],
											[0.087, 0.182],
											[1.404, 0.487],
											[1.42, -0.22],
											[1.224, -0.621],
											[0.817, -0.715],
											[0.826, -2.081],
											[-0.325, -1.708],
											[-0.79, -1.534],
											[-0.57, -1.559],
											[0.208, -1.107],
											[1.059, -1.008],
											[1.314, -1.164],
											[0.973, -1.574],
											[0.003, -0.218],
											[-0.153, -0.629],
											[-0.223, -0.766],
											[-0.209, -0.629],
											[-0.289, -0.579],
											[-0.468, -0.436],
											[-0.559, -0.367],
											[-0.562, -0.372],
											[-0.57, -0.539],
											[-0.53, -0.463],
											[-0.446, -0.505],
											[-0.318, -0.664],
											[-0.383, -2.474],
											[0.723, -1.51],
											[1.182, -1.119],
											[0.993, -1.299],
											[0.201, -2.628],
											[-0.738, -1.332],
											[-1.171, -0.914],
											[-1.098, -1.375],
											[-0.014, -0.152],
											[-0.03, -0.137],
											[-0.068, -0.098],
											[-0.129, -0.037],
										],
										v: [
											[2490, 1077],
											[2488.798, 1078.825],
											[2487.49, 1080.54],
											[2486.12, 1082.207],
											[2484.737, 1083.888],
											[2483.387, 1085.645],
											[2482.116, 1087.542],
											[2480.972, 1089.639],
											[2480, 1092],
											[2479.227, 1094.97],
											[2478.846, 1098.067],
											[2478.75, 1101.308],
											[2478.827, 1104.707],
											[2478.967, 1108.279],
											[2479.062, 1112.038],
											[2479, 1116],
											[2478.619, 1119.441],
											[2477.968, 1122.867],
											[2477.334, 1126.359],
											[2477, 1130],
											[2477.215, 1134.318],
											[2477.833, 1139.064],
											[2478.535, 1143.777],
											[2479, 1148],
											[2479.277, 1155.465],
											[2479.514, 1162.375],
											[2480.245, 1168.098],
											[2482, 1172],
											[2482.369, 1172.101],
											[2482.955, 1172.047],
											[2483.564, 1171.969],
											[2484, 1172],
											[2484.247, 1172.36],
											[2484.459, 1173],
											[2484.692, 1173.64],
											[2485, 1174],
											[2489.288, 1174.324],
											[2493.306, 1172.986],
											[2496.421, 1170.905],
											[2498, 1169],
											[2498.581, 1163.367],
											[2496.737, 1158.554],
											[2494.525, 1153.964],
											[2494, 1149],
											[2496.05, 1145.891],
											[2499.759, 1142.696],
											[2503.339, 1138.654],
											[2505, 1133],
											[2504.755, 1131.662],
											[2504.169, 1129.502],
											[2503.499, 1127.342],
											[2503, 1126],
											[2501.843, 1124.495],
											[2500.282, 1123.308],
											[2498.579, 1122.217],
											[2497, 1121],
											[2495.339, 1119.526],
											[2493.864, 1118.105],
											[2492.707, 1116.381],
											[2492, 1114],
											[2492.671, 1108.167],
											[2495.69, 1104.367],
											[2499.113, 1100.883],
											[2501, 1096],
											[2500.069, 1090.28],
											[2497.079, 1087.13],
											[2493.55, 1083.916],
											[2491, 1078],
											[2490.939, 1077.561],
											[2490.798, 1077.202],
											[2490.507, 1076.993],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 21,
								s: [
									{
										i: [
											[0.173, -0.012],
											[0.889, -0.736],
											[0.682, -1.16],
											[0.452, -1.344],
											[0.198, -1.288],
											[-0.025, -0.715],
											[-0.062, -0.748],
											[-0.006, -0.784],
											[0.142, -0.822],
											[0.193, -0.583],
											[0.204, -0.526],
											[0.159, -0.555],
											[0.058, -0.67],
											[-0.021, -1.416],
											[-0.066, -1.413],
											[-0.012, -1.415],
											[0.14, -1.423],
											[0.215, -0.901],
											[0.238, -0.93],
											[0.154, -0.939],
											[-0.037, -0.928],
											[-0.259, -0.995],
											[-0.349, -1.172],
											[-0.29, -1.09],
											[-0.083, -0.752],
											[0.076, -4.819],
											[-0.351, -3.703],
											[-1.877, -2.032],
											[-4.499, 0.193],
											[-1.851, 0.588],
											[-1.5, 1.037],
											[-0.899, 1.43],
											[-0.047, 1.767],
											[0.844, 1.407],
											[1.149, 1.04],
											[0.855, 1.113],
											[-0.037, 1.626],
											[-0.485, 0.899],
											[-0.778, 0.749],
											[-0.904, 0.601],
											[-0.863, 0.454],
											[-1.299, 0.343],
											[-1.227, 0.204],
											[-1.064, 0.26],
											[-0.81, 0.508],
											[-0.765, 0.767],
											[-0.681, 0.887],
											[-0.451, 0.802],
											[-0.073, 0.51],
											[0.038, 0.593],
											[0.098, 0.699],
											[0.136, 0.631],
											[0.153, 0.39],
											[0.736, 0.808],
											[0.818, 0.689],
											[0.548, 0.411],
											[-0.074, -0.025],
											[2.353, -0.24],
											[2.026, 0.078],
											[0.94, 1.609],
											[-2.065, 1.804],
											[-0.221, 0.251],
											[-1.373, 2.016],
											[0.487, 2.809],
											[2.892, 1.229],
											[2.862, 0.798],
											[1.111, 0.302],
											[0.632, 0.532],
											[0.286, 1.551],
											[0.705, 1.827],
											[0.631, -0.195],
										],
										o: [
											[-1.073, 0.073],
											[-0.889, 0.736],
											[-0.682, 1.16],
											[-0.452, 1.344],
											[-0.105, 0.685],
											[0.025, 0.715],
											[0.062, 0.748],
											[0.006, 0.784],
											[-0.126, 0.727],
											[-0.193, 0.583],
											[-0.204, 0.526],
											[-0.159, 0.555],
											[-0.123, 1.426],
											[0.021, 1.416],
											[0.066, 1.413],
											[0.012, 1.415],
											[-0.084, 0.852],
											[-0.215, 0.901],
											[-0.238, 0.93],
											[-0.154, 0.939],
											[0.023, 0.562],
											[0.259, 0.995],
											[0.349, 1.172],
											[0.29, 1.09],
											[0.595, 5.381],
											[-0.076, 4.819],
											[0.351, 3.703],
											[1.877, 2.032],
											[1.953, -0.084],
											[1.851, -0.588],
											[1.5, -1.037],
											[0.899, -1.43],
											[0.059, -2.213],
											[-0.844, -1.407],
											[-1.149, -1.04],
											[-0.855, -1.113],
											[0.024, -1.05],
											[0.485, -0.899],
											[0.778, -0.749],
											[0.904, -0.601],
											[1.28, -0.674],
											[1.299, -0.343],
											[1.227, -0.204],
											[1.064, -0.26],
											[0.701, -0.44],
											[0.765, -0.767],
											[0.681, -0.887],
											[0.451, -0.802],
											[0.045, -0.314],
											[-0.038, -0.593],
											[-0.098, -0.699],
											[-0.136, -0.631],
											[-0.302, -0.769],
											[-0.736, -0.808],
											[-0.818, -0.689],
											[-0.548, -0.411],
											[-1.923, -0.656],
											[-2.353, 0.24],
											[-2.026, -0.078],
											[-1.481, -2.535],
											[2.065, -1.804],
											[0.994, -1.128],
											[1.373, -2.016],
											[-0.569, -3.284],
											[-2.892, -1.23],
											[-0.925, -0.258],
											[-1.111, -0.303],
											[-1.152, -0.969],
											[-0.286, -1.551],
											[-0.305, -0.791],
											[-0.631, 0.195],
										],
										v: [
											[2482, 1071],
											[2479.051, 1072.274],
											[2476.687, 1075.178],
											[2474.98, 1078.993],
											[2474, 1083],
											[2473.903, 1085.1],
											[2474.056, 1087.294],
											[2474.181, 1089.592],
											[2474, 1092],
											[2473.507, 1093.944],
											[2472.898, 1095.586],
											[2472.339, 1097.184],
											[2472, 1099],
											[2471.871, 1103.261],
											[2472.026, 1107.503],
											[2472.168, 1111.743],
											[2472, 1116],
											[2471.525, 1118.635],
											[2470.819, 1121.386],
											[2470.203, 1124.194],
											[2470, 1127],
											[2470.46, 1129.4],
											[2471.409, 1132.715],
											[2472.403, 1136.172],
											[2473, 1139],
											[2473.504, 1154.44],
											[2473.643, 1167.362],
											[2476.71, 1176.103],
											[2486, 1179],
											[2491.769, 1177.978],
											[2496.858, 1175.525],
											[2500.519, 1171.81],
											[2502, 1167],
											[2500.673, 1161.68],
											[2497.534, 1158.12],
											[2494.378, 1154.999],
											[2493, 1151],
											[2493.805, 1148.077],
											[2495.742, 1145.606],
											[2498.308, 1143.582],
											[2501, 1142],
											[2504.893, 1140.523],
											[2508.706, 1139.751],
											[2512.166, 1139.104],
											[2515, 1138],
											[2517.236, 1136.138],
											[2519.442, 1133.605],
											[2521.178, 1131.02],
											[2522, 1129],
											[2522.005, 1127.596],
											[2521.797, 1125.614],
											[2521.44, 1123.575],
											[2521, 1122],
											[2519.356, 1119.595],
											[2516.937, 1117.309],
											[2514.8, 1115.619],
											[2514, 1115],
											[2507.396, 1114.679],
											[2500.638, 1115.227],
											[2496, 1113],
											[2498.224, 1106.287],
											[2503, 1103],
											[2507.111, 1098.261],
											[2509, 1091],
											[2503.22, 1084.636],
											[2494, 1082],
											[2490.781, 1081.206],
											[2488, 1080],
											[2486.165, 1076.144],
											[2485, 1071],
											[2483.401, 1070.398],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 22,
								s: [
									{
										i: [
											[0.635, 0.358],
											[0.237, -0.328],
											[0.183, -0.283],
											[0.104, -0.313],
											[0, -0.418],
											[0.026, -3.953],
											[0.071, -3.813],
											[0.134, -4.078],
											[0.216, -4.75],
											[0.177, -1.905],
											[0.208, -1.942],
											[0.177, -1.941],
											[0.083, -1.902],
											[-0.037, -2.569],
											[-0.127, -2.373],
											[-0.151, -2.117],
											[-0.109, -1.803],
											[-0.034, -1.839],
											[-0.121, -1.4],
											[-0.402, -0.986],
											[-0.875, -0.599],
											[-1.255, 0.029],
											[-1.512, 0.323],
											[-1.501, 0.382],
											[-1.221, 0.207],
											[-1.182, 0.11],
											[-1.121, 0.149],
											[-1.129, 0.34],
											[-1.205, 0.683],
											[-0.613, 0.278],
											[-0.54, 0.331],
											[-0.383, 0.499],
											[0.16, 1.505],
											[0.865, 1.016],
											[1.679, 0.324],
											[1.849, 1.019],
											[1.543, 1.541],
											[-0.336, 2.642],
											[-0.187, 0.317],
											[-0.198, 0.307],
											[-0.217, 0.46],
											[-0.099, 0.106],
											[-0.822, 0.189],
											[-0.994, 0.468],
											[-4.627, 0.107],
											[-5.347, 0.616],
											[-2.74, 1.293],
											[-2.065, 1.37],
											[0.148, 3.246],
											[1.48, 1.977],
											[1.714, 1.284],
											[2.082, 0.448],
											[6.818, -1.171],
											[1.489, 3.559],
											[-0.242, 1.271],
											[-0.245, 0.403],
											[-3.936, 0.111],
											[-2.592, 0.823],
											[-2.436, 2.837],
											[0.122, 2.899],
											[1.385, 1.571],
											[1.372, 0.619],
											[0.142, 0.46],
											[0.245, 0.077],
											[4.093, -1.243],
											[5.267, 0.596],
											[2.532, 1.233],
											[0.88, 0.843],
											[1.184, 1.318],
											[0.852, 1.376],
											[0.66, 0.369],
										],
										o: [
											[-0.266, 0.449],
											[-0.237, 0.328],
											[-0.183, 0.283],
											[-0.104, 0.313],
											[0, 4.5],
											[-0.026, 3.953],
											[-0.071, 3.813],
											[-0.134, 4.078],
											[-0.083, 1.831],
											[-0.177, 1.905],
											[-0.208, 1.942],
											[-0.177, 1.941],
											[-0.119, 2.706],
											[0.037, 2.569],
											[0.127, 2.373],
											[0.151, 2.117],
											[0.14, 2.303],
											[0.034, 1.839],
											[0.121, 1.4],
											[0.402, 0.986],
											[0.729, 0.498],
											[1.255, -0.029],
											[1.512, -0.323],
											[1.501, -0.382],
											[1.311, -0.222],
											[1.182, -0.11],
											[1.121, -0.149],
											[1.129, -0.34],
											[0.602, -0.341],
											[0.613, -0.278],
											[0.54, -0.331],
											[1.505, -1.962],
											[-0.16, -1.505],
											[-1.436, -1.687],
											[-1.679, -0.324],
											[-2.164, -1.193],
											[-1.543, -1.541],
											[0.054, -0.427],
											[0.187, -0.317],
											[0.034, -0.053],
											[0.217, -0.46],
											[0.716, -0.774],
											[0.822, -0.189],
											[4.144, -1.955],
											[4.627, -0.107],
											[3.976, -0.458],
											[2.74, -1.293],
											[3.073, -2.039],
											[-0.148, -3.246],
											[-1.061, -1.418],
											[-1.714, -1.284],
											[-5.239, -1.127],
											[-6.818, 1.171],
											[-0.214, -0.512],
											[0.242, -1.271],
											[1.664, -2.732],
											[3.936, -0.111],
											[2.377, -0.755],
											[2.436, -2.837],
											[-0.088, -2.099],
											[-1.385, -1.571],
											[-0.188, -0.084],
											[-0.142, -0.46],
											[-4.362, -1.374],
											[-4.093, 1.243],
											[-1.992, -0.226],
											[-2.532, -1.233],
											[-1.412, -1.352],
											[-1.184, -1.318],
											[-0.723, -1.167],
											[-0.66, -0.369],
										],
										v: [
											[2471, 1063],
											[2470.238, 1064.147],
											[2469.6, 1065.045],
											[2469.163, 1065.921],
											[2469, 1067],
											[2468.965, 1079.578],
											[2468.823, 1091.125],
											[2468.52, 1102.86],
											[2468, 1116],
											[2467.594, 1121.614],
											[2467, 1127.393],
											[2466.407, 1133.226],
											[2466, 1139],
											[2465.894, 1146.928],
											[2466.158, 1154.355],
											[2466.592, 1161.105],
											[2467, 1167],
											[2467.213, 1173.206],
											[2467.398, 1178.057],
											[2468.134, 1181.629],
											[2470, 1184],
											[2473.043, 1184.645],
											[2477.261, 1184.058],
											[2481.849, 1182.942],
											[2486, 1182],
											[2489.722, 1181.541],
											[2493.159, 1181.191],
											[2496.516, 1180.496],
											[2500, 1179],
											[2501.844, 1178.1],
											[2503.595, 1177.216],
											[2505, 1176],
											[2506.778, 1170.791],
											[2505, 1167],
											[2500.309, 1164.499],
											[2495, 1163],
											[2489.125, 1159.087],
											[2487, 1153],
											[2487.392, 1151.91],
											[2488, 1151],
											[2488.452, 1150.04],
											[2489, 1149],
											[2491.292, 1147.771],
											[2494, 1147],
											[2507.098, 1144.496],
											[2522, 1144],
											[2531.933, 1141.184],
											[2539, 1137],
											[2542.915, 1128.454],
											[2540, 1120],
											[2535.765, 1115.773],
											[2530, 1113],
											[2510.187, 1114.824],
											[2496, 1113],
											[2496.155, 1109.919],
											[2497, 1107],
											[2506.304, 1103.568],
											[2517, 1103],
											[2524.874, 1097.108],
											[2529, 1088],
											[2526.463, 1082.39],
											[2522, 1079],
											[2521.543, 1077.994],
											[2521, 1077],
											[2508.679, 1077.917],
											[2495, 1080],
											[2487.666, 1077.463],
											[2482, 1074],
											[2478.081, 1070.018],
											[2475, 1066],
											[2472.934, 1063.893],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 23,
								s: [
									{
										i: [
											[1.236, -1.421],
											[0.381, -0.334],
											[0, -1.115],
											[-0.243, -13.315],
											[-0.028, -10.033],
											[-0.04, -9.831],
											[-1.825, -1.825],
											[-1.056, -0.421],
											[-0.563, -0.102],
											[-1.148, 0.278],
											[-1.176, 0.112],
											[-3.449, 0.755],
											[-5.245, -0.49],
											[-1.662, -0.465],
											[-1.737, 0.012],
											[-1.679, 0.185],
											[-1.393, 0.255],
											[-0.65, 0.479],
											[-0.241, 0.053],
											[-0.915, -0.003],
											[-0.667, 0.639],
											[-1.757, 1.89],
											[0.479, 2.052],
											[2.134, 0.591],
											[2.86, 0.051],
											[1.047, -0.453],
											[1.319, -0.216],
											[2.842, 1.023],
											[4.211, 0.793],
											[2.056, -0.218],
											[1.467, 0.437],
											[1.101, 1.166],
											[0.216, 0.923],
											[-3.616, 1.825],
											[-5.663, -0.101],
											[-2.742, -0.545],
											[-2.237, 0.229],
											[-0.898, 0.357],
											[-1.044, 0.434],
											[-1.036, 0.347],
											[-1.546, 0.066],
											[-2.149, -0.787],
											[-3.216, 0.015],
											[-3.284, 1.511],
											[-1.167, 1.938],
											[-0.123, 3.512],
											[1.776, 2.818],
											[5.02, 1.563],
											[4.44, -0.599],
											[3.014, -1.724],
											[2.934, -0.564],
											[2.933, 0.899],
											[1.025, 1.025],
											[-2.016, 3.287],
											[-3.563, 0.224],
											[-1.11, -0.65],
											[-1.327, -0.476],
											[-2.907, -0.412],
											[-2.964, 0.512],
											[-2.058, 1.301],
											[-2.322, 0.546],
											[-1.579, -0.297],
											[-1.697, 1.484],
											[0.849, 4.19],
											[5.298, 0.194],
											[8.668, 3.146],
											[3.814, 1.204],
											[3.857, -0.186],
											[1.701, 0.359],
											[6.18, 0.975],
											[0.743, -0.259],
										],
										o: [
											[-0.476, 0.548],
											[-0.381, 0.334],
											[0.001, 8.707],
											[0.243, 13.315],
											[0.016, 5.851],
											[0.04, 9.831],
											[0.212, 0.212],
											[1.056, 0.421],
											[1.233, 0.225],
											[1.148, -0.278],
											[4.957, -0.472],
											[3.449, -0.755],
											[1.616, 0.151],
											[1.662, 0.465],
											[0.891, -0.006],
											[1.679, -0.185],
											[0.158, -0.029],
											[0.65, -0.479],
											[1.672, -0.371],
											[0.915, 0.003],
											[1.448, -1.389],
											[1.758, -1.89],
											[-0.603, -2.586],
											[-2.134, -0.591],
											[-1.493, -0.026],
											[-1.047, 0.453],
											[-5.421, 0.885],
											[-2.842, -1.023],
											[-2.309, -0.434],
											[-2.056, 0.218],
											[-1.381, -0.411],
											[-1.101, -1.166],
											[-1.127, -4.802],
											[3.616, -1.825],
											[2.796, 0.05],
											[2.742, 0.545],
											[1.363, -0.14],
											[0.898, -0.357],
											[1.311, -0.545],
											[1.036, -0.347],
											[3.188, -0.135],
											[2.149, 0.788],
											[3.697, -0.017],
											[3.284, -1.511],
											[1.284, -2.135],
											[0.123, -3.512],
											[-1.479, -2.347],
											[-5.02, -1.563],
											[-4.01, 0.541],
											[-3.014, 1.724],
											[-3.241, 0.624],
											[-2.933, -0.899],
											[-2.627, -2.627],
											[2.016, -3.287],
											[1.232, -0.077],
											[1.11, 0.65],
											[2.407, 0.865],
											[2.907, 0.412],
											[1.445, -0.249],
											[2.058, -1.301],
											[2.988, -0.703],
											[1.579, 0.297],
											[1.855, -1.622],
											[-1.356, -6.697],
											[-10.321, -0.378],
											[-4.409, -1.6],
											[-3.713, -1.172],
											[-2.256, 0.109],
											[-6.881, -1.452],
											[-1.667, -0.263],
											[-2.772, 0.967],
										],
										v: [
											[2455, 1063],
											[2453.643, 1064.075],
											[2453, 1066],
											[2453.479, 1101.005],
											[2454, 1138],
											[2453.644, 1164.519],
											[2456, 1185],
											[2458.237, 1186.082],
											[2461, 1187],
											[2464.543, 1186.752],
											[2468, 1186],
											[2479.784, 1183.778],
											[2492, 1183],
											[2496.909, 1184.122],
											[2502, 1185],
											[2506.123, 1184.686],
											[2511, 1184],
											[2512.437, 1183.018],
											[2514, 1182],
											[2517.754, 1181.701],
											[2520, 1181],
											[2525.445, 1175.997],
											[2528, 1170],
											[2523.693, 1165.598],
											[2516, 1165],
											[2512.37, 1165.818],
											[2509, 1167],
											[2497.593, 1166.258],
											[2488, 1163],
											[2481.369, 1163.002],
											[2476, 1163],
											[2472.126, 1160.384],
											[2470, 1157],
											[2474.408, 1147.323],
											[2489, 1145],
											[2497.419, 1146.21],
											[2505, 1147],
											[2508.24, 1146.22],
											[2511, 1145],
											[2514.324, 1143.64],
											[2518, 1143],
											[2525.479, 1144.41],
											[2533, 1146],
											[2543.898, 1143.441],
											[2551, 1138],
											[2553.296, 1129.012],
											[2551, 1119],
											[2540.22, 1112.791],
											[2525, 1111],
											[2514.693, 1114.982],
											[2506, 1119],
											[2496.338, 1118.237],
											[2490, 1115],
											[2489.858, 1105.197],
											[2499, 1099],
											[2502.429, 1100.084],
											[2506, 1102],
											[2514.082, 1104.032],
											[2523, 1104],
											[2528.342, 1101.222],
											[2535, 1098],
											[2541.469, 1098.085],
											[2546, 1097],
											[2549, 1085],
											[2535, 1076],
											[2504, 1078],
											[2493, 1071],
											[2482, 1070],
											[2475, 1071],
											[2462, 1062],
											[2459, 1063],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 24,
								s: [
									{
										i: [
											[7.959, -2.932],
											[0.6, -0.38],
											[0.199, -0.331],
											[1.314, -3.602],
											[0.604, -3.697],
											[-0.129, -2.952],
											[0.046, -2.316],
											[0.04, -6.108],
											[-0.271, -5.967],
											[-0.137, -3.38],
											[-0.287, -2.871],
											[-0.408, -1.169],
											[-0.261, -1.257],
											[-0.088, -1.449],
											[-0.354, -0.787],
											[-2.43, -2.581],
											[-0.699, -0.381],
											[-1.088, -0.098],
											[-1.078, -0.298],
											[-1.863, -0.157],
											[-2.814, 0.385],
											[-1.265, 0.212],
											[-1.519, -0.041],
											[-3.273, -0.743],
											[-3.145, 0.072],
											[-0.909, 0.467],
											[-0.828, 0.117],
											[-1.41, -0.105],
											[-0.899, 0.211],
											[-1.779, 0.832],
											[-1.914, 0.211],
											[-1.577, 0.01],
											[-1.449, -0.178],
											[-1.26, -0.462],
											[-1.593, 0.014],
											[-3.523, 1.6],
											[0.327, 3.569],
											[6.512, -0.277],
											[4.421, 0.312],
											[2.451, 0.507],
											[2.765, -0.055],
											[2.195, 6.576],
											[-1.479, 0.876],
											[-1.062, 0.544],
											[-2.244, 0.028],
											[-2.995, -0.352],
											[-3.619, -0.331],
											[-5.28, 0.008],
											[-2.93, 0.369],
											[-2.306, 0.494],
											[-4.386, 1.523],
											[-3.661, 0.985],
											[-2.327, 1.541],
											[0.162, 4.634],
											[1.967, 2.567],
											[1.035, 0.368],
											[5.208, 0.811],
											[2.926, 0.471],
											[4.44, 0.23],
											[6.156, -2.338],
											[4.795, -1.829],
											[1.168, -0.288],
											[7.885, 1.162],
											[1.573, 6.31],
											[-2.453, 2.077],
											[-5.494, 1.498],
											[0.295, 4.927],
											[5.565, 2.304],
											[4.148, 0.75],
											[2.805, 0],
											[2.735, 1.19],
										],
										o: [
											[-0.4, 0.148],
											[-0.6, 0.38],
											[-1.141, 1.894],
											[-1.314, 3.602],
											[-0.47, 2.876],
											[0.129, 2.952],
											[-0.113, 5.6],
											[-0.04, 6.108],
											[0.164, 3.61],
											[0.137, 3.38],
											[0.106, 1.066],
											[0.408, 1.169],
											[0.294, 1.416],
											[0.088, 1.449],
											[0.582, 1.296],
											[2.429, 2.581],
											[0.571, 0.312],
											[1.088, 0.098],
											[2.735, 0.756],
											[1.863, 0.157],
											[1.422, -0.195],
											[1.265, -0.212],
											[3.765, 0.101],
											[3.273, 0.743],
											[0.536, -0.013],
											[0.909, -0.467],
											[1.463, -0.208],
											[1.41, 0.105],
											[1.969, -0.461],
											[1.779, -0.832],
											[1.242, -0.138],
											[1.577, -0.01],
											[1.365, 0.168],
											[1.26, 0.462],
											[3.234, -0.03],
											[3.523, -1.6],
											[-0.53, -5.798],
											[-6.512, 0.277],
											[-2.846, -0.201],
											[-3.484, -0.721],
											[-6.2, 0.123],
											[-1.347, -4.035],
											[0.848, -0.502],
											[1.954, -1.002],
											[3.605, -0.045],
											[3.983, 0.469],
											[6.936, 0.634],
											[2.503, -0.004],
											[3.189, -0.401],
											[5.673, -1.215],
											[2.522, -0.875],
											[3.135, -0.844],
											[3.814, -2.526],
											[-0.081, -2.323],
											[-1.234, -1.611],
											[-5.185, -1.841],
											[-4.531, -0.705],
											[-3.783, -0.609],
											[-9.058, -0.469],
											[-5.704, 2.166],
											[-1.15, 0.439],
											[-8.428, 2.078],
											[-9.916, -1.461],
											[-1.63, -6.54],
											[5.215, -4.416],
											[4.285, -1.169],
											[-0.496, -8.297],
											[-3.628, -1.502],
											[-4.393, -0.794],
											[-3.487, 0],
											[-5.208, -2.266],
										],
										v: [
											[2430, 1063],
											[2428.349, 1063.863],
											[2427, 1065],
											[2423.097, 1073.648],
											[2420, 1085],
											[2419.683, 1093.92],
											[2420, 1102],
											[2419.712, 1119.725],
											[2420, 1138],
											[2420.408, 1148.554],
											[2421, 1158],
											[2421.884, 1161.356],
											[2423, 1165],
											[2423.455, 1169.471],
											[2424, 1173],
											[2429.412, 1179.686],
											[2435, 1185],
											[2437.62, 1185.51],
											[2441, 1186],
											[2447.44, 1187.356],
											[2454, 1187],
											[2457.927, 1186.323],
											[2462, 1186],
											[2472.465, 1187.631],
											[2482, 1189],
											[2484.281, 1188.079],
											[2487, 1187],
											[2491.423, 1187.002],
											[2495, 1187],
											[2500.542, 1184.813],
											[2506, 1183],
											[2510.344, 1182.763],
											[2515, 1183],
											[2518.829, 1184.137],
											[2523, 1185],
											[2534.17, 1182.654],
											[2540, 1175],
											[2527.418, 1168.385],
											[2509, 1170],
											[2501, 1168],
											[2492, 1167],
											[2472, 1165],
											[2477, 1157],
											[2480, 1155],
											[2495, 1151],
											[2505, 1153],
											[2516, 1153],
											[2534, 1156],
											[2543, 1155],
											[2552, 1154],
											[2565, 1147],
											[2575, 1145],
											[2585, 1142],
											[2593, 1128],
											[2590, 1121],
											[2585, 1116],
											[2568, 1116],
											[2557, 1112],
											[2544, 1112],
											[2522, 1113],
											[2508, 1122],
											[2503, 1123],
											[2485, 1121],
											[2467, 1116],
											[2473, 1107],
											[2487, 1100],
											[2497, 1091],
											[2480, 1083],
											[2468, 1075],
											[2457, 1076],
											[2446, 1075],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 25,
								s: [
									{
										i: [
											[0.061, -0.01],
											[1.611, -1.504],
											[0.568, -1.819],
											[-0.117, -1.56],
											[0.095, -1.566],
											[0.013, -7.232],
											[0.431, -9.489],
											[0.011, -3.756],
											[-0.199, -4.543],
											[0.149, -6.025],
											[-1.296, -2.07],
											[-7.671, -0.974],
											[-3.814, 0.296],
											[-1.907, 0.315],
											[-1.019, 0.635],
											[-0.748, 1.666],
											[0.582, 1.344],
											[1.954, 0.002],
											[2.3, 0.958],
											[0.219, 2.217],
											[-4.804, 0.495],
											[-1.473, -0.53],
											[-1.532, -0.043],
											[-3.029, 0.19],
											[-1.457, 0.28],
											[-2.889, 1.825],
											[-2.916, 1.021],
											[-1.516, 0.316],
											[-1.791, 0.336],
											[-1.79, 0.061],
											[-1.427, 0.492],
											[-1.043, 0.764],
											[-0.833, 0.601],
											[-0.8, 0.802],
											[-0.178, 1.623],
											[0.711, 1.141],
											[1.37, 1.481],
											[1.23, 0.937],
											[1.147, 0.245],
											[2.472, -0.457],
											[3.207, 0.419],
											[2.839, 1.561],
											[2.752, 0.378],
											[3.085, -0.732],
											[1.271, -0.537],
											[0.845, -0.085],
											[0.762, -0.296],
											[1.109, -0.6],
											[1.869, 0.057],
											[1.355, 0.82],
											[0.015, 1.61],
											[-3.277, 0.907],
											[-1.913, 1.928],
											[0.062, 0.409],
											[-0.094, 0.208],
											[-0.451, 0.152],
											[-0.117, 0.212],
											[0.037, 2.376],
											[-0.981, 2.374],
											[-1.601, 1.139],
											[-0.771, 1.448],
											[2.082, 2.508],
											[2.563, 0.414],
											[1.216, -0.17],
											[1.119, 0.516],
											[0.636, 1.099],
											[0.899, 0.885],
											[1.614, 0.332],
											[2.927, 0.96],
											[0.852, 1.104],
											[0.882, 0.882],
										],
										o: [
											[-0.988, 0.167],
											[-1.611, 1.504],
											[-0.372, 1.193],
											[0.117, 1.56],
											[-0.516, 8.582],
											[-0.013, 7.232],
											[-0.156, 3.434],
											[-0.011, 3.756],
											[0.3, 6.831],
											[-0.149, 6.025],
											[1.504, 2.402],
											[7.67, 0.974],
											[1.354, -0.105],
											[1.907, -0.315],
											[1.591, -0.99],
											[0.748, -1.666],
											[-0.882, -2.035],
											[-1.955, -0.002],
											[-3.926, -1.635],
											[-0.22, -2.217],
											[1.578, -0.162],
											[1.473, 0.53],
											[1.427, 0.041],
											[3.029, -0.19],
											[3.529, -0.678],
											[2.889, -1.825],
											[1.144, -0.4],
											[1.516, -0.316],
											[1.412, -0.265],
											[1.79, -0.061],
											[0.994, -0.342],
											[1.043, -0.764],
											[1.622, -1.17],
											[0.8, -0.802],
											[0.215, -1.956],
											[-0.711, -1.141],
											[-0.934, -1.009],
											[-1.23, -0.937],
											[-1.907, -0.407],
											[-2.472, 0.457],
											[-2.89, -0.378],
											[-2.839, -1.561],
											[-3.387, -0.465],
											[-3.085, 0.732],
											[-0.859, 0.362],
											[-0.845, 0.085],
											[-1.695, 0.658],
											[-1.109, 0.6],
											[-3.567, -0.11],
											[-1.355, -0.82],
											[-0.022, -2.443],
											[3.277, -0.907],
											[0.155, -0.155],
											[-0.062, -0.409],
											[0.08, -0.178],
											[0.451, -0.153],
											[1.167, -2.124],
											[-0.037, -2.376],
											[0.825, -1.995],
											[1.601, -1.139],
											[1.892, -3.553],
											[-2.082, -2.508],
											[-1.017, -0.164],
											[-1.216, 0.17],
											[-1.559, -0.72],
											[-0.636, -1.099],
											[-2.308, -2.274],
											[-3.183, -0.655],
											[-1.407, -0.461],
											[-0.783, -1.014],
											[-1.079, -1.079],
										],
										v: [
											[2399, 1063],
											[2394.685, 1065.761],
											[2391, 1071],
											[2390.792, 1075.22],
											[2391, 1080],
											[2390.436, 1102.82],
											[2390, 1127],
											[2389.733, 1137.668],
											[2390, 1150],
											[2389.753, 1170.071],
											[2391, 1183],
											[2407.267, 1188.024],
											[2427, 1189],
											[2432.251, 1188.397],
											[2437, 1187],
											[2440.63, 1182.765],
											[2441, 1178],
											[2436.564, 1175.693],
											[2430, 1175],
											[2423.453, 1168.645],
											[2430, 1164],
											[2434.534, 1164.845],
											[2439, 1166],
											[2446.478, 1165.74],
											[2454, 1165],
											[2463.46, 1160.758],
											[2472, 1156],
											[2476.014, 1154.952],
											[2481, 1154],
											[2485.989, 1153.67],
											[2491, 1153],
											[2494.12, 1151.194],
											[2497, 1149],
											[2500.583, 1146.339],
											[2502, 1143],
											[2501.189, 1138.644],
											[2498, 1135],
											[2494.66, 1131.927],
											[2491, 1130],
											[2484.475, 1130.509],
											[2476, 1131],
											[2467.396, 1127.5],
											[2459, 1124],
											[2448.913, 1124.749],
											[2442, 1127],
											[2439.428, 1127.55],
											[2437, 1128],
											[2433.131, 1130.037],
											[2429, 1131],
											[2421.836, 1129.625],
											[2420, 1126],
											[2426.049, 1121.613],
											[2435, 1118],
											[2435.045, 1117.039],
											[2435, 1116],
											[2435.973, 1115.526],
											[2437, 1115],
											[2438.139, 1108.187],
											[2439, 1101],
											[2443.041, 1096.589],
											[2447, 1093],
											[2445.841, 1083.646],
											[2438, 1079],
											[2434.577, 1079.264],
											[2431, 1079],
											[2428.005, 1076.124],
											[2426, 1073],
											[2419, 1068],
											[2408, 1069],
											[2404, 1066],
											[2403, 1062],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 26,
								s: [
									{
										i: [
											[1.127, -0.861],
											[0.246, -0.161],
											[0.188, -0.196],
											[0.098, -0.312],
											[-0.023, -0.508],
											[0.243, -3.508],
											[0.349, -3.909],
											[0.182, -4.197],
											[-0.259, -4.371],
											[-0.048, -2.332],
											[0.052, -2.238],
											[0.145, -2.336],
											[-0.54, -6.152],
											[-0.22, -5.232],
											[-0.006, -3.566],
											[-0.846, -0.698],
											[-3.817, 1.086],
											[-0.933, 0.142],
											[-1.326, -0.137],
											[-1.026, 0.291],
											[-1.446, 1.018],
											[-1.572, 0.461],
											[-1.085, -0.091],
											[-0.883, 0.134],
											[-1.391, 0.582],
											[-1.596, 0.394],
											[-0.87, 0.175],
											[-0.586, 0.071],
											[-5.921, -0.528],
											[-5.139, 0.49],
											[-3.177, 1.389],
											[-1.789, 1.112],
											[-0.518, 0.098],
											[-0.478, 0.326],
											[-1.106, 1.239],
											[-1.162, 1.078],
											[-1.267, 1.003],
											[-1.637, 0.789],
											[-5.787, 2.197],
											[0.599, 5.548],
											[4.368, 0.502],
											[5.037, 0.449],
											[2.825, 0.714],
											[2.729, -0.209],
											[2.906, -1.364],
											[3.767, -0.269],
											[3.704, 0.874],
											[1.688, 0.755],
											[1.763, 0.711],
											[1.908, 0.242],
											[4.946, -0.143],
											[0.334, 4.95],
											[-5.238, 0.956],
											[-3.377, 1.294],
											[-2.012, 1.83],
											[-2.616, 1.786],
											[-3.969, 2.256],
											[1.198, 4.024],
											[1.42, 0.44],
											[1.753, 0.34],
											[0.493, 0.453],
											[0.427, 0.073],
											[6.645, -0.974],
											[5.24, 0.84],
											[2.215, 1.855],
											[0.735, 1.194],
											[1.15, 1.152],
											[0.679, 0.225],
											[2.202, -0.437],
											[1.669, 0.243],
											[1.888, 1.381],
										],
										o: [
											[-0.271, 0.207],
											[-0.246, 0.161],
											[-0.188, 0.196],
											[-0.098, 0.312],
											[0.137, 2.992],
											[-0.243, 3.508],
											[-0.349, 3.909],
											[-0.182, 4.197],
											[0.155, 2.62],
											[0.048, 2.332],
											[-0.052, 2.238],
											[-0.381, 6.162],
											[0.54, 6.151],
											[0.128, 3.037],
											[0.006, 3.566],
											[1.799, 1.485],
											[3.817, -1.085],
											[1.671, -0.255],
											[1.325, 0.137],
											[1.642, -0.466],
											[1.446, -1.018],
											[0.778, -0.228],
											[1.084, 0.091],
											[1.84, -0.28],
											[1.391, -0.582],
											[0.936, -0.231],
											[0.87, -0.175],
											[5.175, -0.624],
											[5.921, 0.528],
											[3.503, -0.333],
											[3.177, -1.389],
											[0.452, -0.281],
											[0.518, -0.098],
											[1.416, -0.965],
											[1.106, -1.239],
											[1.293, -1.199],
											[1.267, -1.003],
											[3.45, -1.662],
											[5.787, -2.197],
											[-0.49, -4.542],
											[-4.368, -0.502],
											[-3.972, -0.354],
											[-2.825, -0.714],
											[-3.607, 0.276],
											[-2.906, 1.364],
											[-3.494, 0.249],
											[-3.704, -0.874],
											[-2.039, -0.912],
											[-1.763, -0.711],
											[-4.883, -0.62],
											[-4.946, 0.143],
											[-0.329, -4.881],
											[5.238, -0.956],
											[2.334, -0.894],
											[2.013, -1.83],
											[4.321, -2.95],
											[3.969, -2.256],
											[-0.565, -1.9],
											[-1.42, -0.44],
											[-0.603, -0.117],
											[-0.493, -0.453],
											[-6.179, -1.054],
											[-6.645, 0.974],
											[-2.407, -0.386],
											[-2.215, -1.855],
											[-0.72, -1.169],
											[-1.15, -1.151],
											[-1.523, -0.505],
											[-2.202, 0.437],
											[-2.321, -0.338],
											[-1.888, -1.381],
										],
										v: [
											[2351, 1061],
											[2350.217, 1061.533],
											[2349.558, 1062.049],
											[2349.121, 1062.791],
											[2349, 1064],
											[2348.773, 1073.778],
											[2347.817, 1084.931],
											[2346.952, 1097.119],
											[2347, 1110],
											[2347.304, 1117.38],
											[2347.296, 1124.187],
											[2347, 1131],
											[2347.549, 1149.698],
											[2349, 1167],
											[2348.961, 1177.754],
											[2350, 1185],
											[2359.65, 1184.72],
											[2368, 1182],
											[2372.484, 1182.027],
											[2376, 1182],
											[2380.553, 1179.496],
											[2385, 1177],
											[2387.921, 1176.93],
											[2391, 1177],
											[2395.683, 1175.586],
											[2400, 1174],
											[2402.762, 1173.38],
											[2405, 1173],
											[2422.027, 1173.399],
											[2439, 1174],
											[2449.286, 1171.084],
											[2457, 1167],
											[2458.48, 1166.534],
											[2460, 1166],
											[2463.69, 1162.585],
											[2467, 1159],
											[2470.741, 1155.692],
											[2475, 1153],
											[2491.037, 1147.914],
											[2501, 1137],
											[2492.91, 1130.431],
											[2478, 1130],
											[2468.067, 1128.078],
											[2460, 1127],
											[2450.62, 1130.006],
											[2441, 1133],
											[2429.645, 1131.753],
											[2421, 1129],
											[2415.402, 1126.498],
											[2410, 1125],
											[2394.088, 1125.747],
											[2385, 1120],
											[2394.22, 1112.309],
											[2409, 1110],
											[2415.288, 1105.669],
											[2422, 1100],
											[2435.639, 1092.805],
											[2441, 1084],
											[2437.891, 1080.829],
											[2433, 1080],
											[2431.368, 1078.967],
											[2430, 1078],
											[2410.296, 1078.84],
											[2392, 1080],
											[2384.746, 1076.106],
											[2380, 1071],
											[2376.97, 1067.292],
											[2374, 1065],
											[2368.11, 1065.304],
											[2362, 1066],
											[2355.605, 1062.601],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 27,
								s: [
									{
										i: [
											[1.237, -0.083],
											[0.286, -0.205],
											[0.377, -0.149],
											[1.904, -1.762],
											[0.948, -1.99],
											[-0.367, -6.767],
											[1.245, -5.267],
											[1.268, -2.104],
											[0.275, -1.825],
											[-0.294, -2.515],
											[-0.123, -2.949],
											[0.07, -2.568],
											[-0.176, -2.284],
											[-0.889, -3.943],
											[-1.193, -1.975],
											[-3.004, -2.543],
											[-1.15, -0.449],
											[-1.301, -0.136],
											[-1.061, -0.169],
											[-4.195, -0.205],
											[-3.671, 0.385],
											[-4.938, 1.272],
											[-7.142, -0.624],
											[-3.55, -1.306],
											[-3.672, -0.502],
											[-5.864, -0.038],
											[-4.974, 0.64],
											[-1.409, 0.728],
											[-1.377, 0.833],
											[-0.977, 0.496],
											[-1.055, 0.389],
											[-2.394, 0.357],
											[-2.178, 0.745],
											[-1.602, 0.963],
											[-1.744, 0.515],
											[-1.206, 0.114],
											[-1.262, 0.273],
											[-4.557, -0.293],
											[-3.607, 1.157],
											[-1.962, 0.517],
											[-5.694, 3.745],
											[-0.464, 3.634],
											[1.962, 1.74],
											[6.197, -0.897],
											[3.902, -0.314],
											[16.197, -1.577],
											[4.762, -0.124],
											[4.047, 0.051],
											[2.705, 0.371],
											[7.23, 0.946],
											[6.718, 0.011],
											[-0.506, 4.016],
											[-5.05, 0.526],
											[-2.869, 0.823],
											[-3.898, 1.139],
											[-2.687, 2.646],
											[-0.658, 0.797],
											[-0.964, 0.608],
											[0.445, 12.242],
											[1.168, 0.535],
											[3.691, 1.152],
											[0.273, 0.041],
											[3.608, 1.113],
											[5.705, -0.522],
											[5.633, -0.498],
											[4.023, 1.529],
											[3.845, 1.694],
											[2.694, 0.63],
											[2.19, 0.236],
											[4.607, 1.08],
											[2.535, 1.535],
										],
										o: [
											[-0.48, 0.033],
											[-0.286, 0.205],
											[-2.435, 0.961],
											[-1.904, 1.762],
											[-2.223, 4.665],
											[0.367, 6.767],
											[-0.653, 2.76],
											[-1.268, 2.103],
											[-0.3, 1.99],
											[0.294, 2.515],
											[0.102, 2.446],
											[-0.07, 2.568],
											[0.251, 3.253],
											[0.889, 3.943],
											[0.834, 1.38],
											[3.004, 2.543],
											[0.734, 0.287],
											[1.301, 0.136],
											[3.55, 0.564],
											[4.195, 0.205],
											[5.104, -0.535],
											[4.938, -1.272],
											[3.128, 0.273],
											[3.55, 1.306],
											[3.569, 0.488],
											[5.864, 0.038],
											[1.987, -0.256],
											[1.409, -0.728],
											[1.039, -0.628],
											[0.977, -0.496],
											[2.244, -0.829],
											[2.394, -0.357],
											[1.848, -0.632],
											[1.602, -0.963],
											[0.914, -0.27],
											[1.206, -0.114],
											[5.756, -1.243],
											[5.727, 0.368],
											[2.288, -0.734],
											[8.785, -2.314],
											[5.195, -3.416],
											[0.317, -2.487],
											[-3.943, -3.497],
											[-4.777, 0.692],
											[-9.881, 0.795],
											[-4.941, 0.481],
											[-3.664, 0.095],
											[-3.131, -0.039],
											[-6.128, -0.84],
											[-9.006, -1.178],
											[-4.73, -0.007],
											[0.753, -5.975],
											[1.364, -0.142],
											[5.214, -1.497],
											[5.765, -1.684],
											[0.693, -0.683],
											[0.678, -0.821],
											[9.503, -5.994],
											[-0.093, -2.564],
											[-3.167, -1.451],
											[-1.671, -0.521],
											[-5.99, -0.905],
											[-5.604, -1.728],
											[-5.129, 0.469],
											[-4.229, 0.374],
											[-4.817, -1.831],
											[-1.807, -0.796],
											[-2.734, -0.639],
											[-7.583, -0.817],
											[-2.844, -0.666],
											[-1.229, -0.744],
										],
										v: [
											[2260, 1061],
											[2258.923, 1061.413],
											[2258, 1062],
											[2251.385, 1066.229],
											[2247, 1072],
											[2245.267, 1090.048],
											[2245, 1109],
											[2241.717, 1116.201],
											[2239, 1122],
											[2239.182, 1128.78],
											[2240, 1137],
											[2239.945, 1144.622],
											[2240, 1152],
											[2241.793, 1163.459],
											[2245, 1173],
											[2251.763, 1179.698],
											[2259, 1185],
											[2262.255, 1185.588],
											[2266, 1186],
											[2277.909, 1187.212],
											[2290, 1187],
											[2304.471, 1183.631],
											[2322, 1182],
											[2332.092, 1184.828],
											[2343, 1188],
											[2357.947, 1188.846],
											[2375, 1188],
											[2379.958, 1186.433],
											[2384, 1184],
											[2386.988, 1182.321],
											[2390, 1181],
											[2397.05, 1179.437],
											[2404, 1178],
											[2409.078, 1175.412],
											[2414, 1173],
											[2417.239, 1172.502],
											[2421, 1172],
											[2433, 1171],
											[2444, 1169],
											[2450, 1168],
											[2472, 1159],
											[2480, 1151],
											[2476, 1141],
											[2458, 1138],
											[2446, 1142],
											[2407, 1137],
											[2393, 1140],
											[2381, 1138],
											[2373, 1139],
											[2355, 1133],
											[2331, 1135],
											[2319, 1131],
											[2344, 1129],
											[2350, 1127],
											[2363, 1124],
											[2373, 1119],
											[2374, 1117],
											[2377, 1116],
											[2411, 1094],
											[2404, 1084],
											[2383, 1082],
											[2380, 1082],
											[2367, 1079],
											[2348, 1076],
											[2333, 1081],
											[2318, 1079],
											[2306, 1070],
											[2298, 1069],
											[2291, 1067],
											[2271, 1067],
											[2261, 1061],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 28,
								s: [
									{
										i: [
											[5.344, -0.769],
											[9.804, -0.266],
											[11.815, 0.277],
											[4.905, -0.374],
											[3.376, -1.991],
											[2.424, -4.895],
											[0.883, -2.073],
											[2.491, -7.447],
											[0.557, -3.786],
											[-1.867, -8.04],
											[-1.19, -2.801],
											[-2.186, -2.348],
											[-0.647, -0.625],
											[-0.607, -0.464],
											[-0.908, -0.604],
											[-4.623, -0.672],
											[-5.603, 0.389],
											[-2.691, 0.33],
											[-2.715, 0.112],
											[-3.01, -0.139],
											[-2.881, -0.417],
											[-3.844, -1.197],
											[-3.805, -0.423],
											[-4.959, 0.252],
											[-4.242, 0.645],
											[-5.468, 0.827],
											[-5.291, -1.456],
											[-1.438, -0.433],
											[-5.426, -1.838],
											[-1.266, -1.213],
											[-1.537, -0.192],
											[-10.454, 1.648],
											[-2.146, 0.374],
											[-1.244, 0.23],
											[-6.202, 1.81],
											[-12.074, 1.301],
											[-7.957, 0.847],
											[-5.993, 2.399],
											[-0.932, 0.563],
											[3.89, 8.22],
											[8.252, 0.384],
											[3.823, 1.37],
											[4.418, 2.762],
											[3.465, 1.284],
											[1.642, 0.272],
											[9.151, -1.983],
											[3.993, -1.576],
											[2.906, -0.955],
											[6.261, 0.117],
											[4.473, 1.536],
											[2.504, 0.717],
											[3.289, -0.107],
											[6.925, -0.383],
											[2.447, 0.978],
											[0.535, 2.994],
											[-0.936, 1.312],
											[-3.269, 1.424],
											[-4.056, 0.513],
											[-3.198, 0.391],
											[-1.215, 8.711],
											[1.21, 1.304],
											[3.898, 1.649],
											[2.208, 0.39],
											[1.583, -0.054],
											[6.366, 0.611],
											[8.598, 1.473],
											[10.439, 0.174],
											[1.784, 0.459],
											[1.987, 1.138],
											[0.793, 0.375],
											[0.469, 0.221],
										],
										o: [
											[-6.199, 0.893],
											[-10.831, 0.294],
											[-3.922, -0.092],
											[-5.495, 0.419],
											[-3.658, 2.158],
											[-1.18, 2.383],
											[-3.335, 7.83],
											[-1.88, 5.619],
											[-1.413, 9.598],
											[0.835, 3.597],
											[1.675, 3.944],
											[0.615, 0.66],
											[0.588, 0.568],
											[0.743, 0.568],
											[4.722, 3.142],
											[5.369, 0.78],
											[2.884, -0.2],
											[2.861, -0.351],
											[2.809, -0.116],
											[3.481, 0.161],
											[3.604, 0.521],
											[3.942, 1.228],
											[4.608, 0.512],
											[5.184, -0.264],
											[6.571, -1],
											[9.746, -1.474],
											[2.058, 0.566],
											[5.286, 1.59],
											[3.342, 1.132],
											[1.343, 1.287],
											[15.979, 1.995],
											[2.241, -0.353],
											[1.122, -0.195],
											[6.913, -1.279],
											[13.415, -3.916],
											[7.289, -0.785],
											[10.008, -1.066],
											[1.533, -0.614],
											[6.859, -4.145],
											[-3.262, -6.892],
											[-7.037, -0.328],
											[-6.249, -2.239],
											[-4.252, -2.658],
											[-1.1, -0.407],
											[-11.675, -1.937],
											[-5.507, 1.193],
											[-2.865, 1.131],
											[-5.141, 1.689],
											[-5.337, -0.1],
											[-2.208, -0.759],
											[-2.985, -0.855],
											[-5.503, 0.179],
											[-2.999, 0.166],
											[-3.353, -1.34],
											[-0.609, -3.405],
											[2.083, -2.92],
											[4.955, -2.159],
											[3.967, -0.502],
											[9.11, -1.113],
											[0.619, -4.438],
											[-1.583, -1.706],
											[-3.164, -1.339],
											[-0.207, -0.037],
											[-7.443, 0.254],
											[-10.257, -0.985],
											[-11.932, -2.045],
											[-2.319, -0.039],
											[-3.891, -1],
											[-0.758, -0.434],
											[-0.385, -0.182],
											[-5.737, -2.71],
										],
										v: [
											[2072, 1061],
											[2047, 1067],
											[2014, 1061],
											[1999, 1062],
											[1985, 1064],
											[1978, 1076],
											[1974, 1082],
											[1967, 1104],
											[1962, 1119],
											[1966, 1151],
											[1968, 1161],
											[1977, 1173],
											[1978, 1176],
											[1981, 1177],
											[1983, 1179],
											[1999, 1187],
											[2016, 1186],
											[2025, 1186],
											[2033, 1184],
											[2042, 1185],
											[2052, 1185],
											[2063, 1189],
											[2075, 1192],
											[2089, 1191],
											[2104, 1191],
											[2121, 1185],
											[2152, 1186],
											[2157, 1187],
											[2172, 1194],
											[2182, 1196],
											[2188, 1198],
											[2233, 1196],
											[2239, 1196],
											[2243, 1194],
											[2263, 1190],
											[2300, 1178],
											[2323, 1177],
											[2349, 1172],
											[2354, 1170],
											[2358, 1151],
											[2341, 1142],
											[2326, 1140],
											[2311, 1131],
											[2300, 1123],
											[2294, 1122],
											[2256, 1123],
											[2244, 1127],
											[2236, 1130],
											[2219, 1134],
											[2205, 1130],
											[2198, 1128],
											[2190, 1126],
											[2174, 1131],
											[2164, 1130],
											[2159, 1124],
											[2160, 1117],
											[2167, 1115],
											[2181, 1111],
											[2192, 1111],
											[2212, 1096],
											[2209, 1082],
											[2200, 1079],
											[2192, 1074],
											[2187, 1075],
											[2169, 1076],
											[2145, 1067],
											[2112, 1071],
											[2104, 1070],
											[2097, 1065],
											[2094, 1065],
											[2093, 1063],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 29,
								s: [
									{
										i: [
											[1.437, -0.538],
											[1.549, -1.152],
											[0.58, -0.933],
											[0.267, -1.689],
											[0.428, -1.428],
											[0.576, -1.47],
											[0.185, -1.421],
											[2.228, -6.648],
											[2.009, -2.733],
											[2.325, -7.819],
											[-3.598, -9.327],
											[-2.699, -3.103],
											[-1.917, -1.425],
											[-1.405, -0.773],
											[-1.991, -0.753],
											[-7.432, 1.239],
											[-1.634, 0.236],
											[-3.936, 0.498],
											[-1.15, 0.192],
											[-1.854, 0.289],
											[-13.73, -2.386],
											[-3.814, -1.891],
											[-1.599, -0.65],
											[-1.375, -0.471],
											[-3.011, -0.728],
											[-8.18, 0.79],
											[-1.951, 0.387],
											[-4.514, 1.2],
											[-2.813, 0.323],
											[-13.249, 0.034],
											[-3.876, 0.211],
											[-5.629, 0.96],
											[-1.82, 0.58],
											[-1.027, 0.507],
											[-1.194, 1.023],
											[-0.862, 0.962],
											[-0.318, 0.537],
											[0.556, 3.434],
											[6.127, 0.439],
											[4.484, -1.019],
											[10.062, 1.252],
											[8.119, -1.188],
											[7.078, -1.083],
											[4.54, 0.563],
											[1.173, 3.725],
											[-5.668, 1.83],
											[-1.627, 0.99],
											[-1.445, 1.958],
											[-1.582, 1.38],
											[-1.898, 1.307],
											[-0.958, 0.858],
											[-0.971, 0.843],
											[-0.891, 0.641],
											[-1.184, 0.987],
											[-0.741, 5.042],
											[3.212, 3.393],
											[3.258, 0.745],
											[7.549, -2.699],
											[5.555, -0.562],
											[4.086, 1.659],
											[6.787, 1.539],
											[5.127, -0.216],
											[6.39, 3.065],
											[2.177, 1.533],
											[0.629, 0.387],
											[0.736, 0.468],
											[2.946, 1.68],
											[0.522, 0.247],
											[0.732, 0.446],
											[2.578, 0.504],
											[1.946, -0.112],
										],
										o: [
											[-1.226, 0.458],
											[-1.549, 1.152],
											[-0.506, 0.815],
											[-0.266, 1.689],
											[-0.509, 1.699],
											[-0.577, 1.47],
											[-1.093, 8.388],
											[-1.016, 3.033],
											[-4.695, 6.387],
											[-2.997, 10.078],
											[1.765, 4.575],
											[0.921, 1.059],
											[1.585, 1.179],
											[1.525, 0.839],
											[6.677, 2.526],
											[1.692, -0.282],
											[3.925, -0.568],
											[1.317, -0.167],
											[2.1, -0.35],
											[11.405, -1.78],
											[5.476, 0.952],
											[1.374, 0.681],
											[1.627, 0.662],
											[2.506, 0.858],
											[6.941, 1.678],
											[1.926, -0.186],
											[5.656, -1.12],
											[2.441, -0.649],
											[12.322, -1.414],
											[3.582, -0.009],
											[6.348, -0.345],
											[0.703, -0.12],
											[1.244, -0.397],
											[1.911, -0.943],
											[1.151, -0.987],
											[0.491, -0.548],
											[2.277, -3.844],
											[-0.907, -5.608],
											[-5.691, -0.408],
											[-9.155, 2.08],
											[-7.368, -0.917],
											[-4.737, 0.693],
											[-5.06, 0.774],
											[-6.542, -0.811],
											[-3.229, -10.254],
											[3.412, -1.102],
											[2.762, -1.681],
											[1.565, -2.12],
											[2.242, -1.956],
											[0.951, -0.655],
											[0.809, -0.725],
											[0.823, -0.714],
											[1.426, -1.027],
											[3.683, -3.072],
											[1, -6.811],
											[-2.13, -2.25],
											[-12.824, -2.934],
											[-5.052, 1.806],
											[-7.374, 0.745],
											[-4.616, -1.874],
											[-4.393, -0.996],
											[-12.686, 0.535],
											[-3.231, -1.55],
											[-0.647, -0.455],
											[-0.926, -0.57],
											[-2.534, -1.609],
											[-0.436, -0.249],
											[-0.78, -0.369],
											[-2.402, -1.465],
											[-2.474, -0.484],
											[-4.236, 0.243],
										],
										v: [
											[1728, 1062],
											[1723.515, 1064.644],
											[1720, 1068],
											[1718.941, 1072.04],
											[1718, 1077],
											[1716.257, 1081.708],
											[1715, 1086],
											[1714, 1111],
											[1708, 1119],
											[1698, 1138],
											[1700, 1163],
											[1706, 1176],
											[1711, 1179],
											[1715, 1183],
											[1721, 1185],
											[1743, 1186],
											[1747, 1184],
											[1759, 1184],
											[1763, 1182],
											[1769, 1182],
											[1811, 1177],
											[1823, 1183],
											[1828, 1184],
											[1832, 1187],
											[1841, 1189],
											[1865, 1191],
											[1871, 1189],
											[1886, 1187],
											[1893, 1185],
											[1931, 1187],
											[1942, 1186],
											[1961, 1186],
											[1965, 1184],
											[1970, 1183],
											[1974, 1179],
											[1978, 1177],
											[1979, 1174],
											[1984, 1164],
											[1967, 1154],
											[1951, 1157],
											[1931, 1154],
											[1904, 1154],
											[1887, 1160],
											[1870, 1160],
											[1858, 1154],
											[1874, 1138],
											[1883, 1136],
											[1887, 1130],
											[1892, 1125],
											[1899, 1121],
											[1903, 1119],
											[1905, 1115],
											[1907, 1112],
											[1911, 1110],
											[1916, 1103],
											[1911, 1091],
											[1901, 1084],
											[1871, 1089],
											[1855, 1094],
											[1832, 1092],
											[1817, 1080],
											[1802, 1081],
											[1770, 1078],
											[1763, 1073],
											[1761, 1072],
											[1759, 1070],
											[1750, 1066],
											[1749, 1064],
											[1746, 1064],
											[1742, 1061],
											[1736, 1062],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 30,
								s: [
									{
										i: [
											[1.841, -0.506],
											[1.59, -1.023],
											[1.161, -1.501],
											[1.132, -2.841],
											[1.318, -3.143],
											[0.434, -0.587],
											[0.137, -0.354],
											[1.043, -3.297],
											[1.621, -1.693],
											[0.298, -0.539],
											[0.408, -0.425],
											[0.397, -0.923],
											[0.396, -1.246],
											[0.199, -2.131],
											[0.376, -1.869],
											[0.646, -2.059],
											[-0.068, -2.357],
											[-0.606, -1.39],
											[-0.53, -1.809],
											[-0.211, -1.913],
											[-0.613, -1.494],
											[-0.94, -1.159],
											[-0.805, -1.309],
											[-1.563, -2.237],
											[-1.948, -2.125],
											[-0.597, -0.836],
											[-0.639, -0.595],
											[-0.516, -0.073],
											[-0.52, -0.321],
											[-6.363, -0.741],
											[-4.185, 1.655],
											[-3.941, 1.209],
											[-3.898, -0.155],
											[-4.145, -1.587],
											[-4.62, -0.501],
											[-6.761, 0.472],
											[-3.63, 0.583],
											[-3.482, 0.47],
											[-3.146, 0.49],
											[-1.635, 0.378],
											[-1.739, 0.222],
											[-7.919, 0.095],
											[-7.514, 0.626],
											[-1.535, 0.408],
											[-1.705, 0.271],
											[-3.047, 0.529],
											[-2.19, 1.309],
											[-0.059, 0.183],
											[4.545, 1.716],
											[8.454, 1.009],
											[7.482, 1.534],
											[7.653, -0.24],
											[7.503, 6.02],
											[-0.163, 3.601],
											[-5.515, 3.507],
											[-2.064, 2.631],
											[0.894, 5.382],
											[5.841, 1.511],
											[5.785, 1.945],
											[0.494, 0.253],
											[1.252, 0.799],
											[1.619, 1.142],
											[0.5, 0.225],
											[0.295, 0.246],
											[3.561, 0.602],
											[6.657, -0.024],
											[3.519, 0.854],
											[3.02, 1.189],
											[2.803, 1.32],
											[0.457, 0.229],
											[1.118, 0.255],
										],
										o: [
											[-1.48, 0.407],
											[-1.59, 1.023],
											[-1.155, 1.493],
											[-1.132, 2.841],
											[-0.125, 0.298],
											[-0.435, 0.587],
											[-1.208, 3.12],
											[-1.043, 3.297],
											[-0.401, 0.419],
											[-0.298, 0.539],
											[-1.017, 1.062],
											[-0.397, 0.923],
											[-0.829, 2.605],
											[-0.199, 2.131],
											[-0.484, 2.406],
											[-0.646, 2.059],
											[0.047, 1.631],
											[0.606, 1.39],
											[0.543, 1.856],
											[0.211, 1.913],
											[0.434, 1.055],
											[0.94, 1.159],
											[1.8, 2.928],
											[1.563, 2.237],
											[0.975, 1.063],
											[0.597, 0.836],
											[0.414, 0.386],
											[0.516, 0.073],
											[4.364, 2.69],
											[6.363, 0.741],
											[3.34, -1.321],
											[3.941, -1.209],
											[3.802, 0.151],
											[4.145, 1.587],
											[4.324, 0.47],
											[6.762, -0.472],
											[3.924, -0.63],
											[3.482, -0.47],
											[1.72, -0.267],
											[1.635, -0.378],
											[7.809, -0.995],
											[7.919, -0.095],
											[1.155, -0.096],
											[1.535, -0.408],
											[3.622, -0.577],
											[3.047, -0.529],
											[1.422, -0.85],
											[3.304, -10.22],
											[-10.097, -3.812],
											[-8.686, -1.037],
											[-4.803, -0.985],
											[-14.056, 0.441],
											[-3.044, -2.443],
											[0.298, -6.575],
											[2.772, -1.763],
											[2.147, -2.737],
											[-0.64, -3.858],
											[-6.199, -1.604],
											[-0.387, -0.13],
											[-1.367, -0.7],
											[-1.999, -1.276],
											[-0.441, -0.311],
											[-0.274, -0.124],
											[-2.75, -2.292],
											[-7.85, -1.326],
											[-3.594, 0.013],
											[-3.991, -0.968],
											[-3.689, -1.452],
											[-0.38, -0.179],
											[-1.013, -0.506],
											[-3.291, -0.75],
										],
										v: [
											[1636, 1056],
											[1631.261, 1058.18],
											[1627, 1062],
											[1623.623, 1068.762],
											[1620, 1078],
											[1619.009, 1079.458],
											[1618, 1081],
											[1614.81, 1091.071],
											[1611, 1099],
											[1610.005, 1100.496],
											[1609, 1102],
											[1607.034, 1104.862],
											[1606, 1108],
											[1604.661, 1115.052],
											[1604, 1121],
											[1602.086, 1127.537],
											[1601, 1134],
											[1602.138, 1138.367],
											[1604, 1143],
											[1604.948, 1148.771],
											[1606, 1154],
											[1608.221, 1157.31],
											[1611, 1161],
											[1615.889, 1168.602],
											[1621, 1175],
											[1623.252, 1177.851],
											[1625, 1180],
											[1626.421, 1180.549],
											[1628, 1181],
											[1645.134, 1186.258],
											[1662, 1185],
											[1673.082, 1180.893],
											[1685, 1179],
											[1696.886, 1182.238],
											[1710, 1186],
											[1728.021, 1185.79],
											[1745, 1184],
											[1756.083, 1182.394],
											[1766, 1181],
											[1770.986, 1179.966],
											[1776, 1179],
											[1799.722, 1177.723],
											[1823, 1177],
											[1827.088, 1176.132],
											[1832, 1175],
											[1842.074, 1173.55],
											[1850, 1171],
											[1856, 1165],
											[1847, 1144],
											[1817, 1145],
											[1795, 1134],
											[1774, 1134],
											[1742, 1130],
											[1736, 1120],
											[1749, 1107],
											[1757, 1102],
											[1760, 1092],
											[1748, 1082],
											[1727, 1080],
											[1725, 1078],
											[1721, 1077],
											[1716, 1072],
											[1714, 1072],
											[1713, 1070],
											[1703, 1065],
											[1680, 1068],
											[1669, 1066],
											[1658, 1063],
											[1649, 1058],
											[1648, 1056],
											[1644, 1056],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[0.99, -0.033],
											[3.078, -1.165],
											[1.825, -2.277],
											[0.465, -1.216],
											[0.486, -1.321],
											[0.283, -1.433],
											[0.396, -0.986],
											[1.12, -1.844],
											[0.81, -1.856],
											[0.971, -2.224],
											[0.16, -1.916],
											[-0.24, -1.137],
											[-0.088, -0.894],
											[-0.549, -3.233],
											[0.525, -3.705],
											[2.197, -4.756],
											[0.677, -5.19],
											[0.092, -3.059],
											[-0.637, -1.715],
											[-1.516, -1.119],
											[-1.715, -0.922],
											[-3.606, -1.067],
											[-3.479, -1.066],
											[-4.802, -0.217],
											[-4.226, 1.267],
											[-7.642, -0.839],
											[-8.653, 1.323],
											[-1.392, 0.618],
											[-1.324, 0.36],
											[-1.716, 0.253],
											[-1.351, 0.504],
											[-0.376, 0.449],
											[-0.278, 0.121],
											[-0.65, 0.068],
											[-0.592, 0.374],
											[-0.934, 0.928],
											[-0.204, 0.62],
											[0.309, 3.131],
											[0.171, 0.234],
											[1.441, 0.046],
											[2.548, 0.876],
											[1.1, 0.804],
											[1.13, 0.28],
											[1.698, -0.016],
											[1.376, 0.61],
											[1.871, 1.716],
											[0.437, 0.659],
											[0.349, 0.284],
											[0.292, 0.389],
											[-0.355, 1.331],
											[-1.269, 1.423],
											[-1.29, 0.886],
											[-1.509, 0.961],
											[-0.74, 0.716],
											[-0.53, 1.176],
											[-0.451, 0.155],
											[-0.119, 0.207],
											[-0.327, 2.245],
											[0.809, 2.393],
											[2.969, 0.916],
											[4.589, 1.803],
											[1.629, 0.917],
											[1.53, 0.959],
											[1.61, 1.59],
											[0.673, 1.457],
											[0.383, 0.924],
											[1.59, 2.064],
											[0.343, 0.211],
											[1.957, 1.077],
											[3.864, 2.282],
											[2.217, 1.594],
										],
										o: [
											[-1.862, 0.063],
											[-3.078, 1.165],
											[-0.653, 0.814],
											[-0.465, 1.216],
											[-0.472, 1.283],
											[-0.283, 1.433],
											[-0.71, 1.766],
											[-1.12, 1.844],
											[-0.955, 2.187],
											[-0.971, 2.224],
											[-0.046, 0.557],
											[0.24, 1.138],
											[0.33, 3.365],
											[0.549, 3.233],
											[-0.537, 3.787],
											[-2.197, 4.756],
											[-0.267, 2.049],
											[-0.092, 3.059],
											[0.223, 0.601],
											[1.516, 1.119],
											[3.099, 1.667],
											[3.606, 1.067],
											[4.564, 1.398],
											[4.802, 0.217],
											[6.778, -2.033],
											[7.642, 0.839],
											[1.107, -0.169],
											[1.392, -0.618],
											[1.784, -0.485],
											[1.716, -0.253],
											[0.22, -0.082],
											[0.376, -0.449],
											[0.806, -0.353],
											[0.65, -0.068],
											[1.061, -0.669],
											[0.934, -0.928],
											[0.408, -1.243],
											[-0.309, -3.131],
											[-0.688, -0.941],
											[-1.441, -0.046],
											[-1.469, -0.505],
											[-1.1, -0.804],
											[-1.833, -0.455],
											[-1.698, 0.016],
											[-1.08, -0.478],
											[-1.871, -1.716],
											[-0.314, -0.473],
											[-0.349, -0.284],
											[-1.689, -2.252],
											[0.355, -1.331],
											[1.332, -1.494],
											[1.29, -0.886],
											[1.508, -0.96],
											[0.74, -0.716],
											[0.079, -0.175],
											[0.451, -0.155],
											[1.503, -2.626],
											[0.327, -2.245],
											[-1.534, -4.534],
											[-2.969, -0.916],
											[-0.953, -0.374],
											[-1.629, -0.917],
											[-1.887, -1.182],
											[-1.61, -1.59],
											[-0.517, -1.119],
											[-1.056, -2.548],
											[-0.241, -0.313],
											[-2.458, -1.513],
											[-3.621, -1.993],
											[-3.124, -1.845],
											[-2.489, -1.789],
										],
										v: [
											[1591, 1057],
											[1582.972, 1058.839],
											[1575, 1064],
											[1573.374, 1067.121],
											[1572, 1071],
											[1570.944, 1075.222],
											[1570, 1079],
											[1567.075, 1084.432],
											[1564, 1090],
											[1560.904, 1096.704],
											[1559, 1103],
											[1559.4, 1105.747],
											[1560, 1109],
											[1561.641, 1118.746],
											[1562, 1129],
											[1557.105, 1141.948],
											[1552, 1157],
											[1551.322, 1165.251],
											[1552, 1173],
											[1554.881, 1175.76],
											[1560, 1179],
											[1570.214, 1182.95],
											[1581, 1186],
											[1595.254, 1188.499],
											[1609, 1187],
											[1630.594, 1186.468],
											[1655, 1187],
											[1658.837, 1185.643],
											[1663, 1184],
											[1668.325, 1183.015],
											[1673, 1182],
											[1673.957, 1181.03],
											[1675, 1180],
											[1677.161, 1179.515],
											[1679, 1179],
											[1682.143, 1176.463],
											[1684, 1174],
											[1683.934, 1166.243],
											[1683, 1160],
											[1679.895, 1158.952],
											[1674, 1158],
											[1670.246, 1155.832],
											[1667, 1154],
											[1661.658, 1153.616],
											[1657, 1153],
											[1652.018, 1149.135],
											[1648, 1145],
											[1646.983, 1143.937],
											[1646, 1143],
											[1644.282, 1137.878],
											[1647, 1134],
											[1650.868, 1130.6],
											[1655, 1128],
											[1658.234, 1125.662],
											[1660, 1123],
											[1660.97, 1122.524],
											[1662, 1122],
											[1664.734, 1114.825],
											[1664, 1108],
											[1657.292, 1100.952],
											[1646, 1098],
											[1641.933, 1095.938],
											[1637, 1093],
											[1631.589, 1088.706],
											[1628, 1084],
											[1626, 1081],
											[1624, 1074],
											[1622, 1073],
											[1616, 1068],
											[1604, 1064],
											[1597, 1057],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[0.377, -0.209],
											[0.288, -0.042],
											[0.277, -0.027],
											[0.226, -0.096],
											[0.134, -0.251],
											[0.204, -0.961],
											[0.11, -1.082],
											[0.101, -1.08],
											[0.176, -0.955],
											[0.266, -1.056],
											[0.284, -1.018],
											[0.251, -0.952],
											[0.166, -0.86],
											[0.072, -1.027],
											[0.025, -0.996],
											[0.066, -0.971],
											[0.193, -0.952],
											[0.366, -0.973],
											[0.414, -0.992],
											[0.341, -1.023],
											[0.146, -1.066],
											[-0.045, -1.393],
											[-0.054, -1.535],
											[0.135, -1.599],
											[0.643, -2.518],
											[0.228, -2.559],
											[-0.099, -1.878],
											[0.246, -1.794],
											[0.429, -1.36],
											[0.115, -1.333],
											[0.021, -2.244],
											[-0.268, -1.887],
											[-0.636, -0.996],
											[-0.311, -0.979],
											[-0.077, -1.203],
											[-0.983, -0.955],
											[-6.481, 0.409],
											[-3.88, 0.468],
											[-2.61, 0.216],
											[-1.936, 0.699],
											[-2.469, 2.23],
											[-2.045, 0.967],
											[-1.58, 0.213],
											[-1.486, 0.583],
											[-1.275, 1.884],
											[0.798, 3.404],
											[3.806, 2.001],
											[0.917, 4.339],
											[-0.336, 2.24],
											[0.086, -0.169],
											[-2.231, 0.866],
											[-1.064, 1.553],
											[2.309, 2.669],
											[1.456, 1.394],
											[0.479, 2.534],
											[1.072, 2.935],
											[0.448, 0.383],
											[0.133, 0.26],
											[0.95, 1.338],
											[2.073, 1.447],
											[1.2, 0.582],
											[0.914, 0.774],
											[1.321, 2.051],
											[1.077, 1.409],
											[0.38, 0.358],
											[0.267, 0.289],
											[1.444, 0.156],
											[4.816, 0.774],
											[0.584, 0.468],
											[0.439, 0.091],
											[1.247, -0.283],
										],
										o: [
											[-0.258, 0.143],
											[-0.288, 0.042],
											[-0.277, 0.027],
											[-0.226, 0.096],
											[-0.383, 0.718],
											[-0.204, 0.961],
											[-0.11, 1.082],
											[-0.101, 1.08],
											[-0.197, 1.068],
											[-0.266, 1.056],
											[-0.284, 1.018],
											[-0.251, 0.952],
											[-0.206, 1.063],
											[-0.072, 1.027],
											[-0.025, 0.996],
											[-0.066, 0.971],
											[-0.196, 0.965],
											[-0.366, 0.973],
											[-0.414, 0.992],
											[-0.341, 1.023],
											[-0.161, 1.172],
											[0.045, 1.393],
											[0.054, 1.535],
											[-0.2, 2.368],
											[-0.643, 2.518],
											[-0.151, 1.695],
											[0.099, 1.878],
											[-0.168, 1.226],
											[-0.429, 1.36],
											[-0.184, 2.139],
											[-0.021, 2.244],
											[0.147, 1.037],
											[0.636, 0.996],
											[0.708, 2.232],
											[0.077, 1.203],
											[3.195, 3.102],
											[6.481, -0.409],
											[3.626, -0.437],
											[2.61, -0.216],
											[3.081, -1.113],
											[2.469, -2.23],
											[1.194, -0.565],
											[1.58, -0.213],
											[2.699, -1.058],
											[1.275, -1.885],
											[-0.858, -3.656],
											[-3.806, -2.001],
											[-0.255, -1.208],
											[0.335, -2.24],
											[1.014, -1.984],
											[2.231, -0.866],
											[2.691, -3.928],
											[-2.309, -2.669],
											[-2.013, -1.928],
											[-0.479, -2.534],
											[-0.077, -0.209],
											[-0.448, -0.383],
											[-1.126, -2.199],
											[-0.95, -1.339],
											[-1.285, -0.898],
											[-1.2, -0.582],
											[-1.638, -1.387],
											[-1.321, -2.051],
											[-0.213, -0.279],
											[-0.38, -0.358],
											[-2.407, -2.604],
											[-1.444, -0.156],
											[-0.224, -0.036],
											[-0.584, -0.468],
											[-1.635, -0.341],
											[-1.247, 0.283],
										],
										v: [
											[1564, 1063],
											[1563.172, 1063.257],
											[1562.314, 1063.339],
											[1561.549, 1063.501],
											[1561, 1064],
											[1560.139, 1066.55],
											[1559.688, 1069.645],
											[1559.393, 1072.918],
											[1559, 1076],
											[1558.293, 1079.194],
											[1557.455, 1082.312],
											[1556.639, 1085.274],
											[1556, 1088],
											[1555.605, 1091.133],
											[1555.482, 1094.166],
											[1555.367, 1097.116],
											[1555, 1100],
											[1554.127, 1102.903],
											[1552.925, 1105.847],
											[1551.762, 1108.868],
											[1551, 1112],
											[1550.875, 1115.868],
											[1551.073, 1120.28],
											[1551, 1125],
											[1549.521, 1132.357],
											[1548, 1140],
											[1548.071, 1145.425],
											[1548, 1151],
											[1546.96, 1154.92],
											[1546, 1159],
											[1545.661, 1165.689],
											[1546, 1172],
											[1547.377, 1175.044],
											[1549, 1178],
											[1549.794, 1182.958],
											[1551, 1186],
											[1566.986, 1189.177],
											[1584, 1187],
											[1593.267, 1186.197],
											[1600, 1185],
											[1608.277, 1179.391],
											[1615, 1174],
											[1619.281, 1173.014],
											[1624, 1172],
											[1630.123, 1167.76],
											[1631, 1160],
											[1622.545, 1152.512],
											[1614, 1144],
											[1614.373, 1137.967],
											[1615, 1134],
											[1620.463, 1130.176],
											[1626, 1127],
											[1625.11, 1117.1],
											[1618, 1111],
											[1614.794, 1104.255],
											[1613, 1096],
											[1612.042, 1095.038],
											[1611, 1094],
											[1608.211, 1088.936],
											[1604, 1085],
											[1600.222, 1082.908],
											[1597, 1081],
											[1592.58, 1075.517],
											[1589, 1070],
											[1588.041, 1069.007],
											[1587, 1068],
											[1582.306, 1064.628],
											[1574, 1064],
											[1572.662, 1063.042],
											[1571, 1062],
											[1566.557, 1062.088],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[-0.731, -1.929],
											[-1.579, 0.174],
											[-1.568, 0.133],
											[-1.455, 0.268],
											[-1.24, 0.578],
											[-0.247, 0.242],
											[-0.287, 0.315],
											[-0.271, 0.291],
											[-0.2, 0.168],
											[-0.566, 0.483],
											[-0.493, 0.469],
											[-0.431, 0.501],
											[-0.379, 0.579],
											[-0.481, 0.593],
											[-0.474, 0.376],
											[-0.631, 0.345],
											[-0.951, 0.501],
											[-0.865, 0.766],
											[-0.534, 0.919],
											[-0.402, 1.194],
											[-0.468, 1.588],
											[-0.084, 0.391],
											[-0.069, 0.4],
											[-0.076, 0.434],
											[-0.104, 0.492],
											[-0.076, 0.567],
											[-0.075, 0.587],
											[-0.087, 0.477],
											[-0.111, 0.236],
											[-0.468, 0.525],
											[-0.543, 0.44],
											[-0.554, 0.445],
											[-0.501, 0.539],
											[-0.536, 0.607],
											[-0.452, 0.642],
											[-0.315, 0.714],
											[-0.124, 0.823],
											[0.081, 1.011],
											[0.193, 1.225],
											[0.265, 1.102],
											[0.299, 0.642],
											[0.498, 0.587],
											[0.574, 0.504],
											[0.54, 0.415],
											[0.393, 0.321],
											[0.37, 0.445],
											[0.271, 0.422],
											[0.266, 0.392],
											[0.352, 0.352],
											[0.362, 0.072],
											[0.366, 0.07],
											[0.249, 0.228],
											[0.679, 0.902],
											[0.409, 0.591],
											[0.891, 2.796],
											[1.092, 1.876],
											[2.175, 2.028],
											[1.403, 2.347],
											[0.022, 1.071],
											[0.378, 0.823],
											[0.451, 0.17],
											[0.126, 0.168],
											[0.087, 0.54],
											[0.319, 0.394],
											[2.46, 0.691],
											[0.277, -3.088],
											[-0.356, -12.016],
											[0.555, -12.233],
											[-0.005, -7.115],
											[0.673, -5.921],
											[0.623, -1.744],
										],
										o: [
											[1.488, -0.389],
											[1.579, -0.174],
											[1.568, -0.133],
											[1.455, -0.268],
											[0.153, -0.071],
											[0.247, -0.242],
											[0.287, -0.315],
											[0.271, -0.291],
											[0.649, -0.544],
											[0.566, -0.483],
											[0.493, -0.469],
											[0.431, -0.501],
											[0.652, -0.996],
											[0.481, -0.593],
											[0.474, -0.376],
											[0.631, -0.345],
											[1.394, -0.734],
											[0.865, -0.766],
											[0.534, -0.92],
											[0.402, -1.194],
											[0.12, -0.408],
											[0.084, -0.391],
											[0.069, -0.4],
											[0.076, -0.433],
											[0.088, -0.417],
											[0.076, -0.567],
											[0.075, -0.587],
											[0.087, -0.477],
											[0.329, -0.7],
											[0.468, -0.525],
											[0.543, -0.44],
											[0.554, -0.445],
											[0.567, -0.611],
											[0.536, -0.607],
											[0.452, -0.642],
											[0.315, -0.714],
											[0.069, -0.46],
											[-0.081, -1.011],
											[-0.193, -1.225],
											[-0.265, -1.102],
											[-0.309, -0.664],
											[-0.498, -0.587],
											[-0.574, -0.504],
											[-0.54, -0.415],
											[-0.562, -0.458],
											[-0.37, -0.445],
											[-0.271, -0.422],
											[-0.266, -0.392],
											[-0.234, -0.234],
											[-0.362, -0.072],
											[-0.366, -0.07],
											[-0.874, -0.8],
											[-0.679, -0.902],
											[-1.345, -1.939],
											[-0.891, -2.796],
											[-0.898, -1.542],
											[-2.175, -2.028],
											[-0.534, -0.894],
											[-0.022, -1.071],
											[-0.07, -0.153],
											[-0.451, -0.17],
											[-0.334, -0.446],
											[-0.087, -0.54],
											[-1.885, -2.324],
											[-2.459, -0.691],
											[-0.87, 9.702],
											[0.356, 12.016],
											[-0.346, 7.619],
											[0.005, 7.115],
											[-0.238, 2.097],
											[-0.623, 1.744],
										],
										v: [
											[1533, 1188],
											[1537.626, 1187.2],
											[1542.372, 1186.783],
											[1546.932, 1186.225],
											[1551, 1185],
											[1551.614, 1184.506],
											[1552.429, 1183.645],
											[1553.279, 1182.713],
											[1554, 1182],
											[1555.819, 1180.471],
											[1557.404, 1179.053],
											[1558.787, 1177.609],
											[1560, 1176],
											[1561.659, 1173.663],
											[1563.051, 1172.257],
											[1564.668, 1171.222],
											[1567, 1170],
											[1570.339, 1167.781],
											[1572.389, 1165.283],
											[1573.744, 1162.143],
											[1575, 1158],
											[1575.301, 1156.807],
											[1575.525, 1155.626],
											[1575.736, 1154.382],
											[1576, 1153],
											[1576.242, 1151.493],
											[1576.466, 1149.73],
											[1576.706, 1148.102],
											[1577, 1147],
											[1578.211, 1145.184],
											[1579.742, 1143.758],
											[1581.403, 1142.453],
											[1583, 1141],
											[1584.668, 1139.183],
											[1586.165, 1137.319],
											[1587.328, 1135.296],
											[1588, 1133],
											[1587.972, 1130.71],
											[1587.552, 1127.273],
											[1586.856, 1123.699],
											[1586, 1121],
											[1584.762, 1119.122],
											[1583.126, 1117.485],
											[1581.427, 1116.105],
											[1580, 1115],
											[1578.625, 1113.644],
											[1577.686, 1112.341],
											[1576.904, 1111.118],
											[1576, 1110],
											[1575.076, 1109.58],
											[1573.953, 1109.407],
											[1573, 1109],
											[1570.652, 1106.343],
											[1569, 1104],
											[1565.811, 1096.452],
											[1563, 1089],
											[1557.879, 1083.604],
											[1552, 1077],
											[1551.383, 1073.947],
											[1551, 1071],
											[1550.042, 1070.512],
											[1549, 1070],
											[1548.488, 1068.461],
											[1548, 1067],
											[1540.794, 1061.941],
											[1536, 1065],
											[1535.764, 1098.102],
											[1536, 1135],
											[1535.745, 1157.273],
											[1535, 1177],
											[1533.274, 1182.625],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[0.639, -0.164],
											[0.989, -1.903],
											[0.27, -2.762],
											[-0.104, -3.103],
											[-0.133, -2.927],
											[-0.167, -1.726],
											[-0.152, -1.692],
											[-0.034, -1.62],
											[0.188, -1.509],
											[0.237, -0.927],
											[0.297, -0.952],
											[0.287, -0.924],
											[0.206, -0.843],
											[0.24, -1.034],
											[0.236, -0.993],
											[0.253, -0.963],
											[0.291, -0.944],
											[0.713, -1.962],
											[0.672, -1.864],
											[0.499, -1.72],
											[0.191, -1.53],
											[-0.265, -3.103],
											[-0.827, -2.561],
											[-1.273, -1.678],
											[-1.604, -0.453],
											[-0.908, 0.023],
											[-1.188, 0.093],
											[-1.061, 0.149],
											[-0.529, 0.189],
											[-0.313, 0.284],
											[-0.291, 0.37],
											[-0.332, 0.401],
											[-0.437, 0.377],
											[-0.245, 0.075],
											[-0.262, 0.038],
											[-0.261, 0.069],
											[-0.242, 0.169],
											[-0.159, 0.262],
											[-0.145, 0.302],
											[-0.163, 0.264],
											[-0.214, 0.148],
											[-0.742, 0.21],
											[-0.663, 0.14],
											[-0.441, 0.258],
											[-0.36, 0.406],
											[-0.309, 0.231],
											[-0.639, 1.007],
											[-0.913, 1.825],
											[-0.563, 0.836],
											[-0.32, 0.803],
											[-0.208, 1.435],
											[-0.421, 1.385],
											[-0.2, 0.638],
											[-0.18, 0.389],
											[-1.098, 1.016],
											[-0.889, 1.384],
											[-0.302, 0.89],
											[-0.351, 0.754],
											[1.978, 4.3],
											[2.09, 4.356],
											[0.81, 2.591],
											[0.622, 2.265],
											[0.346, 1.415],
											[0.342, 1.347],
											[0.762, 1.625],
											[2.88, 1.289],
											[1.225, 0.194],
											[1.175, 0.809],
											[0.833, 1.331],
											[0.547, 0.903],
											[0.867, -0.078],
										],
										o: [
											[-2.054, 0.525],
											[-0.989, 1.903],
											[-0.27, 2.762],
											[0.104, 3.103],
											[0.078, 1.722],
											[0.167, 1.726],
											[0.152, 1.692],
											[0.034, 1.62],
											[-0.106, 0.85],
											[-0.237, 0.927],
											[-0.297, 0.952],
											[-0.287, 0.924],
											[-0.265, 1.085],
											[-0.24, 1.034],
											[-0.236, 0.993],
											[-0.253, 0.963],
											[-0.619, 2.013],
											[-0.713, 1.962],
											[-0.672, 1.864],
											[-0.499, 1.72],
											[-0.413, 3.303],
											[0.265, 3.103],
											[0.827, 2.561],
											[1.273, 1.678],
											[0.221, 0.063],
											[0.908, -0.023],
											[1.188, -0.093],
											[1.061, -0.149],
											[0.4, -0.143],
											[0.313, -0.284],
											[0.291, -0.37],
											[0.332, -0.401],
											[0.209, -0.181],
											[0.245, -0.075],
											[0.262, -0.038],
											[0.261, -0.069],
											[0.207, -0.145],
											[0.159, -0.262],
											[0.145, -0.302],
											[0.163, -0.264],
											[0.678, -0.468],
											[0.742, -0.21],
											[0.663, -0.14],
											[0.249, -0.146],
											[0.36, -0.406],
											[1.531, -1.146],
											[0.639, -1.007],
											[0.428, -0.854],
											[0.563, -0.836],
											[0.746, -1.876],
											[0.208, -1.435],
											[0.019, -0.062],
											[0.2, -0.637],
											[0.72, -1.552],
											[1.098, -1.016],
											[0.441, -0.687],
											[0.302, -0.89],
											[3.003, -6.445],
											[-1.978, -4.3],
											[-1.137, -2.37],
											[-0.81, -2.591],
											[-0.273, -0.993],
											[-0.346, -1.415],
											[-1.072, -4.212],
											[-0.762, -1.625],
											[-0.925, -0.414],
											[-1.225, -0.194],
											[-1.121, -0.772],
											[-0.833, -1.331],
											[-0.894, -1.475],
											[-0.867, 0.078],
										],
										v: [
											[1518, 1064],
											[1513.521, 1067.771],
											[1511.719, 1074.897],
											[1511.558, 1083.825],
											[1512, 1093],
											[1512.394, 1098.182],
											[1512.899, 1103.319],
											[1513.205, 1108.297],
											[1513, 1113],
											[1512.469, 1115.678],
											[1511.651, 1118.51],
											[1510.757, 1121.336],
											[1510, 1124],
											[1509.247, 1127.176],
											[1508.537, 1130.212],
											[1507.81, 1133.143],
											[1507, 1136],
											[1504.969, 1141.973],
											[1502.858, 1147.723],
											[1501.069, 1153.112],
											[1500, 1158],
											[1499.807, 1167.695],
											[1501.475, 1176.275],
											[1504.656, 1182.718],
											[1509, 1186],
											[1510.795, 1186.056],
											[1514.039, 1185.878],
											[1517.513, 1185.511],
											[1520, 1185],
											[1521.054, 1184.346],
											[1521.944, 1183.351],
											[1522.862, 1182.181],
											[1524, 1181],
											[1524.686, 1180.634],
											[1525.452, 1180.482],
											[1526.242, 1180.339],
											[1527, 1180],
											[1527.542, 1179.37],
											[1527.99, 1178.504],
											[1528.443, 1177.637],
											[1529, 1177],
											[1531.166, 1176.03],
											[1533.308, 1175.551],
											[1535, 1175],
											[1535.955, 1174.065],
											[1537, 1173],
											[1539.963, 1170.01],
											[1542, 1166],
											[1543.581, 1163.461],
											[1545, 1161],
											[1546.244, 1156.132],
											[1547, 1152],
											[1547.379, 1150.745],
											[1548, 1149],
											[1550.872, 1145.374],
											[1554, 1142],
											[1555.068, 1139.551],
											[1556, 1137],
											[1556.319, 1121.433],
											[1549, 1109],
											[1546.114, 1101.421],
											[1544, 1094],
											[1543.052, 1090.265],
											[1542, 1086],
											[1539.856, 1077.808],
											[1535, 1074],
											[1531.688, 1073.296],
											[1528, 1072],
											[1525.069, 1068.598],
											[1523, 1065],
											[1520.31, 1063.271],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[0.404, -0.07],
											[0.19, -0.671],
											[0.306, -0.717],
											[0.373, -0.757],
											[0.39, -0.79],
											[0.357, -0.818],
											[0.274, -0.839],
											[0.141, -0.855],
											[0.068, -2.692],
											[-0.035, -2.858],
											[0.039, -2.612],
											[0.292, -1.955],
											[0.242, -0.83],
											[0.292, -0.818],
											[0.279, -0.829],
											[0.204, -0.864],
											[0.16, -0.923],
											[0.142, -0.925],
											[0.161, -0.915],
											[0.217, -0.893],
											[0.852, -2.537],
											[0.697, -2.36],
											[0.246, -2.393],
											[-0.5, -2.635],
											[-0.729, -1.428],
											[-0.955, -1.427],
											[-0.873, -1.035],
											[-0.485, -0.252],
											[-0.641, -0.164],
											[-0.696, -0.068],
											[-0.707, 0.005],
											[-0.675, 0.054],
											[0.138, -1.15],
											[-0.161, -0.914],
											[-0.594, -0.557],
											[-1.16, -0.08],
											[-0.241, 0.147],
											[-0.009, 0.287],
											[-0.013, 0.371],
											[-0.254, 0.399],
											[-0.245, 0.209],
											[-0.288, 0.226],
											[-0.274, 0.279],
											[-0.203, 0.367],
											[-0.68, 1.706],
											[-0.559, 1.758],
											[-0.474, 1.673],
											[-0.427, 1.452],
											[-0.022, 2.083],
											[0.207, 2.103],
											[0.125, 1.968],
											[-0.267, 1.68],
											[-0.455, 0.966],
											[-0.536, 0.834],
											[-0.438, 0.826],
											[-0.16, 0.941],
											[0.044, 1.608],
											[0.257, 1.369],
											[0.335, 1.077],
											[0.277, 0.731],
											[0.081, 0.43],
											[0.051, 0.427],
											[0.07, 0.409],
											[0.136, 0.374],
											[1.621, 2.936],
											[1.463, 3.021],
											[0.736, 3.574],
											[-0.56, 4.597],
											[0.215, 0.118],
											[0.194, 0.139],
											[0.257, 0.076],
										],
										o: [
											[-0.024, 0.619],
											[-0.19, 0.671],
											[-0.306, 0.717],
											[-0.373, 0.757],
											[-0.39, 0.79],
											[-0.357, 0.818],
											[-0.274, 0.839],
											[-0.349, 2.113],
											[-0.068, 2.692],
											[0.035, 2.858],
											[-0.039, 2.612],
											[-0.129, 0.864],
											[-0.242, 0.83],
											[-0.292, 0.818],
											[-0.279, 0.829],
											[-0.215, 0.909],
											[-0.16, 0.923],
											[-0.142, 0.925],
											[-0.161, 0.915],
											[-0.712, 2.923],
											[-0.852, 2.537],
											[-0.697, 2.36],
											[-0.246, 2.393],
											[0.197, 1.038],
											[0.729, 1.428],
											[0.955, 1.427],
											[0.873, 1.035],
											[0.542, 0.282],
											[0.641, 0.164],
											[0.696, 0.068],
											[0.707, -0.005],
											[-0.304, 1.265],
											[-0.138, 1.15],
											[0.161, 0.914],
											[0.594, 0.557],
											[0.709, 0.049],
											[0.241, -0.147],
											[0.009, -0.287],
											[0.013, -0.371],
											[0.145, -0.228],
											[0.245, -0.209],
											[0.288, -0.226],
											[0.274, -0.279],
											[0.838, -1.518],
											[0.68, -1.706],
											[0.559, -1.758],
											[0.474, -1.673],
											[0.561, -1.909],
											[0.022, -2.083],
											[-0.207, -2.103],
											[-0.125, -1.968],
											[0.194, -1.223],
											[0.455, -0.966],
											[0.536, -0.834],
											[0.438, -0.826],
											[0.306, -1.793],
											[-0.044, -1.608],
											[-0.257, -1.369],
											[-0.335, -1.077],
											[-0.158, -0.417],
											[-0.081, -0.43],
											[-0.051, -0.427],
											[-0.07, -0.409],
											[-1.209, -3.32],
											[-1.621, -2.936],
											[-1.463, -3.021],
											[-0.736, -3.574],
											[-0.32, -0.014],
											[-0.215, -0.118],
											[-0.194, -0.139],
											[-0.257, -0.076],
										],
										v: [
											[1509, 1063],
											[1508.667, 1064.938],
											[1507.911, 1067.022],
											[1506.88, 1069.234],
											[1505.724, 1071.556],
											[1504.593, 1073.97],
											[1503.635, 1076.457],
											[1503, 1079],
											[1502.419, 1086.31],
											[1502.414, 1094.737],
											[1502.452, 1103.046],
											[1502, 1110],
											[1501.428, 1112.535],
											[1500.613, 1115],
											[1499.74, 1117.466],
											[1499, 1120],
											[1498.447, 1122.75],
											[1498.004, 1125.524],
											[1497.559, 1128.286],
											[1497, 1131],
											[1494.58, 1139.138],
											[1492.182, 1146.432],
											[1490.693, 1153.51],
											[1491, 1161],
											[1492.466, 1164.797],
											[1495.068, 1169.179],
											[1497.887, 1172.971],
											[1500, 1175],
											[1501.786, 1175.663],
											[1503.801, 1176.005],
											[1505.916, 1176.094],
											[1508, 1176],
											[1507.302, 1179.653],
											[1507.303, 1182.778],
											[1508.402, 1185.015],
											[1511, 1186],
											[1512.367, 1185.838],
											[1512.684, 1185.172],
											[1512.658, 1184.17],
											[1513, 1183],
											[1513.599, 1182.353],
											[1514.413, 1181.709],
											[1515.271, 1180.96],
											[1516, 1180],
											[1518.268, 1175.131],
											[1520.117, 1169.901],
											[1521.658, 1164.722],
											[1523, 1160],
											[1523.796, 1153.973],
											[1523.441, 1147.657],
											[1522.865, 1141.511],
											[1523, 1136],
											[1524.018, 1132.748],
											[1525.55, 1130.079],
											[1527.057, 1127.62],
											[1528, 1125],
											[1528.36, 1119.885],
											[1527.874, 1115.406],
											[1526.952, 1111.724],
											[1526, 1109],
											[1525.654, 1107.726],
											[1525.467, 1106.436],
											[1525.297, 1105.178],
											[1525, 1104],
											[1520.613, 1094.733],
											[1515.846, 1085.915],
											[1512.406, 1076.14],
											[1512, 1064],
											[1511.219, 1063.781],
											[1510.626, 1063.374],
											[1509.97, 1063.03],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 36,
								s: [
									{
										i: [
											[1.148, -0.442],
											[0.119, -0.059],
											[0.1, -0.064],
											[0.084, -0.071],
											[0.073, -0.079],
											[0.065, -0.089],
											[0.062, -0.101],
											[0.062, -0.114],
											[0.066, -0.128],
											[0.34, -0.901],
											[0.236, -0.902],
											[0.157, -0.913],
											[0.102, -0.933],
											[0.071, -0.963],
											[0.065, -1.002],
											[0.082, -1.05],
											[0.124, -1.108],
											[0.153, -1.14],
											[0.168, -1.155],
											[0.171, -1.161],
											[0.162, -1.158],
											[0.14, -1.147],
											[0.107, -1.126],
											[0.061, -1.097],
											[0.075, -1.81],
											[0.065, -1.69],
											[0.083, -1.66],
											[0.126, -1.72],
											[0.333, -6.206],
											[-0.346, -6.237],
											[-1.756, -3.912],
											[-3.898, 0.767],
											[-0.329, 0.157],
											[-0.086, 0.211],
											[-0.089, 0.324],
											[-0.339, 0.495],
											[-0.182, 0.072],
											[-0.213, 0.061],
											[-0.175, 0.084],
											[-0.067, 0.141],
											[0.012, 0.256],
											[0.049, 0.273],
											[0.012, 0.256],
											[-0.098, 0.204],
											[-0.619, 1.572],
											[-0.496, 1.635],
											[-0.379, 1.746],
											[-0.269, 1.903],
											[-0.08, 0.989],
											[-0.059, 1.006],
											[-0.075, 1.014],
											[-0.126, 1.014],
											[-0.287, 1.317],
											[-0.311, 1.249],
											[-0.243, 1.183],
											[-0.085, 1.117],
											[-0.076, 2.271],
											[-0.009, 2.43],
											[0.072, 2.194],
											[0.165, 1.563],
											[0.226, 0.857],
											[0.297, 0.918],
											[0.298, 0.977],
											[0.227, 1.035],
											[0.378, 2.142],
											[0.356, 2.068],
											[0.425, 2.017],
											[0.584, 1.99],
											[0.384, 1.37],
											[0.473, 0.968],
											[0.727, 0.364],
										],
										o: [
											[-0.142, 0.055],
											[-0.119, 0.059],
											[-0.1, 0.064],
											[-0.084, 0.071],
											[-0.073, 0.079],
											[-0.065, 0.089],
											[-0.062, 0.101],
											[-0.062, 0.114],
											[-0.468, 0.91],
											[-0.34, 0.901],
											[-0.236, 0.902],
											[-0.157, 0.913],
											[-0.102, 0.933],
											[-0.071, 0.963],
											[-0.065, 1.002],
											[-0.082, 1.05],
											[-0.125, 1.117],
											[-0.153, 1.14],
											[-0.168, 1.155],
											[-0.171, 1.161],
											[-0.162, 1.158],
											[-0.14, 1.147],
											[-0.107, 1.126],
											[-0.112, 2.019],
											[-0.075, 1.81],
											[-0.065, 1.69],
											[-0.083, 1.66],
											[-0.281, 3.821],
											[-0.333, 6.206],
											[0.346, 6.237],
											[1.756, 3.912],
											[0.819, -0.161],
											[0.329, -0.157],
											[0.086, -0.211],
											[0.089, -0.324],
											[0.08, -0.117],
											[0.182, -0.072],
											[0.213, -0.061],
											[0.175, -0.084],
											[0.097, -0.204],
											[-0.012, -0.256],
											[-0.049, -0.273],
											[-0.012, -0.256],
											[0.747, -1.556],
											[0.619, -1.572],
											[0.496, -1.635],
											[0.379, -1.746],
											[0.136, -0.963],
											[0.08, -0.989],
											[0.059, -1.006],
											[0.075, -1.014],
											[0.173, -1.385],
											[0.287, -1.317],
											[0.311, -1.249],
											[0.243, -1.183],
											[0.13, -1.718],
											[0.076, -2.271],
											[0.009, -2.43],
											[-0.072, -2.194],
											[-0.084, -0.794],
											[-0.226, -0.857],
											[-0.297, -0.918],
											[-0.298, -0.977],
											[-0.491, -2.24],
											[-0.378, -2.142],
											[-0.356, -2.068],
											[-0.425, -2.017],
											[-0.461, -1.571],
											[-0.384, -1.37],
											[-0.473, -0.968],
											[-0.727, -0.364],
										],
										v: [
											[1504, 1062],
											[1503.608, 1062.17],
											[1503.281, 1062.353],
											[1503.005, 1062.555],
											[1502.77, 1062.779],
											[1502.564, 1063.031],
											[1502.375, 1063.316],
											[1502.191, 1063.637],
											[1502, 1064],
											[1500.795, 1066.714],
											[1499.936, 1069.417],
											[1499.352, 1072.138],
											[1498.969, 1074.904],
											[1498.715, 1077.745],
											[1498.517, 1080.689],
											[1498.303, 1083.764],
											[1498, 1087],
											[1497.581, 1090.388],
											[1497.097, 1093.834],
											[1496.586, 1097.31],
											[1496.084, 1100.791],
											[1495.628, 1104.251],
											[1495.254, 1107.662],
											[1495, 1111],
											[1494.726, 1116.721],
											[1494.522, 1121.949],
											[1494.307, 1126.953],
											[1494, 1132],
											[1492.896, 1147.63],
											[1492.732, 1166.882],
											[1495.702, 1182.694],
											[1504, 1188],
											[1505.66, 1187.537],
											[1506.22, 1187],
											[1506.42, 1186.213],
											[1507, 1185],
											[1507.41, 1184.726],
											[1508.02, 1184.536],
											[1508.62, 1184.329],
											[1509, 1184],
											[1509.108, 1183.301],
											[1508.998, 1182.499],
											[1508.889, 1181.697],
											[1509, 1181],
											[1511.047, 1176.32],
											[1512.717, 1171.521],
											[1514.029, 1166.462],
											[1515, 1161],
											[1515.315, 1158.07],
											[1515.515, 1155.076],
											[1515.707, 1152.044],
											[1516, 1149],
											[1516.713, 1144.948],
											[1517.632, 1141.098],
											[1518.486, 1137.45],
											[1519, 1134],
											[1519.313, 1127.917],
											[1519.443, 1120.767],
											[1519.352, 1113.733],
											[1519, 1108],
											[1518.517, 1105.523],
											[1517.714, 1102.862],
											[1516.805, 1100.019],
											[1516, 1097],
											[1514.719, 1090.434],
											[1513.64, 1084.125],
											[1512.491, 1078.004],
											[1511, 1072],
											[1509.773, 1067.537],
											[1508.53, 1063.98],
											[1506.771, 1061.933],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 37,
								s: [
									{
										i: [
											[0.141, -0.065],
											[0.162, -0.087],
											[0.142, -0.09],
											[0.122, -0.092],
											[0.103, -0.094],
											[0.085, -0.096],
											[0.067, -0.097],
											[0.051, -0.098],
											[0.035, -0.098],
											[0.02, -0.098],
											[0.005, -0.098],
											[-0.009, -0.097],
											[-0.022, -0.096],
											[-0.034, -0.094],
											[-0.045, -0.092],
											[-0.056, -0.09],
											[-0.066, -0.087],
											[-0.075, -0.084],
											[-0.083, -0.081],
											[-0.091, -0.077],
											[-0.097, -0.073],
											[-0.103, -0.068],
											[-0.109, -0.063],
											[-0.113, -0.058],
											[-0.117, -0.052],
											[-0.12, -0.046],
											[-0.122, -0.039],
											[-0.124, -0.032],
											[-0.125, -0.025],
											[-0.125, -0.017],
											[-0.124, -0.009],
											[-0.122, 0],
											[-0.12, 0.008],
											[-0.117, 0.018],
											[-0.113, 0.027],
											[-0.109, 0.037],
											[-0.103, 0.048],
											[-0.097, 0.059],
											[-0.09, 0.07],
											[-0.083, 0.082],
											[-0.074, 0.094],
											[-0.065, 0.106],
											[-0.055, 0.119],
											[-0.06, 0.153],
											[-0.046, 0.148],
											[-0.032, 0.142],
											[-0.019, 0.136],
											[-0.007, 0.129],
											[0.005, 0.123],
											[0.017, 0.116],
											[0.028, 0.11],
											[0.038, 0.103],
											[0.048, 0.096],
											[0.058, 0.089],
											[0.067, 0.081],
											[0.075, 0.074],
											[0.083, 0.066],
											[0.09, 0.059],
											[0.097, 0.051],
											[0.104, 0.043],
											[0.11, 0.035],
											[0.115, 0.026],
											[0.12, 0.018],
											[0.124, 0.009],
											[0.128, 0.001],
											[0.131, -0.008],
											[0.134, -0.017],
											[0.137, -0.026],
											[0.138, -0.036],
											[0.14, -0.045],
											[0.141, -0.055],
										],
										o: [
											[-0.184, 0.084],
											[-0.162, 0.087],
											[-0.142, 0.09],
											[-0.122, 0.092],
											[-0.103, 0.094],
											[-0.085, 0.096],
											[-0.067, 0.097],
											[-0.051, 0.098],
											[-0.035, 0.098],
											[-0.02, 0.098],
											[-0.005, 0.098],
											[0.009, 0.097],
											[0.022, 0.096],
											[0.034, 0.094],
											[0.045, 0.092],
											[0.056, 0.09],
											[0.066, 0.087],
											[0.075, 0.084],
											[0.083, 0.081],
											[0.091, 0.077],
											[0.097, 0.073],
											[0.103, 0.068],
											[0.109, 0.063],
											[0.113, 0.058],
											[0.117, 0.052],
											[0.12, 0.046],
											[0.122, 0.039],
											[0.124, 0.032],
											[0.125, 0.025],
											[0.125, 0.017],
											[0.124, 0.009],
											[0.122, 0],
											[0.12, -0.008],
											[0.117, -0.018],
											[0.113, -0.027],
											[0.109, -0.037],
											[0.103, -0.048],
											[0.097, -0.059],
											[0.09, -0.07],
											[0.083, -0.082],
											[0.074, -0.094],
											[0.065, -0.106],
											[0.074, -0.159],
											[0.06, -0.153],
											[0.046, -0.148],
											[0.032, -0.142],
											[0.019, -0.136],
											[0.007, -0.129],
											[-0.005, -0.123],
											[-0.017, -0.116],
											[-0.028, -0.11],
											[-0.038, -0.103],
											[-0.048, -0.096],
											[-0.058, -0.089],
											[-0.067, -0.081],
											[-0.075, -0.074],
											[-0.083, -0.066],
											[-0.09, -0.059],
											[-0.097, -0.051],
											[-0.104, -0.043],
											[-0.11, -0.035],
											[-0.115, -0.026],
											[-0.12, -0.018],
											[-0.124, -0.009],
											[-0.128, -0.001],
											[-0.131, 0.008],
											[-0.134, 0.017],
											[-0.137, 0.026],
											[-0.138, 0.036],
											[-0.14, 0.045],
											[-0.141, 0.055],
										],
										v: [
											[2211, 1120],
											[2210.481, 1120.257],
											[2210.025, 1120.523],
											[2209.63, 1120.797],
											[2209.292, 1121.077],
											[2209.011, 1121.363],
											[2208.782, 1121.652],
											[2208.606, 1121.944],
											[2208.478, 1122.238],
											[2208.397, 1122.533],
											[2208.36, 1122.827],
											[2208.365, 1123.12],
											[2208.411, 1123.41],
											[2208.494, 1123.695],
											[2208.612, 1123.976],
											[2208.763, 1124.25],
											[2208.945, 1124.516],
											[2209.156, 1124.774],
											[2209.392, 1125.022],
											[2209.653, 1125.259],
											[2209.935, 1125.484],
											[2210.237, 1125.695],
											[2210.556, 1125.892],
											[2210.889, 1126.074],
											[2211.235, 1126.238],
											[2211.591, 1126.385],
											[2211.955, 1126.512],
											[2212.325, 1126.619],
											[2212.698, 1126.705],
											[2213.072, 1126.768],
											[2213.445, 1126.807],
											[2213.815, 1126.822],
											[2214.179, 1126.81],
											[2214.535, 1126.771],
											[2214.88, 1126.703],
											[2215.213, 1126.606],
											[2215.531, 1126.478],
											[2215.832, 1126.318],
											[2216.114, 1126.126],
											[2216.374, 1125.898],
											[2216.609, 1125.636],
											[2216.819, 1125.337],
											[2217, 1125],
											[2217.201, 1124.531],
											[2217.359, 1124.08],
											[2217.475, 1123.646],
											[2217.552, 1123.23],
											[2217.59, 1122.833],
											[2217.592, 1122.454],
											[2217.558, 1122.095],
											[2217.491, 1121.756],
											[2217.392, 1121.438],
											[2217.262, 1121.14],
											[2217.103, 1120.863],
											[2216.916, 1120.608],
											[2216.703, 1120.375],
											[2216.466, 1120.164],
											[2216.206, 1119.976],
											[2215.924, 1119.812],
											[2215.622, 1119.671],
											[2215.302, 1119.555],
											[2214.965, 1119.464],
											[2214.612, 1119.397],
											[2214.246, 1119.356],
											[2213.867, 1119.341],
											[2213.478, 1119.352],
											[2213.079, 1119.391],
											[2212.673, 1119.456],
											[2212.26, 1119.549],
											[2211.843, 1119.671],
											[2211.422, 1119.821],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 38,
								s: [
									{
										i: [
											[0.058, -0.038],
											[0.188, -0.138],
											[0.158, -0.133],
											[0.129, -0.128],
											[0.101, -0.123],
											[0.075, -0.118],
											[0.05, -0.112],
											[0.027, -0.106],
											[0.005, -0.1],
											[-0.016, -0.094],
											[-0.035, -0.088],
											[-0.052, -0.082],
											[-0.068, -0.076],
											[-0.083, -0.069],
											[-0.096, -0.062],
											[-0.107, -0.055],
											[-0.118, -0.048],
											[-0.126, -0.041],
											[-0.134, -0.034],
											[-0.14, -0.026],
											[-0.144, -0.019],
											[-0.147, -0.011],
											[-0.148, -0.003],
											[-0.148, 0.005],
											[-0.147, 0.013],
											[-0.144, 0.022],
											[-0.14, 0.03],
											[-0.134, 0.039],
											[-0.126, 0.048],
											[-0.118, 0.057],
											[-0.107, 0.066],
											[-0.096, 0.075],
											[-0.083, 0.084],
											[-0.068, 0.094],
											[-0.052, 0.104],
											[-0.035, 0.114],
											[-0.016, 0.124],
											[0.005, 0.134],
											[0.027, 0.144],
											[0.05, 0.154],
											[0.075, 0.165],
											[0.101, 0.176],
											[0.129, 0.187],
											[0.052, 0.066],
											[0.053, 0.059],
											[0.053, 0.052],
											[0.054, 0.046],
											[0.055, 0.039],
											[0.055, 0.033],
											[0.056, 0.027],
											[0.057, 0.021],
											[0.057, 0.016],
											[0.058, 0.011],
											[0.058, 0.006],
											[0.058, 0.001],
											[0.059, -0.003],
											[0.059, -0.007],
											[0.059, -0.011],
											[0.059, -0.015],
											[0.059, -0.018],
											[0.06, -0.021],
											[0.06, -0.024],
											[0.06, -0.026],
											[0.06, -0.029],
											[0.06, -0.031],
											[0.059, -0.032],
											[0.059, -0.034],
											[0.059, -0.035],
											[0.059, -0.036],
											[0.059, -0.037],
											[0.058, -0.037],
										],
										o: [
											[-0.22, 0.143],
											[-0.188, 0.138],
											[-0.158, 0.133],
											[-0.129, 0.128],
											[-0.101, 0.123],
											[-0.075, 0.118],
											[-0.05, 0.112],
											[-0.027, 0.106],
											[-0.005, 0.1],
											[0.016, 0.094],
											[0.035, 0.088],
											[0.052, 0.082],
											[0.068, 0.076],
											[0.083, 0.069],
											[0.096, 0.062],
											[0.107, 0.055],
											[0.118, 0.048],
											[0.126, 0.041],
											[0.134, 0.034],
											[0.14, 0.026],
											[0.144, 0.019],
											[0.147, 0.011],
											[0.148, 0.003],
											[0.148, -0.005],
											[0.147, -0.013],
											[0.144, -0.022],
											[0.14, -0.03],
											[0.134, -0.039],
											[0.126, -0.048],
											[0.118, -0.057],
											[0.107, -0.066],
											[0.096, -0.075],
											[0.083, -0.084],
											[0.068, -0.094],
											[0.052, -0.104],
											[0.035, -0.114],
											[0.016, -0.124],
											[-0.005, -0.134],
											[-0.027, -0.144],
											[-0.05, -0.154],
											[-0.075, -0.165],
											[-0.101, -0.176],
											[-0.051, -0.074],
											[-0.052, -0.066],
											[-0.053, -0.059],
											[-0.053, -0.052],
											[-0.054, -0.046],
											[-0.055, -0.039],
											[-0.055, -0.033],
											[-0.056, -0.027],
											[-0.057, -0.021],
											[-0.057, -0.016],
											[-0.058, -0.011],
											[-0.058, -0.006],
											[-0.058, -0.001],
											[-0.059, 0.003],
											[-0.059, 0.007],
											[-0.059, 0.011],
											[-0.059, 0.015],
											[-0.059, 0.018],
											[-0.06, 0.021],
											[-0.06, 0.024],
											[-0.06, 0.026],
											[-0.06, 0.029],
											[-0.06, 0.031],
											[-0.059, 0.032],
											[-0.059, 0.034],
											[-0.059, 0.035],
											[-0.059, 0.036],
											[-0.059, 0.037],
											[-0.058, 0.037],
										],
										v: [
											[2216, 1120],
											[2215.387, 1120.422],
											[2214.868, 1120.829],
											[2214.439, 1121.221],
											[2214.095, 1121.598],
											[2213.832, 1121.959],
											[2213.646, 1122.303],
											[2213.531, 1122.63],
											[2213.485, 1122.94],
											[2213.501, 1123.233],
											[2213.577, 1123.507],
											[2213.708, 1123.762],
											[2213.888, 1123.998],
											[2214.115, 1124.215],
											[2214.383, 1124.412],
											[2214.688, 1124.588],
											[2215.026, 1124.744],
											[2215.393, 1124.878],
											[2215.783, 1124.99],
											[2216.194, 1125.08],
											[2216.619, 1125.148],
											[2217.056, 1125.192],
											[2217.498, 1125.213],
											[2217.944, 1125.21],
											[2218.387, 1125.182],
											[2218.823, 1125.129],
											[2219.248, 1125.052],
											[2219.659, 1124.948],
											[2220.049, 1124.818],
											[2220.415, 1124.662],
											[2220.753, 1124.478],
											[2221.059, 1124.267],
											[2221.327, 1124.028],
											[2221.553, 1123.761],
											[2221.734, 1123.464],
											[2221.864, 1123.138],
											[2221.939, 1122.783],
											[2221.956, 1122.397],
											[2221.909, 1121.981],
											[2221.794, 1121.533],
											[2221.608, 1121.054],
											[2221.344, 1120.543],
											[2221, 1120],
											[2220.846, 1119.79],
											[2220.689, 1119.601],
											[2220.53, 1119.434],
											[2220.369, 1119.288],
											[2220.206, 1119.161],
											[2220.041, 1119.053],
											[2219.873, 1118.963],
											[2219.705, 1118.891],
											[2219.534, 1118.835],
											[2219.362, 1118.795],
											[2219.189, 1118.769],
											[2219.015, 1118.759],
											[2218.839, 1118.761],
											[2218.663, 1118.777],
											[2218.486, 1118.804],
											[2218.308, 1118.842],
											[2218.13, 1118.891],
											[2217.951, 1118.949],
											[2217.773, 1119.016],
											[2217.594, 1119.091],
											[2217.415, 1119.174],
											[2217.236, 1119.263],
											[2217.057, 1119.357],
											[2216.879, 1119.456],
											[2216.702, 1119.56],
											[2216.525, 1119.667],
											[2216.349, 1119.776],
											[2216.174, 1119.888],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 39,
								s: [
									{
										i: [
											[0.155, -0.054],
											[0.014, -0.005],
											[0.015, -0.006],
											[0.016, -0.007],
											[0.017, -0.008],
											[0.018, -0.009],
											[0.019, -0.01],
											[0.02, -0.011],
											[0.021, -0.011],
											[0.022, -0.012],
											[0.022, -0.013],
											[0.023, -0.014],
											[0.023, -0.014],
											[0.023, -0.015],
											[0.024, -0.015],
											[0.024, -0.016],
											[0.024, -0.016],
											[0.024, -0.017],
											[0.024, -0.017],
											[0.023, -0.018],
											[0.023, -0.018],
											[0.023, -0.018],
											[0.022, -0.019],
											[0.022, -0.019],
											[0.021, -0.019],
											[0.02, -0.019],
											[0.019, -0.02],
											[0.018, -0.02],
											[0.017, -0.02],
											[0.016, -0.02],
											[0.015, -0.02],
											[0.013, -0.02],
											[0.012, -0.02],
											[0.01, -0.02],
											[0.009, -0.02],
											[0.007, -0.019],
											[0.005, -0.019],
											[0.003, -0.019],
											[0.001, -0.019],
											[-0.001, -0.019],
											[-0.003, -0.018],
											[-0.005, -0.018],
											[-0.008, -0.018],
											[-0.074, -0.128],
											[-0.079, -0.101],
											[-0.083, -0.075],
											[-0.085, -0.051],
											[-0.087, -0.029],
											[-0.088, -0.009],
											[-0.088, 0.009],
											[-0.086, 0.025],
											[-0.084, 0.04],
											[-0.081, 0.053],
											[-0.077, 0.064],
											[-0.072, 0.073],
											[-0.067, 0.08],
											[-0.06, 0.086],
											[-0.052, 0.089],
											[-0.043, 0.091],
											[-0.034, 0.091],
											[-0.023, 0.089],
											[-0.012, 0.085],
											[0.001, 0.079],
											[0.014, 0.072],
											[0.028, 0.062],
											[0.044, 0.051],
											[0.06, 0.038],
											[0.077, 0.023],
											[0.095, 0.007],
											[0.114, -0.012],
											[0.134, -0.032],
										],
										o: [
											[-0.012, 0.004],
											[-0.014, 0.005],
											[-0.015, 0.006],
											[-0.016, 0.007],
											[-0.017, 0.008],
											[-0.018, 0.009],
											[-0.019, 0.01],
											[-0.02, 0.011],
											[-0.021, 0.011],
											[-0.022, 0.012],
											[-0.022, 0.013],
											[-0.023, 0.014],
											[-0.023, 0.014],
											[-0.023, 0.015],
											[-0.024, 0.015],
											[-0.024, 0.016],
											[-0.024, 0.016],
											[-0.024, 0.017],
											[-0.024, 0.017],
											[-0.023, 0.018],
											[-0.023, 0.018],
											[-0.023, 0.018],
											[-0.022, 0.019],
											[-0.022, 0.019],
											[-0.021, 0.019],
											[-0.02, 0.019],
											[-0.019, 0.02],
											[-0.018, 0.02],
											[-0.017, 0.02],
											[-0.016, 0.02],
											[-0.015, 0.02],
											[-0.013, 0.02],
											[-0.012, 0.02],
											[-0.01, 0.02],
											[-0.009, 0.02],
											[-0.007, 0.019],
											[-0.005, 0.019],
											[-0.003, 0.019],
											[-0.001, 0.019],
											[0.001, 0.019],
											[0.003, 0.018],
											[0.005, 0.018],
											[0.069, 0.158],
											[0.074, 0.128],
											[0.079, 0.101],
											[0.083, 0.075],
											[0.085, 0.051],
											[0.087, 0.029],
											[0.088, 0.009],
											[0.088, -0.009],
											[0.086, -0.025],
											[0.084, -0.04],
											[0.081, -0.053],
											[0.077, -0.064],
											[0.072, -0.073],
											[0.067, -0.08],
											[0.06, -0.086],
											[0.052, -0.089],
											[0.043, -0.091],
											[0.034, -0.091],
											[0.023, -0.089],
											[0.012, -0.085],
											[-0.001, -0.079],
											[-0.014, -0.072],
											[-0.028, -0.062],
											[-0.044, -0.051],
											[-0.06, -0.038],
											[-0.077, -0.023],
											[-0.095, -0.007],
											[-0.114, 0.012],
											[-0.134, 0.032],
										],
										v: [
											[2220, 1121],
											[2219.962, 1121.014],
											[2219.919, 1121.031],
											[2219.873, 1121.052],
											[2219.823, 1121.074],
											[2219.769, 1121.1],
											[2219.713, 1121.128],
											[2219.654, 1121.159],
											[2219.592, 1121.192],
											[2219.528, 1121.227],
											[2219.462, 1121.265],
											[2219.395, 1121.304],
											[2219.326, 1121.346],
											[2219.256, 1121.389],
											[2219.185, 1121.434],
											[2219.113, 1121.481],
											[2219.042, 1121.529],
											[2218.97, 1121.579],
											[2218.899, 1121.63],
											[2218.828, 1121.683],
											[2218.758, 1121.736],
											[2218.69, 1121.791],
											[2218.622, 1121.846],
											[2218.556, 1121.903],
											[2218.493, 1121.96],
											[2218.431, 1122.018],
											[2218.372, 1122.076],
											[2218.316, 1122.135],
											[2218.263, 1122.194],
											[2218.213, 1122.253],
											[2218.167, 1122.313],
											[2218.125, 1122.372],
											[2218.087, 1122.432],
											[2218.054, 1122.491],
											[2218.025, 1122.55],
											[2218.001, 1122.609],
											[2217.983, 1122.667],
											[2217.97, 1122.724],
											[2217.963, 1122.781],
											[2217.963, 1122.837],
											[2217.968, 1122.893],
											[2217.981, 1122.947],
											[2218, 1123],
											[2218.215, 1123.429],
											[2218.446, 1123.773],
											[2218.688, 1124.037],
											[2218.94, 1124.226],
											[2219.198, 1124.347],
											[2219.46, 1124.405],
											[2219.724, 1124.405],
											[2219.985, 1124.353],
											[2220.241, 1124.254],
											[2220.489, 1124.114],
											[2220.728, 1123.939],
											[2220.953, 1123.734],
											[2221.161, 1123.504],
											[2221.351, 1123.255],
											[2221.519, 1122.992],
											[2221.663, 1122.722],
											[2221.779, 1122.449],
											[2221.865, 1122.179],
											[2221.917, 1121.918],
											[2221.934, 1121.671],
											[2221.912, 1121.444],
											[2221.848, 1121.242],
											[2221.74, 1121.071],
											[2221.585, 1120.936],
											[2221.379, 1120.843],
											[2221.121, 1120.798],
											[2220.807, 1120.805],
											[2220.434, 1120.87],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 18, s: [0], h: 1 },
							{ t: 19, s: [100], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 1',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 19,
								s: [
									{
										i: [
											[0.503, -0.362],
											[0.308, -1.385],
											[-0.012, -1.672],
											[-0.314, -1.326],
											[-0.597, -0.347],
											[-1.438, 1.382],
											[-0.151, 0.693],
											[1.047, 1.996],
											[1.047, 0.945],
											[0.47, -0.049],
										],
										o: [
											[-0.646, 0.466],
											[-0.308, 1.385],
											[0.012, 1.672],
											[0.314, 1.326],
											[2.097, 1.22],
											[1.438, -1.382],
											[0.234, -1.072],
											[-1.047, -1.996],
											[-0.618, -0.557],
											[-0.47, 0.048],
										],
										v: [
											[2492, 1125],
											[2490.573, 1127.935],
											[2490.135, 1132.678],
											[2490.629, 1137.332],
											[2492, 1140],
											[2497.459, 1138.935],
											[2500, 1135],
											[2498.461, 1129.904],
											[2495, 1125],
											[2493.414, 1124.311],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 24,
								s: [
									{
										i: [
											[5.461, -1.543],
											[1.733, -1.59],
											[0.468, -1.964],
											[-0.624, -2.017],
											[-1.542, -1.75],
											[-1.468, -1.14],
											[-1.093, -0.265],
											[-3.941, 4.216],
											[3.636, 6.046],
											[3.367, 1.025],
										],
										o: [
											[-3.172, 0.896],
											[-1.733, 1.59],
											[-0.468, 1.964],
											[0.624, 2.017],
											[1.036, 1.176],
											[1.468, 1.14],
											[7.87, 1.911],
											[3.941, -4.216],
											[-2.071, -3.445],
											[-3.367, -1.025],
										],
										v: [
											[2537, 1073],
											[2529.687, 1076.809],
											[2526.429, 1082.219],
											[2526.708, 1088.27],
											[2530, 1094],
											[2533.957, 1097.683],
											[2538, 1100],
											[2556.629, 1095.468],
											[2558, 1079],
											[2550.043, 1072.259],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 25,
								s: [
									{
										i: [
											[11.311, -3.405],
											[0.655, -0.479],
											[1.258, -1.446],
											[0.719, -1.256],
											[0.309, -2.889],
											[-1.114, -1.792],
											[-4.471, -0.679],
											[-1.55, 0.279],
											[-0.717, 6.344],
											[1.41, 3.79],
										],
										o: [
											[-2.123, 0.639],
											[-0.655, 0.479],
											[-1.815, 2.086],
											[-0.719, 1.256],
											[-0.575, 5.389],
											[2.395, 3.852],
											[1.805, 0.274],
											[4.679, -0.843],
											[0.375, -3.321],
											[-3.11, -8.357],
										],
										v: [
											[2533, 1070],
											[2529.351, 1071.395],
											[2527, 1074],
											[2523.37, 1078.398],
											[2522, 1084],
											[2524, 1093],
											[2536, 1102],
											[2542, 1102],
											[2554, 1088],
											[2552, 1079],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 26,
								s: [
									{
										i: [
											[0.323, -0.097],
											[1.254, -2.781],
											[0.233, -0.424],
											[-1.908, -3.991],
											[-3.779, -1.735],
											[-2.665, -0.585],
											[1.549, 9.253],
											[2.292, 2.462],
											[2.117, 0.544],
											[2.905, 0.621],
										],
										o: [
											[-4.108, 1.234],
											[-0.16, 0.356],
											[-3.624, 6.597],
											[2.261, 4.728],
											[3.211, 1.474],
											[11.026, 2.422],
											[-0.448, -2.677],
											[-2.159, -2.319],
											[-0.663, -0.171],
											[-1.061, -0.227],
										],
										v: [
											[2523, 1070],
											[2517, 1074],
											[2515, 1075],
											[2514, 1092],
											[2520, 1098],
											[2527, 1102],
											[2544, 1082],
											[2539, 1075],
											[2532, 1069],
											[2524, 1069],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 27,
								s: [
									{
										i: [
											[6.073, -1.094],
											[0.87, 0.095],
											[0.745, -0.24],
											[1.376, -1.313],
											[0.123, -0.309],
											[-1.201, -3.4],
											[-1.376, -0.614],
											[-2.881, 4.517],
											[2.708, 2.825],
											[3.178, 1.563],
										],
										o: [
											[-0.774, 0.139],
											[-0.87, -0.095],
											[-1.372, 0.441],
											[-1.376, 1.313],
											[-1.371, 3.439],
											[0.703, 1.989],
											[7.002, 3.124],
											[2.563, -4.019],
											[-1.679, -1.752],
											[-4.944, -2.431],
										],
										v: [
											[2539, 1072],
											[2536.478, 1071.925],
											[2534, 1072],
											[2529.564, 1075.099],
											[2527, 1078],
											[2527, 1095],
											[2536, 1103],
											[2558, 1098],
											[2558, 1077],
											[2553, 1073],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 28,
								s: [
									{
										i: [
											[0.456, -0.199],
											[1.256, -3.465],
											[-2.064, -3.249],
											[-3.211, -0.485],
											[-1.896, 0.925],
											[-1.008, 1.286],
											[-0.131, 0.448],
											[0.927, 2.911],
											[1.965, 1.052],
											[2.232, -0.167],
										],
										o: [
											[-2.042, 0.892],
											[-1.256, 3.465],
											[1.261, 1.985],
											[3.211, 0.485],
											[0.836, -0.408],
											[1.008, -1.286],
											[0.672, -2.305],
											[-0.927, -2.911],
											[-1.616, -0.865],
											[-2.232, 0.167],
										],
										v: [
											[2556, 1078],
											[2550.421, 1085.233],
											[2551, 1096],
											[2558.524, 1099.682],
											[2567, 1099],
											[2570.029, 1096.03],
											[2572, 1093],
											[2571.477, 1084.56],
											[2567, 1078],
											[2560.63, 1077.202],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 29,
								s: [
									{
										i: [
											[3.392, -1.198],
											[1.091, -1.258],
											[0.229, -1.117],
											[-4.102, -2.783],
											[-4.7, 1.539],
											[-0.715, 0.758],
											[-0.678, 0.818],
											[-0.561, 0.975],
											[-0.01, 1.202],
											[2.478, 1.174],
										],
										o: [
											[-2.409, 0.851],
											[-1.091, 1.258],
											[-1.111, 5.408],
											[4.103, 2.783],
											[0.461, -0.151],
											[0.715, -0.758],
											[0.745, -0.898],
											[0.561, -0.975],
											[0.025, -2.829],
											[-3.445, -1.632],
										],
										v: [
											[1997, 1076],
											[1991.866, 1079.3],
											[1990, 1083],
											[1995.642, 1095.71],
											[2010, 1098],
											[2011.838, 1096.5],
											[2014, 1094],
											[2016.051, 1091.228],
											[2017, 1088],
											[2008, 1076],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 30,
								s: [
									{
										i: [
											[6.733, -1.475],
											[1.654, -1.751],
											[0.26, -1.31],
											[-3.384, -2.757],
											[-5.578, 1.633],
											[-0.418, 0.458],
											[-0.242, 0.14],
											[-0.705, 1.565],
											[-0.178, 0.483],
											[0.217, 0.226],
										],
										o: [
											[-3.124, 0.684],
											[-1.654, 1.751],
											[-1.112, 5.604],
											[3.384, 2.757],
											[0.088, -0.026],
											[0.418, -0.459],
											[2.51, -1.453],
											[0.17, -0.378],
											[1.27, -3.45],
											[-3.816, -3.983],
										],
										v: [
											[2014, 1077],
											[2006.852, 1081.031],
											[2004, 1086],
											[2007.982, 1098.928],
											[2022, 1101],
											[2022.885, 1100.086],
											[2024, 1099],
											[2027, 1096],
											[2029, 1095],
											[2028, 1083],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[4.361, -1.42],
											[0.711, -0.267],
											[0.645, -0.374],
											[0.486, -0.545],
											[0.235, -0.781],
											[-3.377, -2.308],
											[-4.083, 0.515],
											[-1.143, 2.271],
											[0.105, 1.722],
											[2.602, 1.415],
										],
										o: [
											[-0.686, 0.224],
											[-0.711, 0.267],
											[-0.645, 0.374],
											[-0.486, 0.545],
											[-1.592, 5.283],
											[3.377, 2.308],
											[1.534, -0.193],
											[1.143, -2.271],
											[-0.229, -3.76],
											[-2.602, -1.415],
										],
										v: [
											[2030, 1084],
											[2027.881, 1084.719],
											[2025.824, 1085.664],
											[2024.105, 1087.027],
											[2023, 1089],
											[2026.743, 1100.348],
											[2039, 1103],
											[2043.229, 1098.646],
											[2045, 1092],
											[2040.599, 1084.115],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[1.27, -0.677],
											[0.859, -0.597],
											[0.152, -0.394],
											[-3.049, -1.756],
											[-2.467, 1.325],
											[-0.653, 1.431],
											[0.04, 1.046],
											[0.965, 1.45],
											[0.095, 0.039],
											[1.45, -0.177],
										],
										o: [
											[-0.412, 0.22],
											[-0.859, 0.597],
											[-1.662, 4.303],
											[3.049, 1.756],
											[0.426, -0.229],
											[0.653, -1.431],
											[-0.044, -1.159],
											[-0.965, -1.451],
											[-0.931, -0.383],
											[-1.45, 0.177],
										],
										v: [
											[2044, 1089],
											[2041.805, 1090.37],
											[2040, 1092],
											[2043.404, 1101.221],
											[2053, 1102],
											[2054.849, 1099.113],
											[2056, 1095],
											[2054.039, 1090.661],
											[2052, 1088],
											[2048.254, 1087.705],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[0.03, -0.006],
											[0.252, -1.018],
											[-1.046, -0.914],
											[-1.4, -0.189],
											[-0.81, 1.157],
											[0.064, 0.836],
											[0.492, 0.745],
											[0.807, 0.412],
											[1.008, -0.165],
											[-0.015, -0.247],
										],
										o: [
											[-2.494, 0.5],
											[-0.252, 1.018],
											[1.046, 0.914],
											[1.4, 0.189],
											[0.478, -0.683],
											[-0.064, -0.836],
											[-0.492, -0.745],
											[-0.807, -0.412],
											[-0.029, 0.005],
											[0.015, 0.247],
										],
										v: [
											[2057, 1093],
											[2053.118, 1095.432],
											[2054.545, 1098.485],
											[2058.45, 1100.295],
											[2062, 1099],
											[2062.593, 1096.661],
											[2061.73, 1094.228],
											[2059.752, 1092.431],
											[2057, 1092],
											[2057, 1092.499],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[0.039, -0.023],
											[-0.152, -0.944],
											[-1.097, -0.692],
											[-1.272, -0.152],
											[-0.676, 0.676],
											[0.293, 0.838],
											[0.903, 0.702],
											[1.034, 0.388],
											[0.686, -0.103],
											[-0.02, -0.243],
										],
										o: [
											[-1.564, 0.907],
											[0.152, 0.944],
											[1.097, 0.692],
											[1.272, 0.152],
											[0.796, -0.796],
											[-0.293, -0.838],
											[-0.902, -0.702],
											[-1.034, -0.388],
											[-0.039, 0.006],
											[0.019, 0.243],
										],
										v: [
											[2064, 1093],
											[2062.074, 1095.848],
											[2064.14, 1098.374],
											[2067.886, 1099.713],
											[2071, 1099],
											[2071.636, 1096.505],
											[2069.724, 1094.151],
											[2066.7, 1092.472],
											[2064, 1092],
											[2064.001, 1092.487],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[0.471, -0.256],
											[0.197, -0.415],
											[-0.22, -0.355],
											[-0.472, -0.243],
											[-0.557, -0.081],
											[-0.475, 0.133],
											[-0.228, 0.397],
											[0.139, 0.516],
											[0.501, 0.233],
											[0.611, -0.025],
										],
										o: [
											[-0.781, 0.425],
											[-0.197, 0.415],
											[0.22, 0.355],
											[0.472, 0.243],
											[0.557, 0.081],
											[0.475, -0.133],
											[0.473, -0.825],
											[-0.139, -0.516],
											[-0.5, -0.233],
											[-0.611, 0.025],
										],
										v: [
											[2072, 1095],
											[2070.575, 1096.272],
											[2070.651, 1097.439],
											[2071.731, 1098.348],
											[2073.315, 1098.847],
											[2074.904, 1098.782],
											[2076, 1098],
											[2076.439, 1095.994],
											[2075.417, 1094.877],
											[2073.686, 1094.572],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 36,
								s: [
									{
										i: [
											[0.946, -0.399],
											[0.684, -0.706],
											[0.44, -0.956],
											[-0.09, -1.022],
											[-0.903, -0.903],
											[-1.556, 0.439],
											[-0.959, 1.587],
											[0.077, 1.729],
											[1.551, 0.866],
											[0.847, -0.243],
										],
										o: [
											[-0.644, 0.272],
											[-0.684, 0.706],
											[-0.44, 0.956],
											[0.09, 1.022],
											[1.715, 1.715],
											[1.556, -0.439],
											[0.959, -1.587],
											[-0.077, -1.729],
											[-0.665, -0.371],
											[-0.847, 0.243],
										],
										v: [
											[2204, 1117],
											[2201.936, 1118.513],
											[2200.178, 1121.053],
											[2199.582, 1124.066],
											[2201, 1127],
											[2206.016, 1128.662],
											[2209.898, 1125.371],
											[2211.331, 1120.144],
											[2209, 1116],
											[2206.711, 1115.923],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 37,
								s: [
									{
										i: [
											[0.01, -0.001],
											[0.509, -0.123],
											[0.369, -0.274],
											[0.05, -0.514],
											[-0.447, -0.844],
											[-0.852, 0.186],
											[-0.523, 0.982],
											[0.216, 0.93],
											[1.365, 0.029],
											[-0.004, -0.25],
										],
										o: [
											[-0.471, 0.062],
											[-0.509, 0.123],
											[-0.369, 0.274],
											[-0.05, 0.514],
											[0.771, 1.457],
											[0.852, -0.186],
											[0.523, -0.982],
											[-0.216, -0.93],
											[-0.007, 0],
											[0.004, 0.25],
										],
										v: [
											[2395, 1168],
											[2393.485, 1168.254],
											[2392.123, 1168.826],
											[2391.45, 1169.985],
											[2392, 1172],
											[2394.538, 1173.694],
											[2396.705, 1171.73],
											[2397.269, 1168.65],
											[2395, 1167],
											[2395.002, 1167.499],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 38,
								s: [
									{
										i: [
											[0.832, -0.413],
											[0.225, -0.349],
											[-0.053, -0.352],
											[-0.243, -0.302],
											[-0.345, -0.199],
											[-0.359, -0.043],
											[-0.286, 0.166],
											[-0.16, 0.555],
											[0.159, 0.535],
											[0.49, 0.212],
										],
										o: [
											[-0.59, 0.293],
											[-0.225, 0.349],
											[0.053, 0.352],
											[0.243, 0.302],
											[0.345, 0.199],
											[0.359, 0.043],
											[0.468, -0.272],
											[0.16, -0.555],
											[-0.159, -0.535],
											[-0.49, -0.212],
										],
										v: [
											[2399, 1170],
											[2397.8, 1170.975],
											[2397.564, 1172.039],
											[2398.029, 1173.033],
											[2398.932, 1173.797],
											[2400.01, 1174.172],
											[2401, 1174],
											[2401.946, 1172.683],
											[2401.951, 1170.972],
											[2400.981, 1169.775],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 39,
								s: [
									{
										i: [
											[0.055, -0.023],
											[0.031, -0.448],
											[-0.66, -0.284],
											[-0.736, -0.068],
											[-0.197, 0.197],
											[0.124, 0.502],
											[0.485, 0.366],
											[0.493, 0.181],
											[0.149, -0.052],
											[-0.028, -0.239],
										],
										o: [
											[-1.336, 0.563],
											[-0.031, 0.448],
											[0.66, 0.284],
											[0.736, 0.068],
											[0.589, -0.589],
											[-0.124, -0.502],
											[-0.485, -0.366],
											[-0.493, -0.181],
											[-0.055, 0.019],
											[0.028, 0.239],
										],
										v: [
											[1770, 1174],
											[1768.104, 1175.529],
											[1769.2, 1176.64],
											[1771.447, 1177.181],
											[1773, 1177],
											[1773.61, 1175.352],
											[1772.608, 1174.038],
											[1771.052, 1173.206],
											[1770, 1173],
											[1770, 1173.497],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 18, s: [0], h: 1 },
							{ t: 19, s: [100], h: 1 },
							{ t: 20, s: [0], h: 1 },
							{ t: 24, s: [100], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 2',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 19,
								s: [
									{
										i: [
											[1.251, -0.836],
											[0.18, -1.04],
											[-0.61, -0.862],
											[-0.985, -0.391],
											[-0.945, 0.372],
											[-0.184, 1.077],
											[0.411, 1.121],
											[0.889, 0.483],
										],
										o: [
											[-1.385, 0.925],
											[-0.18, 1.04],
											[0.61, 0.862],
											[0.985, 0.391],
											[0.894, -0.352],
											[0.184, -1.077],
											[-0.411, -1.121],
											[-0.889, -0.483],
										],
										v: [
											[2491, 1161],
											[2488.757, 1164.02],
											[2489.505, 1166.946],
											[2492.001, 1168.898],
											[2495, 1169],
											[2496.588, 1166.685],
											[2496.218, 1163.218],
											[2494.239, 1160.642],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 24,
								s: [
									{
										i: [
											[1.282, -0.697],
											[-0.015, -1.408],
											[-1.405, -0.937],
											[-1.687, 0.065],
											[-0.863, 1.596],
											[0.197, 1.156],
											[0.883, 0.674],
											[1.244, 0.056],
										],
										o: [
											[-2.481, 1.35],
											[0.015, 1.408],
											[1.405, 0.937],
											[1.687, -0.065],
											[0.813, -1.503],
											[-0.197, -1.156],
											[-0.883, -0.674],
											[-1.244, -0.056],
										],
										v: [
											[2512, 1082],
											[2508.579, 1086.269],
											[2510.985, 1089.918],
											[2515.899, 1091.359],
											[2520, 1089],
											[2520.842, 1084.977],
											[2519.141, 1082.199],
											[2515.87, 1081.072],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 25,
								s: [
									{
										i: [
											[0.222, -0.09],
											[0.273, -0.99],
											[-0.642, -0.874],
											[-1.094, -0.322],
											[-1.083, 0.666],
											[-0.053, 1.788],
											[0.797, 0.489],
											[0.759, -0.341],
										],
										o: [
											[-1.651, 0.669],
											[-0.273, 0.99],
											[0.642, 0.874],
											[1.094, 0.322],
											[0.583, -0.359],
											[0.053, -1.788],
											[-0.741, -0.455],
											[-0.759, 0.341],
										],
										v: [
											[2508, 1083],
											[2505.23, 1085.597],
											[2505.9, 1088.501],
											[2508.619, 1090.405],
											[2512, 1090],
											[2513.035, 1086.098],
											[2512, 1082],
											[2509.611, 1082.091],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 26,
								s: [
									{
										i: [
											[0.05, -0.017],
											[0.432, -0.799],
											[-0.27, -0.806],
											[-0.817, -0.479],
											[-1.208, 0.182],
											[-0.196, 1.715],
											[1.001, 0.958],
											[0.619, -0.359],
										],
										o: [
											[-1.288, 0.458],
											[-0.432, 0.799],
											[0.27, 0.806],
											[0.817, 0.479],
											[1.216, -0.183],
											[0.196, -1.715],
											[-0.473, -0.453],
											[-0.619, 0.359],
										],
										v: [
											[2499, 1084],
											[2496.459, 1085.968],
											[2496.256, 1088.459],
											[2497.924, 1090.47],
											[2501, 1091],
											[2503.163, 1087.582],
											[2502, 1083],
											[2500.182, 1083.147],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 27,
								s: [
									{
										i: [
											[1.639, 0.671],
											[0.33, -0.334],
											[0.382, -0.23],
											[0.236, -0.281],
											[-0.107, -0.486],
											[-0.922, -0.333],
											[-0.145, 0.097],
											[0.024, 1.21],
										],
										o: [
											[-0.08, 0.592],
											[-0.33, 0.334],
											[-0.382, 0.23],
											[-0.236, 0.281],
											[0.168, 0.764],
											[0.922, 0.333],
											[0.735, -0.49],
											[-0.024, -1.21],
										],
										v: [
											[2491, 1085],
											[2490.336, 1086.35],
											[2489.22, 1087.158],
											[2488.243, 1087.887],
											[2488, 1089],
											[2490.017, 1090.645],
											[2492, 1091],
											[2493.28, 1088.136],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 28,
								s: [
									{
										i: [
											[3.551, -1.485],
											[1.207, -1.844],
											[-1.298, -3.008],
											[-1.121, -0.404],
											[-1.042, -0.285],
											[-1.317, 0.634],
											[-0.722, 2.289],
											[1.909, 1.932],
										],
										o: [
											[-1.472, 0.615],
											[-1.207, 1.844],
											[0.474, 1.098],
											[1.121, 0.404],
											[3.012, 0.823],
											[1.317, -0.634],
											[1.185, -3.756],
											[-1.909, -1.932],
										],
										v: [
											[2266, 1084],
											[2261.422, 1087.706],
											[2261, 1095],
											[2263.574, 1097.11],
											[2267, 1098],
											[2273.218, 1098.334],
											[2276, 1094],
											[2274.551, 1085.069],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 29,
								s: [
									{
										i: [
											[2.938, -1.912],
											[0.347, -1.688],
											[-0.803, -1.543],
											[-1.498, -0.833],
											[-1.739, 0.441],
											[-0.742, 2.008],
											[0.53, 2.098],
											[1.757, 0.791],
										],
										o: [
											[-1.95, 1.269],
											[-0.347, 1.688],
											[0.803, 1.543],
											[1.498, 0.833],
											[2.059, -0.522],
											[0.742, -2.008],
											[-0.53, -2.098],
											[-1.757, -0.791],
										],
										v: [
											[2570, 1083],
											[2566.668, 1087.578],
											[2567.465, 1092.565],
											[2571.03, 1096.27],
											[2576, 1097],
											[2580.189, 1092.856],
											[2580.495, 1086.349],
											[2577.053, 1081.667],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 30,
								s: [
									{
										i: [
											[1.07, -0.611],
											[-0.085, -1.423],
											[-1.594, -0.873],
											[-1.838, 0.111],
											[-0.814, 1.531],
											[0.358, 1.131],
											[1.059, 0.65],
											[1.296, 0.069],
										],
										o: [
											[-2.692, 1.537],
											[0.085, 1.423],
											[1.594, 0.873],
											[1.838, -0.111],
											[0.805, -1.513],
											[-0.358, -1.131],
											[-1.059, -0.65],
											[-1.296, -0.069],
										],
										v: [
											[2580, 1087],
											[2576.405, 1091.549],
											[2579.241, 1095.103],
											[2584.705, 1096.355],
											[2589, 1094],
											[2589.553, 1090.01],
											[2587.312, 1087.314],
											[2583.665, 1086.211],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[5.841, -1.846],
											[1.153, -3.117],
											[-1.659, -1.732],
											[-0.617, -0.746],
											[-7.547, 1.168],
											[-0.495, 0.574],
											[0.992, 3.138],
											[2.393, 1.258],
										],
										o: [
											[-5.073, 1.604],
											[-1.228, 3.319],
											[0.615, 0.642],
											[3.705, 4.479],
											[1.879, -0.291],
											[1.468, -1.703],
											[-1.044, -3.302],
											[-5.149, -2.708],
										],
										v: [
											[1710, 1088],
											[1701, 1096],
											[1701, 1109],
											[1702, 1111],
											[1725, 1118],
											[1734, 1114],
											[1737, 1097],
											[1727, 1089],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[8.406, -3.559],
											[0.623, -4.593],
											[-1.799, -2.803],
											[-3.526, -0.974],
											[-3.638, 0.856],
											[-1.664, 1.006],
											[0.128, -0.134],
											[1.198, 4.095],
										],
										o: [
											[-4.309, 1.824],
											[-0.622, 4.593],
											[1.258, 1.961],
											[3.526, 0.974],
											[0.471, -0.111],
											[1.664, -1.006],
											[2.129, -2.234],
											[-1.952, -6.671],
										],
										v: [
											[1726, 1091],
											[1718.919, 1101.766],
											[1721, 1114],
											[1728.715, 1118.613],
											[1740, 1119],
											[1743.949, 1116.816],
											[1747, 1115],
											[1751, 1098],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[9.266, -3.714],
											[1.938, -3.674],
											[-1.773, -4.493],
											[-2.336, -1.517],
											[-0.331, -0.109],
											[-0.872, 0.835],
											[-0.47, 2.183],
											[1.395, 1.395],
										],
										o: [
											[-2.023, 0.811],
											[-1.938, 3.674],
											[0.325, 0.825],
											[2.336, 1.517],
											[5.18, 1.694],
											[2.102, -2.014],
											[1.197, -5.556],
											[-1.41, -1.41],
										],
										v: [
											[1744, 1095],
											[1737.153, 1102.238],
											[1736, 1115],
											[1740.996, 1119.037],
											[1746, 1122],
											[1761, 1122],
											[1768, 1113],
											[1765, 1097],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[5.516, -1.833],
											[1.296, -1.627],
											[0.287, -1.65],
											[-0.852, -1.821],
											[-2.134, -1.583],
											[-6.653, -0.739],
											[-2.685, 2.848],
											[0.887, 2.797],
										],
										o: [
											[-2.531, 0.841],
											[-1.296, 1.627],
											[-0.544, 3.134],
											[0.852, 1.821],
											[4.323, 3.208],
											[5.382, 0.598],
											[1.518, -1.61],
											[-2.057, -6.487],
										],
										v: [
											[1766, 1098],
											[1760.317, 1101.893],
											[1758, 1107],
											[1758.492, 1114.163],
											[1763, 1119],
											[1776, 1123],
											[1787, 1121],
											[1790, 1106],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[2.174, -0.909],
											[1.476, -2.702],
											[-1.566, -3.496],
											[-2.608, -0.422],
											[-2.145, 1.192],
											[-0.362, 0.435],
											[0.735, 2.764],
											[1.25, 0.249],
										],
										o: [
											[-1.664, 0.696],
											[-1.476, 2.702],
											[1.245, 2.78],
											[3.701, 0.599],
											[0.5, -0.278],
											[1.72, -2.067],
											[-1.061, -3.989],
											[-2.174, -0.433],
										],
										v: [
											[1788, 1104],
											[1782.578, 1109.399],
											[1782, 1119],
											[1792, 1123],
											[1800, 1123],
											[1803, 1122],
											[1804, 1111],
											[1795, 1103],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 36,
								s: [
									{
										i: [
											[1.778, -0.329],
											[0.678, -0.079],
											[0.439, -0.528],
											[0.216, -1.056],
											[0.089, 0.284],
											[-2.859, 1.009],
											[2.213, 3.448],
											[0.628, -0.195],
										],
										o: [
											[-0.85, 0.158],
											[-0.678, 0.079],
											[-0.048, 0.059],
											[-0.216, 1.056],
											[0.776, 2.484],
											[2.859, -1.009],
											[-0.712, -1.11],
											[-0.628, 0.195],
										],
										v: [
											[2386, 1168],
											[2383.692, 1168.223],
											[2382, 1169],
											[2381.53, 1171.257],
											[2381, 1173],
											[2388.242, 1174.949],
											[2391, 1168],
											[2389.299, 1166.921],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 37,
								s: [
									{
										i: [
											[0.766, -0.263],
											[0.113, -0.908],
											[-0.962, -0.701],
											[-1.219, -0.067],
											[-0.658, 0.994],
											[0.116, 0.722],
											[0.585, 0.462],
											[0.802, 0.134],
										],
										o: [
											[-2.007, 0.689],
											[-0.113, 0.908],
											[0.962, 0.701],
											[1.219, 0.067],
											[0.606, -0.915],
											[-0.116, -0.722],
											[-0.585, -0.462],
											[-0.802, -0.134],
										],
										v: [
											[1761, 1172],
											[1758.024, 1174.503],
											[1759.502, 1177.024],
											[1762.979, 1178.283],
											[1766, 1177],
											[1766.672, 1174.527],
											[1765.558, 1172.733],
											[1763.415, 1171.823],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 38,
								s: [
									{
										i: [
											[1.964, -1.123],
											[0.279, -0.178],
											[0.258, -0.256],
											[0.1, -0.324],
											[-0.195, -0.385],
											[-0.963, 0.315],
											[-0.526, 1.079],
											[0.45, 0.6],
										],
										o: [
											[-0.162, 0.092],
											[-0.279, 0.178],
											[-0.258, 0.256],
											[-0.1, 0.324],
											[0.861, 1.693],
											[0.963, -0.315],
											[0.526, -1.079],
											[-0.45, -0.6],
										],
										v: [
											[1766, 1173],
											[1765.305, 1173.408],
											[1764.464, 1174.062],
											[1763.892, 1174.934],
											[1764, 1176],
											[1766.87, 1177.757],
											[1769.237, 1175.356],
											[1769.486, 1172.527],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 18, s: [0], h: 1 },
							{ t: 19, s: [100], h: 1 },
							{ t: 20, s: [0], h: 1 },
							{ t: 24, s: [100], h: 1 },
							{ t: 39, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 3',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 24,
								s: [
									{
										i: [
											[0.58, -0.28],
											[-0.703, -2.373],
											[-2.908, 0.807],
											[3.555, 1.273],
										],
										o: [
											[-2.72, 1.315],
											[0.703, 2.373],
											[3.966, -1.101],
											[-1.494, -0.535],
										],
										v: [
											[2482, 1106],
											[2479.279, 1112.592],
											[2485, 1116],
											[2485, 1105],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 25,
								s: [
									{
										i: [
											[0.547, -0.243],
											[-2.076, -1.916],
											[-1.685, 3.438],
											[1.657, 0.647],
										],
										o: [
											[-3.988, 1.774],
											[2.076, 1.916],
											[0.574, -1.171],
											[-1.466, -0.573],
										],
										v: [
											[2478, 1107],
											[2476.745, 1113.909],
											[2484, 1113],
											[2481, 1106],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 26,
								s: [
									{
										i: [
											[-0.306, 0.029],
											[-0.974, -1.979],
											[-1.971, 1.282],
											[1.558, 2.395],
										],
										o: [
											[-3.867, 0.365],
											[0.974, 1.979],
											[0.967, -0.629],
											[-0.688, -1.057],
										],
										v: [
											[2475, 1108],
											[2471.622, 1112.735],
											[2477, 1115],
											[2478, 1108],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 27,
								s: [
									{
										i: [
											[2.185, -1.471],
											[-1.456, -1.34],
											[-1.432, 0.553],
											[1.708, 1.078],
										],
										o: [
											[-3.256, 2.193],
											[1.456, 1.34],
											[1.974, -0.762],
											[-0.813, -0.513],
										],
										v: [
											[2467, 1109],
											[2465.484, 1114.559],
											[2471, 1116],
											[2472, 1108],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 28,
								s: [
									{
										i: [
											[1.993, -0.807],
											[0.582, -0.688],
											[-0.983, -0.983],
											[-0.569, 1.631],
										],
										o: [
											[-0.656, 0.266],
											[-0.582, 0.688],
											[1.717, 1.717],
											[0.569, -1.631],
										],
										v: [
											[2486, 1087],
											[2483.771, 1088.462],
											[2484, 1091],
											[2487.782, 1089.682],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 29,
								s: [
									{
										i: [
											[5.715, -3.651],
											[-1.865, -2.805],
											[-3.503, 1.751],
											[-0.255, 0.857],
										],
										o: [
											[-3.961, 2.532],
											[1.865, 2.805],
											[0.147, -0.074],
											[2.545, -8.564],
										],
										v: [
											[2277, 1087],
											[2274.902, 1096.212],
											[2284, 1099],
											[2287, 1095],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 30,
								s: [
									{
										i: [
											[2.073, -0.73],
											[1.072, -1.283],
											[-1.046, -1.601],
											[1.633, 3.707],
										],
										o: [
											[-0.76, 0.267],
											[-1.072, 1.283],
											[3.506, 5.365],
											[-0.972, -2.206],
										],
										v: [
											[2289, 1089],
											[2285.645, 1091.5],
											[2285, 1096],
											[2294, 1090],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[0.453, -0.231],
											[-1.546, -1.329],
											[-0.873, 0.874],
											[2.319, 1.142],
										],
										o: [
											[-3.056, 1.557],
											[1.546, 1.329],
											[0.269, -0.269],
											[-1.351, -0.665],
										],
										v: [
											[2590, 1089],
											[2589.053, 1093.823],
											[2594, 1095],
											[2593, 1088],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[1.167, -1.167],
											[-1.239, -0.399],
											[-0.459, 1.011],
											[0.808, 0.573],
										],
										o: [
											[-1.415, 1.415],
											[1.239, 0.399],
											[0.397, -0.874],
											[-0.808, -0.573],
										],
										v: [
											[2596, 1092],
											[2596.594, 1094.82],
											[2600, 1094],
											[2599.173, 1091.47],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[5.936, -2.783],
											[-0.223, -1.879],
											[-2.234, 2.234],
											[-0.038, 1.493],
										],
										o: [
											[-3.664, 1.718],
											[0.559, 4.703],
											[0.468, -0.468],
											[0.144, -5.616],
										],
										v: [
											[1592, 1100],
											[1588, 1106],
											[1600, 1110],
											[1601, 1105],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[0.065, -0.026],
											[-2.093, -1.693],
											[-1.665, 2.358],
											[1.926, -0.174],
										],
										o: [
											[-4.035, 1.585],
											[2.093, 1.693],
											[2.607, -3.69],
											[-0.06, 0.005],
										],
										v: [
											[1602, 1103],
											[1600.725, 1108.957],
											[1608, 1109],
											[1602, 1102],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[0.025, -0.004],
											[0.663, -0.781],
											[-0.385, -0.757],
											[5.55, 0.12],
										],
										o: [
											[-0.731, 0.12],
											[-0.663, 0.781],
											[2.991, 5.884],
											[-0.018, 0],
										],
										v: [
											[1613, 1106],
											[1610.663, 1107.522],
											[1610, 1110],
											[1613, 1105],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 36,
								s: [
									{
										i: [
											[2.637, -1.537],
											[-2.922, -4.172],
											[-1.173, -0.059],
											[1.645, 1.205],
										],
										o: [
											[-1.392, 0.811],
											[0.373, 0.533],
											[5.426, 0.272],
											[-0.804, -0.589],
										],
										v: [
											[1752, 1171],
											[1749, 1179],
											[1754, 1180],
											[1758, 1170],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 23, s: [0], h: 1 },
							{ t: 24, s: [100], h: 1 },
							{ t: 37, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 4',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 25,
								s: [
									{
										i: [
											[10.026, -1.02],
											[2.709, -1.284],
											[2.421, -2.38],
											[1.174, -2.692],
											[-0.335, -4.637],
											[-1.16, -1.775],
											[-3.02, -2.197],
											[-4.46, -1.179],
											[-5.929, 1.066],
											[-2.471, 2.392],
											[-1.493, 3.6],
											[0.541, 3.85],
											[3.016, 3.347],
										],
										o: [
											[-4.742, 0.482],
											[-2.709, 1.284],
											[-2.64, 2.596],
											[-1.174, 2.692],
											[0.34, 4.702],
											[1.16, 1.775],
											[3.229, 2.349],
											[4.46, 1.179],
											[4.625, -0.832],
											[2.47, -2.392],
											[2.178, -5.254],
											[-0.541, -3.85],
											[-6.199, -6.88],
										],
										v: [
											[2583, 1109],
											[2572.259, 1111.576],
											[2565, 1117],
											[2559.268, 1124.469],
											[2558, 1135],
											[2559.99, 1143.879],
											[2566, 1149],
											[2577.475, 1154.562],
											[2593, 1155],
											[2603.349, 1150.076],
											[2609, 1141],
											[2611.396, 1127.57],
											[2606, 1117],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 26,
								s: [
									{
										i: [
											[6.175, -1.391],
											[3.458, -3.152],
											[0.072, -0.187],
											[0.222, -2.299],
											[-4.399, -4.794],
											[-2.245, -0.829],
											[-4.998, -0.7],
											[-3.275, 0.472],
											[-1.845, 4.954],
											[0.721, 3.693],
											[3.525, 2.392],
											[1.937, 0.788],
											[0.955, 0.202],
										],
										o: [
											[-4.958, 1.117],
											[-1.196, 1.09],
											[-0.272, 0.711],
											[-1.127, 11.65],
											[2.147, 2.34],
											[3.405, 1.258],
											[4.132, 0.579],
											[6.007, -0.866],
											[1.728, -4.638],
											[-0.688, -3.525],
											[-1.081, -0.733],
											[-1.849, -0.752],
											[-4.91, -1.04],
										],
										v: [
											[2585, 1108],
											[2574, 1113],
											[2568, 1120],
											[2567, 1127],
											[2576, 1150],
											[2581, 1154],
											[2594, 1155],
											[2604, 1157],
											[2619, 1143],
											[2621, 1124],
											[2612, 1111],
											[2607, 1110],
											[2603, 1107],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 27,
								s: [
									{
										i: [
											[0.043, -0.024],
											[0.176, -0.097],
											[0.19, -0.124],
											[0.158, -0.148],
											[0.083, -0.169],
											[-0.037, -0.187],
											[-0.856, -0.161],
											[-0.907, 0.416],
											[-0.347, 0.744],
											[0.822, 0.822],
											[0.413, -0.035],
											[0.421, -0.187],
											[0.298, -0.183],
										],
										o: [
											[-0.119, 0.066],
											[-0.176, 0.097],
											[-0.19, 0.124],
											[-0.158, 0.148],
											[-0.083, 0.169],
											[0.195, 0.986],
											[0.856, 0.161],
											[0.907, -0.416],
											[0.347, -0.744],
											[-0.272, -0.272],
											[-0.413, 0.035],
											[-0.421, 0.187],
											[-0.298, 0.183],
										],
										v: [
											[2480, 1110],
											[2479.546, 1110.245],
											[2478.986, 1110.577],
											[2478.453, 1110.986],
											[2478.08, 1111.464],
											[2478, 1112],
											[2479.729, 1113.658],
											[2482.525, 1113.212],
											[2484.559, 1111.41],
											[2484, 1109],
											[2482.94, 1108.683],
											[2481.657, 1109.056],
											[2480.545, 1109.65],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 28,
								s: [
									{
										i: [
											[0, 0],
											[0.313, -0.175],
											[0.27, -0.229],
											[0.192, -0.243],
											[0.081, -0.218],
											[-0.064, -0.154],
											[-0.895, -0.081],
											[-0.864, 0.503],
											[-0.287, 0.832],
											[0.836, 0.909],
											[0.321, -0.05],
											[0.322, -0.183],
											[0.215, -0.166],
										],
										o: [
											[-0.322, 0.083],
											[-0.313, 0.175],
											[-0.27, 0.229],
											[-0.192, 0.243],
											[-0.081, 0.218],
											[0.38, 0.919],
											[0.895, 0.081],
											[0.864, -0.502],
											[0.287, -0.832],
											[-0.213, -0.232],
											[-0.321, 0.05],
											[-0.322, 0.183],
											[-0.215, 0.166],
										],
										v: [
											[2463, 1110],
											[2462.038, 1110.397],
											[2461.156, 1111.013],
											[2460.454, 1111.731],
											[2460.035, 1112.433],
											[2460, 1113],
											[2462.048, 1114.437],
											[2464.822, 1113.741],
											[2466.686, 1111.675],
											[2466, 1109],
											[2465.172, 1108.765],
											[2464.18, 1109.152],
											[2463.349, 1109.713],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 29,
								s: [
									{
										i: [
											[3.33, -1.042],
											[0.26, -0.145],
											[0.37, -0.237],
											[0.61, -0.587],
											[0.424, -0.554],
											[0.599, -0.978],
											[0.462, -6.728],
											[-4.722, -4.575],
											[-6.963, 1.073],
											[-2.853, 2.378],
											[-1.15, 7.305],
											[7.431, 4.228],
											[2.853, 0.763],
										],
										o: [
											[-0.588, 0.184],
											[-0.26, 0.145],
											[-0.688, 0.44],
											[-0.528, 0.508],
											[-0.593, 0.775],
											[-2.817, 4.599],
											[-0.687, 10.009],
											[3.754, 3.637],
											[1.023, -0.158],
											[5.355, -4.465],
											[1.604, -10.183],
											[-2.092, -1.19],
											[-5.389, -1.44],
										],
										v: [
											[2077, 1097],
											[2075.837, 1097.46],
											[2075, 1098],
											[2072, 1099],
											[2071, 1102],
											[2069, 1104],
											[2065, 1118],
											[2070, 1135],
											[2087, 1141],
											[2096, 1136],
											[2104, 1120],
											[2096, 1100],
											[2088, 1098],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 30,
								s: [
									{
										i: [
											[6.012, -1.408],
											[2.026, -1.581],
											[0.912, -1.976],
											[0.427, -0.549],
											[0.08, -0.349],
											[-1.325, -3.643],
											[-1.623, -1.41],
											[-1.625, -0.808],
											[-2.906, 0.633],
											[-0.917, 0.688],
											[-0.788, 0.83],
											[0.054, -0.235],
											[3.412, 2.077],
										],
										o: [
											[-2.984, 0.698],
											[-2.026, 1.581],
											[-0.211, 0.457],
											[-0.427, 0.549],
											[-0.925, 4.019],
											[1.325, 3.643],
											[0.535, 0.465],
											[1.888, 0.939],
											[1.926, -0.419],
											[0.963, -0.722],
											[0.963, -1.013],
											[2.222, -9.693],
											[-3.788, -2.306],
										],
										v: [
											[2094, 1096],
											[2086.446, 1099.542],
											[2082, 1105],
											[2080.902, 1106.581],
											[2080, 1108],
											[2081.089, 1119.956],
											[2086, 1128],
											[2092, 1131],
											[2103, 1132],
											[2108, 1128],
											[2111, 1127],
											[2116, 1119],
											[2109, 1097],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[0.131, -0.072],
											[0.186, -0.715],
											[-0.496, -0.625],
											[-0.823, -0.408],
											[-0.794, -0.064],
											[-0.408, 0.408],
											[-0.11, 0.57],
											[-0.006, 0.774],
											[0.232, 0.692],
											[0.604, 0.325],
											[0.407, -0.032],
											[0.37, -0.159],
											[0.278, -0.172],
										],
										o: [
											[-1.225, 0.678],
											[-0.186, 0.715],
											[0.496, 0.625],
											[0.823, 0.408],
											[0.794, 0.064],
											[0.082, -0.082],
											[0.11, -0.57],
											[0.006, -0.774],
											[-0.232, -0.692],
											[-0.388, -0.208],
											[-0.407, 0.032],
											[-0.37, 0.159],
											[-0.278, 0.172],
										],
										v: [
											[2294, 1091],
											[2291.972, 1093.121],
											[2292.526, 1095.164],
											[2294.594, 1096.746],
											[2297.108, 1097.485],
											[2299, 1097],
											[2299.321, 1095.951],
											[2299.528, 1093.865],
											[2299.221, 1091.596],
											[2298, 1090],
											[2296.794, 1089.764],
											[2295.614, 1090.079],
											[2294.627, 1090.605],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[0.024, -0.011],
											[0.195, -0.389],
											[-0.361, -0.287],
											[-0.607, -0.159],
											[-0.544, -0.006],
											[-0.171, 0.171],
											[-0.019, 0.37],
											[0, 0.48],
											[0.02, 0.37],
											[0.039, 0.039],
											[0.317, -0.047],
											[0.311, -0.18],
											[0.214, -0.167],
										],
										o: [
											[-1.06, 0.467],
											[-0.195, 0.389],
											[0.361, 0.287],
											[0.607, 0.159],
											[0.544, 0.006],
											[0.039, -0.039],
											[0.019, -0.37],
											[0, -0.481],
											[-0.019, -0.37],
											[-0.229, -0.229],
											[-0.317, 0.047],
											[-0.311, 0.18],
											[-0.214, 0.167],
										],
										v: [
											[2300, 1093],
											[2298.194, 1094.291],
											[2298.52, 1095.311],
											[2300.048, 1095.986],
											[2301.851, 1096.241],
											[2303, 1096],
											[2303.088, 1095.331],
											[2303.117, 1094],
											[2303.088, 1092.669],
											[2303, 1092],
											[2302.158, 1091.763],
											[2301.193, 1092.14],
											[2300.381, 1092.697],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[4.412, -1.061],
											[0.649, -0.208],
											[0.454, -0.227],
											[0.276, -0.265],
											[1.412, -2.505],
											[-1.146, -3.547],
											[-2.084, -1.366],
											[-2.283, -0.362],
											[-2.45, 1.538],
											[-0.427, 1.447],
											[0.293, 1.952],
											[0.223, 0.847],
											[1.905, 1.096],
										],
										o: [
											[-0.86, 0.207],
											[-0.649, 0.208],
											[-0.454, 0.227],
											[-1.497, 1.434],
											[-1.412, 2.505],
											[0.38, 1.175],
											[2.084, 1.366],
											[3.774, 0.599],
											[2.45, -1.538],
											[0.397, -1.345],
											[-0.293, -1.952],
											[-0.966, -3.675],
											[-1.906, -1.096],
										],
										v: [
											[1692, 1102],
											[1689.741, 1102.618],
											[1688.091, 1103.266],
											[1687, 1104],
											[1682.017, 1109.916],
											[1681, 1119],
											[1685.072, 1123.109],
											[1692, 1126],
											[1701.51, 1124.035],
											[1706, 1119],
											[1705.965, 1113.627],
											[1705, 1109],
											[1701.084, 1101.948],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[2.401, -1.229],
											[0.762, -0.956],
											[0.183, -1.073],
											[-0.306, -1.085],
											[-0.702, -0.99],
											[-1.007, -0.79],
											[-1.221, -0.484],
											[-1.343, -0.073],
											[-1.374, 0.444],
											[-0.728, 1.678],
											[0.572, 1.933],
											[1.528, 1.534],
											[2.137, 0.48],
										],
										o: [
											[-1.433, 0.734],
											[-0.762, 0.956],
											[-0.183, 1.073],
											[0.306, 1.085],
											[0.702, 0.99],
											[1.007, 0.79],
											[1.221, 0.484],
											[1.343, 0.073],
											[2.374, -0.768],
											[0.728, -1.678],
											[-0.572, -1.933],
											[-1.528, -1.534],
											[-2.137, -0.48],
										],
										v: [
											[1700, 1107],
											[1696.73, 1109.561],
											[1695.336, 1112.632],
											[1695.543, 1115.895],
											[1697.078, 1119.034],
											[1699.665, 1121.731],
											[1703.03, 1123.669],
											[1706.9, 1124.531],
											[1711, 1124],
											[1715.567, 1120.168],
											[1715.714, 1114.588],
											[1712.478, 1109.224],
											[1706.894, 1106.039],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[1.713, -0.707],
											[0.679, -0.519],
											[0.353, -0.533],
											[0.122, -0.498],
											[-0.012, -0.412],
											[-1.076, -1.077],
											[-1.543, -0.585],
											[-1.446, 0.034],
											[-0.784, 0.782],
											[-0.172, 1.616],
											[0.259, 1.52],
											[0.517, 0.485],
											[1.64, 0.006],
										],
										o: [
											[-1.101, 0.454],
											[-0.679, 0.519],
											[-0.353, 0.533],
											[-0.122, 0.498],
											[0.044, 1.44],
											[1.076, 1.077],
											[1.543, 0.585],
											[1.446, -0.034],
											[0.776, -0.773],
											[0.172, -1.616],
											[-0.259, -1.52],
											[-0.726, -0.681],
											[-1.64, -0.006],
										],
										v: [
											[1715, 1111],
											[1712.354, 1112.472],
											[1710.83, 1114.063],
											[1710.141, 1115.622],
											[1710, 1117],
											[1711.821, 1120.808],
											[1715.89, 1123.333],
											[1720.514, 1124.192],
											[1724, 1123],
											[1725.379, 1119.181],
											[1725.207, 1114.243],
											[1724, 1111],
											[1720.241, 1109.959],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 36,
								s: [
									{
										i: [
											[0.09, -0.026],
											[0.351, -0.672],
											[-0.399, -0.697],
											[-0.79, -0.503],
											[-0.824, -0.092],
											[-0.501, 0.538],
											[-0.037, 0.597],
											[0.04, 0.806],
											[0.182, 0.681],
											[0.389, 0.221],
											[0.314, -0.051],
											[0.272, -0.164],
											[0.198, -0.156],
										],
										o: [
											[-1.458, 0.429],
											[-0.351, 0.672],
											[0.399, 0.697],
											[0.79, 0.503],
											[0.824, 0.092],
											[0.05, -0.054],
											[0.037, -0.597],
											[-0.04, -0.806],
											[-0.182, -0.681],
											[-0.322, -0.183],
											[-0.314, 0.051],
											[-0.272, 0.164],
											[-0.198, 0.156],
										],
										v: [
											[1624, 1173],
											[1621.377, 1174.706],
											[1621.538, 1176.814],
											[1623.411, 1178.668],
											[1625.922, 1179.615],
											[1628, 1179],
											[1628.147, 1177.94],
											[1628.158, 1175.751],
											[1627.84, 1173.437],
											[1627, 1172],
											[1626.039, 1171.832],
											[1625.152, 1172.185],
											[1624.439, 1172.696],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 24, s: [0], h: 1 },
							{ t: 25, s: [100], h: 1 },
							{ t: 37, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 5',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 25,
								s: [
									{
										i: [
											[4.668, -0.831],
											[1.772, -0.928],
											[0.39, -1.019],
											[-1.567, -2.841],
											[-2.305, -0.534],
											[-2.595, 3.047],
											[1.635, 5.028],
											[0.77, 0.706],
											[-0.212, -0.231],
										],
										o: [
											[-1.523, 0.271],
											[-1.772, 0.928],
											[-1.573, 4.101],
											[1.567, 2.841],
											[5.254, 1.217],
											[2.595, -3.047],
											[-0.132, -0.406],
											[-0.77, -0.706],
											[-3.233, -3.531],
										],
										v: [
											[2527, 1125],
											[2521.651, 1126.939],
											[2518, 1130],
											[2518.592, 1140.675],
											[2525, 1146],
											[2537.167, 1142.684],
											[2539, 1130],
											[2537.242, 1128.022],
											[2536, 1127],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 26,
								s: [
									{
										i: [
											[3.938, -1.485],
											[1.353, -2.073],
											[-0.157, -1.673],
											[-2.783, -2.046],
											[-1.624, -0.2],
											[-2.06, 1.778],
											[-0.493, 2.415],
											[1.442, 2.602],
											[1.851, 0.566],
										],
										o: [
											[-2.747, 1.036],
											[-1.353, 2.073],
											[0.245, 2.616],
											[2.783, 2.046],
											[4.266, 0.526],
											[2.06, -1.778],
											[0.617, -3.024],
											[-1.442, -2.602],
											[-2.236, -0.684],
										],
										v: [
											[2535, 1126],
											[2528.822, 1131.023],
											[2527, 1137],
											[2532.465, 1144.312],
											[2540, 1148],
											[2549.33, 1145.706],
											[2553, 1139],
											[2551.351, 1130.157],
											[2546, 1125],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 27,
								s: [
									{
										i: [
											[13.845, -3.767],
											[-4.222, -17.297],
											[-1.383, -1.953],
											[-1.686, -0.826],
											[-4.235, 0.874],
											[-3.307, 6.417],
											[-0.251, 0.832],
											[-0.113, 0.531],
											[4.943, 5.256],
										],
										o: [
											[-12.139, 3.303],
											[0.215, 0.881],
											[1.597, 2.255],
											[3.65, 1.789],
											[3.739, -0.772],
											[0.395, -0.766],
											[0.112, -0.37],
											[2.584, -12.179],
											[-6.73, -7.157],
										],
										v: [
											[2605, 1111],
											[2587, 1146],
											[2591, 1153],
											[2597, 1159],
											[2619, 1161],
											[2637, 1146],
											[2637, 1143],
											[2639, 1141],
											[2632, 1118],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 28,
								s: [
									{
										i: [
											[9.428, -2.645],
											[0.283, -0.172],
											[0.321, -0.159],
											[0.993, -0.334],
											[0.349, -0.326],
											[0.766, -1.247],
											[0.426, -2.857],
											[-1.547, 18.304],
											[2.192, 3.343],
										],
										o: [
											[-0.548, 0.154],
											[-0.283, 0.172],
											[-0.68, 0.337],
											[-0.993, 0.334],
											[-0.903, 0.845],
											[-1.345, 2.19],
											[-4.008, 26.878],
											[0.277, -3.276],
											[-4.447, -6.78],
										],
										v: [
											[2633, 1121],
											[2631.829, 1121.496],
											[2631, 1122],
											[2628.251, 1123.008],
											[2626, 1124],
											[2624, 1129],
											[2621, 1134],
											[2658, 1140],
											[2654, 1129],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 29,
								s: [
									{
										i: [
											[3.516, -1.268],
											[1.365, -2.043],
											[0.098, -2.53],
											[-1.427, -2.316],
											[-3.511, -1.019],
											[-1.162, 0.362],
											[-0.646, 4.428],
											[2.06, 2.202],
											[2.223, 0.642],
										],
										o: [
											[-2.371, 0.855],
											[-1.365, 2.043],
											[-0.098, 2.53],
											[0.793, 1.288],
											[3.511, 1.019],
											[3.476, -1.085],
											[0.646, -4.428],
											[-1.592, -1.702],
											[-2.223, -0.642],
										],
										v: [
											[2657, 1129],
											[2651.331, 1133.522],
											[2649.072, 1140.556],
											[2651, 1148],
											[2658.724, 1151.738],
											[2667, 1153],
											[2673.152, 1143.337],
											[2671, 1132],
											[2665.443, 1128.273],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 30,
								s: [
									{
										i: [
											[4.113, -2.242],
											[0.518, -0.053],
											[0.361, -0.477],
											[0.227, -2.022],
											[-0.781, -1.758],
											[-2.515, -0.682],
											[-2.182, 1.436],
											[-0.391, 2.827],
											[1.293, 1.555],
										],
										o: [
											[-0.566, 0.309],
											[-0.518, 0.053],
											[-0.873, 1.153],
											[-0.227, 2.022],
											[0.758, 1.707],
											[2.515, 0.682],
											[1.729, -1.137],
											[0.391, -2.827],
											[-1.407, -1.692],
										],
										v: [
											[2673, 1135],
											[2671.347, 1135.373],
											[2670, 1136],
											[2668.26, 1141.047],
											[2669, 1147],
											[2674.432, 1150.857],
											[2682, 1150],
											[2685.267, 1143.314],
											[2684, 1136],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[1.449, -0.633],
											[0.674, -0.379],
											[0.473, -0.476],
											[0.201, -0.642],
											[-2.818, -2.192],
											[-3.083, 2.096],
											[-0.757, 2.498],
											[1.254, 2.177],
											[2.18, 0.298],
										],
										o: [
											[-0.802, 0.35],
											[-0.674, 0.379],
											[-0.473, 0.476],
											[-1.357, 4.329],
											[2.818, 2.192],
											[1.224, -0.832],
											[0.757, -2.498],
											[-0.829, -1.439],
											[-2.181, -0.298],
										],
										v: [
											[1770, 1099],
											[1767.769, 1100.076],
											[1766.03, 1101.34],
											[1765, 1103],
											[1768.17, 1113.319],
											[1778, 1114],
											[1781.358, 1108.509],
											[1781, 1101],
											[1775.965, 1098.446],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[6.099, -2.432],
											[1.267, -1.002],
											[0.554, -1.445],
											[0.15, -2.801],
											[0.042, 0.142],
											[-4.642, -2.23],
											[-3.772, 1.163],
											[-0.695, 4.314],
											[2.584, 1.88],
										],
										o: [
											[-1.376, 0.549],
											[-1.267, 1.002],
											[-0.36, 0.937],
											[-0.15, 2.801],
											[0.658, 2.243],
											[4.643, 2.23],
											[2.671, -0.824],
											[0.991, -6.154],
											[-1.756, -1.278],
										],
										v: [
											[1664, 1099],
											[1659.883, 1101.328],
											[1657, 1105],
											[1656.262, 1111.81],
											[1656, 1117],
											[1665.164, 1124.555],
											[1679, 1127],
											[1687, 1115],
											[1684, 1099],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[5.314, -1.716],
											[1.016, -0.404],
											[0.579, -0.945],
											[0.144, -1.847],
											[-0.301, -1.43],
											[-3.621, -1.52],
											[-2.703, 1.948],
											[-0.57, 3.994],
											[1.553, 2.106],
										],
										o: [
											[-1.359, 0.439],
											[-1.016, 0.404],
											[-0.725, 1.184],
											[-0.144, 1.847],
											[0.813, 3.869],
											[3.621, 1.52],
											[1.271, -0.917],
											[0.571, -3.994],
											[-1.319, -1.788],
										],
										v: [
											[2162, 1110],
											[2158.415, 1111.121],
											[2156, 1113],
											[2154.731, 1117.815],
											[2155, 1123],
											[2162.583, 1131.363],
											[2173, 1131],
											[2176.119, 1122.391],
											[2175, 1112],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[3.476, -1.376],
											[0.902, -1.624],
											[-0.394, -1.697],
											[-1.349, -1.225],
											[-1.962, -0.207],
											[-1.249, 0.46],
											[-0.672, 0.959],
											[-0.252, 1.286],
											[2.114, 1.658],
										],
										o: [
											[-2.539, 1.005],
											[-0.902, 1.624],
											[0.394, 1.697],
											[1.349, 1.225],
											[1.985, 0.209],
											[1.249, -0.46],
											[0.672, -0.959],
											[0.931, -4.745],
											[-2.114, -1.658],
										],
										v: [
											[2179, 1114],
											[2173.924, 1118.08],
											[2173.248, 1123.197],
											[2175.948, 1127.716],
											[2181, 1130],
											[2185.812, 1129.581],
											[2188.654, 1127.409],
											[2190, 1124],
											[2187.805, 1114.409],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[2.872, -1.932],
											[0.481, -0.808],
											[0.191, -0.865],
											[-0.01, -0.75],
											[-0.124, -0.461],
											[-2.567, -0.577],
											[-2.112, 1.554],
											[0.589, 3.509],
											[2.423, 1.085],
										],
										o: [
											[-0.859, 0.578],
											[-0.481, 0.808],
											[-0.191, 0.865],
											[0.01, 0.75],
											[0.775, 2.884],
											[2.567, 0.577],
											[2.112, -1.554],
											[-0.435, -2.59],
											[-2.423, -1.086],
										],
										v: [
											[2188, 1117],
											[2186.012, 1119.122],
											[2185.026, 1121.674],
											[2184.776, 1124.14],
											[2185, 1126],
											[2190.574, 1131.147],
											[2198.154, 1129.638],
											[2201, 1122],
											[2196.328, 1116.109],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 24, s: [0], h: 1 },
							{ t: 25, s: [100], h: 1 },
							{ t: 36, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 6',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 25,
								s: [
									{
										i: [
											[4.172, -0.734],
											[1.274, -0.579],
											[0.894, -1.329],
											[-2.985, -2.568],
											[-4.871, 1.45],
											[-0.631, 3.327],
											[2.032, 1.943],
										],
										o: [
											[-2.011, 0.354],
											[-1.274, 0.579],
											[-2.811, 4.178],
											[2.985, 2.568],
											[2.508, -0.746],
											[0.631, -3.328],
											[-1.859, -1.778],
										],
										v: [
											[2551, 1167],
											[2546.162, 1168.269],
											[2543, 1171],
											[2544.239, 1181.721],
											[2557, 1184],
											[2561.905, 1176.898],
											[2560, 1168],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 26,
								s: [
									{
										i: [
											[3.776, -0.991],
											[1.55, -1.195],
											[-0.111, -2.458],
											[-4.038, -1.908],
											[-3.733, 1.22],
											[0.59, 3.24],
											[2.197, 0.546],
										],
										o: [
											[-2.909, 0.764],
											[-1.55, 1.194],
											[0.117, 2.587],
											[4.038, 1.908],
											[3.469, -1.134],
											[-0.46, -2.53],
											[-2.544, -0.632],
										],
										v: [
											[2499, 1169],
											[2492.235, 1171.729],
											[2490, 1177],
											[2497.288, 1184.355],
											[2510, 1186],
											[2517, 1175],
											[2509, 1168],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 27,
								s: [
									{
										i: [
											[5.058, -1.947],
											[1.159, -0.8],
											[0.454, -1.447],
											[0.181, -2.352],
											[-0.586, -1.031],
											[-3.638, 1.826],
											[1.169, 3.502],
										],
										o: [
											[-0.912, 0.351],
											[-1.159, 0.8],
											[0.137, -0.438],
											[-0.181, 2.352],
											[1.397, 2.46],
											[3.386, -1.699],
											[-1.445, -4.331],
										],
										v: [
											[2553, 1129],
											[2549.656, 1130.678],
											[2547, 1134],
											[2546.664, 1137.899],
											[2547, 1144],
											[2564, 1147],
											[2568, 1134],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 28,
								s: [
									{
										i: [
											[2.038, -0.757],
											[0.42, -0.487],
											[0.545, -0.765],
											[-3.22, -1.565],
											[-1.894, 3.15],
											[0.816, 2.05],
											[0.59, 0.379],
										],
										o: [
											[-0.774, 0.287],
											[-0.42, 0.487],
											[-2.773, 3.889],
											[3.22, 1.565],
											[0.855, -1.422],
											[-0.816, -2.05],
											[-1.747, -1.121],
										],
										v: [
											[2566, 1133],
											[2564.328, 1134.142],
											[2563, 1136],
											[2565.5, 1144.78],
											[2575, 1143],
											[2574.583, 1137.217],
											[2572, 1133],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 29,
								s: [
									{
										i: [
											[1.269, -0.943],
											[0.166, -1.049],
											[-0.588, -0.968],
											[-1.388, -0.408],
											[-1.56, 0.385],
											[-0.498, 0.83],
											[2.743, 0.803],
										],
										o: [
											[-0.875, 0.65],
											[-0.166, 1.049],
											[0.588, 0.968],
											[1.261, 0.37],
											[1.56, -0.385],
											[2.242, -3.733],
											[-2.743, -0.803],
										],
										v: [
											[2574, 1138],
											[2572.427, 1140.669],
											[2573.047, 1143.816],
											[2576, 1146],
											[2580.572, 1145.9],
											[2584, 1144],
											[2581.633, 1137.493],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 30,
								s: [
									{
										i: [
											[0.827, -0.551],
											[-0.372, -0.995],
											[-1.213, -0.486],
											[-0.984, 0.502],
											[0.313, 1.968],
											[0.613, 0.48],
											[0.82, -0.14],
										],
										o: [
											[-1.538, 1.024],
											[0.372, 0.995],
											[1.213, 0.486],
											[0.984, -0.502],
											[-0.208, -1.308],
											[-0.613, -0.48],
											[-0.82, 0.14],
										],
										v: [
											[2584, 1139],
											[2582.518, 1142.148],
											[2585.162, 1144.488],
											[2588.725, 1144.585],
											[2590, 1141],
											[2588.718, 1138.37],
											[2586.519, 1137.912],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[0.269, -0.016],
											[0.712, -4.806],
											[-4.909, -1.683],
											[-3.035, 0.294],
											[-1.748, 6.196],
											[1.997, 3.015],
											[2.834, 0.493],
										],
										o: [
											[-8.613, 0.499],
											[-1.163, 7.852],
											[1.331, 0.456],
											[7.577, -0.734],
											[1.797, -6.37],
											[-2.123, -3.203],
											[-1.032, -0.179],
										],
										v: [
											[2125, 1102],
											[2109, 1114],
											[2117, 1133],
											[2126, 1133],
											[2138, 1123],
											[2137, 1108],
											[2127, 1101],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[2.296, -0.851],
											[0.586, -1.15],
											[-0.231, -1.197],
											[-0.952, -0.699],
											[-1.575, 0.346],
											[-0.469, 1.765],
											[0.941, 1.34],
										],
										o: [
											[-1.5, 0.556],
											[-0.586, 1.15],
											[0.231, 1.197],
											[0.952, 0.699],
											[1.933, -0.425],
											[0.469, -1.765],
											[-0.941, -1.34],
										],
										v: [
											[1786, 1102],
											[1782.895, 1104.696],
											[1782.388, 1108.354],
											[1784.187, 1111.335],
											[1788, 1112],
											[1791.589, 1108.273],
											[1790.869, 1103.174],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[0.039, -0.021],
											[-0.515, -1.115],
											[-1.423, -0.524],
											[-0.678, 0.678],
											[1.352, 1.419],
											[1.06, -0.146],
											[-0.019, -0.244],
										],
										o: [
											[-2.047, 1.094],
											[0.515, 1.115],
											[1.423, 0.524],
											[1.468, -1.468],
											[-1.352, -1.419],
											[-0.037, 0.005],
											[0.019, 0.244],
										],
										v: [
											[2709, 1142],
											[2707.115, 1145.466],
											[2710.435, 1148.077],
											[2714, 1148],
											[2713.396, 1143.289],
											[2709, 1141],
											[2709.001, 1141.488],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[1.438, -0.805],
											[0.095, -0.69],
											[-0.517, -0.554],
											[-0.729, -0.212],
											[-0.539, 0.334],
											[-0.035, 1.009],
											[0.71, 0.576],
										],
										o: [
											[-1.108, 0.62],
											[-0.095, 0.69],
											[0.517, 0.554],
											[0.729, 0.212],
											[0.796, -0.494],
											[0.035, -1.009],
											[-0.71, -0.576],
										],
										v: [
											[2719, 1143],
											[2717.295, 1145.016],
											[2718.029, 1146.932],
											[2719.998, 1148.132],
											[2722, 1148],
											[2723.242, 1145.509],
											[2722.226, 1142.894],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[2.073, -1.116],
											[0.278, -0.178],
											[0.255, -0.256],
											[0.099, -0.325],
											[-0.189, -0.386],
											[-0.987, 0.816],
											[0.017, 1.085],
										],
										o: [
											[-0.169, 0.091],
											[-0.278, 0.178],
											[-0.255, 0.256],
											[-0.099, 0.325],
											[0.94, 1.922],
											[0.987, -0.816],
											[-0.017, -1.085],
										],
										v: [
											[2725, 1143],
											[2724.296, 1143.405],
											[2723.463, 1144.058],
											[2722.899, 1144.931],
											[2723, 1146],
											[2726.154, 1147.041],
											[2727.872, 1143.571],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 24, s: [0], h: 1 },
							{ t: 25, s: [100], h: 1 },
							{ t: 36, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 7',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 25,
								s: [
									{
										i: [
											[3.5, -0.867],
											[1.469, -1.091],
											[0.728, -2.043],
											[0.262, -0.914],
											[-0.284, -0.68],
											[-1.55, -0.979],
											[-0.847, -0.198],
											[-3.762, 2.302],
											[1.293, 4.019],
											[1.511, 1.023],
											[0.716, 0.164],
										],
										o: [
											[-2.395, 0.593],
											[-1.469, 1.091],
											[-0.236, 0.664],
											[-0.262, 0.914],
											[-0.048, -0.115],
											[1.55, 0.979],
											[5.246, 1.229],
											[3.762, -2.302],
											[-0.239, -0.745],
											[-1.511, -1.023],
											[-2.784, -0.638],
										],
										v: [
											[2483, 1170],
											[2477.25, 1172.413],
											[2474, 1177],
											[2473.11, 1179.488],
											[2473, 1182],
											[2475.829, 1183.765],
											[2480, 1186],
											[2494.404, 1183.936],
											[2499, 1174],
											[2495.857, 1171.064],
											[2492, 1169],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 26,
								s: [
									{
										i: [
											[2.159, -1.045],
											[0.717, -1.456],
											[-0.342, -1.471],
											[-0.887, -1.155],
											[-0.921, -0.507],
											[-1.324, -0.354],
											[-1.234, -0.16],
											[-0.473, 0.173],
											[0.062, 3.701],
											[1.751, 1.097],
											[1.961, 0.105],
										],
										o: [
											[-2.287, 1.108],
											[-0.717, 1.456],
											[0.342, 1.471],
											[0.887, 1.155],
											[0.743, 0.409],
											[1.324, 0.354],
											[1.234, 0.16],
											[2.997, -1.099],
											[-0.062, -3.701],
											[-0.997, -0.624],
											[-1.961, -0.105],
										],
										v: [
											[2561, 1169],
											[2556.622, 1172.928],
											[2556.188, 1177.401],
											[2558.16, 1181.424],
											[2561, 1184],
											[2564.268, 1185.179],
											[2568.273, 1185.985],
											[2571, 1186],
											[2575.061, 1177.499],
											[2572, 1169],
											[2567.372, 1167.747],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 27,
								s: [
									{
										i: [
											[0.168, -0.044],
											[1.241, -1.468],
											[0.326, -1.887],
											[-0.611, -1.817],
											[-2.686, -1.066],
											[-1.528, 0.423],
											[-1.265, 2.865],
											[1.13, 2.966],
											[2.131, 1.213],
											[1.23, 0.51],
											[0.328, -0.293],
										],
										o: [
											[-2.133, 0.561],
											[-1.241, 1.468],
											[-0.326, 1.887],
											[0.726, 2.16],
											[2.686, 1.066],
											[2.068, -0.573],
											[1.265, -2.865],
											[-0.244, -0.64],
											[-2.131, -1.213],
											[-0.522, -0.216],
											[-0.327, 0.293],
										],
										v: [
											[2515, 1167],
											[2509.934, 1170.166],
											[2507.578, 1175.321],
											[2508, 1181],
											[2513.899, 1185.937],
											[2521, 1187],
											[2526.399, 1181.295],
											[2527, 1172],
											[2522.74, 1168.902],
											[2517, 1166],
											[2515.734, 1166.305],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 28,
								s: [
									{
										i: [
											[5.182, -0.747],
											[0.828, -0.029],
											[0.77, -0.11],
											[0.627, -0.358],
											[1.475, -3.643],
											[-1.374, -3.584],
											[-1.267, -1.049],
											[-0.739, -0.305],
											[-3.901, 2.039],
											[-0.564, 2.842],
											[2.53, 2.544],
										],
										o: [
											[-0.801, 0.116],
											[-0.828, 0.029],
											[-0.77, 0.11],
											[-1.475, 0.842],
											[-1.475, 3.643],
											[0.191, 0.499],
											[1.267, 1.049],
											[4.833, 1.998],
											[3.901, -2.039],
											[1.303, -6.569],
											[-2.53, -2.544],
										],
										v: [
											[2399, 1147],
											[2396.535, 1147.175],
											[2394.116, 1147.341],
											[2392, 1148],
											[2386.863, 1155.443],
											[2386, 1167],
											[2388.589, 1169.646],
											[2392, 1172],
											[2405.702, 1171.13],
											[2413, 1163],
											[2410.864, 1149.513],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 29,
								s: [
									{
										i: [
											[1.716, -0.65],
											[1.216, -6.871],
											[-4.216, -4.864],
											[-1.154, -0.421],
											[-1.1, 0.181],
											[-2.518, 3.855],
											[2.677, 3.656],
											[1.844, 1.179],
											[0.961, 0.497],
											[1.665, 0.702],
											[1.774, 0.296],
										],
										o: [
											[-6.49, 2.458],
											[-1.667, 9.418],
											[1.185, 1.367],
											[2.519, 0.919],
											[3.795, -0.626],
											[3.76, -5.757],
											[-0.545, -0.745],
											[-1.277, -0.817],
											[-1.206, -0.623],
											[-2.172, -0.916],
											[-4.304, -0.718],
										],
										v: [
											[2250, 1145],
											[2241, 1159],
											[2246, 1175],
											[2251, 1179],
											[2266, 1180],
											[2280, 1171],
											[2281, 1152],
											[2277, 1150],
											[2274, 1147],
											[2269, 1146],
											[2265, 1144],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 30,
								s: [
									{
										i: [
											[5.64, -0.704],
											[2.145, -1.097],
											[1.148, -1.94],
											[0.233, -2.93],
											[-0.562, -2.442],
											[-1.003, -1.047],
											[-0.614, -0.676],
											[-0.784, -0.503],
											[-5.582, 1.653],
											[-1.181, 5.914],
											[4.289, 3.678],
										],
										o: [
											[-3.222, 0.402],
											[-2.145, 1.097],
											[-1.148, 1.94],
											[-0.253, 3.184],
											[0.562, 2.442],
											[0.762, 0.795],
											[0.614, 0.675],
											[5.492, 3.525],
											[5.582, -1.653],
											[1.796, -8.99],
											[-4.289, -3.678],
										],
										v: [
											[2277, 1142],
											[2268.97, 1144.212],
											[2264.051, 1148.732],
											[2262, 1156],
											[2262.558, 1164.602],
											[2265, 1170],
											[2266.983, 1172.219],
											[2269, 1174],
											[2286.733, 1176.579],
											[2298, 1165],
											[2293.078, 1146.229],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[1.774, -0.471],
											[0.686, -0.321],
											[0.457, -0.466],
											[0.168, -0.662],
											[-0.182, -0.91],
											[-1.143, -0.717],
											[-1.396, 0.065],
											[-1.055, 0.874],
											[-0.12, 1.711],
											[0.446, 0.898],
											[1.067, 0.318],
										],
										o: [
											[-0.854, 0.227],
											[-0.686, 0.321],
											[-0.457, 0.466],
											[-0.168, 0.662],
											[0.294, 1.472],
											[1.143, 0.717],
											[1.396, -0.065],
											[1.056, -0.874],
											[0.089, -1.27],
											[-0.446, -0.898],
											[-1.067, -0.318],
										],
										v: [
											[2689, 1138],
											[2686.675, 1138.808],
											[2684.946, 1139.975],
											[2683.994, 1141.654],
											[2684, 1144],
											[2686.304, 1147.289],
											[2690.262, 1148.273],
											[2694.088, 1146.87],
											[2696, 1143],
											[2695.487, 1139.697],
											[2693.24, 1137.822],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[4.635, -1.705],
											[1.436, -2.039],
											[-0.613, -3.787],
											[-3.956, -1.718],
											[-3.548, 0.737],
											[-1.197, 4.452],
											[1.836, 2.559],
											[0.435, -0.052],
											[0.164, 0.074],
											[0.21, 0.466],
											[0.077, 0.1],
										],
										o: [
											[-2.869, 1.055],
											[-1.436, 2.039],
											[0.626, 3.867],
											[3.956, 1.718],
											[3.048, -0.633],
											[1.197, -4.452],
											[-0.097, -0.134],
											[-0.435, 0.052],
											[-0.082, -0.037],
											[-0.21, -0.466],
											[-1.319, -1.717],
										],
										v: [
											[2139, 1108],
											[2132.388, 1112.451],
											[2131, 1121],
											[2138.808, 1129.453],
											[2151, 1131],
											[2157.663, 1121.944],
											[2157, 1110],
											[2156.051, 1109.955],
											[2155, 1110],
											[2154.496, 1109.047],
											[2154, 1108],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[0.032, -0.022],
											[-0.018, -1.13],
											[-0.905, -0.901],
											[-1.185, -0.321],
											[-0.857, 0.61],
											[-0.177, 0.638],
											[-0.066, 0.856],
											[0.144, 0.79],
											[0.453, 0.439],
											[0.878, 0.007],
											[0.677, -0.206],
										],
										o: [
											[-1.477, 1.007],
											[0.018, 1.13],
											[0.905, 0.901],
											[1.185, 0.321],
											[0.19, -0.135],
											[0.177, -0.638],
											[0.066, -0.856],
											[-0.144, -0.79],
											[-0.637, -0.617],
											[-0.878, -0.007],
											[-0.677, 0.206],
										],
										v: [
											[2067, 1148],
											[2064.962, 1151.293],
											[2066.498, 1154.426],
											[2069.785, 1156.346],
											[2073, 1156],
											[2073.576, 1154.769],
											[2073.965, 1152.456],
											[2073.872, 1149.915],
											[2073, 1148],
											[2070.616, 1147.163],
											[2068.173, 1147.56],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[0.026, -0.016],
											[-0.327, -1.001],
											[-1.236, -0.562],
											[-1.167, 0.346],
											[-0.117, 1.72],
											[0.637, 0.551],
											[0.885, 0.268],
											[0.68, 0.083],
											[0.023, -0.003],
											[-0.009, -0.143],
											[-0.009, -0.147],
										],
										o: [
											[-1.563, 0.974],
											[0.327, 1.001],
											[1.236, 0.562],
											[1.167, -0.345],
											[0.064, -0.933],
											[-0.637, -0.551],
											[-0.885, -0.268],
											[-0.68, -0.083],
											[-0.026, 0.004],
											[0.009, 0.143],
											[0.009, 0.147],
										],
										v: [
											[2077, 1146],
											[2075.391, 1149.079],
											[2077.98, 1151.54],
											[2081.829, 1151.981],
											[2084, 1149],
											[2083.027, 1146.799],
											[2080.63, 1145.595],
											[2078.168, 1145.094],
											[2077, 1145],
											[2076.983, 1145.254],
											[2077.018, 1145.722],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[0.033, -0.015],
											[0.085, -0.601],
											[-0.624, -0.438],
											[-0.772, -0.118],
											[-0.36, 0.36],
											[0.006, 0.578],
											[0.265, 0.602],
											[0.501, 0.348],
											[0.714, -0.186],
											[-0.011, -0.143],
											[-0.011, -0.145],
										],
										o: [
											[-1.356, 0.606],
											[-0.085, 0.601],
											[0.624, 0.438],
											[0.772, 0.118],
											[0.276, -0.276],
											[-0.006, -0.578],
											[-0.265, -0.602],
											[-0.501, -0.348],
											[-0.033, 0.009],
											[0.011, 0.143],
											[0.011, 0.145],
										],
										v: [
											[2087, 1146],
											[2084.978, 1147.851],
											[2085.926, 1149.45],
											[2088.161, 1150.324],
											[2090, 1150],
											[2090.399, 1148.649],
											[2089.985, 1146.808],
											[2088.829, 1145.313],
											[2087, 1145],
											[2086.978, 1145.261],
											[2087.022, 1145.727],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 24, s: [0], h: 1 },
							{ t: 25, s: [100], h: 1 },
							{ t: 36, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 8',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 25,
								s: [
									{
										i: [
											[-0.186, 0.078],
											[0.555, -0.626],
											[-0.132, -0.564],
											[-0.59, -0.432],
											[-0.819, -0.229],
											[-0.819, 0.044],
											[-0.59, 0.387],
											[-0.133, 0.801],
											[0.554, 1.286],
											[0.373, 0.247],
											[0.432, 0.048],
											[0.406, -0.068],
											[0.295, -0.102],
											[0.097, -0.053],
										],
										o: [
											[-1.471, 0.618],
											[-0.555, 0.626],
											[0.132, 0.564],
											[0.59, 0.432],
											[0.819, 0.23],
											[0.819, -0.044],
											[0.59, -0.387],
											[0.133, -0.801],
											[-0.227, -0.528],
											[-0.373, -0.247],
											[-0.432, -0.048],
											[-0.406, 0.068],
											[-0.295, 0.102],
											[-0.097, 0.053],
										],
										v: [
											[2458, 1174],
											[2455.017, 1175.883],
											[2454.439, 1177.687],
											[2455.577, 1179.2],
											[2457.747, 1180.21],
											[2460.261, 1180.506],
											[2462.432, 1179.878],
											[2463.574, 1178.113],
											[2463, 1175],
											[2462.078, 1173.858],
											[2460.849, 1173.436],
											[2459.569, 1173.487],
											[2458.497, 1173.763],
											[2457.888, 1174.017],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 26,
								s: [
									{
										i: [
											[0.674, -0.271],
											[0.394, -0.35],
											[0.147, -0.389],
											[-0.062, -0.389],
											[-0.23, -0.352],
											[-0.359, -0.276],
											[-0.449, -0.162],
											[-0.5, -0.01],
											[-0.51, 0.18],
											[-0.308, 0.443],
											[-0.002, 0.535],
											[0.248, 0.509],
											[0.445, 0.366],
											[0.587, 0.106],
										],
										o: [
											[-0.681, 0.273],
											[-0.394, 0.35],
											[-0.147, 0.389],
											[0.062, 0.389],
											[0.23, 0.352],
											[0.359, 0.276],
											[0.449, 0.162],
											[0.5, 0.01],
											[0.668, -0.235],
											[0.308, -0.443],
											[0.002, -0.535],
											[-0.248, -0.509],
											[-0.445, -0.366],
											[-0.587, -0.106],
										],
										v: [
											[2463, 1175],
											[2461.397, 1175.945],
											[2460.595, 1177.062],
											[2460.478, 1178.239],
											[2460.925, 1179.359],
											[2461.819, 1180.31],
											[2463.042, 1180.977],
											[2464.475, 1181.245],
											[2466, 1181],
											[2467.449, 1179.953],
											[2467.901, 1178.457],
											[2467.518, 1176.863],
											[2466.465, 1175.521],
											[2464.905, 1174.783],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 27,
								s: [
									{
										i: [
											[1.651, -0.725],
											[1.004, -1.665],
											[0.105, -1.925],
											[-0.952, -1.548],
											[-2.166, -0.533],
											[-1.949, 0.348],
											[-1.278, 1.082],
											[-0.565, 1.568],
											[0.189, 1.807],
											[0.438, 0.869],
											[0.57, 0.804],
											[0.472, 0.532],
											[0.143, 0.056],
											[1.816, -0.175],
										],
										o: [
											[-1.747, 0.767],
											[-1.004, 1.665],
											[-0.105, 1.925],
											[0.952, 1.548],
											[2.578, 0.634],
											[1.949, -0.348],
											[1.278, -1.082],
											[0.565, -1.568],
											[-0.076, -0.729],
											[-0.438, -0.869],
											[-0.57, -0.804],
											[-0.472, -0.532],
											[-1.085, -0.424],
											[-1.816, 0.175],
										],
										v: [
											[2574, 1170],
											[2569.834, 1173.807],
											[2568.131, 1179.352],
											[2569.363, 1184.72],
											[2574, 1188],
											[2580.8, 1188.368],
											[2585.65, 1186.162],
											[2588.424, 1182.126],
											[2589, 1177],
											[2588.171, 1174.55],
											[2586.6, 1171.989],
											[2584.979, 1169.934],
											[2584, 1169],
											[2579.425, 1168.638],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 28,
								s: [
									{
										i: [
											[1.584, -0.781],
											[0.696, -0.754],
											[0.259, -0.838],
											[-0.106, -0.848],
											[-0.4, -0.783],
											[-0.622, -0.643],
											[-0.772, -0.428],
											[-0.85, -0.138],
											[-0.856, 0.226],
											[-0.715, 0.989],
											[-0.158, 1.297],
											[0.351, 1.275],
											[0.811, 0.921],
											[1.222, 0.235],
										],
										o: [
											[-1.206, 0.595],
											[-0.696, 0.754],
											[-0.259, 0.838],
											[0.106, 0.848],
											[0.4, 0.783],
											[0.622, 0.643],
											[0.772, 0.428],
											[0.85, 0.138],
											[1.321, -0.349],
											[0.715, -0.989],
											[0.158, -1.297],
											[-0.351, -1.275],
											[-0.811, -0.921],
											[-1.222, -0.235],
										],
										v: [
											[2527, 1172],
											[2524.165, 1174.042],
											[2522.75, 1176.449],
											[2522.538, 1178.998],
											[2523.315, 1181.463],
											[2524.865, 1183.62],
											[2526.973, 1185.245],
											[2529.423, 1186.113],
											[2532, 1186],
											[2535.042, 1183.91],
											[2536.338, 1180.397],
											[2536.036, 1176.456],
											[2534.281, 1173.081],
											[2531.22, 1171.264],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 29,
								s: [
									{
										i: [
											[0.537, -0.251],
											[0.761, -1.34],
											[-0.28, -1.438],
											[-1.015, -1.277],
											[-1.443, -0.857],
											[-1.566, -0.179],
											[-1.381, 0.758],
											[-0.528, 0.95],
											[-0.368, 1.298],
											[-0.032, 1.324],
											[0.483, 1.027],
											[1.136, 0.54],
											[1.378, 0.197],
											[1.178, -0.066],
										],
										o: [
											[-2.108, 0.984],
											[-0.761, 1.34],
											[0.28, 1.438],
											[1.015, 1.277],
											[1.443, 0.857],
											[1.566, 0.179],
											[0.509, -0.279],
											[0.528, -0.95],
											[0.368, -1.298],
											[0.032, -1.324],
											[-0.452, -0.962],
											[-1.136, -0.54],
											[-1.378, -0.197],
											[-1.178, 0.066],
										],
										v: [
											[2008, 1150],
											[2003.774, 1153.551],
											[2003.13, 1157.782],
											[2005.149, 1161.918],
											[2008.913, 1165.184],
											[2013.503, 1166.803],
											[2018, 1166],
											[2019.6, 1164.075],
											[2020.988, 1160.622],
											[2021.633, 1156.608],
											[2021, 1153],
											[2018.508, 1150.767],
											[2014.627, 1149.682],
											[2010.683, 1149.505],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 30,
								s: [
									{
										i: [
											[4.193, -1.677],
											[0.878, -0.535],
											[0.523, -0.736],
											[0.133, -1.083],
											[-0.291, -1.575],
											[-0.942, -0.997],
											[-1.302, -0.596],
											[-1.334, -0.175],
											[-1.039, 0.263],
											[-0.751, 0.746],
											[-0.381, 1.078],
											[-0.065, 1.293],
											[0.198, 1.39],
											[1.837, 0.863],
										],
										o: [
											[-1.198, 0.479],
											[-0.878, 0.535],
											[-0.523, 0.736],
											[-0.133, 1.083],
											[0.255, 1.38],
											[0.942, 0.997],
											[1.301, 0.596],
											[1.334, 0.175],
											[1.174, -0.297],
											[0.751, -0.746],
											[0.381, -1.078],
											[0.065, -1.293],
											[-0.461, -3.224],
											[-1.837, -0.863],
										],
										v: [
											[2018, 1149],
											[2014.876, 1150.486],
											[2012.766, 1152.356],
											[2011.773, 1155.049],
											[2012, 1159],
											[2013.877, 1162.571],
											[2017.323, 1164.966],
											[2021.358, 1166.127],
											[2025, 1166],
											[2027.874, 1164.405],
											[2029.559, 1161.639],
											[2030.214, 1158.053],
											[2030, 1154],
											[2026.799, 1147.824],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[0.946, -0.265],
											[0.264, -0.157],
											[0.357, -0.206],
											[2.794, -2.331],
											[0.495, -3.755],
											[-6.142, -3.309],
											[-6.461, 1.034],
											[-1.586, 0.609],
											[-0.479, 0.376],
											[-1.118, 2.591],
											[-0.233, 1.622],
											[4.053, 3.546],
											[4.324, 0.845],
											[2.313, 0.238],
										],
										o: [
											[-0.585, 0.164],
											[-0.264, 0.158],
											[-3.328, 1.919],
											[-2.794, 2.331],
											[-1.028, 7.797],
											[6.142, 3.309],
											[1.177, -0.188],
											[1.586, -0.609],
											[1.295, -1.015],
											[1.118, -2.591],
											[0.622, -4.33],
											[-0.936, -0.819],
											[-2.473, -0.483],
											[-4.805, -0.494],
										],
										v: [
											[1764, 1145],
											[1762.829, 1145.468],
											[1762, 1146],
											[1752.376, 1152.123],
											[1747, 1161],
											[1756.383, 1177.623],
											[1777, 1181],
											[1781.523, 1179.641],
											[1785, 1178],
											[1788.796, 1171.955],
											[1791, 1165],
											[1788, 1147],
											[1781, 1146],
											[1774, 1144],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[0.401, -0.259],
											[0.331, -0.792],
											[-0.32, -0.698],
											[-0.716, -0.518],
											[-0.857, -0.252],
											[-0.743, 0.1],
											[-0.374, 0.537],
											[-0.087, 0.715],
											[0.055, 0.865],
											[0.239, 0.718],
											[0.466, 0.273],
											[0.394, 0.017],
											[0.457, -0.1],
											[0.459, -0.192],
										],
										o: [
											[-1.237, 0.801],
											[-0.331, 0.792],
											[0.32, 0.698],
											[0.716, 0.518],
											[0.857, 0.252],
											[0.743, -0.1],
											[0.186, -0.267],
											[0.087, -0.715],
											[-0.055, -0.865],
											[-0.239, -0.718],
											[-0.271, -0.159],
											[-0.394, -0.017],
											[-0.457, 0.1],
											[-0.459, 0.192],
										],
										v: [
											[2700, 1140],
											[2697.711, 1142.41],
											[2697.757, 1144.666],
											[2699.374, 1146.51],
											[2701.797, 1147.686],
											[2704.26, 1147.935],
											[2706, 1147],
											[2706.42, 1145.453],
											[2706.478, 1143.009],
											[2706.047, 1140.561],
											[2705, 1139],
											[2703.987, 1138.743],
											[2702.695, 1138.873],
											[2701.305, 1139.317],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[5.349, -1.592],
											[0.753, -0.495],
											[1.683, -4.839],
											[-5.352, -2.412],
											[-0.445, -0.221],
											[-0.14, -0.042],
											[-3.993, 1.281],
											[-0.24, 0.18],
											[-0.796, 0.69],
											[1.008, 5.518],
											[2.715, 3.121],
											[0.709, 0.508],
											[0.706, 0.214],
											[0.491, 0.216],
										],
										o: [
											[-1.203, 0.358],
											[-4.523, 2.971],
											[-2.885, 8.293],
											[0.366, 0.165],
											[0.65, 0.322],
											[3.399, 1.035],
											[0.179, -0.057],
											[0.915, -0.687],
											[3.848, -3.335],
											[-0.405, -2.217],
											[-1.705, -1.96],
											[-0.586, -0.42],
											[-0.363, -0.11],
											[-5.496, -2.416],
										],
										v: [
											[1817, 1149],
											[1815, 1150],
											[1805, 1161],
											[1812, 1177],
											[1813, 1179],
											[1816, 1180],
											[1834, 1181],
											[1835, 1179],
											[1838, 1178],
											[1844, 1163],
											[1840, 1158],
											[1836, 1152],
											[1833, 1152],
											[1831, 1150],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[2.759, -0.673],
											[1.986, -1.317],
											[1.443, -1.998],
											[0.486, -2.557],
											[-0.886, -2.994],
											[-0.624, -0.842],
											[-0.812, -0.778],
											[-0.782, -0.547],
											[-0.535, -0.148],
											[-2.595, 0.365],
											[-1.74, 1.061],
											[-1.293, 5.808],
											[3.736, 3.206],
											[1.913, 0.078],
										],
										o: [
											[-2.113, 0.515],
											[-1.986, 1.317],
											[-1.443, 1.998],
											[-0.486, 2.557],
											[0.219, 0.74],
											[0.624, 0.842],
											[0.812, 0.778],
											[0.782, 0.547],
											[1.878, 0.519],
											[2.595, -0.364],
											[2.563, -1.562],
											[1.293, -5.808],
											[-1.588, -1.362],
											[-1.913, -0.078],
										],
										v: [
											[1844, 1150],
											[1837.748, 1152.78],
											[1832.501, 1157.782],
											[1829.504, 1164.644],
											[1830, 1173],
											[1831.318, 1175.415],
											[1833.526, 1177.888],
											[1835.971, 1179.917],
											[1838, 1181],
											[1845.103, 1181.185],
											[1852, 1179],
											[1858.725, 1166.233],
											[1856, 1151],
											[1850.878, 1148.973],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[1.604, -0.434],
											[1.098, -0.664],
											[0.702, -0.812],
											[0.378, -0.867],
											[0.126, -0.83],
											[-0.864, -2.052],
											[-1.702, -1.286],
											[-2.112, -0.341],
											[-2.094, 0.784],
											[-1.345, 3.3],
											[0.777, 3.278],
											[1.029, 1.456],
											[0.666, 0.253],
											[1.549, -0.255],
										],
										o: [
											[-1.566, 0.424],
											[-1.098, 0.664],
											[-0.702, 0.812],
											[-0.378, 0.867],
											[-0.401, 2.639],
											[0.864, 2.052],
											[1.702, 1.286],
											[2.112, 0.341],
											[1.396, -0.523],
											[1.345, -3.3],
											[-0.219, -0.923],
											[-1.029, -1.456],
											[-1.201, -0.456],
											[-1.549, 0.256],
										],
										v: [
											[1858, 1158],
											[1854.022, 1159.655],
											[1851.34, 1161.891],
											[1849.738, 1164.432],
											[1849, 1167],
											[1849.802, 1174.083],
											[1853.758, 1179.135],
											[1859.585, 1181.62],
											[1866, 1181],
											[1870.629, 1174.566],
											[1872, 1164],
											[1869.835, 1159.997],
											[1867, 1157],
											[1862.802, 1156.833],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 24, s: [0], h: 1 },
							{ t: 25, s: [100], h: 1 },
							{ t: 36, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 9',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 26,
								s: [
									{
										i: [
											[2.3, -0.581],
											[0.528, -0.057],
											[0.387, -0.023],
											[0.29, -0.08],
											[0.238, -0.227],
											[-1.11, -1.734],
											[-3.035, -0.399],
											[-2.658, 1.034],
											[0.021, 2.565],
											[2.668, 0.553],
										],
										o: [
											[-0.713, 0.18],
											[-0.528, 0.057],
											[-0.387, 0.023],
											[-0.29, 0.08],
											[-3.118, 2.97],
											[1.11, 1.734],
											[3.035, 0.399],
											[2.658, -1.034],
											[-0.028, -3.37],
											[-2.668, -0.553],
										],
										v: [
											[2413, 1177],
											[2411.149, 1177.332],
											[2409.787, 1177.43],
											[2408.782, 1177.562],
											[2408, 1178],
											[2405.563, 1185.08],
											[2412.355, 1188.303],
											[2421.47, 1187.375],
											[2426, 1182],
											[2421.203, 1176.537],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 27,
								s: [
									{
										i: [
											[0.547, 0.037],
											[0.242, -0.356],
											[0.072, -0.351],
											[-0.104, -0.318],
											[-0.284, -0.259],
											[-0.469, -0.172],
											[-0.659, -0.059],
											[0.01, 0.574],
											[0.122, 0.461],
											[0.302, 0.282],
										],
										o: [
											[-0.408, 0.334],
											[-0.242, 0.356],
											[-0.072, 0.351],
											[0.104, 0.318],
											[0.284, 0.259],
											[0.469, 0.172],
											[0.037, -0.62],
											[-0.01, -0.574],
											[-0.123, -0.461],
											[-0.301, -0.282],
										],
										v: [
											[2469, 1175],
											[2468.024, 1176.042],
											[2467.552, 1177.109],
											[2467.599, 1178.12],
											[2468.18, 1178.993],
											[2469.309, 1179.647],
											[2471, 1180],
											[2471.057, 1178.193],
											[2470.875, 1176.625],
											[2470.256, 1175.494],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 28,
								s: [
									{
										i: [
											[1.359, -0.69],
											[0.259, -1.554],
											[-1.22, -1.272],
											[-1.869, -0.457],
											[-1.688, 0.89],
											[-0.437, 0.922],
											[-0.097, 1.052],
											[0.138, 0.854],
											[0.269, 0.328],
											[1.991, 0.216],
										],
										o: [
											[-2.568, 1.303],
											[-0.259, 1.554],
											[1.22, 1.272],
											[1.869, 0.457],
											[0.881, -0.465],
											[0.437, -0.922],
											[0.097, -1.052],
											[-0.138, -0.854],
											[-0.676, -0.824],
											[-1.991, -0.216],
										],
										v: [
											[2588, 1175],
											[2583.967, 1179.419],
											[2585.616, 1183.79],
											[2590.457, 1186.516],
											[2596, 1186],
											[2597.95, 1183.838],
											[2598.724, 1180.795],
											[2598.636, 1177.854],
											[2598, 1176],
											[2593.512, 1174.364],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 29,
								s: [
									{
										i: [
											[3.251, -0.85],
											[1.045, -0.447],
											[0.738, -0.678],
											[0.324, -1.017],
											[-0.198, -1.464],
											[-2.667, -1.308],
											[-2.014, 0.586],
											[-0.86, 3.146],
											[1.329, 1.575],
											[1.556, 0.306],
										],
										o: [
											[-1.246, 0.325],
											[-1.045, 0.447],
											[-0.738, 0.678],
											[-0.324, 1.017],
											[0.318, 2.355],
											[2.667, 1.308],
											[2.89, -0.84],
											[0.86, -3.146],
											[-0.527, -0.624],
											[-1.556, -0.306],
										],
										v: [
											[2422, 1152],
											[2418.537, 1153.132],
											[2415.835, 1154.792],
											[2414.216, 1157.306],
											[2414, 1161],
											[2419.228, 1166.705],
											[2427, 1168],
											[2432.664, 1161.051],
											[2432, 1153],
											[2429.043, 1151.394],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 30,
								s: [
									{
										i: [
											[0.302, -0.146],
											[-0.089, -1.276],
											[-1.378, -0.88],
											[-1.6, 0.085],
											[-0.756, 1.621],
											[0.307, 0.764],
											[0.673, 0.688],
											[0.779, 0.456],
											[0.626, 0.066],
											[0.267, -0.23],
										],
										o: [
											[-2.267, 1.101],
											[0.089, 1.276],
											[1.378, 0.88],
											[1.6, -0.085],
											[0.318, -0.681],
											[-0.307, -0.764],
											[-0.673, -0.688],
											[-0.779, -0.456],
											[-0.628, -0.066],
											[-0.268, 0.23],
										],
										v: [
											[2435, 1155],
											[2431.999, 1158.707],
											[2434.466, 1162.083],
											[2439.199, 1163.418],
											[2443, 1161],
											[2442.95, 1158.793],
											[2441.415, 1156.576],
											[2439.172, 1154.821],
											[2437, 1154],
											[2435.756, 1154.341],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[3.099, -0.685],
											[1.918, -1.375],
											[0.938, -1.974],
											[0.175, -2.824],
											[-0.556, -0.904],
											[-0.648, -1.203],
											[-5.939, 1.229],
											[-1.867, 1.335],
											[2.468, 8.578],
											[0.798, 0.335],
										],
										o: [
											[-2.39, 0.528],
											[-1.918, 1.375],
											[-0.856, 1.801],
											[-0.175, 2.824],
											[0.475, 0.772],
											[2.846, 5.284],
											[0.32, -0.066],
											[4.887, -3.495],
											[-0.462, -1.606],
											[-2.541, -1.066],
										],
										v: [
											[2299, 1149],
											[2292.411, 1151.916],
											[2288, 1157],
											[2286.44, 1164.673],
											[2287, 1171],
											[2288, 1174],
											[2306, 1181],
											[2311, 1178],
											[2318, 1158],
											[2310, 1149],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[3.283, -0.79],
											[1.223, -0.37],
											[0.917, -1.697],
											[0.43, -1.183],
											[-0.614, -3.418],
											[-10.57, 2.584],
											[-0.702, 0.526],
											[-1.106, 4.059],
											[5.141, 2.318],
											[0.474, 0.194],
										],
										o: [
											[-1.245, 0.299],
											[-2.82, 0.853],
											[-0.623, 1.153],
											[-0.874, 2.402],
											[1.554, 8.652],
											[1.044, -0.255],
											[3.236, -2.422],
											[2.317, -8.5],
											[-0.377, -0.17],
											[-4.503, -1.843],
										],
										v: [
											[1790, 1148],
											[1786, 1148],
											[1780, 1154],
											[1778, 1157],
											[1777, 1165],
											[1807, 1178],
											[1814, 1173],
											[1819, 1165],
											[1813, 1150],
											[1812, 1148],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[10.48, -2.686],
											[0.566, 0.074],
											[0.345, -0.198],
											[1.283, -1.35],
											[0.065, -0.14],
											[0.262, -2.442],
											[-0.444, -1.381],
											[-4.074, -1.284],
											[-2.849, 1.791],
											[-0.439, 2.042],
										],
										o: [
											[-0.391, 0.1],
											[-0.566, -0.074],
											[-0.802, 0.461],
											[-1.283, 1.35],
											[-0.395, 0.85],
											[-0.262, 2.442],
											[0.853, 2.656],
											[4.075, 1.284],
											[0.635, -0.399],
											[2.741, -12.758],
										],
										v: [
											[2342, 1155],
											[2340.466, 1154.927],
											[2339, 1155],
											[2335.448, 1158.24],
											[2333, 1161],
											[2331.871, 1166.601],
											[2332, 1173],
											[2340.503, 1179.335],
											[2352, 1179],
											[2356, 1172],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[2.892, -1.157],
											[0.473, -0.087],
											[0.454, -0.39],
											[0.565, -2.871],
											[-1.654, -2.319],
											[-3.573, -0.453],
											[-1.698, 1.226],
											[-0.151, 0.242],
											[1.089, 3.34],
											[2.08, 0.516],
										],
										o: [
											[-0.653, 0.261],
											[-0.473, 0.087],
											[-1.394, 1.198],
											[-0.565, 2.871],
											[1.008, 1.414],
											[3.573, 0.453],
											[0.23, -0.166],
											[1.238, -1.991],
											[-0.45, -1.381],
											[-2.069, -0.513],
										],
										v: [
											[2356, 1160],
											[2354.35, 1160.403],
											[2353, 1161],
											[2349.714, 1167.659],
											[2351, 1176],
											[2358.982, 1178.98],
											[2368, 1178],
											[2369, 1175],
											[2371, 1165],
											[2364, 1159],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[0.864, -0.466],
											[0.661, -0.673],
											[0.308, -0.761],
											[-0.007, -0.833],
											[-0.284, -0.889],
											[-3.257, -0.186],
											[-1.053, 1.612],
											[0.621, 2.493],
											[1.437, 0.615],
											[1.48, -0.263],
										],
										o: [
											[-1.053, 0.569],
											[-0.661, 0.672],
											[-0.308, 0.761],
											[0.007, 0.833],
											[0.918, 2.87],
											[3.257, 0.186],
											[0.922, -1.412],
											[-0.621, -2.493],
											[-1.214, -0.519],
											[-1.481, 0.263],
										],
										v: [
											[2372, 1165],
											[2369.438, 1166.866],
											[2367.994, 1169.019],
											[2367.553, 1171.413],
											[2368, 1174],
											[2375.398, 1178.362],
											[2383, 1176],
											[2383.27, 1169.402],
											[2380, 1164],
											[2375.738, 1163.761],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 25, s: [0], h: 1 },
							{ t: 26, s: [100], h: 1 },
							{ t: 36, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 10',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 27,
								s: [
									{
										i: [
											[4.102, -1.448],
											[1.139, -1.184],
											[-0.925, -1.781],
											[-3.238, 0.441],
											[-0.039, 3.18],
										],
										o: [
											[-1.369, 0.483],
											[-1.139, 1.184],
											[1.01, 1.944],
											[3.238, -0.441],
											[0.06, -4.995],
										],
										v: [
											[2425, 1180],
											[2420.78, 1182.526],
											[2420, 1187],
											[2427.729, 1189.343],
											[2434, 1184],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 28,
								s: [
									{
										i: [
											[1.948, -1.16],
											[0.23, -0.636],
											[-0.27, -0.651],
											[-2.062, 0.72],
											[1.948, 2.806],
										],
										o: [
											[-1.35, 0.804],
											[-0.23, 0.636],
											[0.699, 1.688],
											[2.062, -0.72],
											[-1.395, -2.009],
										],
										v: [
											[2434, 1181],
											[2431.785, 1183.115],
											[2432, 1185],
											[2437.485, 1186.37],
											[2439, 1181],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 29,
								s: [
									{
										i: [
											[2.559, -1.01],
											[0.672, -0.891],
											[0.116, -0.965],
											[-2.867, 1.964],
											[0.62, 1.503],
										],
										o: [
											[-1.197, 0.472],
											[-0.672, 0.891],
											[-0.574, 4.783],
											[1.235, -0.846],
											[-0.834, -2.022],
										],
										v: [
											[2537, 1176],
											[2534.189, 1178.131],
											[2533, 1181],
											[2543, 1186],
											[2544, 1178],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 30,
								s: [
									{
										i: [
											[1.947, -1.16],
											[0.23, -0.636],
											[-0.27, -0.651],
											[-2.062, 0.72],
											[1.948, 2.807],
										],
										o: [
											[-1.35, 0.804],
											[-0.23, 0.636],
											[0.699, 1.688],
											[2.062, -0.72],
											[-1.395, -2.01],
										],
										v: [
											[2544, 1179],
											[2541.785, 1181.114],
											[2542, 1183],
											[2547.485, 1184.371],
											[2549, 1179],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[4.472, -1.827],
											[0.695, -1.443],
											[-1.064, -2.768],
											[-2.523, 0.969],
											[4.32, 4.32],
										],
										o: [
											[-2.418, 0.988],
											[-0.531, 1.103],
											[0.662, 1.722],
											[3.191, -1.225],
											[-1.066, -1.066],
										],
										v: [
											[2034, 1150],
											[2029, 1152],
											[2029, 1159],
											[2040, 1163],
											[2043, 1150],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[4.249, -2.279],
											[0.543, -0.054],
											[0.293, -0.495],
											[-2.923, -1.26],
											[-1.418, 2.615],
										],
										o: [
											[-0.534, 0.287],
											[-0.543, 0.054],
											[-2.11, 3.577],
											[2.923, 1.26],
											[3.158, -5.827],
										],
										v: [
											[2045, 1152],
											[2043.319, 1152.343],
											[2042, 1153],
											[2044.854, 1160.644],
											[2053, 1159],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[1.244, -0.665],
											[-0.203, -1.031],
											[-0.986, -0.599],
											[-0.723, 0.425],
											[0.824, 1.44],
										],
										o: [
											[-1.625, 0.87],
											[0.203, 1.031],
											[0.986, 0.599],
											[1.539, -0.905],
											[-0.824, -1.44],
										],
										v: [
											[2459, 1160],
											[2457.129, 1162.998],
											[2459.175, 1165.591],
											[2462, 1166],
											[2462.586, 1161.823],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[2.252, -0.789],
											[0.332, -0.215],
											[0.24, -0.277],
											[-0.107, -0.245],
											[-0.813, 1.27],
										],
										o: [
											[-0.169, 0.059],
											[-0.332, 0.215],
											[-0.24, 0.277],
											[0.999, 2.289],
											[0.813, -1.27],
										],
										v: [
											[2468, 1162],
											[2467.185, 1162.434],
											[2466.263, 1163.194],
											[2466, 1164],
											[2469.438, 1164.125],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[3.441, -1.014],
											[0.539, -0.412],
											[0.287, -0.546],
											[-0.284, -0.542],
											[-1.215, 2.007],
										],
										o: [
											[-0.471, 0.139],
											[-0.539, 0.412],
											[-0.287, 0.546],
											[1.58, 3.013],
											[1.215, -2.007],
										],
										v: [
											[1676, 1169],
											[1674.405, 1169.861],
											[1673.085, 1171.333],
											[1673, 1173],
											[1678.265, 1172.499],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 26, s: [0], h: 1 },
							{ t: 27, s: [100], h: 1 },
							{ t: 36, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 11',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 29,
								s: [
									{
										i: [
											[0.111, -0.068],
											[-1.879, -1.305],
											[-1.238, 1.537],
											[0.628, 1.429],
											[1.177, 0.428],
										],
										o: [
											[-3.755, 2.317],
											[1.879, 1.305],
											[0.69, -0.856],
											[-0.628, -1.429],
											[0.189, 0.069],
										],
										v: [
											[2598, 1179],
											[2596.755, 1184.891],
											[2603, 1185],
											[2602.9, 1181.178],
											[2600, 1178],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 30,
								s: [
									{
										i: [
											[2.252, -0.789],
											[0.332, -0.215],
											[0.24, -0.277],
											[-0.107, -0.245],
											[-0.813, 1.27],
										],
										o: [
											[-0.169, 0.059],
											[-0.332, 0.215],
											[-0.24, 0.277],
											[0.999, 2.289],
											[0.813, -1.27],
										],
										v: [
											[2605, 1181],
											[2604.185, 1181.434],
											[2603.263, 1182.194],
											[2603, 1183],
											[2606.438, 1183.125],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[0.573, -0.33],
											[-1.732, -1.617],
											[-1.358, 1.358],
											[0.396, 1.785],
											[0.94, 0.338],
										],
										o: [
											[-3.287, 1.889],
											[1.732, 1.617],
											[0.524, -0.524],
											[-0.396, -1.785],
											[-1.477, -0.531],
										],
										v: [
											[2444, 1157],
											[2443.017, 1162.935],
											[2449, 1164],
											[2449.098, 1159.861],
											[2447, 1156],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[10.007, -2.332],
											[1.329, -3.394],
											[0.022, 0.067],
											[-4.635, 4.442],
											[-0.578, 3.658],
										],
										o: [
											[-4.442, 1.035],
											[-0.927, 2.367],
											[2.135, 6.547],
											[1.771, -1.697],
											[2.123, -13.436],
										],
										v: [
											[2319, 1154],
											[2308, 1162],
											[2307, 1172],
											[2332, 1176],
											[2336, 1169],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[3.125, -2.043],
											[-3.458, -2.81],
											[-2.744, 1.885],
											[-0.42, 1.197],
											[-0.12, 1.073],
										],
										o: [
											[-5.575, 3.646],
											[3.458, 2.81],
											[0.2, -0.138],
											[0.42, -1.197],
											[0.952, -8.521],
										],
										v: [
											[1718, 1163],
											[1717.26, 1173.648],
											[1729, 1176],
											[1730.06, 1173.702],
											[1731, 1170],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[5.434, -4.352],
											[0.315, -1.946],
											[-0.563, -1.171],
											[-3.038, 0.548],
											[-0.531, 1.541],
										],
										o: [
											[-1.303, 1.043],
											[-0.315, 1.946],
											[1.316, 2.734],
											[3.038, -0.548],
											[2.424, -7.033],
										],
										v: [
											[1731, 1167],
											[1728.6, 1171.904],
											[1729, 1177],
											[1736.589, 1179.706],
											[1743, 1176],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[0.708, -0.401],
											[-2.196, -2.614],
											[-2.522, 1.899],
											[1.328, 2.574],
											[2.146, -0.148],
										],
										o: [
											[-4.307, 2.442],
											[2.196, 2.614],
											[2.457, -1.85],
											[-1.328, -2.575],
											[-1.298, 0.089],
										],
										v: [
											[1742, 1169],
											[1740.378, 1177.756],
											[1749, 1180],
											[1749.952, 1172.502],
											[1744, 1168],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 28, s: [0], h: 1 },
							{ t: 29, s: [100], h: 1 },
							{ t: 36, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 12',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 29,
								s: [
									{
										i: [
											[1.896, -1.896],
											[0.17, -0.534],
											[-0.151, -0.441],
											[-0.511, -0.177],
											[0.071, 1.662],
										],
										o: [
											[-0.453, 0.453],
											[-0.17, 0.534],
											[0.151, 0.441],
											[2.18, 0.754],
											[-0.071, -1.662],
										],
										v: [
											[2442, 1182],
											[2441.055, 1183.524],
											[2441.016, 1185.03],
											[2442, 1186],
											[2445.057, 1183.144],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 31,
								s: [
									{
										i: [
											[1.765, -0.928],
											[-0.091, -0.864],
											[-0.811, -0.47],
											[-0.616, 0.37],
											[0.314, 1.329],
										],
										o: [
											[-1.543, 0.811],
											[0.091, 0.864],
											[0.811, 0.47],
											[1.024, -0.614],
											[-0.314, -1.328],
										],
										v: [
											[2551, 1180],
											[2549.051, 1182.625],
											[2550.631, 1184.737],
											[2553, 1185],
											[2554.092, 1181.343],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 32,
								s: [
									{
										i: [
											[0.505, 0.028],
											[-0.516, -1.877],
											[-2.673, 0.304],
											[-0.444, 1.479],
											[0.595, 0.992],
										],
										o: [
											[-3.738, -0.205],
											[0.516, 1.877],
											[0.818, -0.093],
											[0.444, -1.479],
											[-0.934, -1.558],
										],
										v: [
											[2453, 1158],
											[2448.691, 1161.575],
											[2454, 1165],
											[2456.059, 1162.174],
											[2456, 1158],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[7.043, -3.4],
											[0.24, -0.15],
											[-2.239, -4.585],
											[-1.313, -0.325],
											[5.172, 6.34],
										],
										o: [
											[-0.257, 0.124],
											[-2.399, 1.498],
											[0.316, 0.646],
											[14.449, 3.573],
											[-2.121, -2.6],
										],
										v: [
											[1589, 1165],
											[1588, 1165],
											[1584, 1180],
											[1590, 1184],
											[1601, 1167],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[5.791, -2.419],
											[1.21, -2.011],
											[-0.561, -2.238],
											[-5.157, 2.245],
											[-0.247, 2.255],
										],
										o: [
											[-1.722, 0.719],
											[-1.21, 2.011],
											[1.168, 4.661],
											[2.032, -0.885],
											[0.918, -8.397],
										],
										v: [
											[1602, 1168],
											[1597.287, 1172.361],
											[1596, 1179],
											[1613, 1185],
											[1617, 1178],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 35,
								s: [
									{
										i: [
											[3.988, -1.63],
											[1.044, -1.347],
											[-1.148, -2.216],
											[-2.783, 1.761],
											[1.758, 3.101],
										],
										o: [
											[-0.973, 0.398],
											[-1.044, 1.347],
											[1.628, 3.144],
											[2.783, -1.761],
											[-1.22, -2.152],
										],
										v: [
											[1615, 1173],
											[1611.41, 1175.636],
											[1611, 1181],
											[1619.039, 1182.183],
											[1622, 1174],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 28, s: [0], h: 1 },
							{ t: 29, s: [100], h: 1 },
							{ t: 30, s: [0], h: 1 },
							{ t: 31, s: [100], h: 1 },
							{ t: 36, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 13',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 1,
						k: [
							{
								t: 32,
								s: [
									{
										i: [
											[9.26, -5.277],
											[0.442, -1.094],
											[-6.62, 2.497],
											[-0.945, 1.319],
										],
										o: [
											[-0.64, 0.365],
											[-3.796, 9.4],
											[1.052, -0.396],
											[5.983, -8.349],
										],
										v: [
											[1700, 1161],
											[1696, 1164],
											[1710, 1177],
											[1713, 1174],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 33,
								s: [
									{
										i: [
											[6.118, -3.674],
											[-0.413, -1.358],
											[-0.789, -0.25],
											[-0.337, 2.547],
										],
										o: [
											[-2.759, 1.657],
											[0.747, 2.458],
											[3.848, 1.22],
											[0.724, -5.47],
										],
										v: [
											[1659, 1166],
											[1656, 1173],
											[1661, 1176],
											[1669, 1174],
										],
										c: true,
									},
								],
								h: 1,
							},
							{
								t: 34,
								s: [
									{
										i: [
											[0.692, -0.297],
											[-0.881, -2.078],
											[-0.652, 0.899],
											[1.784, 0.689],
										],
										o: [
											[-3.256, 1.399],
											[1.278, 3.013],
											[1.252, -1.726],
											[-1.766, -0.682],
										],
										v: [
											[1668, 1168],
											[1665, 1174],
											[1674, 1175],
											[1672, 1167],
										],
										c: true,
									},
								],
								h: 1,
							},
						],
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 31, s: [0], h: 1 },
							{ t: 32, s: [100], h: 1 },
							{ t: 35, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 14',
				},
				{
					inv: false,
					mode: 'a',
					pt: {
						a: 0,
						k: {
							i: [
								[4.484, -1.138],
								[-0.615, -4.994],
								[-1.379, 5.418],
								[1.015, 0.854],
							],
							o: [
								[-3.853, 0.978],
								[1.146, 9.302],
								[0.653, -2.564],
								[-2.191, -1.845],
							],
							v: [
								[1644, 1164],
								[1636, 1172],
								[1657, 1173],
								[1654, 1165],
							],
							c: true,
						},
						ix: 1,
					},
					o: {
						a: 1,
						k: [
							{ t: 31, s: [0], h: 1 },
							{ t: 32, s: [100], h: 1 },
							{ t: 33, s: [0], h: 1 },
						],
						ix: 3,
					},
					x: { a: 0, k: 0, ix: 4 },
					nm: 'Mask 15',
				},
			],
			ef: [
				{
					ty: 21,
					nm: 'Fill',
					np: 9,
					mn: 'ADBE Fill',
					ix: 1,
					en: 1,
					ef: [
						{ ty: 10, nm: 'Fill Mask', mn: 'ADBE Fill-0001', ix: 1, v: { a: 0, k: 0, ix: 1 } },
						{ ty: 7, nm: 'All Masks', mn: 'ADBE Fill-0007', ix: 2, v: { a: 0, k: 0, ix: 2 } },
						{ ty: 2, nm: 'Color', mn: 'ADBE Fill-0002', ix: 3, v: { a: 0, k: [0.090196080506, 0.168627455831, 0.301960796118, 1], ix: 3 } },
						{ ty: 7, nm: 'Invert', mn: 'ADBE Fill-0006', ix: 4, v: { a: 0, k: 0, ix: 4 } },
						{ ty: 0, nm: 'Horizontal Feather', mn: 'ADBE Fill-0003', ix: 5, v: { a: 0, k: 0, ix: 5 } },
						{ ty: 0, nm: 'Vertical Feather', mn: 'ADBE Fill-0004', ix: 6, v: { a: 0, k: 0, ix: 6 } },
						{ ty: 0, nm: 'Opacity', mn: 'ADBE Fill-0005', ix: 7, v: { a: 0, k: 1, ix: 7 } },
					],
				},
			],
			sw: 3840,
			sh: 2160,
			sc: '#ece8e5',
			ip: 19,
			op: 40,
			st: 0,
			bm: 0,
		},
		{ ddd: 0, ind: 3, ty: 0, nm: 'V1_pre', refId: 'comp_0', sr: 1, ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 540, 0], ix: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 } }, ao: 0, tm: { a: 1, k: [{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, n: ['0p833_0p833_0p167_0p167'], t: 79, s: [3.12], e: [0] }, { t: 158 }], ix: 2 }, w: 1920, h: 1080, ip: 79, op: 158, st: 79, bm: 0 },
		{
			ddd: 0,
			ind: 4,
			ty: 4,
			nm: 'Shape Layer 2',
			sr: 1,
			ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 540, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 } },
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
									],
									o: [
										[0, 0],
										[0, 0],
									],
									v: [
										[-290, 23],
										[253, 23],
									],
									c: false,
								},
								ix: 2,
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false,
						},
						{ ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 4, ix: 5 }, lc: 1, lj: 1, ml: 4, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
						{ ty: 'fl', c: { a: 0, k: [0.933333333333, 0.921568627451, 0.909803921569, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
						{ ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false,
				},
				{ ty: 'tm', s: { a: 1, k: [{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, n: ['0p833_0p833_0p167_0p167'], t: 127, s: [100], e: [0] }, { t: 140 }], ix: 1 }, e: { a: 1, k: [{ i: { x: [0.1], y: [1] }, o: { x: [0.9], y: [0] }, n: ['0p1_1_0p9_0'], t: 113, s: [100], e: [0] }, { t: 133 }], ix: 2 }, o: { a: 0, k: 0, ix: 3 }, m: 1, ix: 2, nm: 'Trim Paths 1', mn: 'ADBE Vector Filter - Trim', hd: false },
			],
			ip: 113,
			op: 141,
			st: 95,
			bm: 0,
		},
		{ ddd: 0, ind: 5, ty: 0, nm: 'V1_pre', refId: 'comp_0', sr: 1, ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 540, 0], ix: 2 }, a: { a: 0, k: [960, 540, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 } }, ao: 0, w: 1920, h: 1080, ip: 0, op: 79, st: 0, bm: 0 },
		{
			ddd: 0,
			ind: 6,
			ty: 4,
			nm: 'Shape Layer 1',
			sr: 1,
			ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [960, 540, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [200, 200, 100], ix: 6 } },
			ao: 0,
			shapes: [
				{
					ty: 'gr',
					it: [
						{
							ind: 0,
							ty: 'sh',
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
									],
									o: [
										[0, 0],
										[0, 0],
									],
									v: [
										[-290, 23],
										[253, 23],
									],
									c: false,
								},
								ix: 2,
							},
							nm: 'Path 1',
							mn: 'ADBE Vector Shape - Group',
							hd: false,
						},
						{ ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 4, ix: 5 }, lc: 1, lj: 1, ml: 4, nm: 'Stroke 1', mn: 'ADBE Vector Graphic - Stroke', hd: false },
						{ ty: 'fl', c: { a: 0, k: [0.933333333333, 0.921568627451, 0.909803921569, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
						{ ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transform' },
					],
					nm: 'Shape 1',
					np: 3,
					cix: 2,
					ix: 1,
					mn: 'ADBE Vector Group',
					hd: false,
				},
				{ ty: 'tm', s: { a: 1, k: [{ i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, n: ['0p833_0p833_0p167_0p167'], t: 32, s: [0], e: [100] }, { t: 45 }], ix: 1 }, e: { a: 1, k: [{ i: { x: [0.1], y: [1] }, o: { x: [0.9], y: [0] }, n: ['0p1_1_0p9_0'], t: 18, s: [0], e: [100] }, { t: 38 }], ix: 2 }, o: { a: 0, k: 0, ix: 3 }, m: 1, ix: 2, nm: 'Trim Paths 1', mn: 'ADBE Vector Filter - Trim', hd: false },
			],
			ip: 18,
			op: 46,
			st: 0,
			bm: 0,
		},
	],
	markers: [],
};
