import './assets/main.css';

import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import * as Sentry from "@sentry/vue";
import { createHead } from 'unhead';

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://4a06b1ac5be8cf34bd8bcfaaa76608f3@o313055.ingest.us.sentry.io/4507311267053568",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const head = createHead();
// @ts-ignore
app.use(head);
app.use(router);
app.mount('#app');
