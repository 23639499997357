<template>
  <header>
    <h2>
      Publicly available research publications<br />
      Co-published with <strong>UBS</strong>, <strong>Generali</strong> and
      <strong>Deloitte</strong>
    </h2>
  </header>
  <div class="publications-wrapper">
    <a href="/publications/A_Survey_of_Global_Collecting_in_2022.pdf">
      <picture>
        <source srcset="/publications/image.webp" type="image/webp">
        <source srcset="/publications/image.png" type="image/jpeg"> 
        <img
          src="/publications/image.png"
          alt="Cover of publication: A Survey of Global Collecting in 2022. An Art Bassel & UBS Report"
          loading="lazy"
          decoding="async"
        />
      </picture>
    </a>
    <a href="/publications/The Role of Cities in the US Art Ecosystem.pdf">
      <picture>
        <source srcset="/publications/image_1.webp" type="image/webp">
        <source srcset="/publications/image_1.png" type="image/jpeg">
        <img
          src="/publications/image_1.png"
          alt="A publication The Role of Cities in the US Art Echosystem from UBS"
          loading="lazy"
          decoding="async"
        />
      </picture>
    </a>
    <a href="/publications/Extract_Report-visibilita-arte-italiana-stampa 2022.pdf">
      <picture>
        <source srcset="/publications/image_2.webp" type="image/webp">
        <source srcset="/publications/image_2.png" type="image/jpeg">
        <img
          src="/publications/image_2.png"
          alt="A publication about the role of Museums, commercial galleries and non profit in building a thriving art ecosystem in Italy and especially Milan"
          loading="lazy"
          decoding="async"
        />
      </picture>
    </a>
    <a href="/publications/RAPPORT-Art-Market-Day_BAT_.pdf">
      <picture>
        <source srcset="/publications/image_3.webp" type="image/webp">
        <source srcset="/publications/image_3.png" type="image/jpeg">
        <img 
          src="/publications/image_3.png"
          alt="A publication about the role of Museums, commercial galleries and non profit in building a thriving art ecosystem in Paris, France"
          loading="lazy"
          decoding="async"
        />
      </picture>
    </a>
    <a href="/publications/Extract_Deloitte-art-finance-report-2021.pdf">
      <picture>
        <source srcset="/publications/image_4.webp" type="image/webp">
        <source srcset="/publications/image_4.png" type="image/jpeg">
        <img
          src="/publications/image_4.png"
          alt="A publication about the importance of hundreds of cities from around the world in the global art ecosystem"
          loading="lazy"
          decoding="async"
        />
      </picture>
    </a>
  </div>
</template>

<style scoped>
.publications-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: calc(1vh + 1vw + 1rem);
}

a {
  display: inline-block;
}

img {
  max-height: 205px;
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

h2 {
  font-weight: 400;
  margin: 0 0 calc(1vh + 1vw + 1rem) 0;
  text-wrap: balance;
}
</style>
